import React, { createContext } from 'react';

export interface DiseaseSettingsOptionsContextType {
  diseaseSettingsOptions: { [key: string]: any };
  setDiseaseSettingsOptions: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
}

export const DiseaseSettingsOptionsContext = createContext<DiseaseSettingsOptionsContextType>({
  diseaseSettingsOptions: { '1': {}, '3': {}, '4': {}, '5': {}, '6': {}, '7': {} },
  setDiseaseSettingsOptions: () => {

  }
});
