import React, { useEffect, useRef } from 'react';
import * as Plotly from 'plotly.js';
import { Data, Layout, MapboxCenter, PlotRelayoutEvent, Shape } from 'plotly.js';
import { MAPBOX_ACCESS_TOKEN, PLOTLY_TEMPLATE } from '../../../../pages/static';

type Props = {
  latitude: number;
  longitude: number;
  zoom: number;
  style: string;
  shapes: Array<Partial<Shape>>;
  setOuterCoordinates?: Function;
};

export const ScatterMapbox: React.FC<Props> = (props) => {
  const chartRef = useRef<HTMLDivElement>(null);

  const data: Data[] = [
    {
      type: 'scattermapbox',
      lat: [props.latitude],
      lon: [props.longitude],
      mode: 'markers',
      marker: props.style === 'mapbox://styles/mapbox/satellite-streets-v9' && props.shapes.length === 0 ? {
        size: 14,
        color: '#001c4d'
      } : {
        size: 0
      }
    }
  ];

  const layout: Partial<Layout> = {
    height: props.shapes.length !== 0 ? 500 : props.style === 'mapbox://styles/mapbox/satellite-streets-v9' ? 475 : 450,
    margin: { l: 25, r: 25, t: 15, b: 15 },
    hovermode: 'closest',
    mapbox: {
      accesstoken: MAPBOX_ACCESS_TOKEN,
      bearing: 0,
      center: {
        lat: props.latitude,
        lon: props.longitude
      },
      pitch: 0,
      style: props.style,
      zoom: props.zoom
    },
    shapes: props.shapes,
    template: PLOTLY_TEMPLATE
  };

  useEffect(() => {
    const handleMove = (event: PlotRelayoutEvent) => {
      if (props.setOuterCoordinates) {
        // @ts-ignore
        const center: Partial<MapboxCenter> | undefined = event['mapbox.center'];
        if (center) {
          props.setOuterCoordinates(center.lat + '; ' + center.lon);
        }
      }
    };

    if (chartRef.current) {
      Plotly.newPlot(chartRef.current, data, layout, { responsive: true, scrollZoom: true }).then((chart) => {
        chart.on('plotly_relayout', handleMove);
      });
    }

    return () => {
      if (chartRef.current) {
        Plotly.purge(chartRef.current);
      }
    };
  }, [props.latitude, props.longitude, props.zoom, props.style]);

  return (
    <>
      <div ref={chartRef} />
    </>
  );
};