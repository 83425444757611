import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Plotly from 'plotly.js';
import { Data, Layout } from 'plotly.js';
import { getObjectValues } from '../../../../../pages/utils';
import { DQV_COL } from '../../../../../pages/static';

export const DiagnosisBottomLeftBarChart: React.FC<any> = ({ stats, statsColumns, dqvText }) => {
  const { t: translate } = useTranslation();

  const chartRef = useRef<HTMLDivElement>(null);

  const data: Data[] = statsColumns.filter((col: string) => col.startsWith('CountViolations_')).map((col: string) => ({
    x: getObjectValues<string>(stats['Executed']).map((value: string) => value.split('T')[0] + ' ' + value.split('T')[1]),
    y: getObjectValues<number>(stats[col]).map((value: number, index) => value / getObjectValues<number>(stats['Total animals'])[index]),
    customdata: getObjectValues(stats[col]),
    width: 0.5 * 1000 * 3600 * 24,
    marker: {
      color: DQV_COL[dqvText[col.split('_').slice(-1)[0]]]
    },
    name: col.split('_').slice(-1)[0],
    hovertemplate: translate('Executed: %{x}<br>Violation rate: %{y:.2%}<br>Violations raised: %{customdata}'),
    type: 'bar'
  }));

  const layout: Partial<Layout> = {
    barmode: 'stack',
    title: translate('Data quality violations - last 30 days'),
    showlegend: true,
    legend: {
      xanchor: 'center',
      x: 0.5,
      yanchor: 'top',
      y: 1.18,
      orientation: 'h'
    },
    yaxis: {
      tickformat: ',.0%'
    },
    hovermode: 'closest'
  };

  useEffect(() => {
    if (chartRef.current) {
      Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
    }
  }, []);

  return (
    <>
      <div ref={chartRef} />
    </>
  );
};