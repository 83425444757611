import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Table, { ColumnsType } from "antd/es/table";

interface TableDataType {
    radarCategory: string;
    scoreName: string;
    ruleId: string;
    invalidScore: number;
};

export const DataQualityFromDashboardInvalidTable: React.FC<any> = ({ invalidTableData, loading }) => {
    const { t: translate } = useTranslation();

    const columns: ColumnsType<TableDataType> = [
        {
            title: translate("Radar Category"),
            dataIndex: 'radarCategory',
            key: 'radarCategory'
        },
        {
            title: translate("Score Name"),
            dataIndex: 'scoreName',
            key: 'scoreName'
        },
        {
            title: translate("Rule Id"),
            dataIndex: 'ruleId',
            key: 'ruleId'
        },
        {
            title: translate("Invalid Score"),
            dataIndex: 'invalidScore',
            key: 'invalidScore'
        }
    ];

    const [tableData, setTableData] = useState<TableDataType[]>([]);

    useEffect(() => {
        if (invalidTableData !== undefined) {
            setTableData(
                invalidTableData.tables[0].rows.map((value: (string | number)[]) => {
                    return {
                        radarCategory: value[2],
                        scoreName: value[4],
                        ruleId: value[3],
                        invalidScore: value[5]

                    }
                })
            );
        }
    }, [invalidTableData]);

    return (
        <Table columns={columns} dataSource={tableData} scroll={{ y: 240 }} pagination={false} loading={loading} />
    );
};