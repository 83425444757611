import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticationContext, SnackbarMessagesContext, SnackbarMessageType, ViewContext } from '@ddw/react-framework';
import { HerdProfileContext } from '../../../../context';
import { Docs } from '../../../../typings/types';
import { useNavigate } from 'react-router-dom';
import { DDWApplication, Input, Select, SelectOption, ViewSize } from '@ddw/react-components';
import { Api } from '../../../../services/api';
import useAsyncEffect from 'use-async-effect';
import { PageContainer } from '../../../../component/PageContainer';
import { Alert, Col, IconContainer, Label, Paragraph, Row, SubHeader } from '../../../components';
import { SpeedometerIcon } from '../../../../component/Icons/SpeedometerIcon';
import { HerdSelectorSelectParent } from '../../../../component/HerdSelector/herdselector.styled';
import { PagesContext } from '../../../../context/PagesContext';
import { guardianPages } from '../../../pages';

export const GuardianDiagnosisDocsPage: React.FC = () => {
  const { t: translate } = useTranslation();
  const { user, getAccessToken } = useContext(AuthenticationContext);
  const { herdId, permissions } = useContext(HerdProfileContext);
  const { addMessageToStack } = useContext(SnackbarMessagesContext);
  const { setPages } = useContext(PagesContext);
  const { viewSize } = useContext(ViewContext);

  const [docsData, setDocsData] = useState<Docs>();

  const [loading, setLoading] = useState(true);
  const [dataErrorOrWarningSeverity, setDataErrorOrWarningSeverity] = useState('');

  const navigate = useNavigate();

  const equipmentOptions: SelectOption[] = [
    { label: translate('Not specified'), value: 'Global' },
    { label: translate('Activity meter'), value: 'Activity' },
    { label: translate('BCS camera'), value: 'BCS' },
    { label: translate('Feeding'), value: 'Feeding' },
    { label: translate('Herd Navigator'), value: 'HN' },
    { label: translate('OCC'), value: 'OCC' },
    { label: translate('Only Robot'), value: 'MilkOnly' }
  ];
  const [equipment, setEquipment] = useState<SelectOption>({ label: '', value: '' });
  const [equipmentDisabled, setEquipmentDisabled] = useState(false);

  const groupOptions: SelectOption[] = [
    { label: translate('Not specified'), value: 'Global' },
    { label: translate('Lactation 1'), value: 'Lact 1' },
    { label: translate('Lactation 2'), value: 'Lact 2' },
    { label: translate('Lactation 3+'), value: 'Lact 3+' },
    { label: translate('DIM < 60'), value: 'DIM <= 60' },
    { label: translate('DIM > 60'), value: 'DIM > 60' }
  ];
  const [group, setGroup] = useState<SelectOption>({ label: '', value: '' });
  const [groupDisabled, setGroupDisabled] = useState(false);

  const specificityCheckpointOptions: SelectOption[] = [
    { label: '95%', value: '95' },
    { label: '98%', value: '98' },
    { label: '99%', value: '99' }
  ];
  const [specificityCheckpoint, setSpecificityCheckpoint] = useState<SelectOption>({ label: '', value: '' });

  const [nominalSensitivity, setNominalSensitivity] = useState('');
  const [realSensitivity, setRealSensitivity] = useState('');
  const [nominalSpecificity, setNominalSpecificity] = useState('');
  const [realSpecificity, setRealSpecificity] = useState('');
  const [specificityWithDynamicFiltering, setSpecificityWithDynamicFiltering] = useState('');

  const fetchDocs = async () => {
    const result = await Api.diagnosis.fetchDocs(getAccessToken(), DDWApplication.DairyInsights, herdId);
    if (result.success) {
      if (result.message === '') {
        setDocsData(result.data);
        setEquipment(equipmentOptions[0]);
        setGroup(groupOptions[0]);
        setSpecificityCheckpoint(specificityCheckpointOptions[1]);
        setDataErrorOrWarningSeverity('');
      } else {
        setDataErrorOrWarningSeverity('warning');
      }
    } else {
      if (result.message !== '') {
        navigate('/home');
        addMessageToStack({
          messageType: SnackbarMessageType.error,
          message: translate('There was a problem fetching data.'),
          autoHide: false
        });
      } else {
        setDataErrorOrWarningSeverity('danger');
      }
    }
  };

  useAsyncEffect(async () => {
    setPages(guardianPages(translate, permissions.prevention, permissions.diagnosis, permissions.visio, user !== undefined ? user['profile']['UserEmail'] : ''));
    if (herdId != 0) {
      setLoading(true);
      await fetchDocs();
      setLoading(false);
    }
  }, [herdId]);

  useEffect(() => {
    if (specificityCheckpoint.value !== '') {
      if (equipment.value === 'Global' && group.value === 'Global') {
        setEquipmentDisabled(false);
        setGroupDisabled(false);
      } else if (equipment.value !== 'Global') {
        setEquipmentDisabled(false);
        setGroupDisabled(true);
      } else {
        setEquipmentDisabled(true);
        setGroupDisabled(false);
      }

      let scope_arg: SelectOption;
      if (equipment.value === 'Global') {
        scope_arg = group;
      } else {
        scope_arg = equipment;
      }

      const global = docsData?.performance.find((p) => p.LocalScope === 'Global');
      const local = docsData?.performance.find((p) => p.LocalScope === scope_arg.value);

      setNominalSensitivity(`${global != null && global[`StaticSeAtSp${specificityCheckpoint.value}vsGlobal`] != null
          ? global[`StaticSeAtSp${specificityCheckpoint.value}vsGlobal`].toFixed(1) : 'N.A.'}`);

      setRealSensitivity(`${local != null && local[`StaticSeAtSp${specificityCheckpoint.value}vsGlobal`] != null
          ? local[`StaticSeAtSp${specificityCheckpoint.value}vsGlobal`].toFixed(1) : 'N.A.'}`);

      setNominalSpecificity(`${global != null && global[`StaticSpAtSp${specificityCheckpoint.value}vsGlobal`] != null
          ? global[`StaticSpAtSp${specificityCheckpoint.value}vsGlobal`].toFixed(2) : 'N.A.'}`);

      setRealSpecificity(`${local != null && local[`StaticSpAtSp${specificityCheckpoint.value}vsGlobal`] != null
          ? local[`StaticSpAtSp${specificityCheckpoint.value}vsGlobal`].toFixed(2) : 'N.A.'}`);

      setSpecificityWithDynamicFiltering(
        `${local != null && local[`DynamicSpAtSp${specificityCheckpoint.value}vsGlobal`] != null
          ? local[`DynamicSpAtSp${specificityCheckpoint.value}vsGlobal`].toFixed(1)
          : 'N.A.'
        }`
      );
    }
  }, [equipment, group, specificityCheckpoint]);

  return (
    <>
      <PageContainer hasFooter={false} loading={loading}>
        {dataErrorOrWarningSeverity === '' ? (
          <>
            <Row>
              <Col width={'75%'}>
                <SubHeader>
                  <Row style={{ alignItems: 'center' }}>
                    <Col width={'3%'}>
                      <IconContainer>
                        <SpeedometerIcon />
                      </IconContainer>
                    </Col>

                    <Col width={'90%'}>{translate('Model performance - Interactive report')}</Col>
                  </Row>
                </SubHeader>

                <Paragraph>
                  {translate(
                    'Select specific equipment or animal groups to explore local behaviour of the model in different conditions.'
                  )}
                </Paragraph>
              </Col>
            </Row>

            <Row className={'mt-3'} style={{ alignItems: 'center' }}>
              <Col width={viewSize > ViewSize.S ? '20%' : '30%'}>
                <Label>{translate('Select equipment')}</Label>
              </Col>

              <Col width={viewSize > ViewSize.S ? 'auto' : '60%'}>
                <div style={{width: viewSize > ViewSize.S ? '450px' : '100%'}} className={equipmentDisabled ? 'disable' : ''}>
                  <Select
                    name={translate('Equipment')}
                    field={`equipment`}
                    options={equipmentOptions}
                    selected={equipment}
                    onChange={(selected: any) => {
                      setEquipment(selected);
                    }}
                  ></Select>
                </div>
              </Col>
            </Row>

            <Row className={'mt-2'} style={{ alignItems: 'center' }}>
              <Col width={viewSize > ViewSize.S ? '20%' : '30%'}>
                <Label>{translate('Select group')}</Label>
              </Col>

              <Col width={viewSize > ViewSize.S ? 'auto' : '60%'}>
                <div style={{width: viewSize > ViewSize.S ? '450px' : '100%'}} className={groupDisabled ? 'disable' : ''}>
                  <Select
                    name={translate('Group')}
                    field={`group`}
                    options={groupOptions}
                    selected={group}
                    onChange={(selected) => {
                      setGroup(selected);
                    }}
                  ></Select>
                </div>
              </Col>
            </Row>

            <Row className={'mt-2'} style={{ alignItems: 'center' }}>
              <Col width={viewSize > ViewSize.S ? '20%' : '30%'}>
                <Label>{translate('Specificity checkpoint')}</Label>
              </Col>

              <Col width={viewSize > ViewSize.S ? 'auto' : '60%'}>
                <div style={{width: viewSize > ViewSize.S ? '450px' : '100%'}}>
                  <Select
                    name={``}
                    field={`checkpoint`}
                    options={specificityCheckpointOptions}
                    selected={specificityCheckpoint}
                    onChange={(selected) => {
                      setSpecificityCheckpoint(selected);
                    }}
                  ></Select>
                </div>
              </Col>
            </Row>

            <hr className={'footer-line-break mt-3'} />

            <Row className={'mt-3'} style={{ alignItems: 'center' }}>
              <Col width={viewSize > ViewSize.S ? '30%' : '60%'}>
                <Label>{translate('Nominal Sensitivity, as per user settings (%)')}</Label>
              </Col>
              <Col width={viewSize > ViewSize.S ? '6%' : '25%'} className={'disable'}>
                <Input
                  name={``}
                  field={`nominal-sensitivity`}
                  value={nominalSensitivity}
                  onChange={setNominalSensitivity}
                  readOnly={true}
                />
              </Col>
            </Row>

            <Row className={'mt-1'} style={{ alignItems: 'center' }}>
              <Col width={viewSize > ViewSize.S ? '30%' : '60%'}>
                <Label>{translate('Estimated real Sensitivity (%)')}</Label>
              </Col>
              <Col width={viewSize > ViewSize.S ? '6%' : '25%'} className={'disable'}>
                <Input
                  name={``}
                  field={`real-sensitivity`}
                  value={realSensitivity}
                  onChange={setRealSensitivity}
                  readOnly={true}
                />
              </Col>
            </Row>

            <Row className={'mt-1'} style={{ alignItems: 'center' }}>
              <Col width={viewSize > ViewSize.S ? '30%' : '60%'}>
                <Label>{translate('Nominal Specificity (%)')}</Label>
              </Col>
              <Col width={viewSize > ViewSize.S ? '6%' : '25%'} className={'disable'}>
                <Input
                  name={``}
                  field={`nominal-specificity`}
                  value={nominalSpecificity}
                  onChange={setNominalSpecificity}
                  readOnly={true}
                />
              </Col>
            </Row>

            <Row className={'mt-1'} style={{ alignItems: 'center' }}>
              <Col width={viewSize > ViewSize.S ? '30%' : '60%'}>
                <Label>{translate('Estimated real Specificity (%)')}</Label>
              </Col>
              <Col width={viewSize > ViewSize.S ? '6%' : '25%'} className={'disable'}>
                <Input
                  name={``}
                  field={`real-specificity`}
                  value={realSpecificity}
                  onChange={setRealSpecificity}
                  readOnly={true}
                />
              </Col>
            </Row>

            <Row className={'mt-1'} style={{ alignItems: 'center' }}>
              <Col width={viewSize > ViewSize.S ? '30%' : '60%'}>
                <Label>{translate('Estimated Specificity with dynamic filtering (%)')}</Label>
              </Col>
              <Col width={viewSize > ViewSize.S ? '6%' : '25%'} className={'disable'}>
                <Input
                  name={``}
                  field={`specificity-with-dynamic-filtering`}
                  value={specificityWithDynamicFiltering}
                  onChange={setSpecificityWithDynamicFiltering}
                  readOnly={true}
                />
              </Col>
            </Row>
          </>
        ) : (
          <Alert maxWidth={viewSize > ViewSize.S ? '25%' : '100%'} severity={dataErrorOrWarningSeverity}>
            {dataErrorOrWarningSeverity === 'danger' ? (
              <div>
                {herdId} - {translate('Data not available')}
              </div>
            ) : (
              <div>
                {herdId} - {translate('There is no active session yet for this herd. Please retry later.')}
              </div>
            )}
          </Alert>
        )}
      </PageContainer>
    </>
  );
};
