import { useTranslation } from 'react-i18next';
import { ButtonContainer, Col, Header, MilkUnitSelectParent, Paragraph, Row, Span, Title } from '../components';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  ButtonType,
  DDWApplication,
  DividerStyle,
  Input,
  Select,
  SelectOption,
  ViewSize
} from '@ddw/react-components';
import {
  AuthenticationContext,
  Repository,
  SnackbarMessagesContext,
  SnackbarMessageType, UserSettingsContext,
  ViewContext
} from '@ddw/react-framework';
import { HerdSelectorSelectParent } from '../../component/HerdSelector/herdselector.styled';
import { SubParagraph } from '../components/sub-paragraph';
import { PageContainer } from '../../component/PageContainer';
import { useFetchSupportedLanguages, useFetchUserSettings } from '../../services/DataHooks';
import useAsyncEffect from 'use-async-effect';
import { Api } from '../../services/api';
import { HerdProfileContext } from '../../context';
import { languageCodeToLanguage } from '../../i18n';
import { PagesContext } from '../../context/PagesContext';
import { mainPages } from '../pages';
import { useNavigate } from 'react-router-dom';
import { CURRENCIES } from '../static';
import { getCurrencySign } from '../utils';
import { Checkbox } from 'antd';

export const SettingsPage: React.FC = () => {
  const { userSettings, updateUserSettings } = useContext(UserSettingsContext);
  const { t: translate, i18n } = useTranslation();
  const { viewSize } = useContext(ViewContext);
  const { addMessageToStack } = useContext(SnackbarMessagesContext);
  const { getAccessToken } = useContext(AuthenticationContext);
  const { herdId, permissions } = useContext(HerdProfileContext);
  const { setPages } = useContext(PagesContext);
  const { user } = useContext(AuthenticationContext);

  const milkSourceOptions: SelectOption[] = [
    { label: translate('Automatic'), value: 'Automatic' },
    { label: translate('Milk Meter'), value: '1' },
    { label: translate('Milk Recording'), value: '0' }
  ];
  const [milkSource, setMilkSource] = useState(milkSourceOptions[0]);

  const milkUnitOptions: SelectOption[] = [
    { label: translate('Kilograms'), value: 'kg' },
    { label: translate('Pounds'), value: 'lb' }
  ];
  const [milkUnit, setMilkUnit] = useState({ label: '', value: '' });

  const [animalBodyweightL1, setAnimalBodyweightL1] = useState<string>('583.3');
  const [animalBodyweightL2, setAnimalBodyweightL2] = useState<string>('625');
  const [lactationRationCost, setLactationRationCost] = useState<string>('0.35');
  const [dryCowRationCost, setDryCowRationCost] = useState<string>('0.15');

  const [groups, setGroups] = useState<any>([]);

  const { fetchSupportedLanguages } = useFetchSupportedLanguages();
  const { fetchUserSettings } = useFetchUserSettings();

  const [languageOptions, setLanguageOptions] = useState<Repository<SelectOption[]>>({ data: [], loading: true });
  const [language, setLanguage] = useState({ label: '', value: '' });

  const [currency, setCurrency] = useState<SelectOption>(CURRENCIES[0]);

  const [loading, setLoading] = useState<boolean>(true);
  const [herdSettingsLoading, setHerdSettingsLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const setRationCost = (index: number) => (event: string) => {
    const rationCost = Number(event);
    const _groups = [...groups];
    _groups[index].RationCost = rationCost;
    setGroups(_groups);
  };

  const handleIgnoredChange = (index: number) => (event: any) => {
    const _groups = [...groups];
    _groups[index].Ignored = event.target.checked;
    setGroups(_groups);
  };

  useAsyncEffect(async () => {
    setPages(mainPages(translate, permissions.visio, user !== undefined ? user['profile']['UserEmail'] : ''));
    if (herdId !== 0) {
      setHerdSettingsLoading(true);
      const result = await fetchSupportedLanguages();
      if (result.success && result.data.length > 0) {
        setLanguageOptions({ data: result.data, loading: false });
        const userSettings = await fetchUserSettings();
        setLoading(false);
        if (userSettings.success) {
          setMilkSource({
            label: milkSourceOptions.find((milkSource) => milkSource.value == userSettings.data.milkSource)!.label,
            value: String(userSettings.data.milkSource)
          });

          setAnimalBodyweightL1(String(userSettings.data.feeding.animalBodyweightL1));
          setAnimalBodyweightL2(String(userSettings.data.feeding.animalBodyweightL2));
          setLactationRationCost(String(userSettings.data.feeding.lactationRationCost));
          setDryCowRationCost(String(userSettings.data.feeding.dryCowRationCost));

          setGroups(userSettings.data.groups);

          setMilkUnit({
            label: milkUnitOptions.find((milkUnit) => milkUnit.value == userSettings.data.milkUnit)!.label,
            value: userSettings.data.milkUnit
          });

          setLanguage({ label: userSettings.data.language, value: String(userSettings.data.languageCode) });
          setCurrency(CURRENCIES.find(option => option.value === userSettings.data.currency)!);
        } else {
          navigate('/home');
          addMessageToStack({
            messageType: SnackbarMessageType.error,
            message: translate('There was a problem fetching settings.'),
            autoHide: false
          });
        }
      } else {
        setLanguageOptions({ data: [], loading: false, error: result.message });
      }
      setHerdSettingsLoading(false);
    }
  }, [i18n.language, herdId]);

  const saveNewSettings = async (herd_selected: number) => {
    document.body.style.cursor = 'progress';
    const result = await Api.userSettings.updateUserSettings(
      getAccessToken(),
      DDWApplication.DairyInsights,
      herd_selected,
      {
        milkSource: milkSource.value == 'Automatic' ? milkSource.value : parseInt(milkSource.value),
        feeding: {
          animalBodyweightL1: Number(animalBodyweightL1),
          animalBodyweightL2: Number(animalBodyweightL2),
          lactationRationCost: Number(lactationRationCost),
          dryCowRationCost: Number(dryCowRationCost)
        },
        groups: groups,
        milkUnit: milkUnit.value,
        language: language.label,
        languageCode: parseInt(language.value),
        currency: currency.value
      }
    );
    if (result.success) {
      const preferredLanguage: string = languageCodeToLanguage[language.value];
      await i18n.changeLanguage(preferredLanguage);
      await updateUserSettings({ ...userSettings, preferredLanguage });
      sessionStorage.setItem('preferredCurrency', currency.value);
      addMessageToStack({
        messageType: SnackbarMessageType.success,
        message: translate('Settings updated successfully. Any setting relating to feeding will apply within 24h.'),
        autoHide: true
      });
    } else {
      addMessageToStack({
        messageType: SnackbarMessageType.error,
        message: translate('There was a problem updating settings.'),
        autoHide: true
      });
    }
    document.body.style.cursor = 'default';
  };

  return (
    <>
      <PageContainer>
        <Header>{translate('Settings')}</Header>
        <Title>{translate('Herd-level settings')}</Title>
        <SubParagraph>
          {translate(
            'These settings are applied at a herd level and will be visible to all PREDICTA users having access to this herd. Settings that impact predictions will be effective within 24h.'
          )}
        </SubParagraph>
        {/* <Paragraph>{translate('Milk Source for predictions')}</Paragraph>
        <SubParagraph>
          {translate(
            'Tell us whether we should use Milk Meter or Milk Recording as the main source of milking data for our predictions. Our default \'Automatic\' option will set the milk source to whatever available, giving preference to Milk Meter in case both sources are present.'
          )}
        </SubParagraph>
        <HerdSelectorSelectParent>
          <Select
            name={viewSize > ViewSize.S ? translate('Milk Source') : ''}
            field={`milkSource`}
            options={milkSourceOptions}
            selected={milkSource}
            loading={loading}
            onChange={(selected: SelectOption) => {
              setMilkSource(selected);
            }}
          ></Select>
        </HerdSelectorSelectParent> */}
        <HerdSelectorSelectParent>
          <Input field='bodyweightL1' name={translate('Bodyweight (kg) (L1 animals)')} value={String(animalBodyweightL1)} type='number' onChange={setAnimalBodyweightL1} loading={herdSettingsLoading} />
        </HerdSelectorSelectParent>

        <SubParagraph>
          {translate('Source for default value of 583.3 kg for bodyweight of L1 animals')}: <a href='https://www.jdscommun.org/article/S2666-9102(23)00057-1/fulltext' target="_blank">The association between insemination eligibility and reproductive performance of nulliparous heifers on subsequent body weight and milk production of primiparous Holstein cows - JDS Communications</a>
        </SubParagraph>

        <HerdSelectorSelectParent>
          <Input field='bodyweightL2' name={translate('Bodyweight (kg) (L2 animals)')} value={String(animalBodyweightL2)} type='number' onChange={setAnimalBodyweightL2} loading={herdSettingsLoading} />
        </HerdSelectorSelectParent>



        {/* <HerdSelectorSelectParent>
          <Input field='dcc' name={translate('DCC (dry cow cost/day)') + ' (' + getCurrencySign() + ')'} value={String(dryCowRationCost)} type='number' onChange={setDryCowRationCost} loading={herdSettingsLoading} />
      </HerdSelectorSelectParent>*/}



        {/* <HerdSelectorSelectParent>
          <Input field='lfc' name={translate('LFC (Lactation Feed Cost)/kg Dry matter (DM)') + ' (' + getCurrencySign() + ')'} value={String(lactationRationCost)} type='number' onChange={setLactationRationCost} loading={herdSettingsLoading} />
        </HerdSelectorSelectParent> */}

        {groups.map((group: any, index: number) => {
          return (
            <Row key={index} style={{ alignItems: 'center' }}>
              <Col>
                <HerdSelectorSelectParent>
                  <Input field={'group-' + group.GroupId} name={translate('Group') + ' ' + (group.GroupName !== null ? group.GroupName : group.GroupId) + ' ' + translate('Ration Cost')} value={String(group.RationCost)} type='number' onChange={setRationCost(index)} loading={herdSettingsLoading} />
                </HerdSelectorSelectParent>
              </Col>

              <Col>
                <Checkbox checked={group.Ignored} onChange={handleIgnoredChange(index)}>
                  <Span>{translate('Ignored')}</Span>
                </Checkbox>
              </Col>
            </Row>
          );
        })}

        <Paragraph style={{
          color: 'blue', cursor: 'pointer'
        }} onClick={() => {
          navigate('/herd/historical/', { state: { selection: 'feeding' } });
        }}>{translate('See Milk Production vs Feed Cost chart')}
        </Paragraph>

        <DividerStyle />

        <Title>{translate('User preferences')}</Title>
        <SubParagraph>
          {translate(
            'These settings are applied at the user level and will be visible to this login account only, with immediate effect.'
          )}
        </SubParagraph>
        <MilkUnitSelectParent>
          <Select
            name={translate('Milk Unit')}
            field={`milkUnit`}
            options={milkUnitOptions}
            selected={milkUnit}
            loading={loading}
            onChange={(selected: SelectOption) => {
              setMilkUnit(selected);
            }}
          ></Select>
        </MilkUnitSelectParent>
        <HerdSelectorSelectParent>
          <Select
            name={translate('Language')}
            field={`language`}
            options={languageOptions.data}
            selected={language}
            loading={loading}
            error={
              languageOptions.error || (languageOptions.data.length === 0 && !languageOptions.loading)
                ? translate('No supported languages')
                : undefined
            }
            onChange={(selected) => {
              setLanguage(selected);
            }}
          ></Select>
        </HerdSelectorSelectParent>
        <HerdSelectorSelectParent>
          <Select
            name={translate('Currency')}
            field={`currency`}
            options={CURRENCIES}
            selected={currency}
            loading={loading}
            onChange={(selected) => {
              setCurrency(selected);
            }}
          ></Select>
        </HerdSelectorSelectParent>
        <ButtonContainer>
          <Button
            name={'save'}
            type={ButtonType.Primary}
            onClick={async () => await saveNewSettings(herdId)}
            isOutlined={false}
          >
            {' '}
            {translate('Save')}
          </Button>
        </ButtonContainer>
      </PageContainer>
    </>
  );
};
