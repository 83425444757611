import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Plotly from 'plotly.js';
import { Data, Layout } from 'plotly.js';
import { DDW_grey, DDW_orange, PLOTLY_TEMPLATE } from '../../../../../pages/static';
import { getObjectValues } from '../../../../../pages/utils';
import { DiagnosisIssuesSubset } from '../../../../../typings/types';

interface Props {
  subset: DiagnosisIssuesSubset;
  dqvText: { [key: number]: string };
}

export const DiagnosisIssuesBarChart: React.FC<Props> = ({ subset, dqvText }) => {
  const { t: translate } = useTranslation();

  const chartRef = useRef<HTMLDivElement>(null);

  const data: Data[] = [
    {
      type: 'bar',
      y: Object.values(dqvText),
      x: Object.keys(dqvText).map((k) =>
        getObjectValues<number>(subset.DataQualityViolation).filter((dqv: number) => String(dqv) === k).length
      ),
      marker: { color: DDW_orange },
      orientation: 'h'
    }
  ];

  const layout: Partial<Layout> = {
    barmode: 'group',
    bargap: 0.5,
    hovermode: 'closest',
    xaxis: {
      side: 'top',
      title: translate('Active occurrences'),
      gridcolor: DDW_grey
    },
    yaxis: {
      automargin: true,
      ticks: 'outside',
      ticklen: 5,
      tickcolor: 'rgba(0, 0, 0, 0)',
      autorange: 'reversed'
    },
    template: PLOTLY_TEMPLATE
  };

  useEffect(() => {
    if (chartRef.current) {
      Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
    }
  }, []);

  return (
    <>
      <div ref={chartRef} />
    </>
  );
};