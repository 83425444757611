import Plotly, { Data, Layout } from 'plotly.js';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DDW_dark_orange, KPI_CODES, PLOTLY_TEMPLATE } from '../../../../../pages/static';
import { format, parse } from 'date-fns';

export const AnimalsDryBarChart: React.FC<any> = ({ props }) => {
    const { t: translate } = useTranslation();

    const chartRef = useRef<HTMLDivElement>(null);

    const data: Data[] = [{
        x: props.filter((data: any) => data.kpiCode === KPI_CODES['NrOfDryCows']).map((x: any) => format(parse(x.periodValue, 'yyyyMM', new Date()), 'MMM yyyy')),
        y: props.filter((data: any) => data.kpiCode === KPI_CODES['NrOfDryCows']).map((x: any) => parseFloat(x.value).toFixed(0)),
        type: 'bar',
        opacity: 1.0,
        marker: { color: DDW_dark_orange }
    }];

    const layout: Partial<Layout> = {
        title: {
            text: translate('Animals dry'),
            yanchor: 'top'
        },
        showlegend: false,
        hovermode: 'closest',
        template: PLOTLY_TEMPLATE
    };

    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
        }
    }, [props]);

    return (
        <div ref={chartRef} />
    );
};