import { DDWApplication, parseError, putHeaders, statusHelper, toJSON } from "@ddw/react-components";
import { ServiceResult } from "@ddw/react-framework/build/src/types";
import { urlState } from "../../pages/utils";

export const fetchCurrenciesConversionRates = (accessToken: string, application: DDWApplication): Promise<ServiceResult<any>> => {
    const headers = putHeaders(accessToken, application);
    headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
    const url = urlState.url + `currencies-conversion-rates`;
    return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};
