import React, { useContext, useEffect, useState } from 'react';
import { Col, Label, Row } from '../../pages/components';
import { useTranslation } from 'react-i18next';
import { Button, IconLib, Input, Select, SelectOption } from '@ddw/react-components';
import { Checkbox, Radio } from 'antd';
import { CALL_CODES, NOTIFICATION_DEFAULTS } from '../../pages/static';
import { isolateCallcodeFromNumber, validateEmail, validateMobileNumber } from '../../pages/utils';
import { HerdSettingsUsersContext, HerdSettingsUsersContextType } from '../../context/HerdSettingsUsersContext';
import { HerdProfileContext } from '../../context';

export const NotificationUserContainer: React.FC<any> = ({ settings, primary, admin, utc_offset, index, modelId }) => {
  const { t: translate } = useTranslation();
  const { herdId } = useContext(HerdProfileContext);
  const { users, setUsers } = useContext<HerdSettingsUsersContextType>(HerdSettingsUsersContext);

  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState<string>('');
  const [alarmFrequency, setAlarmFrequency] = useState(NOTIFICATION_DEFAULTS['ModelAlarmFrequency']);
  const [notificationDay, setNotificationDay] = useState(NOTIFICATION_DEFAULTS['NotificationWeekDay']);

  let notificationMode: number[] = [];

  const [emailChecked, setEmailChecked] = useState(false);
  const [whatsAppChecked, setWhatsAppChecked] = useState(false);
  const [smsChecked, setSmsChecked] = useState(false);

  const callCodesOptions: SelectOption[] =
    Object.entries(CALL_CODES).map(([key, value]) => {
      return {
        label: `${key} (${value})`,
        value: value
      };
    });

  const [callCode, setCallCode] = useState<SelectOption>(callCodesOptions[0]);

  const timeOptions: SelectOption[] = Array.from({ length: 24 }, (_, i) => {
    const label = `${i.toString().padStart(2, '0')}:00 - ${(i + 1).toString().padStart(2, '0')}:00`;
    const value = i.toString();
    const disabled = !admin;
    return { label, value, disabled };
  });

  const [time, setTime] = useState<SelectOption | undefined>(timeOptions[0]);

  const [emailError, setEmailError] = useState('');
  const [mobileNumberError, setMobileNumberError] = useState('');

  const [notificationDayDisabled, setNotificationDayDisabled] = useState(false);

  const updateUserSettingsAttribute = (attribute: string, value: any): void => {
    if (primary) {
      users[String(modelId)]['primary'][attribute] = value;
    } else {
      users[String(modelId)]['secondary'][index - 1][attribute] = value;
    }
  };

  useEffect(() => {
    if (settings === null || settings === undefined) {
      settings = { ...NOTIFICATION_DEFAULTS };
      settings['GuardianModelId'] = modelId === 'unique' ? null : parseInt(modelId);
      settings['_confirmed'] = false;
      settings['PrimaryUser'] = primary;
      settings['HerdProfileId'] = herdId;
      settings['GuardianNotificationSettingsId'] = null;
      if (primary) {
        users[String(modelId)]['primary'] = settings;
      } else {
        users[String(modelId)]['secondary'][index - 1] = settings;
      }
    }

    const binMap: string = settings['AlarmNotificationMode'].toString(2).padStart(3, '0');
    notificationMode = [parseInt(binMap[2]), 2 * parseInt(binMap[1]), 4 * parseInt(binMap[0])];
    setEmailChecked(notificationMode[0] === 1);
    setWhatsAppChecked(notificationMode[1] === 2);
    setSmsChecked(notificationMode[2] === 4);

    // Split mobile into country prefix and phone number
    let _callcode: string | null = null;
    let onlyNumber: string | null = null;

    if (settings['NotificationMobile']) {
      const callcodeAttempt = isolateCallcodeFromNumber(settings['NotificationMobile']);
      if (callcodeAttempt) {
        _callcode = callcodeAttempt[0];
        onlyNumber = callcodeAttempt[1];
      } else {
        onlyNumber = settings['NotificationMobile'];
      }
    }

    setEmail(settings['NotificationEmail']);
    setCallCode(() => {
      // @ts-ignore
      const label: string = Object.entries(CALL_CODES).find(([key, value]) => value == _callcode) !== undefined ? Object.entries(CALL_CODES).find(([key, value]) =>
        value == _callcode
      )[0] : '';
      return {
        value: _callcode !== null ? _callcode : '',
        label: _callcode !== null ? `${label} (${_callcode})` : ''
      };
    });
    setMobile(onlyNumber !== null ? onlyNumber : '');
    setAlarmFrequency(settings['ModelAlarmFrequency']);
    setNotificationDay(settings['NotificationWeekDay']);
    setTime(timeOptions.find((elem) =>
      elem.value == (utc_offset === null ? settings['NotificationHourUTC'] : settings['NotificationHourUTC'] + utc_offset)
    ));
  }, []);

  useEffect(() => {
    setEmailError(!emailChecked || (emailChecked && validateEmail(email)) ? '' : translate('Email is not valid'));
    updateUserSettingsAttribute('NotificationEmail', email);
  }, [email, emailChecked]);

  useEffect(() => {
    setMobileNumberError(
      (!smsChecked && !whatsAppChecked) || validateMobileNumber(callCode.value, mobile) ? '' :
        translate('Mobile number is not valid')
    );
    updateUserSettingsAttribute('NotificationMobile', (mobile && callCode && callCode.value) ? callCode.value + mobile : null);
  }, [mobile, callCode, whatsAppChecked, smsChecked]);

  useEffect(() => {
    if (alarmFrequency === 0) {
      setNotificationDayDisabled(true);
      setNotificationDay(-1);
      updateUserSettingsAttribute('NotificationWeekDay', null);
    } else {
      setNotificationDayDisabled(false);
    }
    updateUserSettingsAttribute('ModelAlarmFrequency', alarmFrequency);
  }, [alarmFrequency]);

  useEffect(() => {
    updateUserSettingsAttribute('NotificationHourUTC', parseInt(String(time?.value)) - utc_offset);
  }, [time]);

  useEffect(() => {
    updateUserSettingsAttribute('AlarmNotificationMode', (emailChecked ? 1 : 0) + (whatsAppChecked ? 2 : 0) + (smsChecked ? 4 : 0));
  }, [emailChecked, whatsAppChecked, smsChecked]);

  return (
    <>
      {!primary &&
        <Row style={{ alignItems: 'center' }}>
          <Col width={'30%'}>
            <Label>
              <strong>{translate('Secondary user #') + index}</strong>
            </Label>
          </Col>
          <Col width={'30%'}>
            <Button name={`remove-user`} isDisabled={!admin} icon={IconLib.delete} onClick={() => {
              if (!users[String(modelId)]['secondary'][index - 1]['_confirmed']) {
                users[String(modelId)]['secondary'].splice(index - 1, 1);
              } else {
                users[String(modelId)]['secondary'][index - 1]['_confirmed'] = false;
                users[String(modelId)]['secondary'][index - 1]['_removed'] = true;
              }
              setUsers({ ...users });
              console.log('Remove user - users', users);
            }}>
              {translate('Remove user')}
            </Button>
          </Col>
        </Row>
      }

      <Row className={'mt-4'} style={{ alignItems: 'center' }}>
        <Col width={'30%'}>
          <Label>
            {translate('Receive alarms via')}
          </Label>
        </Col>
        <Col>
          <Checkbox value={1} disabled={!admin} checked={emailChecked} onChange={(e) => {
            setEmailChecked(e.target.checked);
          }}>
            {translate('Email')}
          </Checkbox>
        </Col>
        <Col>
          <Checkbox value={2} disabled={!admin} checked={whatsAppChecked} onChange={(e) => {
            setWhatsAppChecked(e.target.checked);
          }}>
            {translate('WhatsApp')}
          </Checkbox>
        </Col>
        <Col>
          <Checkbox value={4} disabled={!admin} checked={smsChecked} onChange={(e) => {
            setSmsChecked(e.target.checked);
          }}>
            {translate('SMS')}
          </Checkbox>
        </Col>
      </Row>

      <Row className={'mt-4'} style={{ alignItems: 'center' }}>
        <Col width={'30%'}>
          <Label>
            {translate('Email')}
          </Label>
        </Col>
        <Col width={'70%'}>
          <Input name={translate('Email')} type={'email'} readOnly={!admin} field={`email`} value={email}
                 onChange={setEmail}
                 error={emailError} />
        </Col>
      </Row>

      <Row className={'mt-4'} style={{ alignItems: 'center' }}>
        <Col width={'30%'}>
          <Label>
            {translate('Mobile')}
          </Label>
        </Col>
        <Col width={'70%'}>
          <Select name={translate('Call code')} field={`callcode`} options={callCodesOptions} selected={callCode}
                  onChange={setCallCode} />
          <Input name={translate('Mobile')} readOnly={!admin} field={`mobile`} value={mobile}
                 onChange={setMobile} error={mobileNumberError} />
        </Col>
      </Row>

      <Row className={'mt-4'} style={{ alignItems: 'center' }}>
        <Col width={'30%'}>
          <Label>
            {translate('Alarm frequency')}
          </Label>
        </Col>
        <Col>
          <Radio.Group defaultValue={alarmFrequency} value={alarmFrequency} onChange={(event) => {
            setAlarmFrequency(event.target.value);
          }}>
            <Radio value={0} disabled={!admin}>{translate('As soon as alarm is raised')}</Radio>
            <Radio value={1} disabled={!admin}>{translate('Weekly')}</Radio>
          </Radio.Group>
        </Col>
      </Row>

      <Row className={'mt-4'} style={{ alignItems: 'center' }}>
        <Col width={'30%'}>
          <Label>
            {translate('Notification day')}
          </Label>
        </Col>
        <Col>
          <Radio.Group defaultValue={notificationDay} value={notificationDay} onChange={(event) => {
            setNotificationDay(event.target.value);
            updateUserSettingsAttribute('NotificationWeekDay', event.target.value);
          }}>
            <Radio value={1}
                   disabled={!admin || notificationDayDisabled}
            >{translate('Mon')}</Radio>
            <Radio value={2}
                   disabled={!admin || notificationDayDisabled}
            >{translate('Tue')}</Radio>
            <Radio value={3}
                   disabled={!admin || notificationDayDisabled}
            >{translate('Wed')}</Radio>
            <Radio value={4}
                   disabled={!admin || notificationDayDisabled}
            >{translate('Thu')}</Radio>
            <Radio value={5}
                   disabled={!admin || notificationDayDisabled}
            >{translate('Fri')}</Radio>
            <Radio value={6}
                   disabled={!admin || notificationDayDisabled}
            >{translate('Sat')}</Radio>
            <Radio value={7}
                   disabled={!admin || notificationDayDisabled}
            >{translate('Sun')}</Radio>
          </Radio.Group>
        </Col>
      </Row>

      <Row className={'mt-4'} style={{ alignItems: 'center' }}>
        <Col width={'30%'}>
          <Label>
            {utc_offset ? translate('Local Time') : translate('UTC Time')}
          </Label>
        </Col>
        <Select name={translate('Time')} field={`time`} options={timeOptions} selected={time} onChange={setTime} />
      </Row>

      <hr className={'footer-line-break mt-4'} />
    </>
  );
};