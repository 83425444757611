import React, { useContext, useEffect, useState } from 'react';
import { HerdProfileContext } from '../../context';
import { Alert, FarmAddressAlertLink, Header, MapContainer, IconContainer, Row, Col, Span, SubHeader, Title } from '../components';
import { useTranslation } from 'react-i18next';
import { ClipboardDataIcon } from '../../component/Icons';
import { PageContainer } from '../../component/PageContainer';
import useAsyncEffect from 'use-async-effect';
import { AuthenticationContext, ViewContext } from '@ddw/react-framework';
import { Api } from '../../services/api';
import { Button, ButtonType, DDWApplication, ViewSize } from '@ddw/react-components';
import { FarmAddressDialog } from '../../component';
import { mainPages } from '../pages';
import { PagesContext } from '../../context/PagesContext';
import { ScatterMapbox } from '../../component/Charts/Map/HomePageMap';
import { useNavigate } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import { HerdStabilityScoresTable } from '../../component/Charts/HerdStabilityScoresTable/HerdStabilityScoresTable';

export const HomePage: React.FC = () => {
  const { t: translate } = useTranslation();
  const { herds, herdId, permissions } = useContext(HerdProfileContext);
  const { getAccessToken } = useContext(AuthenticationContext);
  const { setPages } = useContext(PagesContext);
  const { viewSize } = useContext(ViewContext);
  const { user } = useContext(AuthenticationContext);

  const [viewport, setViewport] = useState({
    latitude: 47.16,
    longitude: 9.55,
    zoom: 2
  });
  const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v9');
  const [latitude, setLatitude] = useState(viewport.latitude);
  const [longitude, setLongitude] = useState(viewport.longitude);
  const [farmAddress, setFarmAddress] = useState('');

  const [herdProfileAlertSeverity, setHerdProfileAlertSeverity] = useState('primary');
  const [farmAddressAlertSeverity, setFarmAddressAlertSeverity] = useState('secondary');
  const [farmAddressAlertText, setFarmAddressAlertText] = useState<string>(translate('No herd selected'));
  const [noHerdData, setNoHerdData] = useState(false);

  const [dashboardInformation, setDashboardInformation] = useState({ "guardian": "0", "visio": "0", "volatility": {}, "herdSize": 'N.A.' });

  const [showDialog, setShowDialog] = useState(false);

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useAsyncEffect(async () => {
    setPages(mainPages(translate, permissions.visio, user !== undefined ? user['profile']['UserEmail'] : ''));
    if (herdId !== 0) {
      setLoading(true);
      const result = await Api.farmCoordinates.fetchFarmCoordinates(
        getAccessToken(),
        DDWApplication.DairyInsights,
        herdId
      );

      if (result.success) {
        setViewport({ latitude: result.data.latitude, longitude: result.data.longitude, zoom: 16 });
        setLatitude(result.data.latitude);
        setLongitude(result.data.longitude);
        setMapStyle('mapbox://styles/mapbox/satellite-streets-v9');
        setHerdProfileAlertSeverity(result.data.dataQualityConfirmed ? 'success' : 'warning');
        setNoHerdData(false);

        if (result.data.addressValidated === 2) {
          setFarmAddress(String(result.data.address));
          setFarmAddressAlertSeverity('primary');
          setFarmAddressAlertText(translate('Farm address validated. ') + ' ' + translate(String(result.data.validation?.trim())) + ' ');
        } else {
          setFarmAddress(String(result.data.address));
          setFarmAddressAlertSeverity('warning');
          setFarmAddressAlertText(translate('Farm address not validated.'));
        }
      } else {
        setViewport({
          latitude: 47.16,
          longitude: 9.55,
          zoom: 2
        });
        setMapStyle('mapbox://styles/mapbox/streets-v9');
        setShowDialog(false);
        setLatitude(47.16);
        setLongitude(9.55);
        setFarmAddress('');
        setFarmAddressAlertSeverity('danger');
        setFarmAddressAlertText(
          result.data.addressValidated == 0
            ? translate(
              'No farm address available due to herd data not being available. Please contact our support team.'
            )
            : translate('No farm address available.')
        );
        if (result.data.addressValidated == 0) {
          setNoHerdData(true);
          setHerdProfileAlertSeverity('danger');
        } else {
          setNoHerdData(false);
          setHerdProfileAlertSeverity(result.data.dataQualityConfirmed ? 'success' : 'warning');
        }
      }

      const result2 = await Api.homePageDashboard.fetchHomePageDashboard(getAccessToken(), DDWApplication.DairyInsights, herdId);

      if (result2.success) {
        const _dahsboardInformation = { "guardian": "0", "visio": "0", "volatility": {}, "herdSize": 'N.A.' };

        if (result2.data?.guardian.herdLevelDQ !== null) {
          _dahsboardInformation["guardian"] = "!";
        } else {
          _dahsboardInformation["guardian"] = String(result2.data.guardian.alerts + result2.data.guardian.violations);
        }
        _dahsboardInformation["visio"] = String(result2.data.visio.prescriptions);
        _dahsboardInformation["volatility"] = result2.data?.volatility;
        _dahsboardInformation["herdSize"] = String(result2.data?.herdSize);

        setDashboardInformation(_dahsboardInformation);
      }

      setLoading(false);
    }
  }, [herdId]);

  useEffect(() => {
    return () => {
      setViewport({
        latitude: 47.16,
        longitude: 9.55,
        zoom: 2
      });
      setMapStyle('mapbox://styles/mapbox/streets-v9');
      setLatitude(viewport.latitude);
      setLongitude(viewport.longitude);
      setFarmAddress('');

      setHerdProfileAlertSeverity('primary');
      setFarmAddressAlertSeverity('secondary');
      setFarmAddressAlertText(translate('No herd selected'));
      setNoHerdData(false);
      setDashboardInformation({ "guardian": "0", "visio": "0", "volatility": {}, "herdSize": 'N.A.' });
    };
  }, [herdId]);

  return (
    <>
      <PageContainer loading={loading}>
        {viewSize > ViewSize.S ?
          <>
            <Row style={{ alignItems: 'center' }}>
              <Col width='60%'>
                <Alert severity={herdProfileAlertSeverity} maxWidth={'80%'}>
                  {herdProfileAlertSeverity === 'danger' && (
                    <div>
                      {herdId} - {translate('Data not available')}
                    </div>
                  )}
                  {herdProfileAlertSeverity === 'primary' && (
                    <div>{translate('Select a herd from the navigation bar above to start using PREDICTA')}</div>
                  )}
                  {herdProfileAlertSeverity === 'success' && (
                    <div>
                      {herdId} {herds.data.find((h) => h.id == herdId)?.name} - {translate('Loaded successfully')}
                    </div>
                  )}
                  {herdProfileAlertSeverity === 'warning' && (
                    <div>
                      {herdId} {herds.data.find((h) => h.id == herdId)?.name} -{' '}
                      {translate('Loaded successfully - WARNING: Data Quality not yet confirmed.')}
                    </div>
                  )}
                </Alert>
              </Col>

              <Col width='20%'>
                {permissions.prevention &&
                  <Badge badgeContent={dashboardInformation.guardian} color="error">
                    <Button name={`active-alerts-and-violations`} type={ButtonType.Primary} onClick={() => {
                      navigate('/guardian');
                    }}>
                      {translate('Guardian')}
                    </Button>
                  </Badge>
                }
              </Col>

              <Col width='20%'>
                {permissions.visio && user !== undefined && (user['profile']['UserEmail'].endsWith("@dairydatawarehouse.com") || user['profile']['UserEmail'].endsWith("@delaval.com")) &&
                  <Badge badgeContent={dashboardInformation.visio} color="error">
                    <Button name={`active-prescriptions`} type={ButtonType.Primary} onClick={() => {
                      navigate('/visio/prescriptions');
                    }}>
                      {translate('Visio')}
                    </Button>
                  </Badge>
                }
              </Col>
            </Row>

            <Header>
              <Row style={{ alignItems: 'center' }}>
                <IconContainer>
                  <ClipboardDataIcon />
                </IconContainer>
                {translate('Herd Overview')}
              </Row>
            </Header>

            <Row>
              <Col width='60%'>
                <MapContainer>
                  <ScatterMapbox latitude={viewport.latitude} longitude={viewport.longitude} zoom={viewport.zoom}
                    style={mapStyle} shapes={[]} />
                </MapContainer>
              </Col>

              <Col width='40%'>
                <Title>{translate('Herd Stability Scores')}</Title>

                <HerdStabilityScoresTable volatility={dashboardInformation.volatility} />

                <Row className='mt-5'>
                  <Col>
                    <Span style={{ fontWeight: 'bold' }}>
                      {translate('Herd size: ')}
                    </Span>
                  </Col>

                  <Col>
                    <Span>
                      {dashboardInformation.herdSize} {dashboardInformation.herdSize === 'N.A.' ? '' : translate('animals')}
                    </Span>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col width='60%'>
                <Alert severity={farmAddressAlertSeverity} maxWidth={'80%'}>
                  <>
                    {farmAddressAlertText}
                    {(farmAddressAlertSeverity === 'primary' ||
                      farmAddressAlertSeverity === 'warning' ||
                      (farmAddressAlertSeverity === 'danger' && !noHerdData)) && (
                        <FarmAddressAlertLink onClick={() => setShowDialog(true)}>
                          {farmAddressAlertSeverity === 'primary' && translate('Modify')}
                          {farmAddressAlertSeverity === 'warning' && translate(' Validate to obtain environmental forecast.')}
                          {farmAddressAlertSeverity === 'danger' &&
                            !noHerdData &&
                            translate(' Please add farm address to obtain environmental forecast.')}
                        </FarmAddressAlertLink>
                      )}
                    <FarmAddressDialog
                      showDialog={showDialog}
                      setShowDialog={setShowDialog}
                      latitude={latitude}
                      longitude={longitude}
                      address={farmAddress}
                      setOuterMapViewport={setViewport}
                    />
                  </>
                </Alert>
              </Col>
            </Row>
          </>


          :


          <>
            <Row style={{ alignItems: 'center' }}>
              <Col width='100%'>
                <Alert severity={herdProfileAlertSeverity} maxWidth={'70vw'}>
                  {herdProfileAlertSeverity === 'danger' && (
                    <div>
                      {herdId} - {translate('Data not available')}
                    </div>
                  )}
                  {herdProfileAlertSeverity === 'primary' && (
                    <div>{translate('Select a herd from the navigation bar above to start using PREDICTA')}</div>
                  )}
                  {herdProfileAlertSeverity === 'success' && (
                    <div>
                      {herdId} {herds.data.find((h) => h.id == herdId)?.name} - {translate('Loaded successfully')}
                    </div>
                  )}
                  {herdProfileAlertSeverity === 'warning' && (
                    <div>
                      {herdId} {herds.data.find((h) => h.id == herdId)?.name} -{' '}
                      {translate('Loaded successfully - WARNING: Data Quality not yet confirmed.')}
                    </div>
                  )}
                </Alert>
              </Col>
            </Row>

            <Header>
              <Row style={{ alignItems: 'center' }}>
                <IconContainer>
                  <ClipboardDataIcon />
                </IconContainer>
                {translate('Herd Overview')}
              </Row>
            </Header>

            <Row>
              <Col width='90%'>
                <MapContainer>
                  <ScatterMapbox latitude={viewport.latitude} longitude={viewport.longitude} zoom={viewport.zoom}
                    style={mapStyle} shapes={[]} />
                </MapContainer>
              </Col>
            </Row>

            <Row>
              <Col width='100%'>
                <Alert severity={farmAddressAlertSeverity} maxWidth={'70vw'}>
                  <>
                    {farmAddressAlertText}
                    {(farmAddressAlertSeverity === 'primary' ||
                      farmAddressAlertSeverity === 'warning' ||
                      (farmAddressAlertSeverity === 'danger' && !noHerdData)) && (
                        <FarmAddressAlertLink onClick={() => setShowDialog(true)}>
                          {farmAddressAlertSeverity === 'primary' && translate('Modify')}
                          {farmAddressAlertSeverity === 'warning' && translate(' Validate to obtain environmental forecast.')}
                          {farmAddressAlertSeverity === 'danger' &&
                            !noHerdData &&
                            translate(' Please add farm address to obtain environmental forecast.')}
                        </FarmAddressAlertLink>
                      )}
                    <FarmAddressDialog
                      showDialog={showDialog}
                      setShowDialog={setShowDialog}
                      latitude={latitude}
                      longitude={longitude}
                      address={farmAddress}
                      setOuterMapViewport={setViewport}
                    />
                  </>
                </Alert>
              </Col>
            </Row>

            <Row className='mt-3 mb-3' style={{ alignItems: 'center' }}>
              <Col width='50%'>
                {permissions.prevention &&
                  <Badge badgeContent={dashboardInformation.guardian} color="error">
                    <Button name={`active-alerts-and-violations`} type={ButtonType.Primary} onClick={() => {
                      navigate('/guardian');
                    }}>
                      {translate('Guardian')}
                    </Button>
                  </Badge>
                }
              </Col>

              <Col width='50%'>
                {permissions.visio && user !== undefined && (user['profile']['UserEmail'].endsWith("@dairydatawarehouse.com") || user['profile']['UserEmail'].endsWith("@delaval.com")) &&
                  <Badge badgeContent={dashboardInformation.visio} color="error">
                    <Button name={`active-prescriptions`} type={ButtonType.Primary} onClick={() => {
                      navigate('/visio/prescriptions');
                    }}>
                      {translate('Visio')}
                    </Button>
                  </Badge>
                }
              </Col>
            </Row>

            <Row>
              <Col width='90%'>
                <Title>{translate('Herd Stability Scores')}</Title>
                <HerdStabilityScoresTable volatility={dashboardInformation.volatility} />
              </Col>
            </Row>

            <Row>
              <Col>
                <Span style={{ fontWeight: 'bold' }}>
                  {translate('Herd size: ')}
                </Span>
              </Col>

              <Col>
                <Span>
                  {dashboardInformation.herdSize} {dashboardInformation.herdSize === 'N.A.' ? '' : translate('animals')}
                </Span>
              </Col>
            </Row>
          </>
        }
      </PageContainer>
    </>
  );
};
