import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getColor, getObjectValues } from '../../../../pages/utils';
import * as Plotly from 'plotly.js';
import { Data, Layout } from 'plotly.js';
import { ViewSize } from '@ddw/react-components';
import { ViewContext } from '@ddw/react-framework';
import { PLOTLY_TEMPLATE } from '../../../../pages/static';

export const StatsTopLeftBarChart: React.FC<any> = ({ statsData, model }) => {
  const { t: translate } = useTranslation();
  const { viewSize } = useContext(ViewContext);

  const chartRef = useRef<HTMLDivElement>(null);

  const maxEstimatedDiseaseIncidenceValue: number = (() => {
    let maxEstimatedDiseaseIncidenceValue: number = model == 1 ? 50. : 5.5;

    if (statsData?.estimatedDiseaseIncidenceByMonth && !statsData?.estimatedDiseaseIncidenceByMonth.every((value: number | null) => value === null)) {
      maxEstimatedDiseaseIncidenceValue = Math.max(...statsData?.estimatedDiseaseIncidenceByMonth);

      if (model == 1) {
        if (maxEstimatedDiseaseIncidenceValue > 50.) {
          maxEstimatedDiseaseIncidenceValue = Math.ceil(maxEstimatedDiseaseIncidenceValue / 10) * 10;
        }
      } else {
        if (maxEstimatedDiseaseIncidenceValue > 5.5) {
          maxEstimatedDiseaseIncidenceValue = Math.ceil(maxEstimatedDiseaseIncidenceValue);
        }
      }
    }

    return maxEstimatedDiseaseIncidenceValue;
  })();

  const data: Data[] = [{
    type: 'scatter',
    x: getObjectValues<string>(statsData?.statsPath?.Period).map((period: string) => translate(period.substring(0, 3)) + period.substring(3)),
    y: statsData?.estimatedDiseaseIncidenceByMonth,
    name: translate('Estimated Disease Incidence'),
    yaxis: 'y2',
    line: { dash: 'solid', color: getColor(model, 2) }
  }, {
    type: 'bar',
    x: getObjectValues<string>(statsData?.statsPath?.Period).map((period: string) => translate(period.substring(0, 3)) + period.substring(3)),
    y: getObjectValues<number>(statsData?.statsPath['Total Alarms']).map((value: any, index) => value / getObjectValues<number>(statsData.statsPath['Total Eligible'])[index]),
    opacity: 1.,
    name: translate('Total Alarms'),
    marker: { color: getColor(model, 0) }
  }, {
    type: 'bar',
    x: getObjectValues<string>(statsData?.statsPath?.Period).map((period: string) => translate(period.substring(0, 3)) + period.substring(3)),
    y: getObjectValues<number>(statsData?.statsPath['Total Eligible']).map((value: any, index) => (value - getObjectValues<number>(statsData.statsPath['Total Alarms'])[index]) / value),
    opacity: 1.,
    name: translate('Not alarmed'),
    marker: { color: getColor(model, 1) }
  }
  ];

  const layout: Partial<Layout> = {
    barmode: 'stack',
    title: viewSize > ViewSize.S ? translate('Alarm rate and estimated incidence - last 12 months') : '',
    showlegend: true,
    legend: {
      xanchor: 'center',
      x: 0.5,
      yanchor: 'bottom',
      y: 1,
      orientation: 'h'
    },
    xaxis: { tickangle: -30 },
    yaxis: { title: translate('Alarm rate') },
    yaxis2: {
      anchor: 'x',
      overlaying: 'y',
      side: 'right',
      title: translate('Estimated Disease Incidence (%)'),
      range: [0., maxEstimatedDiseaseIncidenceValue + 5]
    },
    hovermode: 'closest',
    template: PLOTLY_TEMPLATE
  };

  useEffect(() => {
    if (chartRef.current) {
      Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
    }
  }, [viewSize]);

  return (
    <>
      <div ref={chartRef} />
    </>
  );
};