import styled from 'styled-components';

type MapContainerProps = {
  width?: string;
}

export const MapContainer = styled.div<MapContainerProps>`
  // height: 75vh;
  width: ${props => props.width ? props.width : '45vw'};
  padding: 5px;
  margin-left: 15px;
  margin-bottom: 15px;

  @media (max-width: 576px) {
    margin-left: 0;
    width: 80vw;
  }
`;