import styled from 'styled-components';
import { MediaQuery } from '@ddw/react-components';

export const MilkUnitSelectParent = styled.div`
  width: 450px;
  pointer-events: none;
  opacity: 0.7;
  color: gray;
  
  @media ${MediaQuery.S} {
    width: auto;
  }
`;