import React, { PropsWithChildren } from 'react';
import { PageContent } from './pages.styled';
import { Footer } from '../Footer';
import { ActivityIndicator } from '@ddw/react-components';

type PageContainerProps = PropsWithChildren & {
  loading?: boolean;
  hasFooter?: boolean;
};

export const PageContainer: React.FC<PageContainerProps> = ({ hasFooter = true, loading = false, children }) => {
  return (
    <>
      <PageContent overflow={'true'}>
        {loading ? (
          <ActivityIndicator size={200} />
        ) : (
          <>
            {children}
            {hasFooter && <Footer />}
          </>
        )}
      </PageContent>
    </>
  );
};
