import Plotly, { Data, Layout } from "plotly.js";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { DDW_blue, DDW_green, KPI_CODES, PLOTLY_TEMPLATE } from "../../../../../pages/static";

type HerdfolioMilkHistoricLactationChartProps = {
    historicAverage: number[];
    monthlyHerdKpis: any;
};

export const HerdfolioMilkHistoricLactationChart: React.FC<HerdfolioMilkHistoricLactationChartProps> = ({ historicAverage, monthlyHerdKpis }) => {
    const { t: translate } = useTranslation();

    const chartRef = useRef<HTMLDivElement>(null);

    const minimumMilkValue: number = Math.min(...historicAverage);

    const averageDIMValues: number[] = monthlyHerdKpis.filter((data: any) => data.kpiCode === KPI_CODES['AverageDimMilkedCows']).map((x: any) => x.value);
    const dim220Replacement: number = averageDIMValues.length > 0 ? Number(Number(averageDIMValues[averageDIMValues.length - 1]).toFixed(0)) : 220;

    const data: Data[] = [
        {
            x: Array.from({ length: 306 }, (_, j) => j),
            y: historicAverage,
            mode: 'lines',
            name: translate('Average'),
            line: { dash: 'dash', color: '#000000' },
            showlegend: true
        },
        {
            x: [180, 180],
            y: [historicAverage[180], minimumMilkValue],
            mode: 'lines+markers',
            opacity: 1,
            name: translate('Average DIM 180'),
            line: { color: DDW_green, dash: 'solid' },
            marker: { symbol: 'diamond' },
            showlegend: false,
            hovertemplate: translate('Average DIM 180')
        },
        {
            // x: [220, 220],
            x: [dim220Replacement, dim220Replacement],
            // y: [historicAverage[220], minimumMilkValue],
            y: [historicAverage[dim220Replacement], minimumMilkValue],
            mode: 'lines+markers',
            opacity: 1,
            // name: translate('Average DIM 220'),
            name: translate('Average DIM') + ' ' + dim220Replacement,
            line: { color: DDW_blue, dash: 'solid' },
            marker: { symbol: 'diamond' },
            showlegend: false,
            // hovertemplate: translate('Average DIM 220')
            hovertemplate: translate('Average DIM') + ' ' + dim220Replacement
        },
        {
            x: [0, 180],
            y: [historicAverage[180], historicAverage[180]],
            mode: 'lines+markers',
            opacity: 1,
            name: translate('Average daily production'),
            line: { color: DDW_green, dash: 'solid' },
            marker: { symbol: 'diamond' },
            showlegend: false,
            customdata: [historicAverage[180].toFixed(2), historicAverage[180].toFixed(2)],
            hovertemplate: 'DIM 180, %{customdata} L'
        },
        {
            // x: [0, 220],
            x: [0, dim220Replacement],
            // y: [historicAverage[220], historicAverage[220]],
            y: [historicAverage[dim220Replacement], historicAverage[dim220Replacement]],
            mode: 'lines+markers',
            opacity: 1,
            name: translate('Average daily production'),
            line: { color: DDW_blue, dash: 'solid' },
            marker: { symbol: 'diamond' },
            showlegend: false,
            // customdata: [historicAverage[220], historicAverage[220]],
            customdata: [historicAverage[dim220Replacement].toFixed(2), historicAverage[dim220Replacement].toFixed(2)],
            hovertemplate: 'DIM ' + dim220Replacement + ', %{customdata} L'
        }
    ];

    const layout: Partial<Layout> = {
        title: translate('Daily production in function of DIM'),
        yaxis: {
            title: translate('Daily Milk Yield (kg)'),
        },
        xaxis: {
            title: translate('DIM'),
        },
        showlegend: true,
        legend: {
            xanchor: 'center',
            x: 0.5,
            yanchor: 'bottom',
            y: 1,
            orientation: 'h',
        },
        hovermode: 'closest',
        hoverlabel: { namelength: -1 },
        template: PLOTLY_TEMPLATE
    };

    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
        }
    }, [historicAverage]);

    return (
        <div ref={chartRef} />
    );
};