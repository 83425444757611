import { useContext } from 'react';
import { AuthenticationContext } from '@ddw/react-framework';
import { Api } from '../api';
import { DDWApplication } from '@ddw/react-components';
import { HerdProfileContext } from '../../context';
import { PredictaUserSettings } from '../../typings/types';

export const useFetchUserSettings = () => {
  const { getAccessToken } = useContext(AuthenticationContext);
  const { herdId } = useContext(HerdProfileContext);

  const fetchUserSettings = async () => {
    return await Api.userSettings.fetchUserSettings(getAccessToken(), DDWApplication.DairyInsights, herdId);
  };

  return { fetchUserSettings };
};

export const useUpdateUserSettings = (newSettings: PredictaUserSettings) => {
  const { getAccessToken } = useContext(AuthenticationContext);
  const { herdId } = useContext(HerdProfileContext);

  const updateUserSettings = async () => {
    return await Api.userSettings.updateUserSettings(getAccessToken(), DDWApplication.DairyInsights, herdId, newSettings);
  };

  return { updateUserSettings }
};
