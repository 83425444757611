import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonType,
  Dialog,
  DialogButtonsRightSide,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogSize,
  IconLib
} from '@ddw/react-components';
import { Col, IconContainer, Row } from '../../pages/components';
import { AtIcon, PeopleFillIcon } from '../Icons';
import { NotificationUserContainer } from '../NotificationUserContainer';
import { HerdSettingsUsersContext, HerdSettingsUsersContextType } from '../../context/HerdSettingsUsersContext';
import { HerdProfileContext } from '../../context';
import { cloneDeep } from 'lodash';

export const NotificationSettingsDialog: React.FC<any> = ({
  headerName,
  showDialog,
  setShowDialog,
  isAdmin,
  tzOffset,
  modelId,
  setUniqueSummary,
  setShowSaveHint
}) => {
  const { t: translate } = useTranslation();
  const { herdId } = useContext(HerdProfileContext);
  const { users, setUsers } = useContext<HerdSettingsUsersContextType>(HerdSettingsUsersContext);

  const [saved, setSaved] = useState<boolean>(false);

  const settingsCopy = useMemo(() => {
    return cloneDeep(users[modelId]);
  }, [saved]);

  return (
    <>
      <Dialog size={DialogSize.S} show={showDialog} setShow={setShowDialog} closeOnClickOutside={false}
        customMinHeight={'700px'}>
        <DialogHeader>
          <h3 style={{ margin: '0px' }}>{headerName}</h3>
        </DialogHeader>

        <DialogContent>
          <Row className={'mt-4 mb-4'}>
            <Col width={'3%'}>
              <IconContainer>
                <AtIcon />
              </IconContainer>
            </Col>

            <Col style={{ fontWeight: 'bold' }} width={'90%'}>
              {translate('Notification settings')}
            </Col>
          </Row>

          <NotificationUserContainer settings={users[String(modelId)]['primary']} primary={true} admin={isAdmin}
            utc_offset={tzOffset} index={0}
            modelId={modelId} />

          <Row style={{ alignItems: 'center' }} className={'mt-4 mb-4'}>
            <PeopleFillIcon className={'mr-3'} />
            <h3>{translate('Secondary users')}</h3>
          </Row>

          {users[String(modelId)]['secondary'] &&
            users[String(modelId)]['secondary'].filter((notification: any) => !notification['_removed'])
              .map((notification: any, index: number) => {
                return (
                  <div key={notification['UniqueIdentifier']}>
                    <NotificationUserContainer settings={notification} primary={false} admin={isAdmin}
                      utc_offset={tzOffset} index={index + 1}
                      modelId={modelId} />
                  </div>
                );
              })
          }

          <Row className={'mt-4 mb-4'}>
            <Button name={`add-user`} isDisabled={!isAdmin} icon={IconLib.add} onClick={() => {
              users[String(modelId)]['secondary'].push({
                '_confirmed': false,
                'GuardianNotificationSettingsId': null,
                'HerdProfileId': herdId,
                'GuardianModelId': modelId === 'unique' ? null : parseInt(modelId),
                'PrimaryUser': false,
                'ModelAlarmFrequency': 1,
                'AlarmNotificationMode': 0,
                'NotificationLanguage': 2057,
                'NotificationEmail': null,
                'NotificationMobile': null,
                'NotificationWeekDay': 1,
                'NotificationHourUTC': 9,
                'email_modified': false,
                '_newlyAdded': true,
                'UniqueIdentifier': Math.random().toString(36) // this is only used as key for rendering
              });
              console.log('Add user - users', users);
              setUsers({ ...users });
            }}>
              {translate('Add user')}
            </Button>
          </Row>
        </DialogContent>

        <DialogFooter>
          <DialogButtonsRightSide>
            <Button name={`ok`} type={ButtonType.Primary} isOutlined={false} onClick={() => {
              let summary = ((users[String(modelId)]['primary'] && users[String(modelId)]['primary']['NotificationEmail']) ?
                (users[String(modelId)]['primary']['NotificationEmail'] + '; ') : '') + ((users[String(modelId)]['primary'] && users[String(modelId)]['primary']['NotificationMobile']) ?
                  (users[String(modelId)]['primary']['NotificationMobile'] + '; ') : '');

              if (users[String(modelId)]['primary']) {
                users[String(modelId)]['primary']['_confirmed'] = true;
              }

              for (let i = 0; i < users[String(modelId)]['secondary'].length; i++) {
                if (!users[String(modelId)]['secondary'][i]['_removed']) {
                  users[String(modelId)]['secondary'][i]['_confirmed'] = true;

                  if (setUniqueSummary !== null && users[String(modelId)]['secondary'][i]['NotificationEmail']) {
                    summary += users[String(modelId)]['secondary'][i]['NotificationEmail'] + '; ';
                  }
                  if (setUniqueSummary !== null && users[String(modelId)]['secondary'][i]['NotificationMobile']) {
                    summary += users[String(modelId)]['secondary'][i]['NotificationMobile'] + '; ';
                  }
                }
              }

              if (setUniqueSummary !== null) {
                setUniqueSummary(summary);
              }
              
              setSaved(true);
              setShowSaveHint(true);
              setShowDialog(false);
              console.log('Ok - users', users);
            }} isDisabled={!isAdmin}>
              {translate('OK')}
            </Button>
            <Button name={`cancel`} type={ButtonType.Cancel} isOutlined={false} onClick={() => {
              users[modelId] = { ...settingsCopy };
              setShowDialog(false);
              console.log('Cancel - users', users);
            }} isDisabled={!isAdmin}>
              {translate('Cancel')}
            </Button>
          </DialogButtonsRightSide>
        </DialogFooter>
      </Dialog>
    </>
  );
};