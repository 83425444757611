import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Plotly from 'plotly.js';
import { Data, Layout } from 'plotly.js';
import { PLOTLY_TEMPLATE } from '../../../../pages/static';
import { shortenIfPossible } from '../../../../pages/utils';

export const RadarChart: React.FC<any> = ({ radar }) => {
  const { t: translate } = useTranslation();

  const chartRef = useRef<HTMLDivElement>(null);

  const RADAR_DISPLAY_CATEGORIES = [translate('DryOff'), shortenIfPossible(translate('Fertility')), translate('Inventory'), translate('Milking'), translate('MRO'), shortenIfPossible(translate('Health'))];

  const data: Data[] = [
    {
      type: 'scatterpolar',
      r: radar,
      theta: RADAR_DISPLAY_CATEGORIES,
      fill: 'toself',
      marker: { color: 'rgb(0, 255, 255)' }
    }
  ];

  const layout: Partial<Layout> = {
    height: 350,
    margin: { l: 50, r: 50, t: 25, b: 25 },
    polar: {
      radialaxis: {
        visible: true,
        range: [0, 1]
      }
    },
    showlegend: false,
    autosize: true,
    template: PLOTLY_TEMPLATE
  };

  useEffect(() => {
    if (chartRef.current) {
      Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
      //Plotly.newPlot(chartRef.current, data, layout);
    }
  }, []);

  return (
    <>
      <div ref={chartRef} />
    </>
  );
};