import Plotly, { Data, Layout } from "plotly.js";
import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { INVENTORY_COLOURS, INVENTORY_SELECTION, PLOTLY_TEMPLATE } from "../../../../pages/static";
import { getObjectValues } from "../../../../pages/utils";
import { ViewContext } from "@ddw/react-framework";
import { ViewSize } from "@ddw/react-components";

type VisioFarmPopulationEvolutionChartProps = {
    trace1x: string[];
    trace1y: number[];
    trace2x: string[][];
    trace2y: number[][] | { [index: string]: number }[];
    trace3y: { [index: string]: number }[];
    trace4x: string[][];
    trace4y: { [index: string]: number }[];
    tAxis: string[];
    population: string;
};

export const ForecastedFarmPopulationEvolutionChart: React.FC<VisioFarmPopulationEvolutionChartProps> = (props) => {
    const { t: translate } = useTranslation();
    const { viewSize } = useContext(ViewContext);

    const chartRef = useRef<HTMLDivElement>(null);

    const trace3Data: Data[] = props.trace3y.map((_: { [index: string]: number }, index: number) => (props.population === 'abs' ? {
        x: props.tAxis,
        y: getObjectValues<number>(_),
        mode: 'lines',
        opacity: 1,
        name: translate(INVENTORY_SELECTION[index]),
        legendgroup: translate(INVENTORY_SELECTION[index]),
        line: {
            color: INVENTORY_COLOURS[index],
            dash: 'dash'
        },
        visible: INVENTORY_SELECTION[index] === 'Young' ? 'legendonly' : true,
        showlegend: true
    } : {
        x: props.tAxis,
        y: getObjectValues<number>(_),
        type: 'bar',
        opacity: 0.75,
        name: translate(INVENTORY_SELECTION[index]),
        legendgroup: translate(INVENTORY_SELECTION[index]),
        marker: {
            color: INVENTORY_COLOURS[index]
        },
        visible: INVENTORY_SELECTION[index] === 'Young' ? 'legendonly' : true
    }));

    const trace4Data: Data[] = props.trace4x.map((_: string[], index: number) => ({
        x: _,
        y: getObjectValues<number>(props.trace4y[index]),
        mode: 'lines',
        opacity: 0.50,
        name: translate(INVENTORY_SELECTION[index]),
        legendgroup: translate(INVENTORY_SELECTION[index]),
        line: {
            color: INVENTORY_COLOURS[index],
            dash: 'dash'
        },
        visible: INVENTORY_SELECTION[index] === 'Young' ? 'legendonly' : true,
        showlegend: false,
    }));

    const data: Data[] = [...trace3Data, ...trace4Data];

    const layout: Partial<Layout> = {
        title: viewSize <= ViewSize.S ? '' : translate('Forecasted farm population evolution'),
        barmode: props.population === 'abs' ? 'group' : 'relative',
        bargap: props.population === 'abs' ? 0.1 : 0.,
        xaxis: {
            title: translate('Date'),
        },
        yaxis: {
            title: props.population === 'abs' ? translate('Population count') : props.population === 'rel' ?
                translate('Relative population (%)') : translate('Total population count'),
        },
        showlegend: true,
        legend: {
            xanchor: 'center',
            x: 0.5,
            yanchor: 'bottom',
            y: 1,
            orientation: 'h',
        },
        hovermode: 'closest',
        template: PLOTLY_TEMPLATE
    };

    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
        }
    }, [props, viewSize]);

    return (
        <div ref={chartRef} />
    );
};
