import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
//import * as serviceWorker from './serviceWorker';

import { ApplicationSettings } from '@ddw/react-framework';
import { ApplicationSettingsProvider, fetchSettings } from './context';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

(async function () {
  const settings: ApplicationSettings = await fetchSettings();

  ReactDOM.render(
    <ApplicationSettingsProvider>
      <I18nextProvider i18n={i18n}>
        <App settings={settings} />
      </I18nextProvider>
    </ApplicationSettingsProvider>,
    document.getElementById('root')
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
