import MarkdownIt from 'markdown-it';
import React from 'react';

export type FAQ = {
  title: string;
  content: string;
}

export const getGeneralFAQs = (translate: Function): FAQ[] => {
  return [
    {
      title: translate('How does Predicta GUARDIAN work?'),
      content: '1. ' + translate('Sign up for the Predicta GUARDIAN service on https://www.predicta-ddw.com/ or speak to your vet. A DDW representative will call you and help you to connect your herd to the DDW dairy data platform.') +
        '\n' + '2. ' + translate('Once you have connected your herd to the DDW platform, Predicta GUARDIAN processes your herd data on a daily basis through its artificial intelligence engine.') +
        '\n' + '3. ' + translate('Predicta GUARDIAN detects animals at high risk for Ketosis and will notify you around 4 weeks prior to their expected calving date.') +
        '\n' + '4. ' + translate('You will receive the alarm cows with their EarTag via your preferred communication channel (SMS, email, WhatsApp).')
    },
    {
      title: translate('How does Predicta GUARDIAN know the expected calving date?'),
      content: '- ' + translate('Predicta GUARDIAN is processing the complete data set coming out of the herd management system.') +
        '\n- ' + translate('To calculate the expected calving date, Predicta GUARDIAN takes into account insemination dates, pregnancy check results, dry off dates as well as historic calving dates of each animal.') +
        '\n- ' + translate('Consequently, if any of the above events is entered wrongly in the herd management software, the expected calving date might differ from the actual calving date. In this case the KETORISK alarm might be delivered earlier or later than 3-4 weeks prior to the actual calving date.')
    },
    {
      title: translate('How does Predicta calculate the estimated disease incidence for the first 42 DIM and how accurate is it?'),
      content: translate('Predicta uses the same inputs that it uses to generate disease risk alarms for transition cows. It then derives the estimated disease incidence for the herd. Currently Predicta is able to do that for Ketosis and as a combined incidence for all cases of retained placenta, milk fever, displaced abomasum, lameness and mastitis. It is always the combined incidence value of all three diseases, you see repeated for each of the 5 remaining diseases. Differences between those five diseases may of course occur in the herd, but can currently not be specified by the model. That means that for any of the 5 diseases, the incidence can be lower than shown, however is unlikely to be significantly higher than shown.') +
        '\n\n' + translate('Note, that these are estimations and do not strive to provide a maximum level of accuracy. They are designed to support decision making around whether it should be assumed.')
    },
    {
      title: translate('What is Sensitivity and Specificity?'),
      content: '- ' + translate('Sensitivity is the ability to detect a disease case. If sensitivity is set to \'high\', only very few actual disease cases will be missed. However there will also be more false alarms, where an alarm is raised but the animals is actually not developing the condition.') +
        '\n- ' + translate('Specificity measures the ability of the model to not raise alarms for animals that will not develop the condition. If specificity is high, the number of false alarms is minimised.') +
        '\n- ' + translate('Most of the time, sensitivity and specificity are connected: If you want to be on the safe side and miss only very few cases, you will need to be ready to receive more alarms and some of them will actually be false (positive) alarms. You can see the relation between sensitivity and specificity in the ROC curve of the Ketorisk model.')
    },
    {
      title: translate('What is a ROC curve?'),
      content: '- ' + translate('A ROC (Receiver Operated Curve) shows the relation between sensitivity and specificity at all possible setting points of the model.') +
        '\n- ' + translate('Usually sensitivity is shown on the vertical axis in percent. The horizontal axis shows the False Positive Rate (FPR), that is 1 - Specificity (For example: 40% FPR = 60% specificity).')
      // + '\n-' + translate('If you would like to use the ROC to configure the Ketorisk model to your specific needs, you can do so here:')
    },
    {
      title: translate('What is Prevalence and Incidence?'),
      content: '- ' + translate('Incidence is the number of observed new cases of a certain disease in your herd in a given period. For example at DIM 5, 22% of your dairy cows that had not had a Ketosis diagnoses in the current lactation yet may be newly diagnosed with Ketosis') +
        '\n- ' + translate('Prevalence is the total number of cases (including old and new cases) observed. For example at DIM 5, 28% of your dairy cows may have been diagnosed with Ketosis previously in this lactation or may be newly diagnosed with Ketosis.')
    },
    {
      title: translate('Why do I need Predicta GUARDIAN alarm configurator?'),
      content: '- ' + translate('The alarm configurator allows you to customize the performance of Predicta GUARDIAN to your needs. For example it can help you to make an educated decision how many alarms you are willing to check in each period and how many cases of Ketosis you are accepting to miss in order to manage the number of alarms you will be receiving.') +
        '\n- ' + translate('Predicta GUARDIAN will automatically deliver the alarms for the animals in your herd to you through the channel you specified and the language you have selected under https://www.predicta-ddw.com/home. If you would like to modify the language, please change first settings under https://www.predicta-ddw.com/home and then go to notification settings, select “ok” and then save before closing the page.')
    },
    {
      title: translate('How do I use Predicta GUARDIAN alarm configurator?'),
      content: translate('The most sustainable outcome (low environmental footprint+ positive economic result) is achieved, when the prevention management for transition cow diseases is adapted to the estimated incidence of those diseases in a herd. Low incidence (i.e. below 10% Ketosis incidence) might mean for most herds not to deploy resources towards specific Ketosis prevention measures, high incidence (i.e. >30% Ketosis incidence) might mean for most herds to use a high sensitivity setting. The difficulty for a herd manager is sometimes to provide a meaningful estimation of incidence for the different diseases. Predicta can take care of the whole disease estimation and model configuration process for you!') +
        '\n\n' + '1. ' + translate('Navigate to \'Settings\' on the vertical menu on the left.') +
        '\n' + '2. ' + translate('Configure your alarm reception settings (frequency, channel).') +
        '\n' + '3. ' + translate('Enjoy getting risk alerts for dry animals at risk.') +
        '\n\n' + translate('If you would like to override the artificial intelligence functionality just de-select the tick in the “Predicta” column. You can now select manually a sensitivity setting you think is more appropriate for the given disease.') +
        '\n\n' + '4. ' + translate('Select Express, if you want to complete the configuration as quickly as possible.') +
        '\n' + '5. ' + translate('Enter estimated incidence or run with default incidence.') +
        '\n' + '6. ' + translate('Observe estimated operating conditions and potentially \'play\' with different sensitivity settings to see how many alarms per month you would be receiving approximately.') +
        '\n' + '7. ' + translate('Select your preferred notification settings.') +
        '\n' + '8. ' + translate('Save your settings configuration.') +
        '\n' + '9. ' + translate('Predicta GUARDIAN will automatically deliver the alarms for the animals in your herd to you through the channel you specified.')
    },
    {
      title: translate('How do I stop the Predicta GUARDIAN service?'),
      content: translate('There are 3 possibilities to stop receiving Predicta GUARDIAN services:') +
        '\n- ' + translate('Remove your phone number and/or email from the settings page in the Predicta GUARDIAN application. We will stop sending you alarms but might continue to upload your herd data to the DDW cloud. Your non-anonymized data will not be shared with any third party without your explicit consent.') +
        '\n- ' + translate('Send us an email to info@dairydatawarehouse.com and request to stop receiving Predicta GUARDIAN. You can also request us to stop any further farm data upload and to remove all herd data you have previously shared with us.') +
        '\n- ' + translate('Reply \'STOP\' to the SMS message you are receiving. We will stop sending you alarms but might continue to upload your herd data to the DDW cloud. Your non-anonymized data will not be shared with any third party without your explicit consent.')
    },
    {
      title: translate('What should I do with animals that have a data quality alert?'),
      content: '- ' + translate('If we cannot retrieve data in the necessary quality for a certain animal, we are unable to produce a meaningful Ketorisk alarm.') +
        '\n- ' + translate('This could be for example missing milk meter or milk recording data.') +
        '\n- ' + translate('When you visit predicta-ddw.com/ketorisk, you can find information about which animals and for what reason cannot receive Ketorisk alarms due to quality issues. We recommend that you visit this information regularly to be aware of animals where no alarm can be raised.') +
        '\n- ' + translate('As soon as you can correct the data quality issue in the herd management software, we will include the animal into the alarm program.')
    },
    {
      title: translate('What exactly means the risk number in yellow in the report of currently dry animals?'),
      content: translate('An animal with a value of 0.28 has a probability of 28% to get Ketosis. It depends on the level of sensitivity the user has selected under \'Settings\' down to what probability threshold animals are flagged at being at risk. This value should only be used as additional information. All animals flagged at risk for Ketosis should undergo preventive attention. The decision on what Ketosis prevention strategy should be reflected in the sensitivity settings (see Q+A: How Do I risk Predicta GUARDIAN alarm configurator?)')
    },
    {
      title: translate('Who is DDW?'),
      content: translate('Dairy Data Warehouse (DDW) specializes in dairy farm data services for all stakeholders of the dairy value chain. Our headquarters and customer services, including our multilingual helpdesk are based in Assen, Netherlands.') +
        '\n\n' + translate('DDW uses its proprietary technology such as the farm data uploader and the DDW dairy data platform to enable farmers to connect their herd data to the cloud and leverage the power of BIG Data on their farm.') +
        '\n\n' + translate('Find more information on https://www.dairydatawarehouse.com/')
    },
    {
      title: translate('How is DDW ensuring the safety and security of my farm data?'),
      content: '- ' + translate('Farmers are owners of their farm data.') +
        '\n- ' + translate('DDW will never share any non-anonymized farm data with a third party without the explicit content of the farmer for each respective third party (for example the veterinarian)') +
        '\n- ' + translate('DDW uses latest cloud technology to ensure data safety and security for the data its clients have entrusted it with.') +
        '\n- ' + translate('Find more information on https://www.dairydatawarehouse.com/Pages/data-protection.html')
    },
    {
      title: translate('Data Quality Violation: Reproduction'),
      content:
        `**` + translate('Invalid calving interval:') + `**` +
        '\n\n- ' + translate('The minimum calving interval used by Predicta GUARDIAN is 300 days. Cows which violate this rule have a calving interval of less than 300 days.') +
        '\n\n' + `**` + translate('Invalid dry off length:') + `**` +
        '\n\n- ' + translate('Predicta GUARDIAN can predict whether a cow will have a valid dry off period length based on the expected calving date. Cows violating this rule have a predicted dry off period length shorter than 25 days or longer than 150 days.') +
        '\n\n' + `**` + translate('No insemination data for lactation:') + `**` +
        '\n\n- ' + translate('Individual cows require insemination data to be eligible for predictions. Cows violating this rule have no inseminations during the last lactation prior to the dry off event.') +
        '\n\n' + `**` + translate('Invalid age at calving:') + `**` +
        '\n\n- ' + translate('The minimum age at calving in months used in Predicta GUARDIAN is 22. Cows violating this rule are younger than 22 months at calving.') +
        '\n\n' + `**` + translate('Missing dry off event:') + `**` +
        '\n\n- ' + translate('A dry off event must be registered for each individual cow. Cows violating this rule don’t have a dry off event.')
    },
    {
      title: translate('Data Quality Violation: Milk Production'),
      content: '**' + translate('Last MRO (Milk Recording Organization Event) <= 45 days before dry:') + '**' +
        '\n\n- ' + translate('When the system uses MRO data, the latest MRO data should be recorded less or equal to 45 days prior to the dry off date. Cows violating this rule have the latest milk recording date further away than 45 days before the dry off event date.') +
        '\n\n**' + translate('Less than 6 MRO visits in lactation:') + '**' +
        '\n\n- ' + translate('Predicta GUARDIAN requires a minimum of 6 MRO dates per lactation. These do not need to be consecutive. Cows violating this rule have less than 6 MRO events during the last lactation prior to the dry off event.') +
        '\n\n**' + translate('No Fat and Protein information:') + '**' +
        '\n\n- ' + translate('Predicta GUARDIAN requires fat and protein information to predict Milk Fever Risk when using MRO data. Cows violating this rule don’t have fat nor protein values during the last lactation prior to the dry off event.') +
        '\n\n**' + translate('Milk meter covers <80% of lactation:') + '**' +
        '\n\n- ' + translate('Individual cows require >80% of daily milk yields per lactation to be eligible for predictions. Cows violating this rule don’t have 80% of the daily milk yield during the last lactation prior to the dry off event.')
    }
  ];
};

export const getKetosisFAQs = (translate: Function): FAQ[] => {
  return [
    {
      title: translate('Why should I use the Predicta GUARDIAN prediction?'),
      content: '- ' + translate('Ketosis is a costly disease for dairy farmers and makes cows feel miserable when it occurs.') +
        '\n- ' + translate('Beyond its direct negative effects on milk production, it also puts cows at a much higher risk for other diseases. Ketosis is often the beginning of a series of disease events throughout lactation.') +
        '\n- ' + translate('It is not easy for farmers to identify cows, who are at high risk to develop Ketosis prior to them showing actual symptoms.') +
        '\n- ' + translate('There are effective ways of preventing Ketosis, but some preventative treatments need to be applied 3-4 weeks before the expected calving date.') +
        '\n- ' + translate('Predicta GUARDIAN uses artificial intelligence to alert farmers early during the dry period to cows with a high risk of developing Ketosis in the following lactation.') +
        '\n- ' + translate('Predicta GUARDIAN gives farmers the choice to prevent Ketosis and its negative effects on cows and economic farm results.')
    },
    {
      title: translate('What is Ketosis in dairy cows?'),
      content: translate('Ketosis is commonly believed to be the central factor for dairy cow health and production. Historically Ketosis was classified as being either \'primary\' or \'secondary\', the latter being a consequence of preceding disease (Gordon et al. 2013). Today, ketosis is mainly understood as being a consequence from an excessive and ill-regulated Negative Energy Balance after calving.') +
        '\n\n' + translate('Ketosis is differentiated into a clinical and a subclinical form, the latter today attracting most research and generally believed to be the economic most relevant as being the \'tip of the iceberg\'. For diagnostic purposes, today generally beta-hydroxybutyrate (BHB) is used as a marker, for the use of nonesterified fatty acids, which would in diagnostic terms be even more significant, is largely limited to laboratory testing. BHB can easily be tested by a variety of devices in blood, milk and urine.')
    },
    {
      title: translate('How is Ketosis diagnosed?'),
      content: translate('The diagnosis of clinical ketosis is based on the presence of risk factors (early lactation), changes in behavior, mostly reduced milk production, an erratic feeding pattern and decreased activity. The picture is being described as \'a gradual loss of appetite and milk production over several days\' (Smith 2002).') +
        '\n\n' + translate('As the clinical signs are determining the diagnosis, there is little information on what blood parameters are to be expected. Generally, an animal in clinical ketosis may be expected to show blood values of more than 3 mmol/l (Oetzel 2007). In milk, animals with clinical ketosis may exhibit a BHB concentration of more than 0.8 mmol/l (Smith 2002). While the clinical form is associated with various signs, the definition of subclinical ketosis is somewhat arbitrary. Most definitions use the level of beta-hydroxybutyrate in blood, the threshold set between 1,0 mmol/l and 1,4mmol/l. A range of between 1.2 and 2.9 mmol/l is occasionally used to explicitly mark subclinical ketosis (McArt et al. 2012). The study of Suthar et al. (2013) uses various cut-off points of 1.0 to 1.7 mmol/l, respectively. In milk, a quantity of 0.1 mmol/l BHB is believed to be indicative of SCK (Roos et al. 2007; Berge und Vertenten 2014). In a large study covering several hundred Canadian dairy herds, a cut-off value of 0.15 mmol/l BHB was used (Tatone et al. 2017).')
    },
    {
      title: translate('How frequent is Ketosis in Dairy Cows?'),
      content: translate('It appears questionable to differentiate the forms of ketosis, instead, ketosis should be assessed by measured parameters instead of relying on the recognition of clinical signs. (Oetzel 2007). The prevalences reported in literature are deriving from field studies, using both milk and serum values. - Using 1.2 mmol/l in blood: Prevalences in different European countries ranging from 11.2% to 36.6%, in average 21.8% (Suthar et al. 2013) - Using 0.15 mmol/l in milk: Prevalence in Ontario of 21% with herd prevalence of 11% - 31%. Seasonality with lowest prevalences July - November. Higher prevalences in farms with AMS (Tatone et al. 2017) - Using 0.1 mmol/l in milk: Prevalence in day 7 - 21 pp being 43% in Germany, 53% in France, 31% in Italy, 46% in the Netherlands, and 31% in the United Kingdom.') +
        '\n\n' + translate('85% of farms assessed having more than 25% of fresh cows with subclinical ketosis. Clinical ketosis seldom reported, highest incidence of clinical ketosis was 23%. larger herd size negatively correlated with ketosis, positive correlation with part-TMR. Lower incidence from April to June. (Berge und Vertenten 2014). - Using 1.2 mmol/l to 2.9 mmol/l in blood: 43% of 1717 cows with at least one measurement above the threshold within 16 DIM (McArt et al. 2012).')
    },
    {
      title: translate('Are cows with Ketosis at higher risk for other diseases compared to cows without Ketosis?'),
      content: translate('A whole body of literature describes the role of ketosis as initial for further disease.') +
        '\n\n' + translate('An example will be given (Raboisson et al. 2014): The relative risk or OR (95% confidence interval) for cases of subclinical ketosis based on a cut off value of 1.4 mmol/l were:') +
        '\n- ' + translate('abomasal displacement 3.33 (2.60-4.25)') +
        '\n- ' + translate('clinical ketosis 5.38 (3.27-8.83)') +
        '\n- ' + translate('early culling and death 1.92 (1.60-2.30)') +
        '\n- ' + translate('metritis 1.75 (1.54-2.01)') +
        '\n- ' + translate('placental retention 1.52 (1.20-1.93)') +
        '\n- ' + translate('clinical mastitis 1.61 (1.24-2.09)') +
        '\n- ' + translate('lameness 2.01(1.64-2.44)') +
        '\n- ' + translate('The direct mean +- standard deviation of the 305-d milk losses associated with SCK were 251 +- 73 kg after adjusting for abomasal displacement, clinical ketosis, metritis, and placental retention. In a model trying to calculate the cost of subclinical ketosis (defined at values above 1.2 mmol/l) it was estimated that one case including its consequences would be at $134 for animals in first lactation, $111 for multiparous animals, mainly due to losses in reproduction, culling in milk (McArt et al. 2015).')
    },
    {
      title: translate('How can I prevent Ketosis?'),
      content: '- ' + translate('Avoid over-conditioning of cows in late lactation and the early dry period.') +
        '\n- ' + translate('Provide transition ration with adequate energy distribution.') +
        '\n- ' + translate('Optimize transition cow management.') +
        '\n- ' + translate('Take preventive action in high risk animals:') +
        '\n- ' + 'https://www.elanco.co.uk/products-services/dairy/ketosis' +
        '\n- ' + 'https://www.farmchamps.de/ketose-rind'
    },
    {
      title: translate('How is percentage of milk loss due to Ketosis computed?'),
      content: translate('The milk production loss due to SCK and CK, expressed as a percentage of the daily milk yield, was drawn from an uniform distribution with a minimum and maximum of 6% and 8% for SCK and 15% and 17% for CK.') +
        '\n\n- ' + translate('Steeneveld W, Amuta P, van Soest FJS, Jorritsma R, Hogeveen H (2020) Estimating the combined costs of clinical and subclinical ketosis in dairy cows. PLoS ONE 15(4): e0230448. https://doi.org/10.1371/journal.pone.0230448')
    },
    {
      title: translate('Relevant papers on Ketosis in Dairy Cows'),
      content:
        '- Berge, Anna C.; Vertenten, Geert (2014): A field study to determine the prevalence, dairy herd management systems, and fresh cow clinical conditions associated with ketosis in western European dairy herds. In: Journal of dairy science 97 (4), S. 2145–2154. DOI: 10.3168/jds.2013-7163.' +
        '\n- Gordon, Jessica L.; Leblanc, Stephen J.; Duffield, Todd F. (2013): Ketosis treatment in lactating dairy cattle. In: The Veterinary clinics of North America. Food animal practice 29 (2), S. 433–445. DOI: 10.1016/j.cvfa.2013.03.001. McArt, J. A. A.; Nydam, D. V.; Oetzel, G. R. (2012): Epidemiology of subclinical ketosis in early lactation dairy cattle. In: Journal of dairy science 95 (9), S. 5056–5066. DOI: 10.3168/jds.2012-5443.' +
        '\n- McArt, J. A. A.; Nydam, D. V.; Overton, M. W. (2015): Hyperketonemia in early lactation dairy cattle: a deterministic estimate of component and total cost per case. In: Journal of dairy science 98 (3), S. 2043–2054. DOI: 10.3168/jds.2014-8740. Oetzel, G. R. (2007): Herd-Level Ketosis – Diagnosis and Risk Factors. AABP Conference. Vancouver, 2007.' +
        '\n- Ospina, Paula A.; McArt, Jessica A.; Overton, Thomas R.; Stokol, Tracy; Nydam, Daryl V. (2013): Using nonesterified fatty acids and β-hydroxybutyrate concentrations during the transition period for herdlevel monitoring of increased risk of disease and decreased reproductive and milking performance. In: The Veterinary clinics of North America. Food animal practice 29 (2), S. 387–412. DOI: 10.1016/j.cvfa.2013.04.003.' +
        '\n- Raboisson, D.; Mounié, M.; Maigné, E. (2014): Diseases, reproductive performance, and changes in milk production associated with subclinical ketosis in dairy cows: a meta-analysis and review. In: Journal of dairy science 97 (12), S. 7547–7563. DOI: 10.3168/jds.2014-8237.' +
        '\n- Roos, A. P. W. de; van den Bijgaart, H. J. C. M.; Hørlyk, J.; Jong, G. de (2007): Screening for subclinical ketosis in dairy cattle by Fourier transform infrared spectrometry. In: Journal of dairy science 90 (4), S. 1761–1766. DOI: 10.3168/jds.2006-203.' +
        '\n- Smith, B. P. (Hg.) (2002): Metabolic Disorders. Ketosis of Ruminants. Unter Mitarbeit von Fleming S.A. St. Louis: Mosby. Suthar, V. S.; Canelas-Raposo, J.; Deniz, A.; Heuwieser, W. (2013): Prevalence of subclinical ketosis and relationships with postpartum diseases in European dairy cows. In: Journal of dairy science 96 (5), S. 2925–2938. DOI: 10.3168/jds.2012-6035.' +
        '\n- Tatone, Elise H.; Duffield, Todd F.; Leblanc, Stephen J.; DeVries, Trevor J.; Gordon, Jessica L. (2017): Investigating the within-herd prevalence and risk factors for ketosis in dairy cattle in Ontario as diagnosed by the test-day concentration of β-hydroxybutyrate in milk. In: Journal of dairy science 100 (2), S. 1308–1318. DOI: 10.3168/jds.2016-11453.'
    },
    {
      title: translate('What should I do with animals that receive a Predicta GUARDIAN ketosis alarm?'),
      content: '- ' + translate('Once Predicta GUARDIAN has sent you an alarm for a given animal, this animal has a risk to suffer from Ketosis in the following lactation.') +
        '\n- ' + translate('We recommend you click on the hyperlink to the PREDICTA webapp we sent you with the alarm, where you can retrieve further information about the actual risk level of the animal and provide us feedback about actions you have taken.') +
        '\n- ' + translate('We aim to deliver the alarm 4 weeks prior to the expected calving date.') +
        '\n- ' + translate('At this point you may want to consider preventive action for the animal.') +
        '\n- ' + translate('We encourage you to speak to your vet about what preventive actions you can take to avoid Ketosis in high risk animals.')
    },
    {
      title: translate('How should I use the information on the page \'Ketosis Management\'?'),
      content: '- ' + translate('When you visit predicta-ddw.com/ketorisk/ketosismanagement, you can find information about the overall Ketosis situation in your herd.') +
        '\n- ' + translate('As you continue with the program, this page will allow you to better understand Ketosis dynamics in your herd over time as well as how your Ketosis prevention strategy is working.')
    }
  ];
};
