import {
  CALL_CODES,
  DDW_blue,
  DDW_green_set,
  DDW_grey_set,
  DDW_orange_set,
  DDW_pink_set,
  DDW_purple_set,
  DDW_yellow_set,
  DIAGNOSIS_DIS_COL
} from './static';
import { TFunction } from 'i18next';

export const getObjectKeys = function <T extends object>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[];
};

export const getObjectValues = function <T>(dict: { [key: string | number]: T }): T[] {
  if (dict == null) {
    return [];
  }
  return Array.from(Object.values(dict));
};

export const getColor = function (model: string, colorPos: number) {
  switch (model) {
    case '1':
      return DDW_green_set[colorPos];
    case '3':
      return DDW_purple_set[colorPos];
    case '4':
      return DDW_grey_set[colorPos];
    case '5':
      return DDW_orange_set[colorPos];
    case '6':
      return DDW_yellow_set[colorPos];
    case '7':
      return DDW_pink_set[colorPos];
    default:
      return DDW_green_set[colorPos];
  }
};

export const computeSpecificity = function (rocs: any, modelId: number, sensitivity: number) {
  const rocsList = getObjectValues(rocs[modelId]?.Sensitivity);

  let i;
  for (i = 0; i < rocsList.length; i++) {
    if (rocsList[i] === sensitivity) {
      break;
    }
  }
  const spValue = getObjectValues(rocs[modelId]?.Specificity)[i];
  return spValue as number;
};

export const isolateCallcodeFromNumber = function (number: string) {
  const subset = Object.entries(CALL_CODES)
    .filter(([_, code]) => number.startsWith(code))
    .sort((a, b) => b[1].length - a[1].length);

  if (subset.length === 0) {
    return null;
  } else {
    const [country, code] = subset[0];
    const onlyNumber: string = number.slice(code.length);
    return [code, onlyNumber];
  }
};

export function validateEmail(email: string): boolean {
  if (!email) {
    return false;
  } else if (email.includes('/')) {
    return false;
  } else if (email.split('@').length === 2) {
    const [local, domain] = email.split('@');
    if (!local || local.length > 64) {
      return false;
    } else return !(!domain || domain.includes('_'));
  } else {
    return false;
  }
}

export function validateMobileNumber(callcode: string | null, number: string | null): boolean {
  if (callcode === null && number === null) {
    return false;
  } else if (number === null) {
    return false;
  } else {
    const complete_number = callcode !== null ? callcode + number : number;
    // RULE: According to E164 a number must be minimum 8 characters including prefix but excluding + (hence 9)
    if (complete_number.length < 9) {
      return false;
    }
    // RULE: If user does not specify country code in the appropriate widget, there still is the chance of adding it
    // RULE: If user does not specify country code in the appropriate widget, there still is the chance of adding it
    // to the number itself
    else if (!complete_number.startsWith('+')) {
      return false;
    }
    // RULE: A mobile number cannot start with 0 (this enforce people to not enter the internal call prefix)
    else if (number.startsWith('0')) {
      return false;
    }
    // RULE: Ensure that user does not repeat the entry of call code
    else if (callcode !== null && number.startsWith(callcode)) {
      return false;
    }
    // RULE: Reject if 2 + characters are present (this is possible if user enters callcode within the number but the
    // RULE: Reject if 2 + characters are present (this is possible if user enters callcode within the number but the
    // dropdown selection is not None
    else return complete_number.split('+').length <= 2;
  }
}

export function userToStore(user: any) {
  return {
    '_confirmed': true,
    'GuardianNotificationSettingsId': user?.GuardianNotificationSettingsId,
    'HerdProfileId': user?.HerdProfileId,
    'GuardianModelId': user?.GuardianModelId,
    'PrimaryUser': user?.PrimaryUser,
    'ModelAlarmFrequency': user?.ModelAlarmFrequency,
    'AlarmNotificationMode': user?.AlarmNotificationMode,
    'NotificationLanguage': user?.NotificationLanguage,
    'NotificationEmail': user?.NotificationEmail,
    'NotificationMobile': user?.NotificationMobile,
    'NotificationWeekDay': user?.NotificationWeekDay,
    'NotificationHourUTC': user?.NotificationHourUTC,
    'email_modified': false,
    'UniqueIdentifier': Math.random().toString(36) // this is only used as key for rendering
  };
}

export function visioUserToStore(user: any) {
  return {
    '_confirmed': true,
    'HerdProfileId': user?.HerdProfileId,
    'PrimaryUser': user?.PrimaryUser,
    'InventoryInsemPrescSecondaryUserId': user?.InventoryInsemPrescSecondaryUserId,
    'InventoryConfiguration': user?.InventoryConfiguration,
    'PrescriptionFrequency': user?.PrescriptionFrequency,
    'PrescriptionNotificationMode': user?.PrescriptionNotificationMode,
    'NotificationEmail': user?.NotificationEmail,
    'NotificationMobile': user?.NotificationMobile,
    'NotificationWeekDay': user?.NotificationWeekDay,
    'email_modified': false,
    'UniqueIdentifier': Math.random().toString(36) // this is only used as key for rendering
  };
};

export function setBarColour(alarmType: number): string {
  return DIAGNOSIS_DIS_COL[alarmType];
}

export function setMarkerColour(isAlarm: boolean): string {
  if (isAlarm) {
    return '#dc3545'; // Danger
  } else {
    return DDW_blue;
  }
}

export const rollingAverage = (arr: number[], windowSize: number) => {
  let res: (number | null)[] = arr.map((val, index, arr) => {
    const start = Math.max(0, index - windowSize + 1);
    const end = index + 1;
    const subset = arr.slice(start, end);
    const sum = subset.reduce((a, b) => a + b);
    return sum / subset.length;

  });
  for (let i = 0; i < windowSize - 1; i++) {
    res[i] = null;
  }
  return res;
};

export const uuid = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & (0x3 | 0x8));
    return v.toString(16);
  });
};

export const formatDate = (dateString: string | undefined): string => {
  if (dateString === undefined) {
    return '';
  }
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  };
  return date.toLocaleDateString('en-US', options);
};

export const formatDateToYYYYMMDD = (dateString: string | undefined): string => {
  if (dateString === undefined) {
    return '';
  }
  const dateObj = new Date(dateString);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export const findMinInMatrix = (matrix: number[][]): number => {
  let min = Infinity;

  for (let i = 0; i < matrix.length; i++) {
    const row = matrix[i];
    for (let j = 0; j < row.length; j++) {
      const value = row[j];
      if (value < min) {
        min = value;
      }
    }
  }

  return min;
};

export const createMatrixFromHistScatter = (arr: Array<Array<Array<number | string>>>): Array<Array<number | string>> => {
  const matrix: Array<Array<number | string>> = [];

  for (const innerArr of arr) {
    if (innerArr.length >= 2) {
      matrix.push(innerArr[1]);
    }
  }

  return matrix;
};

export const getCurrencySign = (): string => {
  const currency: string | null = sessionStorage.getItem('preferredCurrency');
  switch (currency) {
    case "EUR":
      return "€";
    case "USD":
      return "$";
    case "GBP":
      return "£";
    default:
      return "€";
  };
};

//this is used to translate terms that could come as a variable (i.e., there is no string literal in the code)
const translateMisc = (translate: TFunction): void => {
  translate('Decline');
  translate('Herd');
  translate('Ketosis (BHB)');
  translate('Human');
  translate('(Human)');
  translate('Machine');
  translate('(Machine)');
  translate('Default');
  translate('(Default)');
  translate('Success');
  translate('Error');
  translate('Warning');
  translate('Sign out');
  translate('Impersonate');
  translate('By visiting this website you agree to the storing of cookies to enhance site navigation and analyze site usage.');
  translate('CookieConsent');
  translate('Loading UserSettings...');
  translate('Other');
  translate('Metritis');
  translate('Pneumonia or other infectious disease');
  translate('true');
  translate('false');
  translate('True');
  translate('False');
  translate('SignOut');
  translate('Select a herd from the navigation bar above to start using Predicta Health Alarms');
  translate('Select a herd from the navigation bar above to start using Predicta Milk');
  translate('Select a herd from the navigation bar above to start using Predicta Inventory');
  translate('Select a herd from the navigation bar above to start using Predicta');
  translate('Select a herd from the navigation bar above to start using Predicta GUARDIAN');
  translate('Select a herd from the navigation bar above to start using Predicta MilkFeverRisk');
  translate('Select a herd from the navigation bar above to start using Predicta KetoRisk');
  translate('Predicta Milk');
  translate('Predicta Inventory');
  translate('Predicta Apps');
  translate('Lameness');
  translate('Invalid average dry off days (at herd level)');
  translate('Invalid average calving interval (at herd level)');
  translate('Less than 4 milk records in on-going lactation');
  translate('Move animal to status Barren');
  translate('Inseminate with replacement semen');
  translate('High mortality risk animal');
  translate('Inseminate with beef semen');
  translate('Production difference (kg)');
  translate('Relative performance');
  translate('Milking days');
  translate('Avg Daily Yield (kg/day period)');
  translate('Avg Daily Yield (kg/day in milk)');
  translate("Open");
  translate("Calved");
  translate("Served");
  translate("NotPregnant");
  translate("Pregnant");
  translate("Barren");
  translate("Aborted");
  translate("Dry");
  translate("Sold");
  translate("Dead");
  translate("PresumedLeft");
  translate("Presumed Active");
  translate("Unknown Code");
  translate("null");
  translate("Gastrointestinal Disease");
};

const shorten: { [key: string]: string } = {
  // de
  'Gesundheit': 'Gesund.',
  'die Einstellungen': 'Einstell.',
  'Herdenleistung': 'Herde',
  'Dokumente': 'Dok.',
  'Fruchtbarkeit': 'Fruchtb.',
  'Gesundheitsalarme': 'Alarme',
  'Modellkonfiguration': 'Modellkonfig.',
  // fr
  'Pilote automatique': 'Pilote',
  'La prévention': 'Prévention',
  'Statistiques': 'Statist.',
  'Paramètres': 'Param.',
  'Un service': 'Service',
  // It
  'impostazioni': 'Impostaz.',
  'Impostazioni': 'Impostaz.',
  'Statistiche': 'Statist.',
  // Es
  'Estadisticas': 'Estadist.',
  // nl
  'Gezondheid': 'Gezond.',
  'Instellingen': 'Instell.',
  'Statistieken': 'Statist.',
  'Statistiken': 'Statist.',
  'Onderhoud': 'Onderh.',
  'Documenten': 'Docum.',
  'Problemen': 'Problem.',
  'automatische piloot': 'Auto piloot',
  'Optimaliseren': 'Optim.',
  'Vruchtbaarheid': 'Vruchtb.',
  'Voorspelling': 'Voorsp.',
  'Modelconfiguratie': 'Model configuratie',
  // Pt
  'Definições': 'Definiç.',
  'Estatísticas': 'Estatíst.',
  // sv
  'Modulkonfiguration': 'Modulkonfig.',
  'Inställningar': 'Inst.'
};

export const shortenIfPossible = (translatedTerm: string): string => {
  if (shorten[translatedTerm] !== undefined) {
    return shorten[translatedTerm];
  }
  return translatedTerm;
};

class UrlState {
  private _url: string = "http://localhost:5000/";

  get url(): string {
    return this._url;
  }

  set url(value: string) {
    this._url = value;
  }
}

export const urlState = new UrlState();

export const findMax = (...numbers: number[]): number => {
  return Math.max(...numbers);
};

export interface DiseaseData {
  diseaseIncidence: number;
  diseaseCostPerCases: number;
  preventionCost: number;
  preventionEfficacy: number;
  predictaGuardian: number;
};

export interface SensSpecSetting {
  sensitivity: number;
  specificity: number;
};

export const costCalculator = (
  herdSize: number,
  diseaseData: DiseaseData,
  sensSpecSetting: SensSpecSetting
): number => {
  const {
    diseaseIncidence,
    diseaseCostPerCases,
    preventionCost,
    preventionEfficacy,
    predictaGuardian,
  } = diseaseData;

  const { sensitivity, specificity } = sensSpecSetting;

  const diseaseAnimals = (herdSize * diseaseIncidence) / 100.0;
  const noDiseaseAnimals = herdSize - diseaseAnimals;
  const diseasePositiveRiskLevel = (diseaseAnimals * sensitivity) / 100.0;
  const diseaseNegativeRiskLevel = diseaseAnimals - diseasePositiveRiskLevel;
  const noDiseaseNegativeRiskLevel = (noDiseaseAnimals * specificity) / 100.0;
  const noDiseasePositiveRiskLevel = noDiseaseAnimals - noDiseaseNegativeRiskLevel;
  const preventionTreatments = diseasePositiveRiskLevel + noDiseasePositiveRiskLevel;
  const missedCases = diseaseNegativeRiskLevel;

  const costMissedCases = missedCases * diseaseCostPerCases;
  const costPrevention = preventionTreatments * preventionCost;
  const costFailedPrevention = diseasePositiveRiskLevel * ((100.0 - preventionEfficacy) / 100.0) * diseaseCostPerCases;
  const totalCost = costMissedCases + costPrevention + costFailedPrevention;

  return totalCost;
};

export const findMinimumCostSensitivityAndSpecificity = (rocs: any, modelId: string, herdSize: number, diseaseData: DiseaseData): SensSpecSetting => {
  let minimumCost: number = Number.POSITIVE_INFINITY;
  let minimumCostIndex: number = 0;

  const sensitivityValues: number[] = getObjectValues<number>(rocs[modelId]?.Sensitivity);
  const specificityValues: number[] = getObjectValues<number>(rocs[modelId]?.Specificity);

  for (let i = 0; i < sensitivityValues?.length; i++) {
    const sensitivitySpecificitySetting: SensSpecSetting = { 'sensitivity': sensitivityValues[i], 'specificity': specificityValues[i] };
    const totalCost: number = costCalculator(herdSize, diseaseData, sensitivitySpecificitySetting);

    if (totalCost < minimumCost) {
      minimumCost = totalCost;
      minimumCostIndex = i;
    }
  }

  const minimumCostSensitivity: number = sensitivityValues[minimumCostIndex];
  const minimumCostSpecificity: number = specificityValues[minimumCostIndex];

  return { 'sensitivity': minimumCostSensitivity, 'specificity': minimumCostSpecificity };
};
