import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getObjectValues } from '../../../../pages/utils';
import * as Plotly from 'plotly.js';
import { Layout } from 'plotly.js';
import { PLOTLY_TEMPLATE } from '../../../../pages/static';

export const ServiceChart: React.FC<any> = ({ dayAxis, dayAlarm, dayFilter, df }) => {
  const { t: translate } = useTranslation();

  const dayAxisArr = dayAxis?.map((x: string) => new Date(x));

  const chartRef = useRef<HTMLDivElement>(null);

  const data = [
    {
      x: dayAxisArr,
      y: dayAlarm?.map((x: boolean) => Number(!x && x !== null)),
      fill: 'tonexty',
      line: { shape: 'hv' },
      mode: 'none',
      fillcolor: 'rgba(255, 66, 66, 0.25)',
      name: translate('Days with no service'),
      hoverinfo: 'x+name'
    },
    {
      x: dayAxisArr,
      y: dayFilter.map((x: boolean) => Number(!x && x !== null)),
      fill: 'tonexty',
      line: { shape: 'hv' },
      mode: 'none',
      fillcolor: 'rgba(245, 200, 66, 0.25)',
      name: translate('Dynamic filter unavailable'),
      hoverinfo: 'x+name'
    },
    {
      x: getObjectValues<string>(df['DataUpdated']),
      y: Array(getObjectValues<string>(df['DataUpdated']).length).fill(0.5),
      mode: 'markers',
      line: { color: 'black', width: 1 },
      marker: { color: 'black', size: 8, symbol: 8 },
      name: translate('Data Update'),
      hovertemplate: translate('<br>Data Update<br>DataUpdated: %{x}')
    },
    {
      x: getObjectValues<string>(df['Started']).filter((_, index) => !getObjectValues<boolean>(df['PredictionSuccess'])[index]),
      y: Array(getObjectValues<string>(df['Started']).filter((_, index) => !getObjectValues<boolean>(df['PredictionSuccess'])[index]).length).fill(0.5),
      mode: 'markers',
      line: { color: 'red', width: 1 },
      marker: { color: 'red', size: 10 },
      name: translate('Failed attempt'),
      hovertemplate: translate('<br>Failed attempt<br>Started: %{x}')
    },
    {
      x: getObjectValues<string>(df['Executed']).filter((_, index) => getObjectValues<boolean>(df['PredictionSuccess'])[index]),
      y: Array(getObjectValues<string>(df['Executed']).filter((_, index) => getObjectValues<boolean>(df['PredictionSuccess'])[index]).length).fill(0.5),
      customdata: getObjectValues<number>(df['Lag']).map((value) => parseInt(String(value))),
      mode: 'markers',
      line: { color: 'green', width: 3 },
      marker: { color: 'green', size: 10, symbol: 300 },
      name: translate('Valid session'),
      hovertemplate: translate('<br>Valid session<br>Executed: %{x}<br>Prediction lag: %{customdata} min')
    },
    ...getObjectValues<string>(df['DataUpdated']).map((_, index) => {
      return {
        x: [getObjectValues(df['DataUpdated'])[index], getObjectValues(df['Executed'])[index]],
        y: [0.5, 0.5],
        mode: 'lines',
        line: { color: 'black', width: 0.5, dash: 'dot' },
        name: translate('Prediction lag'),
        legendgroup: 'Prediction lag',
        showlegend: !index,
        hoverinfo: 'x+name'
      };
    })
  ];

  const layout: Partial<Layout> = {
    margin: { l: 25, r: 50, b: 50, t: 10 },
    legend: {
      xanchor: 'center',
      x: 0.5,
      yanchor: 'bottom',
      y: 1.3,
      orientation: 'h'
    },
    hovermode: 'closest',
    hoverlabel: { namelength: -1 },
    template: PLOTLY_TEMPLATE,
    showlegend: true,
    xaxis: {
      range: [dayAxis[0], new Date()],
      showspikes: true,
      spikecolor: 'blue',
      spikethickness: 0.25,
      spikemode: 'across',
      nticks: 15,
      mirror: true,
      showline: true
    },
    yaxis: {
      range: [0, 1],
      showgrid: false, // thin lines in the background
      zeroline: false, // thick line at x=0
      visible: false // numbers below
    }
  };

  useEffect(() => {
    if (chartRef.current) {
      // @ts-ignore
      Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
    }
  }, []);

  return (
    <>
      <div ref={chartRef} style={{ height: '150px' }} />
    </>
  );
};