import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticationContext, SnackbarMessagesContext, SnackbarMessageType, ViewContext } from '@ddw/react-framework';
import { HerdProfileContext } from '../../../../context';
import { useNavigate } from 'react-router-dom';
import { DiagnosisService } from '../../../../typings/types';
import { Api } from '../../../../services/api';
import { DDWApplication, Select, SelectOption, ViewSize } from '@ddw/react-components';
import useAsyncEffect from 'use-async-effect';
import { PageContainer } from '../../../../component/PageContainer';
import { Alert, Col, IconContainer, Paragraph, Row, Span, SubHeader } from '../../../components';
import { HDDNetworkFillIcon } from '../../../../component/Icons/HDDNetworkFillIcon';
import { HerdSelectorSelectParent } from '../../../../component/HerdSelector/herdselector.styled';
import { CardChecklistIcon } from '../../../../component/Icons/CardChecklistIcon';
import { SmartwatchIcon } from '../../../../component/Icons/SmartwatchIcon';
import { ServiceChart } from '../../../../component/Charts/Diagnosis/ServiceChart';
import { PagesContext } from '../../../../context/PagesContext';
import { guardianPages } from '../../../pages';

export const GuardianDiagnosisServicePage: React.FC = () => {
  const { t: translate } = useTranslation();
  const { user, getAccessToken } = useContext(AuthenticationContext);
  const { herdId, permissions } = useContext(HerdProfileContext);
  const { addMessageToStack } = useContext(SnackbarMessagesContext);
  const { setPages } = useContext(PagesContext);
  const { viewSize } = useContext(ViewContext);

  const [serviceData, setServiceData] = useState<DiagnosisService>();

  const periodOptions: SelectOption[] = [
    { label: translate('Last 7 days'), value: '7' },
    { label: translate('Last 15 days'), value: '15' },
    { label: translate('Last 30 days'), value: '30' }
  ];
  const [period, setPeriod] = useState<SelectOption>(periodOptions[0]);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [dataErrorOrWarningSeverity, setDataErrorOrWarningSeverity] = useState('');

  const getBadgeStyle = function (cat: string) {
    let backgroundColor: string;
    let fontColor: string;

    switch (cat) {
      case 'primary':
        backgroundColor = '#007bff';
        fontColor = '#ffffff';
        break;
      case 'secondary':
        backgroundColor = '#6c757d';
        fontColor = '#ffffff';
        break;
      case 'success':
        backgroundColor = '#28a745';
        fontColor = '#ffffff';
        break;
      case 'warning':
        backgroundColor = '#ffc107';
        fontColor = '#212529';
        break;
      case 'danger':
        backgroundColor = '#dc3545';
        fontColor = '#ffffff';
        break;
      case 'info':
        backgroundColor = '#17a2b8';
        fontColor = '#ffffff';
        break;
      case 'light':
        backgroundColor = '#f8f9fa';
        fontColor = '#212529';
        break;
      case 'dark':
        backgroundColor = '#343a40';
        fontColor = '#ffffff';
        break;
      default:
        backgroundColor = '#000000';
        fontColor = '#ffffff';
        break;
    }

    return {
      backgroundColor: backgroundColor,
      color: fontColor,
      width: '120px'
    };
  };

  const fetchService = async () => {
    const result = await Api.diagnosis.fetchService(
      getAccessToken(),
      DDWApplication.DairyInsights,
      herdId,
      period.value
    );
    if (result.success) {
      if (result.message === '') {
        setServiceData(result.data);
        setDataErrorOrWarningSeverity('');
      } else {
        setDataErrorOrWarningSeverity('warning');
      }
    } else {
      if (result.message !== '') {
        navigate('/home');
        addMessageToStack({
          messageType: SnackbarMessageType.error,
          message: translate('There was a problem fetching service data.'),
          autoHide: false
        });
      } else {
        setDataErrorOrWarningSeverity('danger');
      }
    }
  };

  useAsyncEffect(async () => {
    if (herdId != 0) {
      setLoading(true);
      await fetchService();
      setLoading(false);
    }
  }, [herdId, period]);

  useEffect(() => {
    setPages(guardianPages(translate, permissions.prevention, permissions.diagnosis, permissions.visio, user !== undefined ? user['profile']['UserEmail'] : ''));
  }, [herdId]);

  return (
    <>
      <PageContainer hasFooter={false} loading={loading}>
        {dataErrorOrWarningSeverity !== 'danger' ? (
          <>
            <Row>
              <Col width={'75%'}>
                <SubHeader>
                  <Row style={{ alignItems: 'center' }}>
                    <Col width={'3%'}>
                      <IconContainer>
                        <HDDNetworkFillIcon />
                      </IconContainer>
                    </Col>

                    <Col width={'90%'}>
                      {translate('Pipeline health status')}
                    </Col>
                  </Row>
                </SubHeader>
              </Col>
            </Row>

            <Row className={'mt-3'} style={{ alignItems: 'center' }}>
              <Col width={viewSize > ViewSize.S ? '10%' : '30%'} style={{ maxWidth: '200px' }}>
                <Paragraph>{translate('Select period')}</Paragraph>
              </Col>

              <Col style={{ minWidth: '200px' }}>
                <HerdSelectorSelectParent>
                  <Select
                    name={translate('Period')}
                    field={`period`}
                    options={periodOptions}
                    selected={period}
                    onChange={(selected: any) => {
                      setPeriod(selected);
                    }}
                  ></Select>
                </HerdSelectorSelectParent>
              </Col>
            </Row>

            {dataErrorOrWarningSeverity === 'warning' ? (
              <Alert maxWidth={viewSize > ViewSize.S ? '50%' : '75%'} severity={dataErrorOrWarningSeverity} className={'mt-2'}>
                <Span>
                  <div>
                    {herdId} - {translate('There is no active session yet for this herd. Please retry later.')}
                  </div>
                </Span>
              </Alert>
            ) : (
              <>
                <Row className={'mt-5 mb-2'} style={{ alignItems: 'center' }}>
                  <Col width={'3%'} className={'mt-2'}>
                    <IconContainer>
                      <CardChecklistIcon />
                    </IconContainer>
                  </Col>

                  <Col className={'mt-2'}>
                    <h3>{translate(`Check-up (last ${period.value} days)`)}</h3>
                  </Col>
                </Row>

                <Row className={'mt-3'}>
                  <Col width={'40%'}>{translate('Service status')}</Col>
                  <Col>
                    <span
                      className={'badge badge-pill'}
                      style={getBadgeStyle(serviceData?.checkupReport['STATUS']['cat'])}
                    >
                      {serviceData?.checkupReport['STATUS']['value']}
                    </span>
                  </Col>
                </Row>

                <Row className={'mt-2'}>
                  <Col width={'40%'}>{translate('Time since last session (hours)')}</Col>
                  <Col>
                    <span
                      className={'badge badge-pill'}
                      style={getBadgeStyle(serviceData?.checkupReport['time_to_last']['cat'])}
                    >
                      {serviceData?.checkupReport['time_to_last']['value'].toFixed(2)}
                    </span>
                  </Col>
                </Row>

                <Row className={'mt-2'}>
                  <Col width={'40%'}>{translate('Calendar days with no service')}</Col>
                  <Col>
                    <span
                      className={'badge badge-pill'}
                      style={getBadgeStyle(serviceData?.checkupReport['count_no_service']['cat'])}
                    >
                      {serviceData?.checkupReport['count_no_service']['value']}
                    </span>
                  </Col>
                </Row>

                <Row className={'mt-2'}>
                  <Col width={'40%'}>{translate('Calendar days without dynamic filter')}</Col>
                  <Col>
                    <span
                      className={'badge badge-pill'}
                      style={getBadgeStyle(serviceData?.checkupReport['count_no_filter']['cat'])}
                    >
                      {serviceData?.checkupReport['count_no_filter']['value']}
                    </span>
                  </Col>
                </Row>

                <Row className={'mt-2'}>
                  <Col width={'40%'}>{translate('Avg. prediction lag (min)')}</Col>
                  <Col>
                    <span
                      className={'badge badge-pill'}
                      style={getBadgeStyle(serviceData?.checkupReport['avg_lag']['cat'])}
                    >
                      {parseInt(serviceData?.checkupReport['avg_lag']['value'])}
                    </span>
                  </Col>
                </Row>

                <Row className={'mt-2'}>
                  <Col width={'40%'}>{translate('Count prediction lag > 1 hour')}</Col>
                  <Col>
                    <span
                      className={'badge badge-pill'}
                      style={getBadgeStyle(serviceData?.checkupReport['lag_above_hour']['cat'])}
                    >
                      {parseInt(serviceData?.checkupReport['lag_above_hour']['value'])}
                    </span>
                  </Col>
                </Row>

                <Row className={'mt-5'} style={{ alignItems: 'center' }}>
                  <Col width={'3%'}>
                    <IconContainer>
                      <SmartwatchIcon />
                    </IconContainer>
                  </Col>
                  <Col>
                    <h3>{translate('Event timeline (UTC)')}</h3>
                  </Col>
                </Row>

                <Row className={'mb-4 w-100'}>
                  <Col width={'100%'}>
                    <ServiceChart
                      dayAxis={serviceData?.dayAxis}
                      dayAlarm={serviceData?.dayAlarm}
                      dayFilter={serviceData?.dayFilter}
                      df={serviceData?.df}
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        ) : (
          <Alert maxWidth={viewSize > ViewSize.S ? '25%' : '75%'} severity={dataErrorOrWarningSeverity}>
            <Span>
              {herdId} - {translate('Data not available')}
            </Span>
          </Alert>
        )}
      </PageContainer>
    </>
  );
};
