import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DDW_light_grey, DDW_pink, DDW_yellow, DIS_PILL_NAMES } from '../../../pages/static';
import { getObjectKeys } from '../../../pages/utils';
import * as Plotly from 'plotly.js';

export const HeatMap: React.FC<any> = ({ heatmapData, nDryAnimals, heatmapEmpty, heatmapValues }) => {
  const { t: translate } = useTranslation();

  const models: string[] = ['1', '3', '4', '5', '6', '7'];

  const chartRef = useRef<HTMLDivElement>(null);

  const data = [
    {
      z: heatmapValues,
      x: getObjectKeys(heatmapData),
      y: models.map((model: string) => translate(DIS_PILL_NAMES[model])),
      type: 'heatmap',
      hoverongaps: false,
      name: translate('Data Quality Violations Rules'),
      xgap: 1,
      ygap: 1,
      zmin: 0,
      zmid: nDryAnimals / 2,
      zmax: nDryAnimals,
      colorscale: [
        [0, DDW_light_grey],
        [0.1, DDW_yellow],
        [1, DDW_pink]
      ],
      hovertemplate:
        translate('Issue: %{x}<br>Disease: %{y}<br>Number of Animals: %{z}<extra></extra>')
    }
  ];

  const layout = {
    title: translate('Data Quality Violations <br> by Disease'),
    showlegend: true,
    legend: {
      xanchor: 'center',
      x: 2,
      yanchor: 'bottom',
      y: 2,
      orientation: 'h'
    },
    hovermode: 'closest',
    xaxis: {
      tickmode: 'array',
      tickvals: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      ticktext: heatmapEmpty ? ['', '', '', '', '', ''] : getObjectKeys(heatmapData),
      automargin: true
    },
    yaxis: {
      tickmode: 'array',
      tickvals: models.map((model: string) => translate(DIS_PILL_NAMES[model])),
      ticktext: models.map((model: string) => translate(DIS_PILL_NAMES[model])),
      dtick: 1,
      automargin: true,
      tickformat: 'd'
    },
    yaxis_nticks: models.length,
    xaxis_nticks: getObjectKeys(heatmapData).length
  };

  useEffect(() => {
    if (chartRef.current) {
      // @ts-ignore
      Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
    }
  }, []);

  return (
    <>
      <div ref={chartRef} />
    </>
  );
};