import React from 'react';
import { Button, ButtonType, IconLib } from '@ddw/react-components';

interface ButtonWrapperProps {
  name: string;
  children: string | React.ReactNode;
  type: ButtonType;
  isDisabled: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const ButtonWrapper: React.FC<ButtonWrapperProps> = (props) => {
  return (
    <Button name={props.name} type={props.type} isDisabled={props.isDisabled} onClick={props.onClick}>
      {props.children}
    </Button>
  );
};