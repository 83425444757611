import { SvgIconTypeMap, SvgIcon } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import React from 'react';

export const BucketFillIcon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> = (props: any) => {
    return (
      <SvgIcon {...props} style={{fill: "currentcolor"}} viewBox='0 0 16 16'>
        <path d="M2.522 5H2a.5.5 0 0 0-.494.574l1.372 9.149A1.5 1.5 0 0 0 4.36 16h7.278a1.5 1.5 0 0 0 1.483-1.277l1.373-9.149A.5.5 0 0 0 14 5h-.522A5.5 5.5 0 0 0 2.522 5zm1.005 0a4.5 4.5 0 0 1 8.945 0H3.527z"/>
      </SvgIcon>
    );
  };