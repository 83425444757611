import React, { useContext } from 'react';
import { HeaderAction, Menu } from '@ddw/react-framework';
import { PagesContext } from '../../context/PagesContext';
import { version as drc } from '@ddw/react-components/package.json';
import { version as drf } from '@ddw/react-framework/package.json';
import { HerdSelector } from '../../component';

declare type Props = {
  headerActions: HeaderAction[];
};

export const MenuWrapper: React.FC<Props> = ({ headerActions }) => {
  const { pages } = useContext(PagesContext);

  return (
    <Menu pages={pages} headerActions={headerActions} packageVersions={{ drc, drf }}>
      <HerdSelector />
    </Menu>
  );
};