import { ViewContext } from '@ddw/react-framework';
import Plotly, { Data, Layout } from 'plotly.js';
import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BASE_CURRENCY, CONVERSION_RATES_IN_CASE_OF_FETCH_ERROR, DDW_blue, DDW_cyan, DDW_dark_pink, DDW_darkest_grey, DDW_grey, DDW_light_green, DDW_orange, PLOTLY_TEMPLATE } from '../../../../../pages/static';
import { Button, IconLib, ViewSize } from '@ddw/react-components';
import { getCurrencySign } from '../../../../../pages/utils';

export const MilkProductionVsFeedCostChart: React.FC<any> = ({ feedingData, herdGroups }) => {
    const { t: translate } = useTranslation();
    const { viewSize } = useContext(ViewContext);

    const chartRef = useRef<HTMLDivElement>(null);

    const predictaConversionRates = localStorage.getItem('predictaCurrenciesConversionRates') !== null ? JSON.parse(localStorage.getItem('predictaCurrenciesConversionRates')!) : CONVERSION_RATES_IN_CASE_OF_FETCH_ERROR;
    const preferredCurrency = sessionStorage.getItem('preferredCurrency') !== null ? sessionStorage.getItem('preferredCurrency') : BASE_CURRENCY;

    const feedCostPerLiterConverted: number[] = feedingData.values?.feedCostPerLiter.map((cost: number) => (cost * predictaConversionRates[preferredCurrency!]["value"]).toFixed(2));

    const dimKeys: number[] = Array.from({ length: 15 }, (_, i) => (i + 1) * 30);
    const stopAtIndex: number = feedingData.values?.avgLactationLength !== null ? dimKeys.filter(value => value < feedingData.values?.avgLactationLength).length : 15;

    // @ts-ignore
    const data: Data[] = [
        {
            x: feedingData.keys.slice(0, stopAtIndex),
            y: feedingData.values?.estimatedDMI.slice(0, stopAtIndex),
            type: 'bar',
            name: translate('Estimated dry matter intake'),
            marker: {
                color: DDW_cyan
            },
            offsetgroup: 1,
            hovertemplate: translate('DIM: %{x}<br>Estimated dry matter intake (kg): %{y}')
        },
        {
            x: feedingData.keys.slice(0, stopAtIndex),
            y: feedingData.values?.concentrate.slice(0, stopAtIndex),
            type: 'bar',
            name: translate('Concentrate consumed'),
            showlegend: feedingData.values?.concentrate.slice(0, stopAtIndex).some((value: number) => value != 0),
            marker: {
                color: DDW_light_green
            },
            offsetgroup: 1,
            hovertemplate: translate('DIM: %{x}<br>Concentrate consumed (kg): %{y}')
        },
        {
            x: feedingData.keys.slice(0, stopAtIndex),
            y: feedingData.values?.milk.slice(0, stopAtIndex),
            type: 'bar',
            name: translate('Fat corrected milk'),
            marker: {
                color: DDW_orange
            },
            offsetgroup: 2,
            hovertemplate: translate('DIM: %{x}<br>Fat corrected milk (kg): %{y}')
        },
        {
            x: feedingData.keys.slice(0, stopAtIndex),
            y: feedCostPerLiterConverted.slice(0, stopAtIndex),
            mode: 'lines',
            name: translate('Feed cost per liter'),
            line: {
                color: DDW_dark_pink
            },
            yaxis: 'y2',
            hovertemplate: translate('DIM: %{x}<br>Feed cost per liter: %{y}')
        }
    ].concat(herdGroups.keys.map((group: string, index: number) => ({
        x: [Math.ceil(herdGroups.values.averageDimAtGroupChange[index]), Math.ceil(herdGroups.values.averageDimAtGroupChange[index])],
        y: [Math.max(...feedCostPerLiterConverted), Math.min(...feedCostPerLiterConverted)],
        mode: 'lines+markers',
        name: translate("Average DIM at group change"),
        line: { dash: 'dot', color: DDW_blue, width: 1.5 },
        marker: { symbol: 'diamond' },
        showlegend: index === 0,
        customdata: [[herdGroups.values.groupNames[index], herdGroups.values.averageMilkYieldPerAnimal[index].toFixed(2)], [herdGroups.values.groupNames[index], herdGroups.values.averageMilkYieldPerAnimal[index].toFixed(2)]],
        hovertemplate: translate("Group Name: %{customdata[0]}<br>Average DIM at group change: %{x}<br>Average milk yield per animal (kg): %{customdata[1]}"),
        yaxis: 'y2'
    })));

    const layout: Partial<Layout> = {
        title: viewSize > ViewSize.S ? translate('Milk production vs feed cost') : '',
        barmode: 'group',
        showlegend: true,
        xaxis: {
            title: translate('DIM'),
            tickvals: feedingData.keys.slice(0, stopAtIndex)
        },
        yaxis: {
            'title': translate('Average milk production and DMI (kg)')
        },
        yaxis2: {
            anchor: 'x',
            overlaying: 'y',
            side: 'right',
            title: translate('Feeding cost') + ' (' + getCurrencySign() + ')'
        },
        legend: {
            xanchor: 'center',
            x: 0.5,
            yanchor: 'bottom',
            y: 1,
            orientation: 'h',
        },
        hovermode: 'closest',
        template: PLOTLY_TEMPLATE
    };

    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
        }
    }, [viewSize]);

    return (
        <div style={{ position: 'relative' }}>
            <div ref={chartRef} />
            <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                <Button onClick={() => {
                    window.location.href = '/settings';
                }} icon={IconLib.settings} name={'feeding-settings'}>
                    {translate('Settings')}
                </Button>
            </div>
        </div>
    );
};