import React, { useEffect, useRef } from 'react';
import { DDW_green, DDW_pink, DIS_PILL_NAMES, ISSUES_BAR_WIDTH, PLOTLY_TEMPLATE } from '../../../pages/static';
import { useTranslation } from 'react-i18next';
import { getObjectValues } from '../../../pages/utils';
import * as Plotly from 'plotly.js';
import { Data, Layout } from 'plotly.js';

export const PreventionIssuesBarChart: React.FC<any> = ({ activeModels, barData }) => {
  const { t: translate } = useTranslation();

  const chartRef = useRef<HTMLDivElement>(null);

  const data: Data[] = [{
    x: activeModels.map((model: string) => translate(DIS_PILL_NAMES[model])),
    y: getObjectValues<number>(barData['DataQualityViolation']),
    type: 'bar',
    opacity: 1.0,
    name: translate('Prediction OK'),
    width: ISSUES_BAR_WIDTH,
    marker: { color: DDW_green }
  },
    {
      x: activeModels.map((model: string) => translate(DIS_PILL_NAMES[model])),
      y: getObjectValues<number>(barData['DataViolatedTrue']),
      type: 'bar',
      opacity: 1.0,
      name: translate('Data Quality Violated'),
      width: ISSUES_BAR_WIDTH,
      marker: { color: DDW_pink }
    }
  ];

  const layout: Partial<Layout> = {
    title: {
      text: translate('Data Quality Violations vs <br> Predictions by Disease'),
      yanchor: 'top'
    },
    showlegend: true,
    barmode: 'stack',
    // xaxis: { tickvals: activeModels },
    hovermode: 'closest',
    template: PLOTLY_TEMPLATE
  };

  useEffect(() => {
    if (chartRef.current) {
      Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
    }
  }, []);

  return (
    <>
      <div ref={chartRef} />
    </>
  );
};