import * as herdProfiles from './herdProfile';
import * as languages from './languages';
import * as userSettings from './userSettings';
import * as farmCoordinates from './farmCoordinates';
import * as alarms from './alarms';
import * as downloads from './downloads';
import * as issues from './issues';
import * as stats from './stats';
import * as quality from './quality';
import * as guardian from './guardian';
import * as herdSettings from './herdSettings';
import * as diagnosis from './diagnosis';
import * as visio from './visio';
import * as herdfolio from './herdfolio';
import * as conversionRates from './conversionRates';
import * as homePageDashboard from './homePageDashboard';

export class Api {
  public static herdProfiles = herdProfiles;
  public static languages = languages;
  public static userSettings = userSettings;
  public static farmCoordinates = farmCoordinates;
  public static alarms = alarms;
  public static downloads = downloads;
  public static issues = issues;
  public static stats = stats;
  public static quality = quality;
  public static guardian = guardian;
  public static herdSettings = herdSettings;
  public static diagnosis = diagnosis;
  public static visio = visio;
  public static herdfolio = herdfolio;
  public static conversionRates = conversionRates;
  public static homePageDashboard = homePageDashboard;
}