import styled from 'styled-components';
import { CompanyTheme } from '@ddw/react-components';

type ColProps = {
  width?: string
  fontWeight?: string;
  overflow?: string;
}

export const Col = styled.div<ColProps>`
  width: ${props => props.width ? props.width : 'auto'};
  max-width: ${props => props.width ? props.width : '100%'};
  margin-right: 10px;
  font-family: ${({ theme }: { theme: CompanyTheme }) => theme.Font.Family};
  font-weight: ${props => props.fontWeight ? props.fontWeight : 'normal'};
  overflow: ${props => props.overflow ? props.overflow : 'visible'};
  overflow-wrap: break-word;
  white-space: pre-line;
`;