import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticationContext, SnackbarMessagesContext, SnackbarMessageType, ViewContext } from '@ddw/react-framework';
import { HerdProfileContext } from '../../../../context';
import { CardData, DiagnosisIssues } from '../../../../typings/types';
import { useNavigate } from 'react-router-dom';
import { Select } from 'antd';
import { Api } from '../../../../services/api';
import { Button, ButtonType, createTestIdAttributeValue, DDWApplication, IconLib, ViewSize } from '@ddw/react-components';
import useAsyncEffect from 'use-async-effect';
import { PageContainer } from '../../../../component/PageContainer';
import { Alert, Col, IconContainer, Paragraph, Row, SubHeader } from '../../../components';
import { ConeStripedIcon } from '../../../../component/Icons';
import { DiagnosisIssuesPieChart } from '../../../../component/Charts/Diagnosis/Issues/DiagnosisIssuesPieChart';
import { DiagnosisIssuesBarChart } from '../../../../component/Charts/Diagnosis/Issues/DiagnosisIssuesBarChart';
import { FunnelFillIcon } from '../../../../component/Icons/FunnelFillIcon';
import { Strong } from '../../../components/strong';
import { Card, CardContent } from '@mui/material';
import { PagesContext } from '../../../../context/PagesContext';
import { guardianPages } from '../../../pages';

export const GuardianDiagnosisIssuesPage: React.FC = () => {
  const { t: translate } = useTranslation();
  const { user, getAccessToken } = useContext(AuthenticationContext);
  const { herdId, permissions } = useContext(HerdProfileContext);
  const { addMessageToStack } = useContext(SnackbarMessagesContext);
  const { setPages } = useContext(PagesContext);
  const { viewSize } = useContext(ViewContext);

  const { Option } = Select;

  const [issueData, setIssueData] = useState<DiagnosisIssues>({
    cardData: [], freshness: 0, sessionTime: '', countOk: 0, subset: {
      AlarmType: {},
      DataQualityViolation: {},
      DisplayLevel: {},
      Executed: {},
      IS_ALARM: {},
      RiskLevel: {},
      RiskLevel_DA: {},
      RiskLevel_GAST: {},
      RiskLevel_INFC: {},
      RiskLevel_KET: {},
      RiskLevel_MAST: {},
      RiskLevel_METR: {},
      RiskLevel_OTH: {}
    }, dqvText: {}
  });

  const [issueOptions, setIssueOptions] = useState<string[]>([]);
  const [filteredCardData, setFilteredCardData] = useState<CardData[]>([]);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [dataErrorOrWarningSeverity, setDataErrorOrWarningSeverity] = useState('');

  const downloadIssues = async () => {
    document.body.style.cursor = 'progress';
    const result = await Api.downloads.downloadDiagnosisIssues(getAccessToken(), DDWApplication.DairyInsights, herdId);
    const blob = await result.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'guardian_diagnosis_issues-' + herdId + '-' + sessionStorage.getItem('sessionId') + '.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    document.body.style.cursor = 'default';
  };

  const fetchIssues = async () => {
    const result = await Api.diagnosis.fetchIssues(getAccessToken(), DDWApplication.DairyInsights, herdId);
    if (result.success) {
      if (result.message === '') {
        setIssueData(result.data);
        setIssueOptions(Object.values(result.data.dqvText));
        setDataErrorOrWarningSeverity('');
      } else {
        setDataErrorOrWarningSeverity('warning');
      }
    } else {
      if (result.message !== '') {
        navigate('/home');
        addMessageToStack({
          messageType: SnackbarMessageType.error,
          message: translate('There was a problem fetching issues.'),
          autoHide: false
        });
      } else {
        setDataErrorOrWarningSeverity('danger');
      }
    }
  };

  useAsyncEffect(async () => {
    setPages(guardianPages(translate, permissions.prevention, permissions.diagnosis, permissions.visio, user !== undefined ? user['profile']['UserEmail'] : ''));
    if (herdId != 0) {
      setLoading(true);
      await fetchIssues();
      setLoading(false);
    }
  }, [herdId]);

  useEffect(() => {
    setFilteredCardData(issueData.cardData.filter((record) =>
      issueOptions.includes(issueData.dqvText[record.DataQualityViolation])
    ));
  }, [issueOptions]);

  return (
    <>
      <PageContainer hasFooter={false} loading={loading}>
        {dataErrorOrWarningSeverity === '' ?
          <>
            <Row>
              <Col width={'75%'}>
                <SubHeader>
                  <Row style={{ alignItems: 'center' }}>
                    <Col width={'3%'}>
                      <IconContainer>
                        <ConeStripedIcon />
                      </IconContainer>
                    </Col>

                    <Col width={'90%'}>
                      {translate('Data Quality Violations')}
                    </Col>
                  </Row>
                </SubHeader>
                <Paragraph>
                  {translate('Report of animals not eligible for a Health Alarm prediction due to data rule violation.')}
                </Paragraph>
              </Col>
            </Row>

            <Row className={'mt-4 mb-4'}>
              <Alert maxWidth={'100%'}
                severity={issueData?.freshness < 24 ? 'success' : issueData?.freshness < 36 ? 'warning' : 'danger'}>
                {translate('Freshness check: visualising predictions of {{date}} {{time}} UTC ({{hours}} hours ago)', {
                  date: issueData?.sessionTime?.split('T')[0],
                  time: issueData?.sessionTime?.split('T')[1],
                  hours: Math.floor(issueData?.freshness)
                })}
              </Alert>
            </Row>

            {viewSize > ViewSize.S ?
              <>
                <Row>
                  <Col width={'50%'}>
                    <DiagnosisIssuesPieChart cardData={issueData.cardData} countOk={issueData.countOk} />
                  </Col>

                  <Col width={'50%'}>
                    <DiagnosisIssuesBarChart subset={issueData.subset} dqvText={issueData.dqvText} />
                  </Col>
                </Row>
              </> :
              <>
                <Row>
                  <Col width='100%'>
                    <DiagnosisIssuesPieChart cardData={issueData.cardData} countOk={issueData.countOk} />
                  </Col>
                </Row>

                <Row>
                  <Col width='100%'>
                    <DiagnosisIssuesBarChart subset={issueData.subset} dqvText={issueData.dqvText} />
                  </Col>
                </Row>
              </>
            }

            <Row className={'mt-4'} style={{ alignItems: 'center' }}>
              <IconContainer>
                <FunnelFillIcon />
              </IconContainer>

              <Select mode={'multiple'} className={'ml-3'} optionLabelProp='label' allowClear={true}
                onChange={setIssueOptions} value={issueOptions}>
                {Object.values(issueData.dqvText).map((dqv) => (
                  <Option value={dqv} label={dqv}>
                    {dqv}
                  </Option>
                ))}
              </Select>
            </Row>

            <Row className={'mt-4'}>
              <Col width={'15%'}>
                <Strong>{translate('EarTag')}</Strong>
              </Col>
              <Col width={'15%'}>
                <Strong>{translate('Cow #')}</Strong>
              </Col>
              <Col width={'15%'}>
                <Strong>{translate('Parity')}</Strong>
              </Col>
              <Col width={'15%'}>
                <Strong>{translate('DIM')}</Strong>
              </Col>
              <Col width={'40%'}>
                <Strong>{translate('Quality Rule Violated')}</Strong>
              </Col>
            </Row>

            {filteredCardData.map((record) => {
              return (
                <div onClick={() => {
                  navigate('/animal', { state: { eartag: record.EarTag, cowNumber: record.CowNumber } });
                }} onMouseEnter={() => { document.body.style.cursor = 'pointer'; }}
                  onMouseLeave={() => { document.body.style.cursor = 'default'; }}
                  key={createTestIdAttributeValue('row', Math.random().toString(36).substring(7))}
                  className={'mt-2'}>
                  <Card style={{
                    boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)',
                    backgroundColor: '#F7F7F7',
                    marginBottom: '5px',
                    width: '100%'
                  }}>
                    <CardContent>
                      <Row style={{ alignItems: 'center' }}>
                        <Col width={'15%'}>
                          {record.EarTag}
                        </Col>
                        <Col width={'15%'}>
                          {record.CowNumber}
                        </Col>
                        <Col width={'15%'}>
                          {record.Parity}
                        </Col>
                        <Col width={'15%'}>
                          {record.DIM}
                        </Col>
                        <Col width={'40%'}>
                          {issueData.dqvText[record.DataQualityViolation]}
                        </Col>
                      </Row>
                    </CardContent>
                  </Card>
                </div>
              );
            })}

            <Row className={'mt-5 mb-3'}>
              <Col>
                <Button name={'download-issues'}
                  type={ButtonType.Cancel}
                  isOutlined={false}
                  onClick={downloadIssues}
                  icon={IconLib.downloadLight}
                >
                  {translate('Download Data')}
                </Button>
              </Col>
            </Row>
          </> :
          <Alert maxWidth={viewSize > ViewSize.S ? '25%' : '75%'} severity={dataErrorOrWarningSeverity}>
            {dataErrorOrWarningSeverity === 'danger' ? <div>{herdId} - {translate('Data not available')}</div> :
              <div>{herdId} - {translate('There is no active session yet for this herd. Please retry later.')}</div>
            }
          </Alert>
        }
      </PageContainer>
    </>
  );
};