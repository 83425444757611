import { ApplicationPage, AuthenticationContext } from '@ddw/react-framework';
import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import { mainPages } from '../pages';
import { useTranslation } from 'react-i18next';
import { guardianPages, visioPages } from '../pages/pages';

const context = {
  pages: [] as ApplicationPage[],
  setPages: async (pages: ApplicationPage[]) => {
  }
};

export const PagesContext = createContext(context);

export const PagesContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { t: translate } = useTranslation();
  const { user } = useContext(AuthenticationContext);

  const [pages, setMenuPages] = useState<ApplicationPage[]>(() => {
    const preventionPermission = sessionStorage.getItem('preventionPermission');
    const diagnosisPermission = sessionStorage.getItem('diagnosisPermission');
    const visioPermission = sessionStorage.getItem('visioPermission');
    if (window.location.pathname.startsWith('/guardian') && preventionPermission !== null && diagnosisPermission !== null) {
      return guardianPages(translate, preventionPermission === 'true', diagnosisPermission === 'true', visioPermission === 'true', user !== undefined ? user['profile']['UserEmail'] : '');
    } else if ((window.location.pathname.startsWith('/visio') || window.location.pathname === '/animal') && visioPermission !== null) {
      return visioPages(translate, visioPermission === 'true', user !== undefined ? user['profile']['UserEmail'] : '');
    }
    return mainPages(translate, visioPermission === 'true', user !== undefined ? user['profile']['UserEmail'] : '');
  });

  const setPages = async (pages: ApplicationPage[]) => {
    setMenuPages(pages);
  };

  return <PagesContext.Provider value={{ pages, setPages }}>{children}</PagesContext.Provider>;
};
