import React from 'react';
import { Paragraph } from '../../pages/components';
import { version } from '../../version';

export const Footer: React.FC = () => {
  return (
    <>
      <div className={'footer'}>
        <hr className={'footer-line-break'} />
        <Paragraph className={'lead'}>
          {'v' + version + ' - © Dairy Data Warehouse. All Rights Reserved'}
        </Paragraph>
      </div>
    </>
  );
};