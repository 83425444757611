import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import {
  ApplicationSettings,
  AuthenticationContext,
  AuthenticationContextProvider,
  Container,
  Content,
  DialogImpersonate,
  Frame,
  getApplicationInfo,
  Header,
  LoadingPage,
  MonitoringContextProvider,
  SnackbarMessages,
  SnackbarMessagesContextProvider,
  useHeaderActions,
  UserSettingsContext,
  UserSettingsContextProvider,
  ViewContextProvider
} from '@ddw/react-framework';
import './App.css';
import { Company, CompanyThemeContextProvider, DDWApplication } from '@ddw/react-components';
import { BrowserRouter, Outlet } from 'react-router-dom';
import { HerdSelector } from './component';
import { HerdProfileContextProvider } from './context/HerdProfileContext';
import { PagesContext, PagesContextProvider } from './context/PagesContext';
import { MenuWrapper } from './other/MenuWrapper/MenuWrapper';
import { RoutesWrapper } from './other/RouteWrapper/RoutesWrapper';
import { SessionContextProvider } from './context/SessionContext';
import { MaintenancePage } from './pages/MaintenancePage';
import { useTranslation } from 'react-i18next';
import useAsyncEffect from 'use-async-effect';
import { useFetchUserSettings } from './services/DataHooks';
import { languageCodeToLanguage } from './i18n';
import { mainPages } from './pages';
import { useFetchConversionRates } from './services/DataHooks/useFetchConversionRates';
import { PREDICTA_CONVERSION_RATES_FILE_URL } from './pages/static';

interface AppProps {
  settings: ApplicationSettings;
}

export const App: React.FC<AppProps> = ({ settings }) => {
  const appInfo = getApplicationInfo(DDWApplication.DairyInsights);

  return (
    <MonitoringContextProvider monitoringKey={settings.MONITORING_KEY}>
      <AuthenticationContextProvider
        idpHost={settings.IDP_HOST}
        hasAccess={appInfo.accessCheck}
        application={DDWApplication.DairyInsights}
      >
        <Contexts>
          <AppRouter />
        </Contexts>
      </AuthenticationContextProvider>
    </MonitoringContextProvider>
  );
};

const AppRouter: React.FC<PropsWithChildren> = () => {
  const { activeUser } = useContext(AuthenticationContext);

  return (
    <BrowserRouter>
      <RoutesWrapper activeUser={activeUser} element={<Application />} />
    </BrowserRouter>
  );
};

const Application: React.FC = () => {
  const { userSettings, updateUserSettings } = useContext(UserSettingsContext);
  const { t: translate, i18n } = useTranslation();
  const { fetchUserSettings } = useFetchUserSettings();
  // const { fetchConversionRates } = useFetchConversionRates();
  const { setPages } = useContext(PagesContext);
  const { user } = useContext(AuthenticationContext);

  const [showImpersonateDialog, setShowImpersonateDialog] = useState(false);

  const { headerActions } = useHeaderActions(
    [],
    setShowImpersonateDialog,
    null,
    [],
    DDWApplication.DairyInsights,
    true,
    true
  );

  useAsyncEffect(async () => {
    if (sessionStorage.getItem('languageSet') === null) {
      const predictaUserSettings = await fetchUserSettings();
      if (predictaUserSettings.success) {
        const preferredLanguage: string = languageCodeToLanguage[String(predictaUserSettings.data.languageCode)];
        await i18n.changeLanguage(preferredLanguage);
        await updateUserSettings({ ...userSettings, preferredLanguage });
        setPages(mainPages(translate, false, user !== undefined ? user['profile']['UserEmail'] : ''));
        sessionStorage.setItem('preferredCurrency', predictaUserSettings.data.currency);
      }
      sessionStorage.setItem('languageSet', 'languageSet');
    }

    if (localStorage.getItem('predictaCurrenciesConversionRates') === null) {
      const predictaConversionRates = await fetch(PREDICTA_CONVERSION_RATES_FILE_URL);
      if (predictaConversionRates.ok) {
        const arrayBuffer = await predictaConversionRates.arrayBuffer();
        const content = JSON.parse(new TextDecoder().decode(arrayBuffer));
        localStorage.setItem('predictaCurrenciesConversionRates', JSON.stringify(content));
      }
    }
  }, []);

  return (
    <Frame>
      <Header headerActions={headerActions} application={DDWApplication.DairyInsights}>
        <HerdSelector />
      </Header>
      <Container>
        <MenuWrapper headerActions={headerActions} />
        <Content>
          <Outlet />
        </Content>
      </Container>
      <DialogImpersonate showPopup={showImpersonateDialog} setShowPopup={setShowImpersonateDialog} />
      <SnackbarMessages />
    </Frame>
  );
};

const Contexts: React.FC<PropsWithChildren> = ({ children }) => {
  const { userCompany } = useContext(AuthenticationContext);

  const maintenance: boolean = false; // set to true if Predicta is under maintenance

  const [company, setCompany] = useState<Company>();

  useEffect(() => {
    setCompany(userCompany || Company.DDW);
  }, [userCompany]);

  if (!company) {
    return <LoadingPage reason={'Loading company for user'} />;
  }

  return (
    <UserSettingsContextProvider application={DDWApplication.DairyInsights}>
      <CompanyThemeContextProvider company={company}>
        {maintenance ? (
          <MaintenancePage />
        ) : (
          <SnackbarMessagesContextProvider>
            <ViewContextProvider>
              <SessionContextProvider>
                <HerdProfileContextProvider>
                  <PagesContextProvider>{children}</PagesContextProvider>
                </HerdProfileContextProvider>
              </SessionContextProvider>
            </ViewContextProvider>
          </SnackbarMessagesContextProvider>
        )}
      </CompanyThemeContextProvider>
    </UserSettingsContextProvider>
  );
};
