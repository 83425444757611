import React, { createContext } from 'react';

export interface HerdSettingsUsersContextType {
  users: { [key: string]: any };
  setUsers: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
}

export const HerdSettingsUsersContext = createContext<HerdSettingsUsersContextType>({
  users: { 'unique': {'primary': null, 'secondary': []},
    '1': {'primary': null, 'secondary': []},
    '3': {'primary': null, 'secondary': []},
    '4': {'primary': null, 'secondary': []},
    '5': {'primary': null, 'secondary': []},
    '6': {'primary': null, 'secondary': []},
    '7': {'primary': null, 'secondary': []}
  },
  setUsers: () => {
  }
});
