import React, { PropsWithChildren, createContext, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Repository } from '@ddw/react-framework';
import { HerdProfile } from '../typings/types';
import { useFetchHerdProfiles } from '../services/DataHooks';
import { HerdPermissions } from '../typings/permissions/permissions';

const context = {
  herds: { data: [], loading: true } as Repository<HerdProfile[]>,
  herdId: 0 as number,
  setHerdId: async (herdProfileId: number) => { },
  permissions: { prevention: false, diagnosis: false, visio: false } as HerdPermissions,
  setPermissions: async (permissions: HerdPermissions) => { }
};

export const HerdProfileContext = createContext(context);
export const HerdProfileContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [herdId, setSelectedHerdId] = useState<number>(0);
  const [herds, setHerds] = useState<Repository<HerdProfile[]>>({ data: [], loading: true });
  const [permissions, setHerdPermissions] = useState<HerdPermissions>({ prevention: false, diagnosis: false, visio: false });
  const { fetchHerdProfiles } = useFetchHerdProfiles();

  const setHerdId = async (herdId: number) => {
    setSelectedHerdId(herdId);
  };

  const setPermissions = async (permissions: HerdPermissions) => {
    setHerdPermissions(permissions);
  };

  useAsyncEffect(async () => {
    const result = await fetchHerdProfiles();
    if (result.success && result.data.length > 0) {
      setHerds({ data: result.data, loading: false });


      // in case of impersonation, we must make sure the saved herd id is in the list of herds of the new user
      if (sessionStorage.getItem('herdId') !== null) {
        if (!result.data.map((elem) => elem.id).includes(Number(sessionStorage.getItem('herdId')))) {
          sessionStorage.removeItem('herdId');
        }
      }

      // the setting of the herd id in session storage allows for herd to remain selected after page refresh
      sessionStorage.setItem(
        'herdId',
        sessionStorage.getItem('herdId') ? String(sessionStorage.getItem('herdId')) : String(result.data[0].id || 0)
      );

      const herdPermissions = result.data.find(
        (herdProfile) =>
          herdProfile.id ===
          (sessionStorage.getItem('herdId') ? Number(sessionStorage.getItem('herdId')) : result.data[0].id || 0)
      )!.permissions
      setHerdPermissions(herdPermissions);

      sessionStorage.setItem('preventionPermission', String(herdPermissions.prevention));
      sessionStorage.setItem('diagnosisPermission', String(herdPermissions.diagnosis));
      sessionStorage.setItem('visioPermission', String(herdPermissions.visio));

      setSelectedHerdId(
        sessionStorage.getItem('herdId') ? Number(sessionStorage.getItem('herdId')) : result.data[0].id || 0
      );
    } else {
      setHerds({ data: [], loading: false, error: result.message });
    }
  }, []);

  const value = { setHerdId, herdId, herds, permissions, setPermissions };
  return <HerdProfileContext.Provider value={value}>{children}</HerdProfileContext.Provider>;
};
