import Plotly, { Data, Layout } from "plotly.js";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { DDW_blue, DDW_orange, KPI_CODES, PLOTLY_TEMPLATE } from "../../../../../pages/static";
import { format, parse } from 'date-fns';

export const AvgDailyProductionVsAvgDIM: React.FC<any> = ({ props }) => {
    const { t: translate } = useTranslation();

    const chartRef = useRef<HTMLDivElement>(null);

    const data: Data[] = [
        {
            x: props.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerCow']).map((data: any) => format(parse(data.periodValue, 'yyyyMM', new Date()), 'MMM yyyy')),
            y: props.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerCow']).map((data: any) => data.value),
            mode: 'lines',
            yaxis: 'y2',
            name: translate('Average daily production'),
            line: { color: DDW_orange, width: 2.5 }
        },
        {
            x: props.filter((data: any) => data.kpiCode === KPI_CODES['AverageDimMilkedCows']).map((x: any) => format(parse(x.periodValue, 'yyyyMM', new Date()), 'MMM yyyy')),
            y: props.filter((data: any) => data.kpiCode === KPI_CODES['AverageDimMilkedCows']).map((x: any) => x.value),
            mode: 'lines',
            name: translate('Average DIM'),
            line: {
                color: DDW_blue,
                width: 2.5
            }
        },
    ];

    const layout: Partial<Layout> = {
        title: translate('Average daily production vs Average DIM'),
        yaxis: {
            title: translate('Average DIM')
        },
        yaxis2: {
            anchor: 'x',
            overlaying: 'y',
            side: 'right',
            title: translate('Average daily production')
        },
        legend: {
            xanchor: 'center',
            x: 0.5,
            yanchor: 'bottom',
            y: 1,
            orientation: 'h',
        },
        showlegend: true,
        hovermode: 'closest',
        template: PLOTLY_TEMPLATE
    };

    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
        }
    }, [props]);

    return (
        <div ref={chartRef} />
    );
};