import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Typography } from '@mui/material';
import { Col, Row } from '../../../pages/components';
import { AlarmDiffUpIcon } from '../../Icons/AlarmDiffUpIcon';
import { AlarmDiffUpRightIcon } from '../../Icons/AlarmDiffUpRightIcon';
import { AlarmDiffRightIcon } from '../../Icons/AlarmDiffRightIcon';
import { AlarmDiffDownRightIcon } from '../../Icons/AlarmDiffDownRightIcon';
import { AlarmDownDiffIcon } from '../../Icons/AlarmDownDiffIcon';
import { RiskProfileGraph } from '../../Charts/Diagnosis/RiskProfileGraph';
import { RiskEvolutionGraph } from '../../Charts/Diagnosis/RiskEvolutionGraph';
import { useNavigate } from 'react-router-dom';

export const DiagnosisAlarmCard: React.FC<any> = ({ data }) => {
  const { t: translate } = useTranslation();

  const navigate = useNavigate();

  return (
    <>
      <Card style={{
        boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)',
        backgroundColor: '#F7F7F7',
        marginBottom: '5px',
        width: '100%'
      }}>
        <CardContent>
          <Row className={'w-100'}>
            <Col width={'20%'} onClick={() => {
              navigate('/animal', { state: { eartag: data.EarTag, cowNumber: data.CowNumber } });
            }} onMouseEnter={() => { document.body.style.cursor = 'pointer'; }}
              onMouseLeave={() => { document.body.style.cursor = 'default'; }}>
              <Row className={'ml-2'}><Typography
                style={{ fontWeight: 'bold' }}>{translate('EarTag')}</Typography></Row>
              <Row className={'ml-2 mt-1'}>{data.EarTag}</Row>
              <Row className={'ml-2 mt-5'}><Typography
                style={{ fontWeight: 'bold' }}>{translate('Cow #')}</Typography></Row>
              <Row className={'ml-2 mt-1'}>{data.CowNumber}</Row>
            </Col>

            <Col width={'20%'} onClick={() => {
              navigate('/animal', { state: { eartag: data.EarTag, cowNumber: data.CowNumber } });
            }} onMouseEnter={() => { document.body.style.cursor = 'pointer'; }}
              onMouseLeave={() => { document.body.style.cursor = 'default'; }}>
              <Row><Typography style={{ fontWeight: 'bold' }}>{translate('Parity')}</Typography></Row>
              <Row className={'mt-1'}>{data.Parity}</Row>
              <Row className={'mt-5'}><Typography
                style={{ fontWeight: 'bold' }}>{translate('DIM')}</Typography></Row>
              <Row className={'mt-1'}>{data.DIM}</Row>
            </Col>

            <Col width={'20%'} onClick={() => {
              navigate('/animal', { state: { eartag: data.EarTag, cowNumber: data.CowNumber } });
            }} onMouseEnter={() => { document.body.style.cursor = 'pointer'; }}
              onMouseLeave={() => { document.body.style.cursor = 'default'; }}>
              <Row><Typography style={{ fontWeight: 'bold' }}>{translate('Risk')}</Typography></Row>
              <Row className={'mt-1'} style={{ alignItems: 'center' }}>
                <span className={'disease-badge badge-pill badge-primary'} style={{ backgroundColor: '#dc3545' }}>
                  {data.DisplayLevel.toFixed(2)}
                </span>
                {
                  data.Diff === null ? <div></div> :
                    data.Diff > 0.4 ? <AlarmDiffUpIcon style={{ height: '16px' }} className={'ml-1'} /> :
                      data.Diff > 0.05 ? <AlarmDiffUpRightIcon style={{ height: '16px' }} className={'ml-1'} /> :
                        data.Diff > -0.05 ? <AlarmDiffRightIcon style={{ height: '16px' }} className={'ml-1'} /> :
                          data.Diff > -0.4 ?
                            <AlarmDiffDownRightIcon style={{ height: '16px' }} className={'ml-1'} /> :
                            <AlarmDownDiffIcon style={{ height: '16px' }} className={'ml-1'} />
                }
              </Row>
              <Row className={'mt-5'}><Typography
                style={{ fontWeight: 'bold' }}>{translate('Alarm Type')}</Typography></Row>
              <Row className={'mt-1'}>{translate(data.AlarmType)}</Row>
            </Col>

            <Col width={'20%'} onClick={() => {
              navigate('/animal', { state: { eartag: data.EarTag, cowNumber: data.CowNumber } });
            }} onMouseEnter={() => { document.body.style.cursor = 'pointer'; }}
              onMouseLeave={() => { document.body.style.cursor = 'default'; }}>
              <RiskProfileGraph record={data} />
            </Col>

            <Col width={'20%'} className={'ml-2'} onClick={() => {
              navigate('/animal', { state: { eartag: data.EarTag, cowNumber: data.CowNumber } });
            }} onMouseEnter={() => { document.body.style.cursor = 'pointer'; }}
              onMouseLeave={() => { document.body.style.cursor = 'default'; }}>
              <RiskEvolutionGraph record={data} />
            </Col>
          </Row>
        </CardContent>
      </Card>
    </>
  );
};