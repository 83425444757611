import { DDWApplication, parseError, putHeaders, statusHelper, toJSON } from "@ddw/react-components";
import { ServiceResult } from "@ddw/react-framework/build/src/types";
import { urlState } from "../../pages/utils";
import { AnimalHistoricAndFuturePerformance, FarmPopulationEvolution, HerdProductionForecastData, VisioOptimiserContent, VisioPrescription, VisioSettings } from "../../typings/visio/types";

export const fetchPrescriptions = (accessToken: string, application: DDWApplication, herdId: number, animalSearch: string, prescriptionTypes: string, pageNumber: number, pageSize: number): Promise<ServiceResult<VisioPrescription>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  headers.set('page', String(pageNumber));
  headers.set('page-size', String(pageSize));
  headers.set('eartag-or-cow-number', animalSearch);
  headers.set('prescription-types', prescriptionTypes);
  const url = urlState.url + `visio/` + herdId + '/prescriptions';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const updatePrescription = (accessToken: string, application: DDWApplication, herdId: number, ignored: boolean, animalId: number, parity: number, dim: number): Promise<ServiceResult<any>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `visio/` + herdId + '/prescriptions/ignore';
  return fetch(url, {
    method: 'PUT',
    headers,
    body: JSON.stringify({ animalId: animalId, parity: parity, dim: dim, ignored: ignored })
  }).then(statusHelper).then(toJSON).catch(parseError);
};

export const fetchAnimalEarTags = (accessToken: string, application: DDWApplication, herdId: number): Promise<ServiceResult<any>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `visio/` + herdId + '/eartags';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const fetchAnimalHistoricAndFuturePerformance = (accessToken: string, application: DDWApplication, herdId: number, eartag: string, lactation: string): Promise<ServiceResult<AnimalHistoricAndFuturePerformance>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  headers.set('lactation', lactation);
  const url = urlState.url + `visio/` + herdId + '/' + eartag + '/performance';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const fetchAnimalDataQuality = (accessToken: string, application: DDWApplication, herdId: number, eartag: string): Promise<ServiceResult<any>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `visio/` + herdId + '/' + eartag + '/data-quality';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const fetchStats = (accessToken: string, application: DDWApplication, herdId: number, population: string, history: number, projection: number, configuration: string): Promise<ServiceResult<FarmPopulationEvolution>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  headers.set('population', population);
  headers.set('history', String(history));
  headers.set('projection', String(projection));
  headers.set('configuration', configuration);
  const url = urlState.url + `visio/` + herdId + '/stats';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const fetchHerdProductionForecastData = (accessToken: string, application: DDWApplication, herdId: number): Promise<ServiceResult<HerdProductionForecastData>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `visio/` + herdId + '/stats/herd-production-forecast';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const fetchSettings = (accessToken: string, application: DDWApplication, herdId: number): Promise<ServiceResult<VisioSettings>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `visio/` + herdId + '/settings';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const updateSettings = (accessToken: string, application: DDWApplication, herdId: number, body: any): Promise<ServiceResult<any>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `visio/` + herdId + `/update-settings`;
  return fetch(url, { method: 'PUT', headers, body: JSON.stringify(body) })
    .then(statusHelper)
    .then(toJSON)
    .catch(parseError);
};

export const fetchOptimiserContent = (accessToken: string, application: DDWApplication, herdId: number): Promise<ServiceResult<VisioOptimiserContent>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `visio/` + herdId + '/optimiser';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const submitOptimisationReqeust = (
  accessToken: string,
  application: DDWApplication,
  herdSelected: number,
  body: any): Promise<ServiceResult<any>> => {

  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  headers.set('herd-selected', String(herdSelected));
  const url = urlState.url + `visio/` + herdSelected + '/optimisation-request';
  return fetch(url, { method: 'PUT', headers, body: JSON.stringify(body) })
    .then(statusHelper)
    .then(toJSON)
    .catch(parseError);
};

export const resetPrescription = (accessToekn: string, application: DDWApplication, herdSelected: number, cfg: string): Promise<ServiceResult<any>> => {
  const headers = putHeaders(accessToekn, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  headers.set('cfg', cfg);
  const url = urlState.url + `visio/` + herdSelected + '/reset-prescription';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const fetchAnimalComparison = (accessToken: string, application: DDWApplication, herdId: number, animal1: string, animal2: string, comparison: string, timeHorizon: number): Promise<ServiceResult<any>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  headers.set('comparison', comparison);
  headers.set('timeHorizon', String(timeHorizon));
  const url = urlState.url + `visio/` + herdId + '/' + animal1 + '/compare/' + animal2;
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};
