import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticationContext, SnackbarMessagesContext, SnackbarMessageType, ViewContext } from '@ddw/react-framework';
import { HerdProfileContext } from '../../../../context';
import { Feedback } from '../../../../typings/types';
import { useNavigate } from 'react-router-dom';
import { PageContainer } from '../../../../component/PageContainer';
import { Alert, Col, IconContainer, Row, SubHeader } from '../../../components';
import { DDWApplication, ViewSize } from '@ddw/react-components';
import { Api } from '../../../../services/api';
import useAsyncEffect from 'use-async-effect';
import { PencilFillIcon } from '../../../../component/Icons';
import { FeedbackTopLeftBarChart } from '../../../../component/Charts/Feedback/FeedbackTopLeftBarChart';
import { FeedbackTopRightPieChart } from '../../../../component/Charts/Feedback/FeedbackTopRightPieChart';
import { FeedbackMiddleLeftBarChart } from '../../../../component/Charts/Feedback/FeedbackMiddleLeftBarChart';
import { FeedbackMiddleRightBarChart } from '../../../../component/Charts/Feedback/FeedbackMiddleRightBarChart';
import { FeedbackBottomLeftBarChart } from '../../../../component/Charts/Feedback/FeedbackBottomLeftBarChart';
import { FeedbackBottomRightBarChart } from '../../../../component/Charts/Feedback/FeedbackBottomRightBarChart';
import { PagesContext } from '../../../../context/PagesContext';
import { guardianPages } from '../../../pages';

export const GuardianDiagnosisFeedbackPage: React.FC = () => {
  const { t: translate } = useTranslation();
  const { user, getAccessToken } = useContext(AuthenticationContext);
  const { herdId, permissions } = useContext(HerdProfileContext);
  const { addMessageToStack } = useContext(SnackbarMessagesContext);
  const { setPages } = useContext(PagesContext);
  const { viewSize } = useContext(ViewContext);

  const [feedbackData, setFeedbackData] = useState<Feedback>();

  const [loading, setLoading] = useState(true);
  const [dataErrorOrWarningSeverity, setDataErrorOrWarningSeverity] = useState('');

  const navigate = useNavigate();

  const fetchFeedback = async () => {
    const result = await Api.diagnosis.fetchFeedback(getAccessToken(), DDWApplication.DairyInsights, herdId);
    if (result.success) {
      if (result.message === '') {
        setFeedbackData(result.data);
        setDataErrorOrWarningSeverity('');
      } else {
        setDataErrorOrWarningSeverity('warning');
      }
    } else {
      if (result.message !== '') {
        navigate('/home');
        addMessageToStack({
          messageType: SnackbarMessageType.error,
          message: translate('There was a problem fetching feedback.'),
          autoHide: false
        });
      } else {
        setDataErrorOrWarningSeverity('danger');
      }
    }
  };

  useAsyncEffect(async () => {
    setPages(guardianPages(translate, permissions.prevention, permissions.diagnosis, permissions.visio, user !== undefined ? user['profile']['UserEmail'] : ''));
    if (herdId != 0) {
      setLoading(true);
      await fetchFeedback();
      setLoading(false);
    }
  }, [herdId]);

  return (
    <>
      <PageContainer hasFooter={false} loading={loading}>
        {dataErrorOrWarningSeverity === '' ?
          <>
            <Row>
              <Col width={'75%'}>
                <SubHeader>
                  <Row style={{ alignItems: 'center' }}>
                    <Col width={'3%'}>
                      <IconContainer>
                        <PencilFillIcon />
                      </IconContainer>
                    </Col>

                    <Col width={'90%'}>
                      {translate('Feedback section')}
                    </Col>
                  </Row>
                </SubHeader>
              </Col>
            </Row>

            {viewSize > ViewSize.S ?
              <>
                <Row>
                  <Col width={'50%'}>
                    <FeedbackTopLeftBarChart dfCase1={feedbackData?.dfCase1} dfCase1Index={feedbackData?.dfCase1Index} />
                  </Col>

                  <Col width={'50%'}>
                    <FeedbackTopRightPieChart dfCase2={feedbackData?.dfCase2} />
                  </Col>
                </Row>

                <Row>
                  <Col width={'50%'}>
                    <FeedbackMiddleLeftBarChart dfCase3={feedbackData?.dfCase3} />
                  </Col>

                  <Col width={'50%'}>
                    <FeedbackMiddleRightBarChart dfCase4={feedbackData?.dfCase4} />
                  </Col>
                </Row>

                <Row>
                  <Col width={'50%'}>
                    <FeedbackBottomLeftBarChart dfCase5={feedbackData?.dfCase5} dfCase5Index={feedbackData?.dfCase5Index} />
                  </Col>

                  <Col width={'50%'}>
                    <FeedbackBottomRightBarChart dfCase6={feedbackData?.dfCase6}
                      dfCase6Index={feedbackData?.dfCase6Index} />
                  </Col>
                </Row>
              </> :
              <>
                <Row>
                  <Col width='100%'>
                    <FeedbackTopLeftBarChart dfCase1={feedbackData?.dfCase1} dfCase1Index={feedbackData?.dfCase1Index} />
                  </Col>
                </Row>

                <Row>
                  <Col width='100%'>
                    <FeedbackTopRightPieChart dfCase2={feedbackData?.dfCase2} />
                  </Col>
                </Row>

                <Row>
                  <Col width='100%'>
                    <FeedbackMiddleLeftBarChart dfCase3={feedbackData?.dfCase3} />
                  </Col>
                </Row>

                <Row>
                  <Col width='100%'>
                    <FeedbackMiddleRightBarChart dfCase4={feedbackData?.dfCase4} />
                  </Col>
                </Row>

                <Row>
                  <Col width='100%'>
                    <FeedbackBottomLeftBarChart dfCase5={feedbackData?.dfCase5} dfCase5Index={feedbackData?.dfCase5Index} />
                  </Col>
                </Row>

                <Row>
                  <Col width='100%'>
                    <FeedbackBottomRightBarChart dfCase6={feedbackData?.dfCase6}
                      dfCase6Index={feedbackData?.dfCase6Index} />
                  </Col>
                </Row>
              </>
            }
          </> :
          <Alert maxWidth={viewSize > ViewSize.S ? '40%' : '75%'} severity={dataErrorOrWarningSeverity}>
            {dataErrorOrWarningSeverity === 'danger' ? <div>{herdId} - {translate('Data not available')}</div> :
              <div>{herdId} - {translate('There is no active session yet for this herd. Please retry later.')}</div>
            }
          </Alert>
        }
      </PageContainer>
    </>
  );
};