import React, { PropsWithChildren, useState } from 'react';
import { Settings } from '../typings/types';
import { urlState } from '../pages/utils';

export const fetchSettings = async (): Promise<Settings> => {
  try {
    return (await fetch(`${process.env.PUBLIC_URL}/settings.json`, {
      method: 'GET'
    }).then((res) => res.json())) as Settings;
  } catch (err) {
    console.error(err, 'Failed to parse json');
    return {} as Settings;
  }
};

const initialContext = {
  settings: {} as Settings | undefined,
  setSettings: (settings: Settings) => {
  }
};

export const ApplicationSettingsContext = React.createContext(initialContext);

export const ApplicationSettingsProvider: React.FC<PropsWithChildren> = ({ children }: any) => {
  const [settings, setSettings] = useState<Settings>();

  if (settings === undefined) {
    fetchSettings().then((result: Settings) => {
      setSettings({ ...result });
      urlState.url = result.ENDPOINT;
    });
  }

  const contextValues = {
    settings,
    setSettings
  };
  return <ApplicationSettingsContext.Provider value={contextValues}>{children}</ApplicationSettingsContext.Provider>;
};
