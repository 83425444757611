import Plotly, { Data, Layout } from "plotly.js";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { DDW_green, DDW_pink, KPI_CODES, PLOTLY_TEMPLATE } from "../../../../../pages/static";

export const InseminationRiskPregnancyRiskChart: React.FC<any> = ({ props }) => {
    const { t: translate } = useTranslation();

    const chartRef = useRef<HTMLDivElement>(null);

    const data: Data[] = [
        {
            x: props.filter((data: any) => data.kpiCode === KPI_CODES['InseminationRate']).map((data: any) => data.periodValue),
            y: props.filter((data: any) => data.kpiCode === KPI_CODES['InseminationRate']).map((data: any) => data.value),
            type: 'bar',
            name: translate('Insemination Risk'),
            marker: { color: DDW_pink }
        },
        {
            x: props.filter((data: any) => data.kpiCode === KPI_CODES['PregnancyRate']).map((data: any) => data.periodValue),
            y: props.filter((data: any) => data.kpiCode === KPI_CODES['PregnancyRate']).map((data: any) => data.value),
            type: 'bar',
            name: translate('Pregnancy Risk'),
            marker: { color: DDW_green }
        }
    ];

    const layout: Partial<Layout> = {
        barmode: 'overlay',
        title: translate('Insemination Risk / Pregnancy Risk'),
        template: PLOTLY_TEMPLATE
    };

    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
        }
    }, [props]);

    return (
        <div ref={chartRef} />
    );
};