import React, { useState } from 'react';
import { Checkbox } from 'antd';
import { Row, Span } from '../../../pages/components';
import { useTranslation } from 'react-i18next';
import { shortenIfPossible } from '../../../pages/utils';

export const AlarmCardChecklist: React.FC<any> = ({ treated, ignored, disease, changeTreatment }) => {
  const { t: translate } = useTranslation();

  const [treatedChecked, setTreatedChecked] = useState(treated);
  const [ignoredChecked, setIgnoredChecked] = useState(ignored);

  const handleTreatedChange = (event: any) => {
    setTreatedChecked(event.target.checked);
    setIgnoredChecked(false);
    changeTreatment({
      disease: parseInt(disease),
      action: event.target.checked ? 1 : null
    });
  };

  const handleIgnoredChange = (event: any) => {
    setIgnoredChecked(event.target.checked);
    setTreatedChecked(false);
    changeTreatment({
      disease: parseInt(disease),
      action: event.target.checked ? 0 : null
    });
  };

  return (
    <>
      <Row style={{ marginTop: '5px' }}>
        <Checkbox value={1}
                  checked={treatedChecked}
                  onChange={handleTreatedChange}
        >
          <Span>{shortenIfPossible(translate('Prevention'))}</Span>
        </Checkbox>
        <Checkbox value={0}
                  checked={ignoredChecked}
                  onChange={handleIgnoredChange}
        >
          <Span>{translate('Ignored')}</Span>
        </Checkbox>
      </Row>
    </>
  );
};