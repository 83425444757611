import Plotly, { Data, Layout } from "plotly.js";
import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ViewContext } from "@ddw/react-framework";
import { ViewSize } from "@ddw/react-components";
import { DDW_blue, DDW_cyan, DDW_green, DDW_grey, DDW_yellow, PLOTLY_TEMPLATE } from "../../../../../pages/static";

export const HerdHistoricMilkAndSolidsData: React.FC<any> = ({ result, isMroOnlyFarm }) => {
    const { t: translate } = useTranslation();
    const { viewSize } = useContext(ViewContext);

    const chartRef = useRef<HTMLDivElement>(null);

    const dates = result?.keys;
    const producedMilkArray: number[] = result?.values?.milk;

    const mroFatDates = result?.keys?.filter((value: any, index: number) => result?.values?.fatValue[index] > 0);
    const mroProteinDates = result?.keys?.filter((value: any, index: number) => result?.values?.proteinValue[index] > 0);
    const mroMilkDates = result?.keys?.filter((value: any, index: number) => result?.values?.mroMilk[index] > 0);

    const trace1Data = [!isMroOnlyFarm ? {
        x: dates,
        y: producedMilkArray,
        mode: 'lines',
        opacity: 1,
        name: translate('Produced Milk'),
        line: {
            color: DDW_blue
        }
    } : {}];

    const trace2Data: Data[] = result?.values?.fatValue?.filter((value: number) => value > 0).map((value: number, index: number) => ({
        x: [mroFatDates[index]],
        y: [value],
        mode: 'markers',
        opacity: 0.75,
        name: translate('Fat (MRO)'),
        showlegend: index === 0 ? true : false,
        line: { color: DDW_cyan },
        yaxis: 'y2'
    }));

    const trace3Data: Data[] = result?.values?.proteinValue?.filter((value: number) => value > 0).map((value: number, index: number) => ({
        x: [mroProteinDates[index]],
        y: [value],
        mode: 'markers',
        opacity: 0.75,
        name: translate('Protein (MRO)'),
        showlegend: index === 0 ? true : false,
        line: { color: DDW_green },
        yaxis: 'y2'
    }));

    const trace4Data: Data[] = result?.values?.mroMilk?.filter((value: number) => value > 0).map((value: number, index: number) => ({
        x: [mroMilkDates[index]],
        y: [value],
        mode: 'markers',
        opacity: 0.75,
        name: translate('Milk (MRO)'),
        showlegend: index === 0 ? true : false,
        line: { color: DDW_yellow },
        yaxis: 'y'
    }));

    const data = [...trace1Data, ...trace2Data, ...trace3Data, ...trace4Data];

    const layout: Partial<Layout> = {
        title: viewSize > ViewSize.S ? translate('Herd Production History: Milk and Solids') : '',
        xaxis: {
            title: translate('Date')
        },
        yaxis: {
            title: 'Daily Milk Yield (kg)',
            range: [0, Math.max(...producedMilkArray) * 1.05]
        },
        yaxis2: {
            title: translate('Daily Solid Yield (%)'),
            overlaying: 'y',
            side: 'right',
            range: [0, 10]
        },
        showlegend: true,
        legend: {
            xanchor: 'center',
            x: 0.5,
            yanchor: 'bottom',
            y: 1,
            orientation: 'h'
        },
        hovermode: 'closest',
        template: PLOTLY_TEMPLATE
    };

    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
        }
    }, [viewSize, result]);

    return (
        <div ref={chartRef} />
    );
}