import React, { useContext, useEffect, useState } from 'react';
import { PageContainer } from '../../component/PageContainer';
import { Col, Header, IconContainer, Paragraph, Row } from '../components';
import { AwardIcon } from '../../component/Icons';
import { useTranslation } from 'react-i18next';
import useAsyncEffect from 'use-async-effect';
import { HerdProfileContext } from '../../context';
import { AuthenticationContext, SnackbarMessagesContext, SnackbarMessageType, ViewContext } from '@ddw/react-framework';
import { DDWApplication, ViewSize } from '@ddw/react-components';
import { Api } from '../../services/api';
import { RadarChart } from '../../component/Charts/Quality/RadarChart';
import { DataQualityBarChart } from '../../component/Charts/Quality/DataQualityBarChart';
import { DataQuality, DataQualityFromDashboard } from '../../typings/types';
import { DataQualityTable } from '../../component/Charts/Quality/DataQualityTable';
import { DataQualityFromDashboardValidTable } from '../../component/Charts/Quality/DataQualityFromDashboardValidTable';
import { DataQualityFromDashboardInvalidTable } from '../../component/Charts/Quality/DataQualityFromDashboardInvalidTable';
import { DataQualityFromDashboardInvalidAnimalsTable } from '../../component/Charts/Quality/DataQualityFromDashboardInvalidAnimalsTable';

export const QualityPage: React.FC = () => {
  const { t: translate } = useTranslation();
  const { herdId } = useContext(HerdProfileContext);
  const { getAccessToken } = useContext(AuthenticationContext);
  const { addMessageToStack } = useContext(SnackbarMessagesContext);
  const { viewSize } = useContext(ViewContext);

  const [loading, setLoading] = useState(true);
  const [loadingDataFromDashboard, setLoadingDataFromDashboard] = useState<boolean>(true);
  const [dataQuality, setDataQuality] = useState<DataQuality>({
    data: {
      'RuleId': [], 'PredictaScore': [], 'Name': [], 'DqsHintDisplay': []
    },
    radar: []
  });
  const [dataQualityFromDashboard, setDataQualityFromDashboard] = useState<DataQualityFromDashboard>({
    valid: {
      'tables': [{
        'rows': []
      }]
    },
    invalid: {
      'tables': [{
        'rows': []
      }]
    },
    invalidAnimals: {
      'tables': [{
        'rows': []
      }]
    }
  });

  useAsyncEffect(async () => {
    if (herdId != 0) {
      setLoading(true);
      const result = await Api.quality.fetchDataQuality(getAccessToken(), DDWApplication.DairyInsights, herdId);
      if (result.success && result.data !== null) {
        setDataQuality(result.data);
      } else {
        setDataQuality({
          data: {
            'RuleId': [], 'PredictaScore': [], 'Name': [], 'DqsHintDisplay': []
          },
          radar: []
        });
        addMessageToStack({
          messageType: SnackbarMessageType.error,
          message: translate('There was a problem fetching data quality information.'),
          autoHide: false
        });
      }
      setLoading(false);

      setLoadingDataFromDashboard(true);
      const resultFromDashboard = await Api.quality.fetchDataQualityFromDashboard(getAccessToken(), DDWApplication.DairyInsights, herdId);
      if (resultFromDashboard.success) {
        setDataQualityFromDashboard(resultFromDashboard.data);
      }
      setLoadingDataFromDashboard(false);
    }
  }, [herdId]);

  useEffect(() => {
    return () => {
      setLoading(true);
      setLoadingDataFromDashboard(true);
      setDataQuality({
        data: {
          'RuleId': [], 'PredictaScore': [], 'Name': [], 'DqsHintDisplay': []
        },
        radar: []
      });
      setDataQualityFromDashboard({
        valid: {
          'tables': [{
            'rows': []
          }]
        },
        invalid: {
          'tables': [{
            'rows': []
          }]
        },
        invalidAnimals: {
          'tables': [{
            'rows': []
          }]
        }
      });
    };
  }, [herdId]);

  return (
    <>
      <PageContainer loading={loading}>
        <Header>
          <Row style={{ alignItems: 'center' }}>
            <IconContainer>
              <AwardIcon />
            </IconContainer>
            {translate("Data Quality")}
          </Row>
        </Header>

        {viewSize > ViewSize.S ?
          <>
            <Row className={'mt-2'}>
              <Paragraph>
                {/* {translate('Report of data quality scores fingerprint (left) and by macro-areas (right).')} */}
                {translate('Report of data quality scores by macro-areas.')}
              </Paragraph>
            </Row>

            <Row className={'mt-3'}>
              {/* <Col width={'75%'}>
                <DataQualityBarChart barData={dataQuality?.data} />
              </Col> */}
              <Col width={'50%'} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <RadarChart radar={dataQuality?.radar} />
              </Col>
            </Row>

            {/* <Row className='mt-3'>
              <Col width='75%' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <DataQualityTable barData={dataQuality?.data} />
              </Col>
            </Row> */}

            <Row className='mt-3'>
              <Col width='50%'>
                <DataQualityFromDashboardInvalidTable invalidTableData={dataQualityFromDashboard?.invalid} loading={loadingDataFromDashboard} />
              </Col>

              <Col width='50%'>
                <DataQualityFromDashboardValidTable validTableData={dataQualityFromDashboard?.valid} loading={loadingDataFromDashboard} />
              </Col>
            </Row>

            <Row className='mt-3'>
              
            </Row>

            <Row className='mt-3'>
              <Col width='100%'>
                <DataQualityFromDashboardInvalidAnimalsTable invalidAnimalsTableData={dataQualityFromDashboard?.invalidAnimals} loading={loadingDataFromDashboard} />
              </Col>
            </Row>
          </> :
          <>
            {/* <Row>
              <Col width='100%'>
                <DataQualityBarChart barData={dataQuality?.data} />
              </Col>
            </Row> */}

            <Row>
              <Col width='100%'>
                <RadarChart radar={dataQuality?.radar} />
              </Col>
            </Row>

            <Row className='mt-3'>
              <Col width='100%'>
                <DataQualityFromDashboardInvalidTable invalidTableData={dataQualityFromDashboard?.invalid} loading={loadingDataFromDashboard} />
              </Col>
            </Row>

            <Row>
              <Col width='100%'>
                <DataQualityFromDashboardValidTable validTableData={dataQualityFromDashboard?.valid} loading={loadingDataFromDashboard} />
              </Col>
            </Row>

            <Row className='mt-3'>
              <Col width='100%'>
                <DataQualityFromDashboardInvalidAnimalsTable invalidAnimalsTableData={dataQualityFromDashboard?.invalidAnimals} loading={loadingDataFromDashboard} />
              </Col>
            </Row>
          </>
        }
      </PageContainer>
    </>
  );
};