import { useContext } from 'react';
import { AuthenticationContext } from '@ddw/react-framework';
import { DDWApplication } from '@ddw/react-components';
import { Api } from '../api';

export const useFetchSupportedLanguages = () => {
  const { getAccessToken } = useContext(AuthenticationContext);

  const fetchSupportedLanguages = async () => {
    return await Api.languages.fetchLanguages(getAccessToken(), DDWApplication.DairyInsights);
  };

  return { fetchSupportedLanguages };
};