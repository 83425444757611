import { DDWApplication, parseError, putHeaders, statusHelper, toJSON } from '@ddw/react-components';
import { ServiceResult } from '@ddw/react-framework/build/src/types';
import { Issues, UpdateTreatment } from '../../typings/types';
import { urlState } from '../../pages/utils';

export const fetchIssues = (accessToken: string, application: DDWApplication, herdId: number): Promise<ServiceResult<Issues>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `herds/` + herdId + '/issues';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const performActionOnTreatmentChangeForIssue = (accessToken: string, application: DDWApplication, herdId: number, guardianAlarmId: string, data: UpdateTreatment): Promise<ServiceResult<any>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `herds/` + herdId + '/issues/' + guardianAlarmId;
  return fetch(url, {
    method: 'PUT',
    headers,
    body: JSON.stringify(data)
  }).then(statusHelper).then(toJSON).catch(parseError);
};
