import Plotly, { Data, Layout } from "plotly.js";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { DDW_blue, DDW_grey, DDW_orange, DDW_purple, KPI_CODES, PLOTLY_TEMPLATE } from "../../../../../pages/static";
import { format, parse } from 'date-fns';
import { ViewSize, useViewSize } from "@ddw/react-components";

export const MonthlyHerdProductionVsDailyProductionChart: React.FC<any> = ({ l1PlusData, allLactationsData }) => {
    const { t: translate } = useTranslation();
    const { viewSize } = useViewSize();

    const chartRef = useRef<HTMLDivElement>(null);

    // the arrays with values for L1, L2 and L3+ must all have the same length otherwise an error occurs
    const upstreamDataOk: boolean = allLactationsData.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerDay'] && data.lactation === "L1").length === allLactationsData.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerDay'] && data.lactation === "L2").length && allLactationsData.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerDay'] && data.lactation === "L2").length === allLactationsData.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerDay'] && data.lactation === "L3+").length;

    const milkProductionPerMonth: number[] = upstreamDataOk ? allLactationsData.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerDay'] && data.lactation === "L1").map((data: any, index: number) => data.value * 30 + allLactationsData.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerDay'] && data.lactation === "L2")[index].value * 30 + allLactationsData.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerDay'] && data.lactation === "L3+")[index].value * 30) : [];

    const l1Percentages: number[] = upstreamDataOk ? allLactationsData.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerDay'] && data.lactation === "L1").map((data: any, index: number) => ((data.value * 30) / milkProductionPerMonth[index] * 100)) : [];
    const l2Percentages: number[] = upstreamDataOk ? allLactationsData.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerDay'] && data.lactation === "L2").map((data: any, index: number) => ((data.value * 30) / milkProductionPerMonth[index] * 100)) : [];
    const l3PlusPercentages: number[] = upstreamDataOk ? allLactationsData.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerDay'] && data.lactation === "L3+").map((data: any, index: number) => ((data.value * 30) / milkProductionPerMonth[index] * 100)) : [];

    const data: Data[] = upstreamDataOk ? [
        {
            x: allLactationsData.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerDay'] && data.lactation === "L1").map((data: any) => format(parse(data.periodValue, 'yyyyMM', new Date()), 'MMM yyyy')),
            y: l1Percentages,
            type: 'bar',
            name: translate('Milk production') + ' - L1',
            marker: { color: DDW_grey },
            customdata: allLactationsData.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerCow'] && data.lactation === "L1").map((data: any, index: number) => [data.value, allLactationsData.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerDay'] && data.lactation === "L1")[index].value * 30, l1PlusData.filter((data: any) => data.kpiCode === KPI_CODES['AverageDimMilkedCows'] && data.lactation)[index].value]),
            hovertemplate: translate('%{x}, %{customdata[1]} L, %{y}%<br>Average milk production per animal (L1): %{customdata[0]} L<br>Average DIM: %{customdata[2]}')
        },
        {
            x: allLactationsData.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerDay'] && data.lactation === "L2").map((data: any) => format(parse(data.periodValue, 'yyyyMM', new Date()), 'MMM yyyy')),
            y: l2Percentages,
            type: 'bar',
            name: translate('Milk production') + ' - L2',
            marker: { color: DDW_blue },
            customdata: allLactationsData.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerCow'] && data.lactation === "L2").map((data: any, index: number) => [data.value, allLactationsData.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerDay'] && data.lactation === "L2")[index].value * 30, l1PlusData.filter((data: any) => data.kpiCode === KPI_CODES['AverageDimMilkedCows'] && data.lactation)[index].value]),
            hovertemplate: translate('%{x}, %{customdata[1]} L, %{y}%<br>Average milk production per animal (L2): %{customdata[0]} L<br>Average DIM: %{customdata[2]}')
        },
        {
            x: allLactationsData.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerDay'] && data.lactation === "L3+").map((data: any) => format(parse(data.periodValue, 'yyyyMM', new Date()), 'MMM yyyy')),
            y: l3PlusPercentages,
            type: 'bar',
            name: translate('Milk production') + ' - L3+',
            marker: { color: DDW_purple },
            customdata: allLactationsData.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerCow'] && data.lactation === "L3+").map((data: any, index: number) => [data.value, allLactationsData.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerDay'] && data.lactation === "L3+")[index].value * 30, l1PlusData.filter((data: any) => data.kpiCode === KPI_CODES['AverageDimMilkedCows'] && data.lactation)[index].value]),
            hovertemplate: translate('%{x}, %{customdata[1]} L, %{y}%<br>Average milk production per animal (L3+): %{customdata[0]} L<br>Average DIM: %{customdata[2]}')
        },
        {
            x: l1PlusData.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerCow']).map((x: any) => format(parse(x.periodValue, 'yyyyMM', new Date()), 'MMM yyyy')),
            y: l1PlusData.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerCow']).map((x: any) => x.value),
            mode: 'lines',
            yaxis: 'y2',
            name: translate('Average milk production'),
            line: {
                color: DDW_orange,
                width: 2.5
            }
        }
    ] : [];

    const layout: Partial<Layout> = {
        title: viewSize > ViewSize.S ? translate('Monthly herd production vs average production per animal') : '',
        yaxis: {
            title: translate('Share of lactation (%)')
        },
        yaxis2: {
            anchor: 'x',
            overlaying: 'y',
            side: 'right',
            title: translate('Average milk production')
        },
        legend: {
            xanchor: 'center',
            x: 0.5,
            yanchor: 'bottom',
            y: 1,
            orientation: 'h',
        },
        barmode: 'stack',
        showlegend: true,
        hovermode: 'closest',
        template: PLOTLY_TEMPLATE
    };

    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
        }
    }, [l1PlusData, allLactationsData, viewSize]);

    return (
        <div ref={chartRef} />
    );
};