import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// interface ILanguage {
//   key: string;
//   label: string;
// }

// // Add languages here to make available in language selector
// export const languages: Array<ILanguage> = [
//   { key: 'de-DE', label: 'Deutsch' },
//   { key: 'en-GB', label: 'English' },
//   { key: 'es-ES', label: 'Español' },
//   { key: 'fr-FR', label: 'Français' },
//   { key: 'it-IT', label: 'Italiano' },
//   { key: 'nl-NL', label: 'Nederlands' },
//   { key: 'pt-BR', label: 'Português (Brasil)' },
//   { key: 'sv-SE', label: 'Svenska' },
//   { key: 'uk-UA', label: 'украї́нська' },
//   { key: 'zh_CN', label: '中文' }
// ];

export const languageCodeToLanguage: { [key: string]: string } = {
  '1031': 'de-DE',
  '1034': 'es-ES',
  '1036': 'fr-FR',
  '1040': 'it-IT',
  '1043': 'nl-NL',
  '1046': 'pt-BR',
  '1053': 'sv-SE',
  '1058': 'uk-UA',
  '2052': 'zh_CN',
  '2057': 'en-GB'
};

const backendOptions = {
  loadPath: '/locales/{{lng}}/translations.json'
};

const detectionOptions = {
  order: ['localStorage', 'navigator'],
  lookupLocalStorage: 'i18nextLng',
  caches: ['localStorage']
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-GB',
    backend: backendOptions,
    detection: detectionOptions,
    debug: false,
    keySeparator: '~~',
    nsSeparator: '~~~',
    returnEmptyString: false,
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
