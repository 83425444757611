import Plotly, { Data, Layout } from "plotly.js";
import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { DDW_grey, DDW_grey_set, DDW_pink, DDW_pink_set, KPI_CODES, PLOTLY_TEMPLATE } from "../../../../../pages/static";
import { format, parse } from 'date-fns';
import { ViewContext } from "@ddw/react-framework";
import { ViewSize } from "@ddw/react-components";

export const DaysOpenVsDaysToConceptionChart: React.FC<any> = ({ props }) => {
    const { t: translate } = useTranslation();
    const { viewSize } = useContext(ViewContext);

    const chartRef = useRef<HTMLDivElement>(null);

    const data: Data[] = [
        {
            x: props.filter((data: any) => (data.kpiCode === KPI_CODES['DaystoFirstInsemination'] && data.lactation === "L1")).map((x: any) => format(parse(x.periodValue, 'yyyyMM', new Date()), 'MMM yyyy')),
            y: props.filter((data: any) => (data.kpiCode === KPI_CODES['DaystoFirstInsemination'] && data.lactation === "L1")).map((x: any) => x.value),
            mode: 'lines',
            name: translate('Days open - L1'),
            line: {
                color: DDW_pink_set[0],
                dash: 'solid'
            }
        },
        {
            x: props.filter((data: any) => (data.kpiCode === KPI_CODES['DaystoFirstInsemination'] && data.lactation === "L2")).map((x: any) => format(parse(x.periodValue, 'yyyyMM', new Date()), 'MMM yyyy')),
            y: props.filter((data: any) => (data.kpiCode === KPI_CODES['DaystoFirstInsemination'] && data.lactation === "L2")).map((x: any) => x.value),
            mode: 'lines',
            name: translate('Days open - L2'),
            line: {
                color: DDW_pink_set[1],
                dash: 'dash'
            }
        },
        {
            x: props.filter((data: any) => (data.kpiCode === KPI_CODES['DaystoFirstInsemination'] && data.lactation === "L3+")).map((x: any) => format(parse(x.periodValue, 'yyyyMM', new Date()), 'MMM yyyy')),
            y: props.filter((data: any) => (data.kpiCode === KPI_CODES['DaystoFirstInsemination'] && data.lactation === "L3+")).map((x: any) => x.value),
            mode: 'lines',
            name: translate('Days open - L3+'),
            line: {
                color: DDW_pink_set[2],
                dash: 'dot'
            }
        },
        {
            x: props.filter((data: any) => (data.kpiCode === KPI_CODES['AvgDaysToConception'] && data.lactation === "L1")).map((x: any) => format(parse(x.periodValue, 'yyyyMM', new Date()), 'MMM yyyy')),
            y: props.filter((data: any) => (data.kpiCode === KPI_CODES['AvgDaysToConception'] && data.lactation === "L1")).map((x: any) => x.value),
            mode: 'lines',
            name: translate('Days to conception - L1'),
            line: {
                color: DDW_grey_set[0],
                width: 2.5,
                dash: 'solid'
            },
            yaxis: 'y2',
        },
        {
            x: props.filter((data: any) => (data.kpiCode === KPI_CODES['AvgDaysToConception'] && data.lactation === "L2")).map((x: any) => format(parse(x.periodValue, 'yyyyMM', new Date()), 'MMM yyyy')),
            y: props.filter((data: any) => (data.kpiCode === KPI_CODES['AvgDaysToConception'] && data.lactation === "L2")).map((x: any) => x.value),
            mode: 'lines',
            name: translate('Days to conception - L2'),
            line: {
                color: DDW_grey,
                width: 2.5,
                dash: 'dash'
            },
            yaxis: 'y2',
        },
        {
            x: props.filter((data: any) => (data.kpiCode === KPI_CODES['AvgDaysToConception'] && data.lactation === "L3+")).map((x: any) => format(parse(x.periodValue, 'yyyyMM', new Date()), 'MMM yyyy')),
            y: props.filter((data: any) => (data.kpiCode === KPI_CODES['AvgDaysToConception'] && data.lactation === "L3+")).map((x: any) => x.value),
            mode: 'lines',
            name: translate('Days to conception - L3+'),
            line: {
                color: DDW_grey_set[2],
                width: 2.5,
                dash: 'dot'
            },
            yaxis: 'y2',
        }
    ];

    const layout: Partial<Layout> = {
        title: translate('Days Open vs Days to conception'),
        legend: {
            orientation: 'h',
            yanchor: 'bottom',
            y: 1,
            xanchor: 'center',
            x: 0.5
        },
        yaxis: {
            title: translate('Days open')
        },
        yaxis2: {
            anchor: 'x',
            overlaying: 'y',
            side: 'right',
            title: translate('Days to conception')
        },
        showlegend: viewSize > ViewSize.S ? true : false,
        hovermode: 'closest',
        template: PLOTLY_TEMPLATE
    };

    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
        }
    }, [props, viewSize]);

    return (
        <div ref={chartRef} />
    );
};