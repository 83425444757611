import { DDWApplication, parseError, putHeaders, statusHelper, toJSON } from "@ddw/react-components";
import { ServiceResult } from "@ddw/react-framework/build/src/types";
import { urlState } from "../../pages/utils";
import { HomePageDashboardInformation } from "../../typings/types";

export const fetchHomePageDashboard = (accessToken: string, application: DDWApplication, herdId: number): Promise<ServiceResult<HomePageDashboardInformation>> => {
    const headers = putHeaders(accessToken, application);
    headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
    const url = urlState.url + `herds/` + herdId + '/dashboard-information';
    return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
  };