import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Plotly from 'plotly.js';
import { getColor } from '../../../../pages/utils';
import { Data, Layout } from 'plotly.js';

export const PieChart: React.FC<any> = ({ statsData, model }) => {
  const { t: translate } = useTranslation();

  const chartRef = useRef<HTMLDivElement>(null);

  const data: Data[] = [
    {
      type: 'pie',
      labels: [
        translate('Alarmed'),
        translate('Not alarmed')
      ],
      values: statsData.predPathValues,
      marker: {
        colors: [
          getColor(model, 0),
          getColor(model, 1)
        ]
      },
      hole: 0.6
    }
  ];

  const layout: Partial<Layout> = {
    title: translate("Alarm rate - currently dry animals"),
    showlegend: true,
    legend: {
      xanchor: "left",
      x: 0.85,
      yanchor: "top",
      y: 1,
    },
    hovermode: "closest",
  }

  useEffect(() => {
    if (chartRef.current) {
      Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
      //Plotly.newPlot(chartRef.current, data, layout);
    }
  }, []);

  return (
    <>
      <div ref={chartRef} />
    </>
  );
};