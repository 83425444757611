import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HerdProfileContext } from '../../../../context';
import { AuthenticationContext, SnackbarMessagesContext, SnackbarMessageType, ViewContext } from '@ddw/react-framework';
import { useNavigate } from 'react-router-dom';
import { PageContainer } from '../../../../component/PageContainer';
import { Alert, Col, IconContainer, Paragraph, Row, SubHeader } from '../../../components';
import { BellFillIcon } from '../../../../component/Icons';
import { DiagnosisAlarms } from '../../../../typings/types';
import useAsyncEffect from 'use-async-effect';
import { Button, ButtonType, DDWApplication, IconLib, ViewSize } from '@ddw/react-components';
import { Api } from '../../../../services/api';
import { DiagnosisAlarmCard } from '../../../../component/Diagnosis/DiagnosisAlarmCard';
import { PagesContext } from '../../../../context/PagesContext';
import { guardianPages } from '../../../pages';

export const GuardianDiagnosisAlarmsPage: React.FC = () => {
  const { t: translate } = useTranslation();
  const { herdId, permissions } = useContext(HerdProfileContext);
  const { user, getAccessToken } = useContext(AuthenticationContext);
  const { addMessageToStack } = useContext(SnackbarMessagesContext);
  const { setPages } = useContext(PagesContext);
  const { viewSize } = useContext(ViewContext);

  const [alarmData, setAlarmData] = useState<DiagnosisAlarms>({ cardData: [], freshness: 0, sessionTime: '' });

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [dataErrorOrWarningSeverity, setDataErrorOrWarningSeverity] = useState('');

  const downloadAlarms = async () => {
    document.body.style.cursor = 'progress';
    const result = await Api.downloads.downloadDiagnosisAlarms(getAccessToken(), DDWApplication.DairyInsights, herdId);
    const blob = await result.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'guardian_diagnosis_alarms-' + herdId + '-' + sessionStorage.getItem('sessionId') + '.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    document.body.style.cursor = 'default';
  };

  useAsyncEffect(async () => {
    setPages(guardianPages(translate, permissions.prevention, permissions.diagnosis, permissions.visio, user !== undefined ? user['profile']['UserEmail'] : ''));
    if (herdId != 0) {
      setLoading(true);

      const result = await Api.diagnosis.fetchAlarms(getAccessToken(), DDWApplication.DairyInsights, herdId);
      if (result.success) {
        if (result.message === '') {
          setAlarmData(result.data);
          setDataErrorOrWarningSeverity('');
        } else {
          setDataErrorOrWarningSeverity('warning');
        }
      } else {
        if (result.message !== '') {
          navigate('/home');
          addMessageToStack({
            messageType: SnackbarMessageType.error,
            message: translate('There was a problem fetching alarms.'),
            autoHide: false
          });
        } else {
          setDataErrorOrWarningSeverity('danger');
        }
      }

      setLoading(false);
    }
  }, [herdId]);

  return (
    <PageContainer hasFooter={false} loading={loading}>
      {dataErrorOrWarningSeverity === '' ?
        <>
          <Row>
            <Col width={'75%'}>
              <SubHeader>
                <Row style={{ alignItems: 'center' }}>
                  <Col width={'3%'}>
                    <IconContainer>
                      <BellFillIcon />
                    </IconContainer>
                  </Col>

                  <Col width={'90%'}>
                    {translate('Active alarm list')}
                  </Col>
                </Row>
              </SubHeader>
              
              <Paragraph>
                {translate('Report of currently milking animals at risk of being experiencing a new disease event.')}
              </Paragraph>
            </Col>
          </Row>

          <Row className={'mt-4 mb-4'}>
            <Alert maxWidth={'100%'}
              severity={alarmData?.freshness < 24 ? 'success' : alarmData?.freshness < 36 ? 'warning' : 'danger'}>
              {translate('Freshness check: visualising predictions of {{date}} {{time}} UTC ({{hours}} hours ago)', {
                date: alarmData?.sessionTime?.split('T')[0],
                time: alarmData?.sessionTime?.split('T')[1],
                hours: Math.floor(alarmData?.freshness)
              })}
            </Alert>
          </Row>

          <Row className={'mt-3'} style={{ display: alarmData.cardData.length > 0 ? 'none' : 'block' }}>
            <Alert severity={'primary'}>
              {translate('There are no currently active alarms needing attention.')}
            </Alert>
          </Row>

          {alarmData.cardData.map((data, index) => {
            return (
              <Row key={index} style={{ margin: '10px' }}>
                <DiagnosisAlarmCard data={data} />
              </Row>
            );
          })}

          <Row className={'mt-5 mb-3'}>
            <Col>
              <Button name={'download-active-alarms'}
                type={ButtonType.Cancel}
                isOutlined={false}
                onClick={downloadAlarms}
                icon={IconLib.downloadLight}
              >
                {translate('Download Data')}
              </Button>
            </Col>
          </Row>
        </> :
        <Alert maxWidth={viewSize > ViewSize.S ? '25%' : '75%'} severity={dataErrorOrWarningSeverity}>
          {dataErrorOrWarningSeverity === 'danger' ? <div>{herdId} - {translate('Data not available')}</div> :
            <div>{herdId} - {translate('There is no active session yet for this herd. Please retry later.')}</div>
          }
        </Alert>
      }
    </PageContainer>
  );
};