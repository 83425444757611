import { DDWApplication, parseError, putHeaders, statusHelper, toJSON } from "@ddw/react-components";
import { ServiceResult } from "@ddw/react-framework/build/src/types";
import { urlState } from "../../pages/utils";
import { CurrentLactationPredictionRelativeToTheFuturePart, UdderHealthData } from "../../typings/herdfolio/types";

export const fetchCurrentLactationPredictionRelativeToTheFuturePart = (accessToken: string, application: DDWApplication, herdId: number): Promise<ServiceResult<CurrentLactationPredictionRelativeToTheFuturePart>> => {
    const headers = putHeaders(accessToken, application);
    headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
    const url = urlState.url + `herdfolio/` + herdId + '/current-lactation-prediction-relative-to-the-future-part';
    return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const fetchUpstreamKpis = (accessToken: string, application: DDWApplication, herdId: number): Promise<ServiceResult<any>> => {
    const headers = putHeaders(accessToken, application);
    headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
    const url = urlState.url + `herdfolio/` + herdId + '/upstream-kpis';
    return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const fetchNumberOfMilkingBeds = (accessToken: string, application: DDWApplication, herdId: number): Promise<ServiceResult<number>> => {
    const headers = putHeaders(accessToken, application);
    headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
    const url = urlState.url + `herdfolio/` + herdId + '/milking-beds';
    return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const fetchInsightsKpis = (accessToken: string, application: DDWApplication, herdId: number): Promise<ServiceResult<any>> => {
    const headers = putHeaders(accessToken, application);
    headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
    const url = urlState.url + `herdfolio/` + herdId + '/insights-kpis';
    return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const fetchVoluntaryWaitingPeriod = (accessToken: string, application: DDWApplication, herdId: number): Promise<ServiceResult<number | null>> => {
    const headers = putHeaders(accessToken, application);
    headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
    const url = urlState.url + `herdfolio/` + herdId + '/voluntary-waiting-period';
    return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const updateVoluntaryWaitingPeriod = (accessToken: string, application: DDWApplication, herdId: number, voluntaryWaitingPeriod: number): Promise<ServiceResult<any>> => {
    const headers = putHeaders(accessToken, application);
    headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
    const url = urlState.url + `herdfolio/` + herdId + '/voluntary-waiting-period';
    return fetch(url, {
      method: 'PUT',
      headers,
      body: JSON.stringify({
        "voluntaryWaitingPeriod": voluntaryWaitingPeriod
      })
    }).then(statusHelper).then(toJSON).catch(parseError);
  };
