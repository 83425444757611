import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Plotly from 'plotly.js';
import { Data, Layout } from 'plotly.js';
import { DIAGNOSIS_DIS_COL, DIS_LABEL_TO_CODE } from '../../../../../pages/static';
import { getObjectValues } from '../../../../../pages/utils';

export const DiagnosisBottomRightBarChart: React.FC<any> = ({ stats, statsColumns }) => {
  const { t: translate } = useTranslation();

  const chartRef = useRef<HTMLDivElement>(null);

  const data: Data[] = statsColumns
    .filter((col: string) => col.startsWith('CountAlerts_'))
    .map((col: string) => {
      return {
        x: getObjectValues<string>(stats['Executed']).map((value: string) => value.split('T')[0] + ' ' + value.split('T')[1]),
        y: getObjectValues<number>(stats[col]),
        width: 0.5 * 1000 * 3600 * 24,
        name: translate(col.split('_').slice(-1)[0]),
        marker: {
          color: DIAGNOSIS_DIS_COL[DIS_LABEL_TO_CODE[col.split('_').slice(-1)[0]]]
        },
        type: 'bar',
        hovertemplate: translate('Executed: %{x}<br>Alarms raised: %{y:d}')
      };
    });


  const layout: Partial<Layout> = {
    barmode: 'stack',
    title: translate('Alarm type - last 30 days'),
    showlegend: true,
    legend: {
      xanchor: 'center',
      x: 0.5,
      yanchor: 'bottom',
      y: 1,
      orientation: 'h'
    },
    hovermode: 'closest'
  };

  useEffect(() => {
    if (chartRef.current) {
      Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
    }
  }, []);

  return (
    <>
      <div ref={chartRef} />
    </>
  );
};