import { DDWApplication, parseError, putHeaders, statusHelper, toJSON } from '@ddw/react-components';
import { ServiceResult } from '@ddw/react-framework/build/src/types';
import {
  DetectedTimeZone,
  FarmAddressValidationData,
  FarmCoordinates,
  ObtainedFarmCoordinatesFromAddress
} from '../../typings/types';
import { urlState } from '../../pages/utils';

export const fetchFarmCoordinates = (accessToken: string, application: DDWApplication, herdId: number): Promise<ServiceResult<FarmCoordinates>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `farm/` + herdId + '/coordinates';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const obtainFarmCoordinatesFromAddress = (accessToken: string, application: DDWApplication, address: string): Promise<ServiceResult<ObtainedFarmCoordinatesFromAddress>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  headers.set('farm-address', encodeURIComponent(address));
  const url = urlState.url + `obtain-coordinates`;
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const detectTimeZoneFromCoordinates = (accessToken: string, application: DDWApplication, coordinates: string): Promise<ServiceResult<DetectedTimeZone>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  headers.set('coordinates', coordinates);
  const url = urlState.url + `detect-timezone`;
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const validateFarmAddress = (accessToken: string, application: DDWApplication, herdId: string, data: FarmAddressValidationData): Promise<ServiceResult<any>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `herds/` + herdId + '/address-validation';
  return fetch(url, {
    method: 'PUT',
    headers,
    body: JSON.stringify(data)
  }).then(statusHelper).then(toJSON).catch(parseError);
};