import React, { useContext, useEffect, useState } from 'react';
import {
  ActivityIndicator,
  Button,
  ButtonType,
  DDWApplication,
  Dialog,
  DialogButtonsRightSide,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogSize,
  Input,
  Select
} from '@ddw/react-components';
import { useTranslation } from 'react-i18next';
import { Alert, MapContainer, Paragraph } from '../../pages/components';
import { Collapse, Typography } from '@material-ui/core';
import { SubParagraph } from '../../pages/components/sub-paragraph';
import { TIME_ZONES } from '../../pages/static';
import { Tooltip } from '@mui/material';
import { Api } from '../../services/api';
import { AuthenticationContext } from '@ddw/react-framework';
import { HerdSelectorSelectParent } from '../HerdSelector/herdselector.styled';
import { HerdProfileContext } from '../../context';
import { ScatterMapbox } from '../Charts/Map/HomePageMap';
import { Shape } from 'plotly.js';

export const FarmAddressDialog: React.FC<any> = ({
                                                   showDialog,
                                                   setShowDialog,
                                                   latitude,
                                                   longitude,
                                                   address,
                                                   setOuterMapViewport
                                                 }) => {
  const { t: translate } = useTranslation();
  const { getAccessToken } = useContext(AuthenticationContext);
  const { herdId } = useContext(HerdProfileContext);

  const [farmAddress, setFarmAddress] = useState(address);
  const [currentStep, setCurrentStep] = useState(1);

  const [saving, setSaving] = useState(false);

  const [viewport, setViewport] = useState({
    latitude: latitude,
    longitude: longitude,
    zoom: 16
  });

  const [stepOneDisabledCoordinates, setStepOneDisabledCoordinates] = useState(true);
  const [stepOneCoordinatesInputError, setStepOneCoordinatesInputError] = useState('');
  const [stepOneCoordinates, setStepOneCoordinates] = useState(viewport.latitude + '; ' + viewport.longitude);
  const [stepTwoCoordinates, setStepTwoCoordinates] = useState(viewport.latitude + '; ' + viewport.longitude);
  const [stepThreeTimeZoneOption, setStepThreeTimeZoneOption] = useState({ label: '', value: '' });

  const [stepOneObtainCoordinatesErrorMessage, setStepOneObtainCoordinatesErrorMessage] = useState({
    message: '',
    severity: ''
  });

  const [stepThreeDetectTimeZoneErrorMessage, setStepThreeDetectTimeZoneErrorMessage] = useState({
    message: '',
    severity: ''
  });

  const [farmAddressValidationMessage, setFarmAddressValidationMessage] = useState({
    message: '',
    severity: ''
  });

  const mapStyle: string = 'mapbox://styles/mapbox/satellite-streets-v9';
  const mapShapes: Array<Partial<Shape>> = [
    {
      type: 'circle',
      xref: 'paper',
      x0: 0.47,
      yref: 'paper',
      y0: 0.44,
      x1: 0.53,
      y1: 0.56,
      line: {
        color: 'rgb(252, 252, 3)',
        width: 4
      }
    }
  ];

  const step = function(forward: boolean) {
    if (forward) {
      if (currentStep < 3) {
        setCurrentStep(currentStep + 1);
      }
    } else {
      if (currentStep > 1) {
        setCurrentStep(currentStep - 1);
      }
    }
  };

  const obtainCoordinates = async function() {
    const result = await Api.farmCoordinates.obtainFarmCoordinatesFromAddress(
      getAccessToken(),
      DDWApplication.DairyInsights,
      farmAddress
    );
    if (result.success) {
      setFarmAddress(result.data.address);
      setStepOneCoordinates(result.data.coordinates);
      setStepTwoCoordinates(result.data.coordinates);
      const split = result.data.coordinates.split('; ');
      const lat = split[0].replaceAll(' ', '');
      const lon = split[1].replaceAll(' ', '');

      const _lat = Number(lat);
      const _lon = Number(lon);

      setViewport({
        latitude: _lat,
        longitude: _lon,
        zoom: 16
      });

      setStepOneObtainCoordinatesErrorMessage({ message: '', severity: '' });
    } else {
      setStepOneObtainCoordinatesErrorMessage({ message: result.message, severity: 'danger' });
      setStepOneCoordinates('');
    }
  };

  const detectTimeZone = async function() {
    const result = await Api.farmCoordinates.detectTimeZoneFromCoordinates(
      getAccessToken(),
      DDWApplication.DairyInsights,
      stepTwoCoordinates
    );
    if (result.success) {
      setStepThreeTimeZoneOption({ label: result.data.timeZoneId, value: result.data.timeZoneId });
      setStepThreeDetectTimeZoneErrorMessage({ message: '', severity: '' });
    } else {
      setStepThreeDetectTimeZoneErrorMessage({ message: result.message, severity: 'danger' });
    }
  };

  const validateFarmAddress = async function() {
    setSaving(true);
    const result = await Api.farmCoordinates.validateFarmAddress(getAccessToken(), DDWApplication.DairyInsights, String(herdId), {
      coordinates: stepTwoCoordinates,
      address: farmAddress,
      timeZoneId: stepThreeTimeZoneOption.value
    });
    setSaving(false);
    if (result.success) {
      if (result.message !== '') {
        setFarmAddressValidationMessage({
          message: result.message,
          severity: 'warning'
        });
      } else {
        setFarmAddressValidationMessage({
          message: translate('Successfully updated validation details.'),
          severity: 'success'
        });
        const split = stepTwoCoordinates.split('; ');
        const lat = split[0].replaceAll(' ', '');
        const lon = split[1].replaceAll(' ', '');

        const _lat = Number(lat);
        const _lon = Number(lon);
        setOuterMapViewport({
          latitude: _lat,
          longitude: _lon,
          zoom: 16
        });
      }
    } else {
      setFarmAddressValidationMessage({ message: result.message, severity: 'danger' });
    }
  };

  useEffect(() => {
    setFarmAddress(address);
    setViewport({ latitude: latitude, longitude: longitude, zoom: 16 });
    setStepOneCoordinates(latitude + '; ' + longitude);
    setStepTwoCoordinates(latitude + '; ' + longitude);
  }, [latitude, longitude, address]);

  useEffect(() => {
    if (stepOneCoordinates === '' || !stepOneCoordinates.includes('; ')) {
      setStepOneCoordinatesInputError(translate('Coordinates format is not valid'));
    } else {
      const split = stepOneCoordinates.split('; ');
      const lat = split[0].replaceAll(' ', '');
      const lon = split[1].replaceAll(' ', '');

      const _lat = Number(lat);
      const _lon = Number(lon);
      if (lat === '' || lon === '' || isNaN(_lat) || isNaN(_lon)) {
        setStepOneCoordinatesInputError(translate('Coordinates format is not valid'));
      } else {
        setStepOneCoordinatesInputError('');
        setStepTwoCoordinates(stepOneCoordinates);
        setViewport({
          latitude: _lat,
          longitude: _lon,
          zoom: 16
        });
      }
    }
  }, [stepOneCoordinates]);

  useEffect(() => {
    if (!showDialog && farmAddressValidationMessage.severity === 'success') {
      setCurrentStep(1);
      setFarmAddressValidationMessage({message: '', severity: ''});
    }
  }, [showDialog]);

  return (
    <>
      <Dialog
        size={DialogSize.S}
        show={showDialog}
        setShow={setShowDialog}
        closeOnClickOutside={true}
        customMinHeight={'700px'}
      >
        <DialogHeader>
          <h3 style={{ margin: '0px' }}>{translate('Farm address validation')}</h3>
        </DialogHeader>
        <DialogContent>
          <Collapse in={currentStep == 1}>
            <h4>{translate('Step 1: Localise farm address')}</h4>
            <Paragraph>
              {translate(
                'Help us localise your farm to provide you with predictions of important environmental parameters, such as temperature, humidity and stress indices. We can proceed to next step once we have determined geographic coordinates of the farm.'
              )}
            </Paragraph>

            <Typography>{translate('Farm address')}</Typography>
            <Input
              name={translate('Enter farm address')}
              field={`farm-address-input`}
              value={farmAddress}
              onChange={setFarmAddress}
            />

            <Button
              name={'obtain coordinates'}
              type={ButtonType.Primary}
              onClick={obtainCoordinates}
              isOutlined={false}
            >
              {' '}
              {translate('Obtain coordinates')}
            </Button>

            {stepOneObtainCoordinatesErrorMessage.message !== '' && (
              <Alert
                style={{ marginTop: '5px' }}
                severity={stepOneObtainCoordinatesErrorMessage.severity}
                maxWidth={'100%'}
              >
                {stepOneObtainCoordinatesErrorMessage.message}
              </Alert>
            )}

            <SubParagraph>
              {translate('Preferred format: <street> <street number>, <ZIP code> <city>, <country>')}
            </SubParagraph>

            <Paragraph>{translate('Or alternatively')}</Paragraph>

            <Tooltip
              title={translate(
                'Manually enter farm coordinates, latitude and longitude separated by semicolon and a space, in degrees with integer and fractional part separated by a dot (<lat[DD.D]>; <lon[DD:D]>); e.g. \'52.992869; 6.566039\''
              )}
              placement={'right'}
            >
              <div style={{ display: 'inline-block' }}>
                <Button
                  name={'enable manual entry'}
                  type={ButtonType.Primary}
                  onClick={() => {
                    setStepOneDisabledCoordinates(false);
                  }}
                  isOutlined={false}
                >
                  {' '}
                  {translate('Enable manual entry')}
                </Button>
              </div>
            </Tooltip>

            <Paragraph>{translate('Farm coordinates')}</Paragraph>

            <Input
              name={translate('Farm coordinates')}
              field={`farm-coordinates`}
              value={stepOneCoordinates}
              onChange={setStepOneCoordinates}
              readOnly={stepOneDisabledCoordinates}
              error={stepOneCoordinatesInputError}
            />

            <SubParagraph>
              {translate(
                'Click on \'Obtain coordinates\' to automatically fill geographic coordinates for a newly entered farm address. If the coordinates cannot be retrieved, or are not accurate enough, enable manual coordinates input with the button above.'
              )}
            </SubParagraph>
          </Collapse>

          <Collapse in={currentStep == 2}>
            <h4>{translate('Step 2: Verify on map')}</h4>
            <Paragraph>
              {translate(
                'Validate farm location through the aerial image below. If the farm does not appear at the centre of the image, move the entire image such to center the yellow circle on top of the main farm building. Zoom level can be modified with the mouse wheel or clicking on the floating buttons at the top-right corner of the graph. Once finished, click \'Next\'.'
              )}
            </Paragraph>

            <MapContainer width={'73vw'} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <ScatterMapbox latitude={viewport.latitude} longitude={viewport.longitude} zoom={viewport.zoom}
                             style={mapStyle} shapes={mapShapes} setOuterCoordinates={setStepTwoCoordinates} />
            </MapContainer>

            <Paragraph>{translate('Farm coordinates')}</Paragraph>

            <Input
              name={translate('Farm coordinates')}
              field={`farm-coordinates-step-two`}
              value={stepTwoCoordinates}
              onChange={() => {
              }}
              readOnly={true}
            />

            <SubParagraph>{translate('Farm coordinates verification')}</SubParagraph>
          </Collapse>

          <Collapse in={currentStep == 3}>
            <h4>{translate('Step 3: Time zone')}</h4>
            <Paragraph>
              {translate(
                'Click on the button below to automatically detect time zone based on farm coordinates, or select the time zone from the given list. This will help us determine the freshness of sensor data and provide a better prediction service.'
              )}
            </Paragraph>

            <Button name={'detect timezone'} type={ButtonType.Primary} onClick={detectTimeZone} isOutlined={false}>
              {' '}
              {translate('Detect time zone based on coordinates')}
            </Button>

            {stepThreeDetectTimeZoneErrorMessage.message !== '' && (
              <Alert severity={stepThreeDetectTimeZoneErrorMessage.severity} maxWidth={'100%'}>
                {stepThreeDetectTimeZoneErrorMessage.message}
              </Alert>
            )}

            <Paragraph>{translate('Farm coordinates')}</Paragraph>
            <HerdSelectorSelectParent>
              <Select
                name={translate('Time zone')}
                field={`time-zone`}
                options={TIME_ZONES.map((timeZone) => {
                  return { label: timeZone, value: timeZone };
                })}
                selected={stepThreeTimeZoneOption}
                onChange={(selected: any) => {
                  setStepThreeTimeZoneOption(selected);
                }}
              ></Select>
            </HerdSelectorSelectParent>
            <SubParagraph>
              {translate('We display time zone codes as per the IANA time zone database naming convention.')}
            </SubParagraph>

            {farmAddressValidationMessage.message !== '' && (
              <Alert severity={farmAddressValidationMessage.severity} maxWidth={'100%'}>
                {farmAddressValidationMessage.message}
              </Alert>
            )}

            <br />

            {saving && (
              <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <ActivityIndicator />
              </div>
            )}
          </Collapse>
        </DialogContent>

        <DialogFooter>
          <DialogButtonsRightSide>
            <Button
              name={'previous'}
              type={ButtonType.Cancel}
              onClick={() => {
                step(false);
              }}
              isOutlined={false}
              isDisabled={currentStep == 1}
            >
              {' '}
              {translate('Previous')}
            </Button>

            <Button
              name={'next'}
              type={ButtonType.Primary}
              onClick={() => {
                step(true);
              }}
              isOutlined={false}
              isDisabled={currentStep == 3 || (currentStep == 1 && stepOneCoordinatesInputError !== '')}
            >
              {' '}
              {translate('Next')}
            </Button>

            <Button
              name={'save'}
              type={ButtonType.Primary}
              onClick={validateFarmAddress}
              isOutlined={false}
              isDisabled={currentStep !== 3 || stepThreeTimeZoneOption.value === ''}
            >
              {' '}
              {translate('Save')}
            </Button>

            <Button
              name={'close'}
              type={ButtonType.Delete}
              onClick={() => {
                setShowDialog(false);
              }}
              isOutlined={false}
            >
              {' '}
              {translate('Close')}
            </Button>
          </DialogButtonsRightSide>
        </DialogFooter>
      </Dialog>
    </>
  );
};
