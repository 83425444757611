import React, { useContext, useEffect, useState } from 'react';
import { PageContainer } from '../../../../component/PageContainer';
import { Alert, Col, Paragraph, Row, SubHeader, IconContainer } from '../../../components';
import { ConeStripedIcon } from '../../../../component/Icons';
import { useTranslation } from 'react-i18next';
import { Button, ButtonType, DDWApplication, DividerStyle, IconLib, Input, SelectOption, ViewSize } from '@ddw/react-components';
import { Api } from '../../../../services/api';
import { useNavigate } from 'react-router-dom';
import { AuthenticationContext, SnackbarMessagesContext, SnackbarMessageType, ViewContext } from '@ddw/react-framework';
import { HerdProfileContext } from '../../../../context';
import { DismissibleCard } from '../../../../component';
import { getObjectKeys } from '../../../utils';
import { IssueCard } from '../../../../component/IssueCard';
import { Issues } from '../../../../typings/types';
import useAsyncEffect from 'use-async-effect';
import { DIS_NAMES, DQ_VIOLATION_RANGE } from '../../../static';
import { PreventionIssuesBarChart, HeatMap } from '../../../../component/Charts';
import { Select } from 'antd';
import { PagesContext } from '../../../../context/PagesContext';
import { guardianPages } from '../../../pages';

export const GuardianPreventionIssuesPage: React.FC = () => {
  const { t: translate } = useTranslation();
  const { user, getAccessToken } = useContext(AuthenticationContext);
  const { herdId, permissions } = useContext(HerdProfileContext);
  const { addMessageToStack } = useContext(SnackbarMessagesContext);
  const { setPages } = useContext(PagesContext);
  const { viewSize } = useContext(ViewContext);

  const { Option } = Select;

  const [issueData, setIssueData] = useState<Issues>();
  const [animalSearch, setAnimalSearch] = useState('');
  const [activeModels, setActiveModels] = useState<SelectOption[]>([]);
  const [diseaseOptions, setDiseaseOptions] = useState<string[]>([]);
  const [actionTakenAlarmsCollapseOpen, setActionTakenAlarmsCollapseOpen] = useState(false);
  const [displayAnimalSearchWarning, setDisplayAnimalSearchWarning] = useState(false);
  const [displayNoActiveAlarmsAlert, setDisplayNoActiveAlarmsAlert] = useState(false);
  const [filteredIssues, setFilteredIssues] = useState<any[]>([]);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [dataErrorOrWarningSeverity, setDataErrorOrWarningSeverity] = useState('');
  const [dataErrorMessage, setDataErrorMessage] = useState<string>('');

  const downloadActiveIssues = async () => {
    const result = await Api.downloads.downloadActiveIssues(getAccessToken(), DDWApplication.DairyInsights, herdId);
    const blob = await result.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'guardian_prevention_alarms_' + 'session_id' + '_' + herdId + '.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const filterIssues = function (earTag: string, cowNumber: string, diseases: any, violations: any, ignored: any, active: boolean) {
    if (!(diseases.filter((disease: string) => DQ_VIOLATION_RANGE.includes(violations[disease])))
      .some((disease: string) => diseaseOptions.map(disease => disease).includes(disease))) {
      return false;
    }

    if (active) {
      if (!Object.values(ignored).some((c) => !c)) {
        return false;
      }
    } else {
      if (!Object.values(ignored).some((c) => c)) {
        return false;
      }
    }

    earTag = earTag !== null ? earTag.replaceAll(' ', '').toLowerCase() : '';
    const input = animalSearch.replaceAll(' ', '').toLowerCase();

    if (input === '') {
      return true;
    }
    if (earTag.startsWith(input)) {
      return true;
    }
    return cowNumber.startsWith(animalSearch);
  };

  useEffect(() => {
    let filteredIssues: any[] = [];
    issueData?.subsetActive.map((pair: any) => {
      if (filterIssues(pair[1]['EarTag'], pair[1]['CowNumber'], getObjectKeys(pair[0]['AnimalAlarmId']), pair[0]['DataQualityViolation'], pair[0]['Ignored'], true)) {
        filteredIssues.push(pair);
      }
    });
    if (filteredIssues.length === 0) {
      if (animalSearch !== '') {
        setDisplayAnimalSearchWarning(true);
        setDisplayNoActiveAlarmsAlert(false);
      } else {
        setDisplayAnimalSearchWarning(false);
        setDisplayNoActiveAlarmsAlert(true);
      }
    } else {
      setDisplayAnimalSearchWarning(false);
      setDisplayNoActiveAlarmsAlert(false);
    }
    setFilteredIssues(filteredIssues);
  }, [animalSearch, diseaseOptions]);

  const fetchIssues = async () => {
    const result = await Api.issues.fetchIssues(getAccessToken(), DDWApplication.DairyInsights, herdId);
    if (result.success) {
      if (result.message === '') {
        const data = result.data;
        setAnimalSearch('');
        setIssueData(data);
        setFilteredIssues(data.subsetActive);
        setActiveModels(data.activeModels.map(model => {
          return { label: DIS_NAMES[model], value: String(model) };
        }));
        setDiseaseOptions(data.activeModels.map(model => {
          return String(model);
        }));
        if (result.data.herdLevelDQ !== null) {
          setDataErrorOrWarningSeverity('danger');
          setDataErrorMessage(translate('This herd failed with data quality violation: {{dq}}', {
            dq: result.data.herdLevelDQ === 34 ? translate('Invalid average dry off days (at herd level)') : translate('Invalid average calving interval (at herd level)')
          }))
        } else {
          setDataErrorOrWarningSeverity('');
          setDataErrorMessage('');
        }
      } else {
        setDataErrorOrWarningSeverity('warning');
      }
    } else {
      if (result.message !== '') {
        navigate('/home');
        addMessageToStack({
          messageType: SnackbarMessageType.error,
          message: translate('There was a problem fetching issues.'),
          autoHide: false
        });
      } else {
        setDataErrorOrWarningSeverity('danger');
      }
    }
  };

  useAsyncEffect(async () => {
    setPages(guardianPages(translate, permissions.prevention, permissions.diagnosis, permissions.visio, user !== undefined ? user['profile']['UserEmail'] : ''));
    if (herdId != 0) {
      setLoading(true);
      await fetchIssues();
      setLoading(false);
    }
  }, [herdId]);

  useEffect(() => {
    return () => {
      setActionTakenAlarmsCollapseOpen(false);
    };
  }, [herdId]);

  return (
    <>
      <PageContainer hasFooter={false} loading={loading}>
        {dataErrorOrWarningSeverity === '' ?
          <>
            <Row>
              <Col width={'75%'}>
                <SubHeader>
                  <Row style={{ alignItems: 'center' }}>
                    <Col width={'3%'}>
                      <IconContainer>
                        <ConeStripedIcon />
                      </IconContainer>
                    </Col>

                    <Col width={'90%'}>
                      {translate('Data Quality Violations')}
                    </Col>
                  </Row>
                </SubHeader>
              </Col>

              <Col className={'mr-3'} width={'25%'}>
                <DismissibleCard title={``}
                  subheader={translate('Information')}
                  content={translate('Would you like to find out what each data quality violation means?')}
                  buttonName={translate('Learn more')}
                  onClick={() => {
                    navigate('/guardian-prevention/help');
                  }} />
              </Col>
            </Row>

            {viewSize > ViewSize.S ?
              <>
                <Row className={'mt-3'}>
                  <Col width={'40%'}>
                    <PreventionIssuesBarChart activeModels={issueData?.activeModels} barData={issueData?.barData} />
                  </Col>

                  <Col width={'60%'}>
                    <HeatMap heatmapData={issueData?.heatmapData}
                      nDryAnimals={issueData?.nDryAnimals} heatmapValues={issueData?.heatmapValues} />
                  </Col>
                </Row>
              </> :
              <>
                <Row className='mt-3'>
                  <Col width='100%'>
                    <PreventionIssuesBarChart activeModels={issueData?.activeModels} barData={issueData?.barData} />
                  </Col>
                </Row>

                <Row>
                  <Col width='100%'>
                    <HeatMap heatmapData={issueData?.heatmapData}
                      nDryAnimals={issueData?.nDryAnimals} heatmapValues={issueData?.heatmapValues} />
                  </Col>
                </Row>
              </>
            }

            <Row className={'mt-3'} style={{ display: issueData?.duplicateTags.length ? '' : 'none' }}>
              <Alert severity={'warning'}>
                <span
                  style={{ fontWeight: 'bold' }}>{translate('Warning: ' + issueData?.duplicateTags.length + ' Animals with duplicate Ear Tags:')}
                </span>
                <br />
                {issueData?.duplicateTags.join(', ')}
              </Alert>
            </Row>

            <Row className={'mt-3'} style={{ alignItems: 'center' }}>
              <Col width={'20%'}>
                <Paragraph>
                  <label>{translate('Show animals at risk for:')}</label>
                </Paragraph>
              </Col>

              <Col width={viewSize > ViewSize.S ? '50%' : '70%'}>
                {/*<MultiSelect name={translate('Disease')}*/}
                {/*             field={`disease`}*/}
                {/*             options={activeModels}*/}
                {/*             selected={diseaseOptions}*/}
                {/*             onChange={setDiseaseOptions} />*/}
                <Select mode={'multiple'} style={{ width: '100%' }} optionLabelProp='label' allowClear={true}
                  onChange={setDiseaseOptions} value={diseaseOptions}>
                  {activeModels.map((option) => (
                    <Option value={option.value} label={translate(option.label)}>
                      {translate(option.label)}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>

            <Row>
              <Col width={'20%'}>
                <Paragraph>
                  {translate('Search by Ear Tag or Cow Number:')}
                </Paragraph>
              </Col>

              <Col width={viewSize > ViewSize.S ? '30%' : '50%'}>
                <Input name={''} field={`ear-tag-or-cow-number`} value={animalSearch} onChange={setAnimalSearch} />
              </Col>
            </Row>

            <Row style={{ display: displayAnimalSearchWarning ? '' : 'none' }}>
              <Alert className={'mt-3'} style={{ display: '' }} severity={'warning'}>
                {translate('No animals match the above Ear Tag or Cow Number')}
              </Alert>
            </Row>

            <Row style={{ display: displayNoActiveAlarmsAlert ? '' : 'none' }}>
              <Alert className={'mt-3'} style={{ display: '' }} severity={'primary'}>
                {translate('There are no currently active alarms needing attention.')}
              </Alert>
            </Row>

            {filteredIssues.map((pair: any) => {
              return (
                <Row key={String(pair[1]['name'])} style={{ margin: '10px' }}>
                  <IssueCard issueData={pair[0]} headerData={pair[1]}
                    multiDiseaseDQDict={issueData?.multiDiseaseDQDict} fetchIssues={fetchIssues} />
                </Row>
              );
            })}

            <Row className={'mt-3 mb-2'}>
              <Button name={`display-alarms-action-taken`}
                type={ButtonType.Cancel}
                isOutlined={false}
                onClick={() => {
                  setActionTakenAlarmsCollapseOpen(!actionTakenAlarmsCollapseOpen);
                }}
              >
                {!actionTakenAlarmsCollapseOpen ? translate('Display active alarms for which action has already been taken') : translate('Hide active alarms for which action has already been taken')}
              </Button>
            </Row>

            {actionTakenAlarmsCollapseOpen &&
              <div>
                {issueData?.subsetActionTaken.map((pair: any) => {
                  if (filterIssues(pair[1]['EarTag'], pair[1]['CowNumber'], getObjectKeys(pair[0]['AnimalAlarmId']), pair[0]['DataQualityViolation'], pair[0]['Ignored'], false)) {
                    return (
                      <Row key={String(pair[1]['name'])} style={{ margin: '10px' }}>
                        <IssueCard issueData={pair[0]} headerData={pair[1]}
                          multiDiseaseDQDict={issueData?.multiDiseaseDQDict} fetchIssues={fetchIssues} />
                      </Row>
                    );
                  }
                })}
              </div>
            }

            <br />
            <DividerStyle />
            <br />

            <Row>
              <Col>
                <Button name={'download-active-issues'}
                  type={ButtonType.Cancel}
                  isOutlined={false}
                  onClick={downloadActiveIssues}
                  icon={IconLib.downloadLight}
                >
                  {translate('Download active issues')}
                </Button>
              </Col>
            </Row>
          </> :
          <Alert maxWidth={viewSize > ViewSize.S ? '50%' : '75%'} severity={dataErrorOrWarningSeverity}>
            {dataErrorOrWarningSeverity === 'danger' ? <div>{herdId} - {dataErrorMessage}</div> :
              <div>{herdId} - {translate('No predictions yet. If this persists for several days, it might indicate incomplete/insufficient data for this module.')}</div>
            }
          </Alert>
        }
      </PageContainer>
    </>
  );
};