import { AuthenticationContext, SnackbarMessageType, SnackbarMessagesContext, ViewContext } from "@ddw/react-framework";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HerdProfileContext } from "../../../context";
import { PagesContext } from "../../../context/PagesContext";
import { PageContainer } from "../../../component/PageContainer";
import { Alert, Col, IconContainer, Paragraph, Row, SubHeader } from "../../components";
import { BellFillIcon } from "../../../component/Icons/BellFillIcon";
import { useNavigate } from "react-router-dom";
import { Pagination, Select } from "antd";
import { Button, ButtonType, DDWApplication, Input, ViewSize } from '@ddw/react-components';
import { DividerStyle, IconLib } from "@ddw/react-components";
import { Api } from "../../../services/api";
import { PrescriptionCard } from "../../../component/PrescriptionCard/PrescriptionCard";
import { Prescription, VisioPrescription } from "../../../typings/visio/types";
import { visioPages } from "../../pages";

export const VisioPrescriptionsPage: React.FC = () => {
    const { t: translate } = useTranslation();
    const { herdId, permissions } = useContext(HerdProfileContext);
    const { user, getAccessToken } = useContext(AuthenticationContext);
    const { addMessageToStack } = useContext(SnackbarMessagesContext);
    const { setPages } = useContext(PagesContext);
    const { viewSize } = useContext(ViewContext);

    const [prescriptionData, setPrescriptionData] = useState<VisioPrescription>();
    const [filteredPrescriptions, setFilteredPrescriptions] = useState<Prescription[]>([]);

    const prescriptionTypeOptions: string[] = [
        "Move animal to status Barren", "Inseminate with replacement semen", "High mortality risk animal", "Inseminate with beef semen"
    ]
    const [prescriptionTypeOptionsSelected, setPrescriptionTypeOptionsSelected] = useState<string[]>(prescriptionTypeOptions);

    const [animalSearch, setAnimalSearch] = useState<string>('');
    const [displayAnimalSearchWarning, setDisplayAnimalSearchWarning] = useState(false);
    const [displayNoActivePrescriptionsAlert, setDisplayNoActivePrescriptionsAlert] = useState(false);
    const [actionTakenPrescriptionsCollapseOpen, setActionTakenPrescriptionsCollapseOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [resultMessage, setResultMessage] = useState<string>('');

    const { Option } = Select;

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [dataErrorOrWarningSeverity, setDataErrorOrWarningSeverity] = useState('');

    const defaultPageSize: number = 20;
    const [pageSize, setPageSize] = useState<number>(defaultPageSize);

    const handlePageChange = async (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPageSize(pageSize);
        setLoading(true);
        await fetchPrescriptions(page, pageSize);
        setLoading(false);
    };

    const searchAndOrFilter = async () => {
        setLoading(true);
        setCurrentPage(1);
        await fetchPrescriptions(1, pageSize);
        setLoading(false);
    };

    const downloadActiveAlarms = async () => {
        document.body.style.cursor = 'progress';
        const result = await Api.downloads.downloadVisioPrescriptions(getAccessToken(), DDWApplication.DairyInsights, herdId);
        const blob = await result.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'visio_prescriptions_' + String(sessionStorage.getItem('sessionId')) + '_' + herdId + '.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        document.body.style.cursor = 'default';
    };

    // parameter `newHerdLoaded` is used to bypass side effects when changing herds
    const fetchPrescriptions = async (pageNumber: number, pageSize: number, newHerdLoaded?: boolean) => {
        const result = await Api.visio.fetchPrescriptions(
            getAccessToken(),
            DDWApplication.DairyInsights,
            herdId,
            !newHerdLoaded ? animalSearch : '',
            !newHerdLoaded ? prescriptionTypeOptionsSelected.join(';') : prescriptionTypeOptions.join(';'),
            pageNumber,
            pageSize
        );

        if (result.success) {
            setPrescriptionData(result.data);
            setFilteredPrescriptions(result.data.prescriptions);
            if (result.data.prescriptions.length === 0) {
                if (animalSearch !== '') {
                    setDisplayAnimalSearchWarning(true);
                } else {
                    setDisplayAnimalSearchWarning(false);
                    if (prescriptionTypeOptions.every((val: string) => prescriptionTypeOptionsSelected.includes(val))) {
                        setDisplayNoActivePrescriptionsAlert(true);
                    } else {
                        setDisplayNoActivePrescriptionsAlert(false);
                    }
                }
            } else {
                setDisplayAnimalSearchWarning(false);
                setDisplayNoActivePrescriptionsAlert(false);
            }
            setTotalPages(result.data.numberOfPages);
            setResultMessage(result.message);
            setDataErrorOrWarningSeverity('');
        } else {
            if (result.message !== '') {
                navigate('/home');
                addMessageToStack({
                    messageType: SnackbarMessageType.error,
                    message: translate('There was a problem fetching prescriptions.'),
                    autoHide: false
                });
            } else {
                setDataErrorOrWarningSeverity('danger');
            }
        }
    };

    const fetchPrescriptionsWrapperForPrescriptionCard = async () => {
        await fetchPrescriptions(currentPage, pageSize, false);
    };

    useEffect(() => {
        setPages(visioPages(translate, permissions.visio, user !== undefined ? user['profile']['UserEmail'] : ''));

        const fetchData = async () => {
            setLoading(true);
            await fetchPrescriptions(1, defaultPageSize, true);
            setLoading(false);
        };

        if (herdId != 0) {
            fetchData();
        }

        return () => {
            setAnimalSearch('');
            setPrescriptionTypeOptionsSelected(prescriptionTypeOptions);
            setActionTakenPrescriptionsCollapseOpen(false);
            setResultMessage('');
        };

    }, [herdId]);

    return (
        <PageContainer hasFooter={false} loading={loading}>
            {dataErrorOrWarningSeverity === '' ?
                <>
                    <Row>
                        <Col width="75%">
                            <SubHeader>
                                <Row style={{ alignItems: 'center' }}>
                                    <Col width={'3%'}>
                                        <IconContainer>
                                            <BellFillIcon />
                                        </IconContainer>
                                    </Col>

                                    <Col width={'90%'}>
                                        {translate('Active prescriptions list')}
                                    </Col>
                                </Row>
                            </SubHeader>
                            <Paragraph>
                                {translate('Report of currently dry animals predicted at risk for developing transition diseases in the following lactation.')}
                            </Paragraph>
                        </Col>

                        {/* <Col className={'mr-3'} width={'25%'} style={{ display: prescriptionData?.violations ? '' : 'none' }}>
                    <DismissibleCard title={``}
                        subheader={translate('Attention!')}
                        content={translate('There are {{violations}} dry animals for which Visio cannot provide a prescription due to data quality violations.', {
                            violations: prescriptionData?.violations
                        })}
                        buttonName={translate('Learn more')}
                        onClick={() => {
                            navigate('/home');
                        }} />
                </Col> */}
                    </Row>

                    <Row className={'mt-3'} style={{ display: prescriptionData?.duplicateTags.length ? '' : 'none' }}>
                        <Alert severity={'warning'}>
                            <span
                                style={{ fontWeight: 'bold' }}>{translate('Warning: ' + prescriptionData?.duplicateTags.length + ' Animals with duplicate Ear Tags:')}
                            </span>
                            <br />
                            {prescriptionData?.duplicateTags.join(', ')}
                        </Alert>
                    </Row>

                    <Row className={'mt-3'} style={{ alignItems: 'center' }}>
                        <Col width={'20%'}>
                            <Paragraph>
                                <label>{translate('Show animals with prescription type:')}</label>
                            </Paragraph>
                        </Col>

                        <Col width={viewSize > ViewSize.S ? '50%' : '70%'}>
                            <Select mode={'multiple'} style={{ width: '100%' }} optionLabelProp='label' allowClear={true}
                                onChange={setPrescriptionTypeOptionsSelected} value={prescriptionTypeOptionsSelected}>
                                {prescriptionTypeOptions.map((prescriptionType: string) => (
                                    <Option value={prescriptionType} label={translate(prescriptionType)}>
                                        {translate(prescriptionType)}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>

                    <Row style={{ alignItems: 'center' }}>
                        <Col width={'20%'}>
                            <Paragraph>
                                {translate('Search by Ear Tag or Cow Number:')}
                            </Paragraph>
                        </Col>

                        <Col width={viewSize > ViewSize.S ? '30%' : '50%'}>
                            <Input name={''} field={`ear-tag-or-cow-number`} value={animalSearch} onChange={setAnimalSearch} />
                        </Col>

                        <Col width="20%" className={"ml-3"}>
                            <Button name={'search-filter'}
                                type={ButtonType.Filter}
                                isOutlined={false}
                                onClick={searchAndOrFilter}
                                icon={IconLib.search}
                            >
                                {translate('Search/Filter')}
                            </Button>
                        </Col>
                    </Row>

                    <Row style={{ display: displayAnimalSearchWarning ? '' : 'none' }}>
                        <Alert className={'mt-3'} style={{ display: '' }} severity={'warning'}>
                            {translate('No animals match the above Ear Tag or Cow Number')}
                        </Alert>
                    </Row>

                    <Row style={{ display: displayNoActivePrescriptionsAlert ? '' : 'none' }}>
                        <Alert className={'mt-3'} style={{ display: '' }} severity={resultMessage !== '' ? 'warning' : 'primary'}>
                            {resultMessage !== '' ? resultMessage : translate('There are no currently active prescriptions needing attention.')}
                        </Alert>
                    </Row>

                    {filteredPrescriptions.filter((prescription: Prescription) => prescription.Ignored === 0)
                        .map((prescription: Prescription) => {
                            return (
                                <Row key={prescription.AnimalId} style={{ margin: '10px' }}>
                                    <PrescriptionCard prescriptionData={prescription} fetchPrescriptions={fetchPrescriptionsWrapperForPrescriptionCard} />
                                </Row>
                            );
                        })
                    }

                    <Row className={'mt-3 mb-2'}>
                        <Pagination style={{ marginLeft: 'auto', marginRight: 'auto' }}
                            current={currentPage}
                            pageSize={pageSize}
                            total={totalPages * pageSize}
                            hideOnSinglePage={true}
                            onChange={handlePageChange} />
                    </Row>

                    <Row className={'mt-3 mb-2'}>
                        <Button name={`display-alarms-action-taken`}
                            type={ButtonType.Cancel}
                            isOutlined={false}
                            onClick={() => {
                                setActionTakenPrescriptionsCollapseOpen(!actionTakenPrescriptionsCollapseOpen);
                            }}
                        >
                            {!actionTakenPrescriptionsCollapseOpen ? translate('Display active prescriptions for which action has already been taken') : translate('Hide active prescriptions for which action has already been taken')}
                        </Button>
                    </Row>

                    {actionTakenPrescriptionsCollapseOpen &&
                        <div>
                            {prescriptionData?.prescriptionsIgnored.map((prescription: Prescription) => {
                                return (
                                    <Row key={prescription.AnimalId} style={{ margin: '10px' }}>
                                        <PrescriptionCard prescriptionData={prescription} fetchPrescriptions={fetchPrescriptionsWrapperForPrescriptionCard} />
                                    </Row>
                                );
                            })
                            }
                        </div>
                    }

                    <br />
                    <DividerStyle />
                    <br />

                    <Row>
                        <Col>
                            <Button name={'download-active-prescriptions'}
                                type={ButtonType.Cancel}
                                isOutlined={false}
                                onClick={downloadActiveAlarms}
                                icon={IconLib.downloadLight}
                            >
                                {translate('Download active prescriptions')}
                            </Button>
                        </Col>
                    </Row>
                </> :
                <Alert maxWidth={viewSize > ViewSize.S ? '25%' : '75%'} severity={dataErrorOrWarningSeverity}>
                    {herdId} - {translate('Data not available')}
                </Alert>
            }
        </PageContainer>
    );
};