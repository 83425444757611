import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Table, { ColumnsType } from "antd/es/table";

interface TableDataType {
    radarCategory: string;
    scoreName: string;
    ruleId: string;
    validScore: number;
};

export const DataQualityFromDashboardValidTable: React.FC<any> = ({ validTableData, loading }) => {
    const { t: translate } = useTranslation();

    const columns: ColumnsType<TableDataType> = [
        {
            title: translate("Radar Category"),
            dataIndex: 'radarCategory',
            key: 'radarCategory'
        },
        {
            title: translate("Score Name"),
            dataIndex: 'scoreName',
            key: 'scoreName'
        },
        {
            title: translate("Rule Id"),
            dataIndex: 'ruleId',
            key: 'ruleId'
        },
        {
            title: translate("Valid Score"),
            dataIndex: 'validScore',
            key: 'validScore'
        }
    ];

    const [tableData, setTableData] = useState<TableDataType[]>([]);

    useEffect(() => {
        if (validTableData !== undefined) {
            setTableData(
                validTableData.tables[0].rows.map((value: (string | number)[]) => {
                    return {
                        radarCategory: value[2],
                        scoreName: value[3],
                        ruleId: value[4],
                        validScore: value[5]

                    }
                })
            );
        }
    }, [validTableData]);

    return (
        <Table columns={columns} dataSource={tableData} scroll={{y: 240}} pagination={false} loading={loading} />
    );
}