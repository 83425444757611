import React, { createContext } from 'react';

export interface VisioSettingsUsersContextType {
    users: { [key: string]: any };
    setUsers: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
}

export const VisioSettingsUsersContext = createContext<VisioSettingsUsersContextType>({
    users: {
        'primary': null, 'secondary': []
    },
    setUsers: () => {
    }
});
