import { SelectOption } from "@ddw/react-components";

export const DIS_NAMES: { [index: string]: string } = {
  '1': 'Ketosis',
  '3': 'Milk Fever',
  '4': 'Displaced Abomasum',
  '5': 'Retained Placenta',
  '6': 'Lameness',
  '7': 'Mastitis'
};

export const HERD_SETTINGS_DIS_NAMES: { [index: string]: string } = {
  '1': 'Ketosis (BHB)',
  '3': 'Milk Fever',
  '4': 'Displaced Abomasum',
  '5': 'Retained Placenta',
  '6': 'Lameness',
  '7': 'Mastitis'
};

export const DIS_PILL_NAMES: { [index: string]: string } = {
  '1': 'Ketosis',
  '3': 'Milk Fever',
  '4': 'Displ. Abomasum',
  '5': 'Ret. Placenta',
  '6': 'Lameness',
  '7': 'Mastitis'
};

export const NOTIFICATION_DEFAULTS = {
  'NotificationDaysToCalving': 30,
  'NotificationDaysToDryOff': 7,
  'NotificationDaysMode': 0,
  'ModelAlarmFrequency': 1,  // Weekly,
  'AlarmNotificationMode': 0,  // None
  'NotificationLanguage': 2057,  // en-GB
  'NotificationEmail': null,
  'NotificationMobile': null,
  'NotificationWeekDay': 1,  // Mon
  'NotificationHourUTC': 8
};

export const VISIO_NOTIFICATION_DEFAULTS = {
  'InventoryConfiguration': 'standard',
  'PrescriptionFrequency': 0, // Daily
  'PrescriptionNotificationMode': 0, // None
  'NotificationEmail': null,
  'NotificationMobile': null,
  'NotificationWeekDay': 1  // Mon
};

export const MODEL_EXPRESS_LEVELS: { [index: number]: number[] } = {
  1: [0, 60, 70, 80, 100],
  3: [0, 50, 70, 90, 100],
  4: [0, 60, 70, 80, 100],
  5: [0, 60, 70, 80, 100],
  6: [0, 60, 70, 80, 100],
  7: [0, 60, 70, 80, 100]
};

export const DDW_blue = '#000073';
export const DDW_cyan = '#20C3F7';
export const DDW_green = '#60D3A3';
export const DDW_grey = '#bcc2c2';
export const DDW_orange = '#FFA282';
export const DDW_light_blue = '#D9D9EA';
export const DDW_light_green = '#B0E9D1';
export const DDW_light_grey = '#EBEBEB';
export const DDW_purple = '#7B08FF';
export const DDW_yellow = '#E8ED54';
export const DDW_dark_grey = '#918C8C';
export const DDW_pink = '#FF7581';
export const DDW_light_purple = '#c79afa';
export const DDW_dark_purple = '#380177';
export const DDW_dark_green = '#336450';
export const DDW_darkest_grey = '#585858';
export const DDW_light_orange = '#fabfab';
export const DDW_dark_orange = '#d13400';
export const DDW_light_pink = '#ffbac0';
export const DDW_dark_pink = '#ba0010';
export const DDW_light_yellow = '#f3f5a9';
export const DDW_dark_yellow = '#D13400';

export const DDW_green_set = [DDW_green, DDW_light_green, DDW_dark_green];
export const DDW_purple_set = [DDW_purple, DDW_light_purple, DDW_dark_purple];
export const DDW_grey_set = [DDW_dark_grey, DDW_light_grey, DDW_darkest_grey];
export const DDW_orange_set = [DDW_orange, DDW_light_orange, DDW_dark_orange];
export const DDW_yellow_set = [DDW_yellow, DDW_light_yellow, DDW_dark_yellow];
export const DDW_pink_set = [DDW_pink, DDW_light_pink, DDW_dark_pink];

export function getRandomHexColor(): string {
  // Generate random R, G, and B values
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);

  // Convert RGB to hexadecimal
  const hexR = r.toString(16).padStart(2, '0');
  const hexG = g.toString(16).padStart(2, '0');
  const hexB = b.toString(16).padStart(2, '0');

  // Concatenate and return the hexadecimal color string
  return `#${hexR}${hexG}${hexB}`;
};

export const ISSUES_BAR_WIDTH = 0.55;
export const BAR_WIDTH = 0.55;

export const DIS_COL: { [index: string]: string } = {
  '1': DDW_green,
  //'1': DDW_yellow,
  //'1': DDW_pink,
  '3': DDW_purple,
  '4': DDW_dark_grey,
  '5': DDW_orange,
  '6': DDW_yellow,
  '7': DDW_pink
};

export const DIAGNOSIS_DIS_COL: { [index: number]: string } = {
  0: DDW_yellow,
  1: '#2355c2',
  2: '#b88948',
  3: DDW_green,
  4: '#a83232',
  5: '#bab08a',
  6: '#000000',
  7: '#89709e'
};

export const DIS_LABEL_TO_CODE: { [index: string]: number } = {
  'Other': 0,
  'General (Risk of Displaced abomasum)': 1,
  'General (Risk of Gastrointestinal disease)': 2,
  'Ketosis': 3,
  'Mastitis': 4,
  'General (Risk of Metritis)': 5,
  'General (Risk of Infectious disease)': 6,
  'General (Risk of other)': 7
};

export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiZGR3aW5zaWdodHMiLCJhIjoiY2thcnl3ZGF5Mm1mOTJ0cDZiMDV4ZTZ4bCJ9.2NovpTgTRvAupL6r6nvFbw';

export const DQV_COL: { [index: number]: string } = {
  1: '#000000',
  2: DDW_grey,
  3: DDW_yellow,
  4: DDW_blue,
  5: DDW_orange
  // 6: DDW_green
};

export const PLOTLY_TEMPLATE = {
  layout: {
    font: { family: 'Segoe UI' },
    title_font: { size: 20 },
  },
};

export const VISIO_PRESCRIPTION_COLOR: { [index: string]: string } = {
  "Move animal to status Barren": DDW_green,
  "Inseminate with replacement semen": DDW_orange,
  "High mortality risk animal": DDW_light_blue,
  "Inseminate with beef semen": DDW_pink,
  null: DDW_grey,
  '': DDW_grey
};

export const INVENTORY_COLOURS: string[] = [DDW_orange, DDW_green, DDW_cyan, DDW_blue, DDW_yellow];

export const INVENTORY_SELECTION: string[] = [
  'Young',
  'Lactation 1',
  'Lactation 2',
  'Lactation 3+',
  'Dry'
];

export const NUMBERED_COLOURS = [
  DDW_blue,
  DDW_cyan,
  DDW_green,
  DDW_yellow,
  DDW_orange,
  DDW_grey
];

export const GUARDIAN_ECONOMIC_CONFIGURATION_VIDEO_URL: string = 'https://ddwtestpred01.blob.core.windows.net/predicta/Web/Resources/GUARDIAN_EconomicAutoconfigurator.mp4?sv=2021-10-04&st=2023-09-05T08%3A42%3A34Z&se=2099-01-01T09%3A42%3A00Z&sr=b&sp=r&sig=sw2wMNrfwe62ix%2B7CyBPdjmBPQb8wP%2Bc2bJLprBHdDo%3D'
export const GUARDIAN_LAMENESS_AND_MASTITIS_VIDEO_URL: string = 'https://ddwtestpred01.blob.core.windows.net/predicta/Web/Resources/GUARDIAN_MastitisLameness.mp4?sv=2021-10-04&st=2023-12-07T09%3A40%3A23Z&se=2100-01-08T09%3A40%3A00Z&sr=b&sp=r&sig=a0CtR04yu1oGjZA2Y2I1AIpHqlcFs3yCOi7kDhRrlVk%3D'

export const PREDICTA_CONVERSION_RATES_FILE_URL: string = 'https://ddwtestpred01.blob.core.windows.net/predicta/Web/Resources/conversion_rates.txt?sv=2021-10-04&st=2024-02-19T12%3A54%3A24Z&se=2039-10-26T05%3A00%3A00Z&sr=b&sp=r&sig=i%2F4K2rg4r7sejAZNkfkPwfUrRPQeeM48yGRYuRLSRYE%3D';

export const GUARDIAN_DISEASE_DEFAULT_COST_VALUES: { [index: string]: { [index: number]: number } } = {
  "disease": {
    1: 150,
    3: 310,
    4: 590,
    5: 290,
    6: 210,
    7: 150
  },
  "prevention": {
    1: 30,
    3: 45,
    4: 30,
    5: 30,
    6: 23,
    7: 30
  }
};

export const ANIMAL_MILK_PRODUCTION_SEL: string[] = [
  'Lactation 1',
  'Lactation 2',
  'Lactation 3+'
];

export const ANIMAL_MILK_PRODUCTION_COLOURS: string[] = [
  DDW_green,
  DDW_cyan,
  DDW_blue,
];

export const BENCHMARKS: string[] = [
  'Benchmark HERD',
  'Benchmark P1',
  'Benchmark P2',
  'Benchmark P3+'
];

export const KPI_CODES: { [index: string]: string } = {
  'InseminationRate': "Kpi002",
  'PregnancyRate': "Kpi003",
  'NrOfDryCows': "Kpi012",
  'AverageDimMilkedCows': "Kpi014",
  'AverageLactation': "Kpi015",
  'DaystoFirstInsemination': "Kpi021",
  'HistoricCalvingInterval': "Kpi022",
  'AvgYield': "Kpi031",
  'AvgDaysToConception': "Kpi032",
  'AvgAgeAtFirstInsemination': "Kpi035",
  'AverageMilkPerDay': "Kpi036",
  'AveragePeakYield': "Kpi043",
  'AverageNumberOfDaysToPeak': "Kpi044",
  'AverageMilkPerCow': "Kpi098"
};

export const SCORE_RULE_ID: { [index: string]: string } = {
  'DQ100': 'percentage_animal_on_farm',
  'DQ101': 'percentage_milking_cows',
  'DQ102': 'percentage_heifers',
  'DQ103': 'percentage_dry',
  'DQ104': 'data_completeness',
  'DQ105': 'milking_completeness',
  'DQ106': 'concentrate_completeness',
  'DQ107': 'insemination_completeness',
  'DQ108': 'herd_not_nan',
  'DQ109': 'currently_dry',
  'DQ110': 'lactation_complete',
  'DQ111': 'dryoff_dates_delta',
  'DQ112': 'percentage_of_lactations',
  'DQ113': 'no_ongoing_previous_lactation',
  'DQ114': 'valid_separation_of_lactation',
  'DQ115': 'valid_birthdate',
  'DQ116': 'animal_non_nan',
  'DQ117': 'dry_off_data',
  'DQ118': 'dry_between_calvings',
  'DQ119': 'time_of_dryoff_after_calving',
  'DQ120': 'no_milk_between_dry_and_calving',
  'DQ121': 'Days_dry',
  'DQ122': 'dryoff_not_nan',
  'DQ123': 'feeding_data',
  'DQ124': 'consecutive_feeding_difference',
  'DQ125': 'contiguous_feeding',
  'DQ126': 'length_feeding_record',
  'DQ127': 'Non_zero_feeding',
  'DQ128': 'completeness_feeding',
  'DQ129': 'feeding_outliers',
  'DQ130': 'feeding_not_nan',
  'DQ131': 'insemination_data',
  'DQ132': 'next_calving_with_insemination',
  'DQ133': 'next_calving_with_pregcheck',
  'DQ134': 'Insemination_with_pregcheck',
  'DQ135': 'Insemination_pregcheck_presence',
  'DQ136': 'Insemination_positive_pregcheck_presence',
  'DQ137': 'Insemination_to_nextCalving_time',
  'DQ138': 'Insemination_pregcheck_to_nextCalving_time_delta',
  'DQ139': 'Insemination_pregcheck_time_delta',
  'DQ140': 'Premature_pregcheck',
  'DQ141': 'insemination_not_nan',
  'DQ142': 'milking_data',
  'DQ143': 'valid_milk_range_mm',
  'DQ144': 'valid_milk_range_mro',
  'DQ145': 'mm_mro',
  'DQ146': 'continuous_milking_mm',
  'DQ147': 'continuous_milking_mro',
  'DQ148': 'missing_milking_milk',
  'DQ149': 'missing_milking_mm',
  'DQ150': 'length_milking_record_mm',
  'DQ151': 'dim_outliers',
  'DQ152': 'non_zero_milk_recording_mm',
  'DQ153': 'non_zero_milk_recording_mro',
  'DQ154': 'fit_lactation_curve_r2',
  'DQ155': 'lactation_curve_threshold',
  'DQ156': 'milk_outliers',
  'DQ157': 'milking_not_nan',
  'DQ158': 'non_zero_feeding_events',
  'DQ159': 'overall_disease_incidence',
  'DQ160': 'percentage_young',
  'DQ161': 'macro_categories_disease_coverage',
  'DQ162': 'recording_leave_events',
  'DQ163': 'death_cull',
  'DQ164': 'dim_gaps'
};

export const CURRENCIES: SelectOption[] = [
  { label: 'EUR (€)', value: 'EUR' },
  { label: 'USD ($)', value: 'USD' },
  { label: 'GBP (£)', value: 'GBP' }
];

export const BASE_CURRENCY = 'EUR';

export const CONVERSION_RATES_IN_CASE_OF_FETCH_ERROR = {
  "EUR": {
    "code": "EUR",
    "value": 1.0
  },
  "USD": {
    "code": "USD",
    "value": 1.0
  },
  "GBP": {
    "code": "GBP",
    "value": 1.0
  }
};

export const TIME_ZONES = [
  'Africa/Abidjan',
  'Africa/Accra',
  'Africa/Addis_Ababa',
  'Africa/Algiers',
  'Africa/Asmara',
  'Africa/Asmera',
  'Africa/Bamako',
  'Africa/Bangui',
  'Africa/Banjul',
  'Africa/Bissau',
  'Africa/Blantyre',
  'Africa/Brazzaville',
  'Africa/Bujumbura',
  'Africa/Cairo',
  'Africa/Casablanca',
  'Africa/Ceuta',
  'Africa/Conakry',
  'Africa/Dakar',
  'Africa/Dar_es_Salaam',
  'Africa/Djibouti',
  'Africa/Douala',
  'Africa/El_Aaiun',
  'Africa/Freetown',
  'Africa/Gaborone',
  'Africa/Harare',
  'Africa/Johannesburg',
  'Africa/Juba',
  'Africa/Kampala',
  'Africa/Khartoum',
  'Africa/Kigali',
  'Africa/Kinshasa',
  'Africa/Lagos',
  'Africa/Libreville',
  'Africa/Lome',
  'Africa/Luanda',
  'Africa/Lubumbashi',
  'Africa/Lusaka',
  'Africa/Malabo',
  'Africa/Maputo',
  'Africa/Maseru',
  'Africa/Mbabane',
  'Africa/Mogadishu',
  'Africa/Monrovia',
  'Africa/Nairobi',
  'Africa/Ndjamena',
  'Africa/Niamey',
  'Africa/Nouakchott',
  'Africa/Ouagadougou',
  'Africa/Porto-Novo',
  'Africa/Sao_Tome',
  'Africa/Timbuktu',
  'Africa/Tripoli',
  'Africa/Tunis',
  'Africa/Windhoek',
  'America/Adak',
  'America/Anchorage',
  'America/Anguilla',
  'America/Antigua',
  'America/Araguaina',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca',
  'America/Argentina/ComodRivadavia',
  'America/Argentina/Cordoba',
  'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Aruba',
  'America/Asuncion',
  'America/Atikokan',
  'America/Atka',
  'America/Bahia',
  'America/Bahia_Banderas',
  'America/Barbados',
  'America/Belem',
  'America/Belize',
  'America/Blanc-Sablon',
  'America/Boa_Vista',
  'America/Bogota',
  'America/Boise',
  'America/Buenos_Aires',
  'America/Cambridge_Bay',
  'America/Campo_Grande',
  'America/Cancun',
  'America/Caracas',
  'America/Catamarca',
  'America/Cayenne',
  'America/Cayman',
  'America/Chicago',
  'America/Chihuahua',
  'America/Coral_Harbour',
  'America/Cordoba',
  'America/Costa_Rica',
  'America/Creston',
  'America/Cuiaba',
  'America/Curacao',
  'America/Danmarkshavn',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Denver',
  'America/Detroit',
  'America/Dominica',
  'America/Edmonton',
  'America/Eirunepe',
  'America/El_Salvador',
  'America/Ensenada',
  'America/Fort_Nelson',
  'America/Fort_Wayne',
  'America/Fortaleza',
  'America/Glace_Bay',
  'America/Godthab',
  'America/Goose_Bay',
  'America/Grand_Turk',
  'America/Grenada',
  'America/Guadeloupe',
  'America/Guatemala',
  'America/Guayaquil',
  'America/Guyana',
  'America/Halifax',
  'America/Havana',
  'America/Hermosillo',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indianapolis',
  'America/Inuvik',
  'America/Iqaluit',
  'America/Jamaica',
  'America/Jujuy',
  'America/Juneau',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Knox_IN',
  'America/Kralendijk',
  'America/La_Paz',
  'America/Lima',
  'America/Los_Angeles',
  'America/Louisville',
  'America/Lower_Princes',
  'America/Maceio',
  'America/Managua',
  'America/Manaus',
  'America/Marigot',
  'America/Martinique',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Mendoza',
  'America/Menominee',
  'America/Merida',
  'America/Metlakatla',
  'America/Mexico_City',
  'America/Miquelon',
  'America/Moncton',
  'America/Monterrey',
  'America/Montevideo',
  'America/Montreal',
  'America/Montserrat',
  'America/Nassau',
  'America/New_York',
  'America/Nipigon',
  'America/Nome',
  'America/Noronha',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Nuuk',
  'America/Ojinaga',
  'America/Panama',
  'America/Pangnirtung',
  'America/Paramaribo',
  'America/Phoenix',
  'America/Port-au-Prince',
  'America/Port_of_Spain',
  'America/Porto_Acre',
  'America/Porto_Velho',
  'America/Puerto_Rico',
  'America/Punta_Arenas',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Recife',
  'America/Regina',
  'America/Resolute',
  'America/Rio_Branco',
  'America/Rosario',
  'America/Santa_Isabel',
  'America/Santarem',
  'America/Santiago',
  'America/Santo_Domingo',
  'America/Sao_Paulo',
  'America/Scoresbysund',
  'America/Shiprock',
  'America/Sitka',
  'America/St_Barthelemy',
  'America/St_Johns',
  'America/St_Kitts',
  'America/St_Lucia',
  'America/St_Thomas',
  'America/St_Vincent',
  'America/Swift_Current',
  'America/Tegucigalpa',
  'America/Thule',
  'America/Thunder_Bay',
  'America/Tijuana',
  'America/Toronto',
  'America/Tortola',
  'America/Vancouver',
  'America/Virgin',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yakutat',
  'America/Yellowknife',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Macquarie',
  'Antarctica/Mawson',
  'Antarctica/McMurdo',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/South_Pole',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'Arctic/Longyearbyen',
  'Asia/Aden',
  'Asia/Almaty',
  'Asia/Amman',
  'Asia/Anadyr',
  'Asia/Aqtau',
  'Asia/Aqtobe',
  'Asia/Ashgabat',
  'Asia/Ashkhabad',
  'Asia/Atyrau',
  'Asia/Baghdad',
  'Asia/Bahrain',
  'Asia/Baku',
  'Asia/Bangkok',
  'Asia/Barnaul',
  'Asia/Beirut',
  'Asia/Bishkek',
  'Asia/Brunei',
  'Asia/Calcutta',
  'Asia/Chita',
  'Asia/Choibalsan',
  'Asia/Chongqing',
  'Asia/Chungking',
  'Asia/Colombo',
  'Asia/Dacca',
  'Asia/Damascus',
  'Asia/Dhaka',
  'Asia/Dili',
  'Asia/Dubai',
  'Asia/Dushanbe',
  'Asia/Famagusta',
  'Asia/Gaza',
  'Asia/Harbin',
  'Asia/Hebron',
  'Asia/Ho_Chi_Minh',
  'Asia/Hong_Kong',
  'Asia/Hovd',
  'Asia/Irkutsk',
  'Asia/Istanbul',
  'Asia/Jakarta',
  'Asia/Jayapura',
  'Asia/Jerusalem',
  'Asia/Kabul',
  'Asia/Kamchatka',
  'Asia/Karachi',
  'Asia/Kashgar',
  'Asia/Kathmandu',
  'Asia/Katmandu',
  'Asia/Khandyga',
  'Asia/Kolkata',
  'Asia/Krasnoyarsk',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Asia/Kuwait',
  'Asia/Macao',
  'Asia/Macau',
  'Asia/Magadan',
  'Asia/Makassar',
  'Asia/Manila',
  'Asia/Muscat',
  'Asia/Nicosia',
  'Asia/Novokuznetsk',
  'Asia/Novosibirsk',
  'Asia/Omsk',
  'Asia/Oral',
  'Asia/Phnom_Penh',
  'Asia/Pontianak',
  'Asia/Pyongyang',
  'Asia/Qatar',
  'Asia/Qostanay',
  'Asia/Qyzylorda',
  'Asia/Rangoon',
  'Asia/Riyadh',
  'Asia/Saigon',
  'Asia/Sakhalin',
  'Asia/Samarkand',
  'Asia/Seoul',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Asia/Srednekolymsk',
  'Asia/Taipei',
  'Asia/Tashkent',
  'Asia/Tbilisi',
  'Asia/Tehran',
  'Asia/Tel_Aviv',
  'Asia/Thimbu',
  'Asia/Thimphu',
  'Asia/Tokyo',
  'Asia/Tomsk',
  'Asia/Ujung_Pandang',
  'Asia/Ulaanbaatar',
  'Asia/Ulan_Bator',
  'Asia/Urumqi',
  'Asia/Ust-Nera',
  'Asia/Vientiane',
  'Asia/Vladivostok',
  'Asia/Yakutsk',
  'Asia/Yangon',
  'Asia/Yekaterinburg',
  'Asia/Yerevan',
  'Atlantic/Azores',
  'Atlantic/Bermuda',
  'Atlantic/Canary',
  'Atlantic/Cape_Verde',
  'Atlantic/Faeroe',
  'Atlantic/Faroe',
  'Atlantic/Jan_Mayen',
  'Atlantic/Madeira',
  'Atlantic/Reykjavik',
  'Atlantic/South_Georgia',
  'Atlantic/St_Helena',
  'Atlantic/Stanley',
  'Australia/ACT',
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Broken_Hill',
  'Australia/Canberra',
  'Australia/Currie',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Hobart',
  'Australia/LHI',
  'Australia/Lindeman',
  'Australia/Lord_Howe',
  'Australia/Melbourne',
  'Australia/North',
  'Australia/NSW',
  'Australia/Perth',
  'Australia/Queensland',
  'Australia/South',
  'Australia/Sydney',
  'Australia/Tasmania',
  'Australia/Victoria',
  'Australia/West',
  'Australia/Yancowinna',
  'Brazil/Acre',
  'Brazil/DeNoronha',
  'Brazil/East',
  'Brazil/West',
  'Canada/Atlantic',
  'Canada/Central',
  'Canada/Eastern',
  'Canada/Mountain',
  'Canada/Newfoundland',
  'Canada/Pacific',
  'Canada/Saskatchewan',
  'Canada/Yukon',
  'CET',
  'Chile/Continental',
  'Chile/EasterIsland',
  'CST6CDT',
  'Cuba',
  'EET',
  'Egypt',
  'Eire',
  'EST',
  'EST5EDT',
  'Etc/GMT',
  'Etc/GMT+0',
  'Etc/GMT+1',
  'Etc/GMT+10',
  'Etc/GMT+11',
  'Etc/GMT+12',
  'Etc/GMT+2',
  'Etc/GMT+3',
  'Etc/GMT+4',
  'Etc/GMT+5',
  'Etc/GMT+6',
  'Etc/GMT+7',
  'Etc/GMT+8',
  'Etc/GMT+9',
  'Etc/GMT-0',
  'Etc/GMT-1',
  'Etc/GMT-10',
  'Etc/GMT-11',
  'Etc/GMT-12',
  'Etc/GMT-13',
  'Etc/GMT-14',
  'Etc/GMT-2',
  'Etc/GMT-3',
  'Etc/GMT-4',
  'Etc/GMT-5',
  'Etc/GMT-6',
  'Etc/GMT-7',
  'Etc/GMT-8',
  'Etc/GMT-9',
  'Etc/GMT0',
  'Etc/Greenwich',
  'Etc/UCT',
  'Etc/Universal',
  'Etc/UTC',
  'Etc/Zulu',
  'Europe/Amsterdam',
  'Europe/Andorra',
  'Europe/Astrakhan',
  'Europe/Athens',
  'Europe/Belfast',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/Bratislava',
  'Europe/Brussels',
  'Europe/Bucharest',
  'Europe/Budapest',
  'Europe/Busingen',
  'Europe/Chisinau',
  'Europe/Copenhagen',
  'Europe/Dublin',
  'Europe/Gibraltar',
  'Europe/Guernsey',
  'Europe/Helsinki',
  'Europe/Isle_of_Man',
  'Europe/Istanbul',
  'Europe/Jersey',
  'Europe/Kaliningrad',
  'Europe/Kiev',
  'Europe/Kirov',
  'Europe/Lisbon',
  'Europe/Ljubljana',
  'Europe/London',
  'Europe/Luxembourg',
  'Europe/Madrid',
  'Europe/Malta',
  'Europe/Mariehamn',
  'Europe/Minsk',
  'Europe/Monaco',
  'Europe/Moscow',
  'Europe/Nicosia',
  'Europe/Oslo',
  'Europe/Paris',
  'Europe/Podgorica',
  'Europe/Prague',
  'Europe/Riga',
  'Europe/Rome',
  'Europe/Samara',
  'Europe/San_Marino',
  'Europe/Sarajevo',
  'Europe/Saratov',
  'Europe/Simferopol',
  'Europe/Skopje',
  'Europe/Sofia',
  'Europe/Stockholm',
  'Europe/Tallinn',
  'Europe/Tirane',
  'Europe/Tiraspol',
  'Europe/Ulyanovsk',
  'Europe/Uzhgorod',
  'Europe/Vaduz',
  'Europe/Vatican',
  'Europe/Vienna',
  'Europe/Vilnius',
  'Europe/Volgograd',
  'Europe/Warsaw',
  'Europe/Zagreb',
  'Europe/Zaporozhye',
  'Europe/Zurich',
  'Factory',
  'GB',
  'GB-Eire',
  'GMT',
  'GMT+0',
  'GMT-0',
  'GMT0',
  'Greenwich',
  'Hongkong',
  'HST',
  'Iceland',
  'Indian/Antananarivo',
  'Indian/Chagos',
  'Indian/Christmas',
  'Indian/Cocos',
  'Indian/Comoro',
  'Indian/Kerguelen',
  'Indian/Mahe',
  'Indian/Maldives',
  'Indian/Mauritius',
  'Indian/Mayotte',
  'Indian/Reunion',
  'Iran',
  'Israel',
  'Jamaica',
  'Japan',
  'Kwajalein',
  'Libya',
  'MET',
  'Mexico/BajaNorte',
  'Mexico/BajaSur',
  'Mexico/General',
  'MST',
  'MST7MDT',
  'Navajo',
  'NZ',
  'NZ-CHAT',
  'Pacific/Apia',
  'Pacific/Auckland',
  'Pacific/Bougainville',
  'Pacific/Chatham',
  'Pacific/Chuuk',
  'Pacific/Easter',
  'Pacific/Efate',
  'Pacific/Enderbury',
  'Pacific/Fakaofo',
  'Pacific/Fiji',
  'Pacific/Funafuti',
  'Pacific/Galapagos',
  'Pacific/Gambier',
  'Pacific/Guadalcanal',
  'Pacific/Guam',
  'Pacific/Honolulu',
  'Pacific/Johnston',
  'Pacific/Kiritimati',
  'Pacific/Kosrae',
  'Pacific/Kwajalein',
  'Pacific/Majuro',
  'Pacific/Marquesas',
  'Pacific/Midway',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Norfolk',
  'Pacific/Noumea',
  'Pacific/Pago_Pago',
  'Pacific/Palau',
  'Pacific/Pitcairn',
  'Pacific/Pohnpei',
  'Pacific/Ponape',
  'Pacific/Port_Moresby',
  'Pacific/Rarotonga',
  'Pacific/Saipan',
  'Pacific/Samoa',
  'Pacific/Tahiti',
  'Pacific/Tarawa',
  'Pacific/Tongatapu',
  'Pacific/Truk',
  'Pacific/Wake',
  'Pacific/Wallis',
  'Pacific/Yap',
  'Poland',
  'Portugal',
  'PRC',
  'PST8PDT',
  'ROC',
  'ROK',
  'Singapore',
  'Turkey',
  'UCT',
  'Universal',
  'US/Alaska',
  'US/Aleutian',
  'US/Arizona',
  'US/Central',
  'US/East-Indiana',
  'US/Eastern',
  'US/Hawaii',
  'US/Indiana-Starke',
  'US/Michigan',
  'US/Mountain',
  'US/Pacific',
  'US/Samoa',
  'UTC',
  'W-SU',
  'WET',
  'Zulu'
];

export const DQ_VIOLATION_RANGE = Array.from({ length: 33 - 10 + 1 }, (_, i) => 10 + i);

export const CALL_CODES = {
  'Afghanistan': '+93',
  //'Åland Islands': '+35818',
  '\u00c5land Islands': '+35818',
  'Albania': '+355',
  'Algeria': '+213',
  'American Samoa': '+1684',
  'Andorra': '+376',
  'Angola': '+244',
  'Anguilla': '+1264',
  'Antigua and Barbuda': '+1268',
  'Argentina': '+54',
  'Armenia': '+374',
  'Aruba': '+297',
  'Ascension': '+247',
  'Australia': '+61',
  'Australian Antarctic Territory': '+6721',
  'Australian External Territories': '+672',
  'Austria': '+43',
  'Azerbaijan': '+994',
  'Bahamas': '+1242',
  'Bahrain': '+973',
  'Bangladesh': '+880',
  'Barbados': '+1246',
  'Barbuda': '+1268',
  'Belarus': '+375',
  'Belgium': '+32',
  'Belize': '+501',
  'Benin': '+229',
  'Bermuda': '+1441',
  'Bhutan': '+975',
  'Bolivia': '+591',
  'Bonaire': '+5997',
  'Bosnia and Herzegovina': '+387',
  'Botswana': '+267',
  'Brazil': '+55',
  'British Indian Ocean Territory': '+246',
  'British Virgin Islands': '+1284',
  'Brunei Darussalam': '+673',
  'Bulgaria': '+359',
  'Burkina Faso': '+226',
  'Burundi': '+257',
  'Cape Verde': '+238',
  'Cambodia': '+855',
  'Cameroon': '+237',
  'Canada': '+1',
  'Caribbean Netherlands 3': '+5993',
  'Caribbean Netherlands 4': '+5994',
  'Caribbean Netherlands 7': '+5997',
  'Cayman Islands': '+1345',
  'Central African Republic': '+236',
  'Chad': '+235',
  'Chatham Island, New Zealand': '+64',
  'Chile': '+56',
  'China': '+86',
  'Christmas Island': '+6189164',
  'Cocos (Keeling) Islands': '+6189162',
  'Colombia': '+57',
  'Comoros': '+269',
  'Congo': '+242',
  'Congo, Democratic Republic of the (Zaire)': '+243',
  'Cook Islands': '+682',
  'Costa Rica': '+506',
  'Ivory Coast': '+225',
  'Croatia': '+385',
  'Cuba': '+53',
  //'Curaçao': '+5999',
  'Cura\u00e7ao': '+5999',
  'Cyprus': '+357',
  'Czech Republic': '+420',
  'Denmark': '+45',
  'Diego Garcia': '+246',
  'Djibouti': '+253',
  'Dominica': '+1767',
  'Dominican Republic 1': '+1809',
  'Dominican Republic 2': '+1829',
  'Dominican Republic 3': '+1849',
  'Easter Island': '+56',
  'Ecuador': '+593',
  'Egypt': '+20',
  'El Salvador': '+503',
  'Equatorial Guinea': '+240',
  'Eritrea': '+291',
  'Estonia': '+372',
  'eSwatini': '+268',
  'Ethiopia': '+251',
  'Falkland Islands': '+500',
  'Faroe Islands': '+298',
  'Fiji': '+679',
  'Finland': '+358',
  'France': '+33',
  'French Antilles': '+596',
  'French Guiana': '+594',
  'French Polynesia': '+689',
  'Gabon': '+241',
  'Gambia': '+220',
  'Georgia': '+995',
  'Germany': '+49',
  'Ghana': '+233',
  'Gibraltar': '+350',
  'Greece': '+30',
  'Greenland': '+299',
  'Grenada': '+1473',
  'Guadeloupe': '+590',
  'Guam': '+1671',
  'Guatemala': '+502',
  'Guinea': '+224',
  'Guinea-Bissau': '+245',
  'Guyana': '+592',
  'Haiti': '+509',
  'Honduras': '+504',
  'Hong Kong': '+852',
  'Hungary': '+36',
  'Iceland': '+354',
  'India': '+91',
  'Indonesia': '+62',
  'Iran': '+98',
  'Iraq': '+964',
  'Ireland': '+353',
  'Israel': '+972',
  'Italy': '+39',
  'Jamaica 1': '+1658',
  'Jamaica 2': '+1876',
  'Jan Mayen': '+4779',
  'Japan': '+81',
  'Jersey': '+441534',
  'Jordan': '+962',
  'Kazakhstan 1': '+76',
  'Kazakhstan 2': '+77',
  'Kenya': '+254',
  'Kiribati': '+686',
  'Korea, North': '+850',
  'Korea, South': '+82',
  'Kosovo': '+383',
  'Kuwait': '+965',
  'Kyrgyzstan': '+996',
  'Laos': '+856',
  'Latvia': '+371',
  'Lebanon': '+961',
  'Lesotho': '+266',
  'Liberia': '+231',
  'Libya': '+218',
  'Liechtenstein': '+423',
  'Lithuania': '+370',
  'Luxembourg': '+352',
  'Macau': '+853',
  'Madagascar': '+261',
  'Malawi': '+265',
  'Malaysia': '+60',
  'Maldives': '+960',
  'Mali': '+223',
  'Malta': '+356',
  'Marshall Islands': '+692',
  'Martinique': '+596',
  'Mauritania': '+222',
  'Mauritius': '+230',
  'Mayotte 1': '+262269',
  'Mayotte 2': '+262639',
  'Mexico': '+52',
  'Micronesia, Federated States of': '+691',
  'Midway Island, USA': '+1808',
  'Moldova': '+373',
  'Monaco': '+377',
  'Mongolia': '+976',
  'Montenegro': '+382',
  'Montserrat': '+1664',
  'Morocco': '+212',
  'Mozambique': '+258',
  'Myanmar': '+95',
  'Nagorno-Karabakh 1': '+37447',
  'Nagorno-Karabakh 2': '+37497',
  'Namibia': '+264',
  'Nauru': '+674',
  'Nepal': '+977',
  'Netherlands': '+31',
  'Nevis': '+1869',
  'New Caledonia': '+687',
  'New Zealand': '+64',
  'Nicaragua': '+505',
  'Niger': '+227',
  'Nigeria': '+234',
  'Niue': '+683',
  'Norfolk Island': '+6723',
  'North Macedonia': '+389',
  'Northern Cyprus': '+90392',
  'Northern Ireland': '+4428',
  'Northern Mariana Islands': '+1670',
  'Norway': '+47',
  'Oman': '+968',
  'Pakistan': '+92',
  'Palau': '+680',
  'Palestine, State of': '+970',
  'Panama': '+507',
  'Papua New Guinea': '+675',
  'Paraguay': '+595',
  'Peru': '+51',
  'Philippines': '+63',
  'Pitcairn Islands': '+64',
  'Poland': '+48',
  'Portugal': '+351',
  'Puerto Rico 1': '+1787',
  'Puerto Rico 2': '+1939',
  'Qatar': '+974',
  //'Réunion': '+262',
  'R\u00e9union': '+262',
  'Romania': '+40',
  'Russia': '+7',
  'Rwanda': '+250',
  'Saba': '+5994',
  //'Saint Barthélemy': '+590',
  'Saint Barth\u00e9lemy': '+590',
  'Saint Helena': '+290',
  'Saint Kitts and Nevis': '+1869',
  'Saint Lucia': '+1758',
  'Saint Martin (France)': '+590',
  'Saint Pierre and Miquelon': '+508',
  'Saint Vincent and the Grenadines': '+1784',
  'Samoa': '+685',
  'San Marino': '+378',
  //'São Tomé and Príncipe': '+239',
  'S\u00e3o Tom\u00e9 and Pr\u00edncipe': '+239',
  'Saudi Arabia': '+966',
  'Senegal': '+221',
  'Serbia': '+381',
  'Seychelles': '+248',
  'Sierra Leone': '+232',
  'Singapore': '+65',
  'Sint Eustatius': '+5993',
  'Sint Maarten (Netherlands)': '+1721',
  'Slovakia': '+421',
  'Slovenia': '+386',
  'Solomon Islands': '+677',
  'Somalia': '+252',
  'South Africa': '+27',
  'South Georgia and the South Sandwich Islands': '+500',
  'South Ossetia': '+99534',
  'South Sudan': '+211',
  'Spain': '+34',
  'Sri Lanka': '+94',
  'Sudan': '+249',
  'Suriname': '+597',
  'Svalbard': '+4779',
  'Sweden': '+46',
  'Switzerland': '+41',
  'Syria': '+963',
  'Taiwan': '+886',
  'Tajikistan': '+992',
  'Tanzania': '+255',
  'Thailand': '+66',
  'East Timor': '+670',
  'Togo': '+228',
  'Tokelau': '+690',
  'Tonga': '+676',
  'Transnistria 1': '+3732',
  'Transnistria 2': '+3735',
  'Trinidad and Tobago': '+1868',
  'Tristan da Cunha': '+2908',
  'Tunisia': '+216',
  'Turkey': '+90',
  'Turkmenistan': '+993',
  'Turks and Caicos Islands': '+1649',
  'Tuvalu': '+688',
  'Uganda': '+256',
  'Ukraine': '+380',
  'United Arab Emirates': '+971',
  'United Kingdom': '+44',
  'United States': '+1',
  'Uruguay': '+598',
  'US Virgin Islands': '+1340',
  'Uzbekistan': '+998',
  'Vanuatu': '+678',
  'Vatican City State': '+3906698',
  'Venezuela': '+58',
  'Vietnam': '+84',
  'Wake Island, USA': '+1808',
  'Wallis and Futuna': '+681',
  'Yemen': '+967',
  'Zambia': '+260',
  'Zanzibar': '+25524',
  'Zimbabwe': '+263'
};
