import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIcon, SvgIconTypeMap } from '@material-ui/core';
import React from 'react';

export const AlarmDiffUpRightIcon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> = (props: any) => {
  return (
    <SvgIcon {...props} style={{fill: "#e67b00"}} viewBox='0 0 16 16'>
      <path fillRule='evenodd'
            d='M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z' />
    </SvgIcon>
  );
};