import Table, { ColumnsType } from "antd/es/table";
import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "../../../pages/components";
import { useNavigate } from "react-router-dom";

interface HerdStabilityScoresTableDataType {
    transitionCowRisk: React.ReactNode;
    production: React.ReactNode;
    health: React.ReactNode;
};

export const HerdStabilityScoresTable: React.FC<any> = ({ volatility }) => {
    const { t: translate } = useTranslation();

    const navigate = useNavigate();

    const columns: ColumnsType<HerdStabilityScoresTableDataType> = [
        {
            title: (
                <a onClick={() => {
                    navigate('/herd/historical', { state: { selection: 'transition-cow-risk' } });
                }}>
                    {translate('Transition Cow Risk')}
                </a>
            ),
            dataIndex: 'transitionCowRisk',
            key: 'transitionCowRisk'
        },
        {
            title: (
                <a onClick={() => {
                    navigate('/herd/historical', { state: { selection: 'milk' } });
                }}>
                    {translate('Production')}
                </a>
            ),
            dataIndex: 'production',
            key: 'production'
        },
        {
            title: (
                <a onClick={() => {
                    navigate('/herd/historical', { state: { selection: 'health' } });
                }}>
                    {translate('Health')}
                </a>),
            dataIndex: 'health',
            key: 'health'
        }
    ];

    const dataSource: HerdStabilityScoresTableDataType[] = [
        {
            transitionCowRisk: (
                <span>{translate('Calving Interval')}: <b>{volatility?.calvingIntervals !== 'N.A.' ? volatility?.calvingIntervals?.toFixed(2) : 'N.A.'}</b></span>
            ),
            production: (
                <span>{translate('Herd Production History: Milk and Solids')}: <b>{volatility?.historicalMilkAndSolids !== 'N.A.' ? volatility?.historicalMilkAndSolids?.toFixed(2) : 'N.A.'}</b></span>
            ),
            health: (
                <span>{translate('Survival rates in function of disease incidence')}: <b>{volatility?.survivalRates !== 'N.A.' ? volatility?.survivalRates?.toFixed(2) : 'N.A.'}</b></span>
            )
        },
        {
            transitionCowRisk: (
                <span>{translate('Lactation curves by lactation (last 6 months)')}: <b>{volatility?.historicalLactationCurves !== 'N.A.' ? volatility?.historicalLactationCurves?.toFixed(2) : 'N.A.'}</b></span>
            ),
            production: '',
            health: ''
        }
    ];

    return (
        <Row>
            <Col width='100%'>
                <Table columns={columns} dataSource={dataSource} pagination={false} />
            </Col>
        </Row>
    );
};