import { TFunction } from 'i18next';
import { ApplicationPage, AuthenticationContext } from '@ddw/react-framework';
import { IconLib } from '@ddw/react-components';
import {
  GuardianHomePage,
  GuardianPreventionAlarmsPage,
  GuardianPreventionHelpPage,
  GuardianPreventionIssuesPage,
  GuardianPreventionSettingsPage,
  GuardianPreventionStatsPage,
  HelpPage,
  HomePage,
  QualityPage
} from '.';
import { SettingsPage } from './SettingsPage';
import { GuardianDiagnosisAlarmsPage } from './Guardian/Diagnosis/GuardianDiagnosisAlarmsPage';
import { GuardianDiagnosisStatsPage } from './Guardian/Diagnosis/GuardianDiagnosisStatsPage';
import { GuardianDiagnosisDocsPage } from './Guardian/Diagnosis/GuardianDiagnosisDocsPage';
import { GuardianDiagnosisFeedbackPage } from './Guardian/Diagnosis/GuardianDiagnosisFeedbackPage';
import { GuardianDiagnosisIssuesPage } from './Guardian/Diagnosis/GuardianDiagnosisIssuesPage';
import { GuardianDiagnosisServicePage } from './Guardian/Diagnosis/GuardianDiagnosisServicePage';
import { GuardianDiagnosisSettingsPage } from './Guardian/Diagnosis/GuardianDiagnosisSettingsPage';
import { shortenIfPossible } from './utils';
import { VisioPrescriptionsPage } from './Visio/VisioPrescriptionsPage/VisioPrescriptionsPage';
import { AnimalPage } from './Visio/AnimalPage/AnimalPage';
import { VisioSettingsPage } from './Visio/VisioSettingsPage/VisioSettingsPage';
import { HerdfolioHistoricPage } from './Herdfolio/HerdfolioHistoricPage';
import { HerdfolioPredictedPage } from './Herdfolio/HerdfolioPredictedPage';

export const HomeRoute = '/home';
export const SettingsRoute = '/settings';
export const HelpRoute = '/help';
export const GuardianRoute = '/guardian';
export const VisioRoute = '/visio';
export const AnimalRoute = '/animal';
export const QualityRoute = '/quality';
export const AlarmsRoute = '/alarms';
export const IssuesRoute = '/issues';
export const StatsRoute = '/stats';
export const ServiceRoute = '/service';
export const FeedbackRoute = '/feedback';
export const GuardianPreventionRoute = '/guardian-prevention';
export const GuardianDiagnosisRoute = '/guardian-diagnosis';
export const PrescriptionsRoute = '/prescriptions';
export const HerdfolioRoute = '/herd';
export const HistoricRoute = '/historical';
export const PredictedRoute = '/predicted';

export const guardianRoutes: string[] = [
  GuardianPreventionRoute + AlarmsRoute,
  GuardianPreventionRoute + IssuesRoute,
  GuardianPreventionRoute + StatsRoute,
  GuardianPreventionRoute + SettingsRoute,
  GuardianPreventionRoute + HelpRoute,
  GuardianDiagnosisRoute + AlarmsRoute,
  GuardianDiagnosisRoute + IssuesRoute,
  GuardianDiagnosisRoute + ServiceRoute,
  GuardianDiagnosisRoute + StatsRoute,
  GuardianDiagnosisRoute + FeedbackRoute,
  GuardianDiagnosisRoute + SettingsRoute,
  GuardianDiagnosisRoute + HelpRoute
];

export const visioRoutes: string[] = [
  VisioRoute + PrescriptionsRoute,
  VisioRoute + SettingsRoute
];

export const mainPages = (translate: TFunction, visioPermission: boolean, userEmail: string): ApplicationPage[] => [
  {
    title: translate('Home'),
    icon: IconLib.home,
    route: HomeRoute,
    content: HomePage
  },
  {
    title: translate('GUARDIAN'),
    icon: IconLib.predictaHealth,
    route: GuardianRoute,
    content: GuardianHomePage
  },
  getVisio(translate, visioPermission, userEmail),
  {
    title: translate('Animal'),
    icon: IconLib.predictaMilk,
    route: AnimalRoute,
    content: AnimalPage
  },
  getHerd(translate),
  {
    title: translate('Quality'),
    icon: IconLib.award,
    route: QualityRoute,
    content: QualityPage
  },
  {
    title: shortenIfPossible(translate('Settings')),
    icon: IconLib.settings,
    route: SettingsRoute,
    content: SettingsPage
  },
  {
    title: translate('Help'),
    icon: IconLib.customHelp,
    route: HelpRoute,
    content: HelpPage
  }
];

export const guardianPages = (translate: TFunction, hasPrevention: boolean, hasDiagnosis: boolean, visioPermission: boolean, userEmail: string): ApplicationPage[] => {
  return userEmail.endsWith("@dairydatawarehouse.com") || userEmail.endsWith("@delaval.com") ? [
    {
      title: translate('GUARDIAN'),
      icon: IconLib.predictaHealth,
      route: GuardianRoute,
      content: GuardianHomePage
    },
    getPrevention(translate, hasPrevention, false),
    getDiagnosis(translate, hasDiagnosis, false),
    {
      title: translate('Home'),
      icon: IconLib.home,
      route: HomeRoute,
      content: HomePage
    },
    getVisio(translate, visioPermission, userEmail),
    {
      title: translate('Animal'),
      icon: IconLib.predictaMilk,
      route: AnimalRoute,
      content: AnimalPage
    },
    getHerd(translate)
  ] : [
    {
      title: translate('GUARDIAN'),
      icon: IconLib.predictaHealth,
      route: GuardianRoute,
      content: GuardianHomePage
    },
    getPrevention(translate, hasPrevention, false),
    {
      title: translate('Home'),
      icon: IconLib.home,
      route: HomeRoute,
      content: HomePage
    },
    getVisio(translate, visioPermission, userEmail),
    {
      title: translate('Animal'),
      icon: IconLib.predictaMilk,
      route: AnimalRoute,
      content: AnimalPage
    },
    getHerd(translate)
  ]
};

export const visioPages = (translate: TFunction, visioPermission: boolean, userEmail: string): ApplicationPage[] => [
  getVisio(translate, visioPermission, userEmail),
  {
    title: translate('Animal'),
    icon: IconLib.predictaMilk,
    route: AnimalRoute,
    content: AnimalPage
  },
  {
    title: translate('Home'),
    icon: IconLib.home,
    route: HomeRoute,
    content: HomePage
  },
  {
    title: translate('GUARDIAN'),
    icon: IconLib.predictaHealth,
    route: GuardianRoute,
    content: GuardianHomePage
  },
  getHerd(translate)
];

export const getPrevention = (translate: TFunction, hasPrevention: boolean, hideFromMenu: boolean): ApplicationPage => {
  return hasPrevention ? {
    title: shortenIfPossible(translate('Prevention')),
    icon: IconLib.shield,
    route: GuardianPreventionRoute,
    hideFromMenu: hideFromMenu,
    subPages: [
      {
        title: translate('Alarms'),
        icon: IconLib.dealer,
        route: AlarmsRoute,
        content: GuardianPreventionAlarmsPage
      },
      {
        title: shortenIfPossible(translate('Issues')),
        icon: IconLib.dealer,
        route: IssuesRoute,
        content: GuardianPreventionIssuesPage
      },
      {
        title: shortenIfPossible(translate('Stats')),
        icon: IconLib.dealer,
        route: StatsRoute,
        content: GuardianPreventionStatsPage
      },
      {
        title: shortenIfPossible(translate('Settings')),
        icon: IconLib.dealer,
        route: SettingsRoute,
        content: GuardianPreventionSettingsPage
      },
      {
        title: translate('Help'),
        icon: IconLib.customHelp,
        route: HelpRoute,
        content: GuardianPreventionHelpPage
      }
    ]
  } :
    {
      title: shortenIfPossible(translate('Prevention')),
      icon: IconLib.shield,
      route: GuardianPreventionRoute
    };
};

export const getDiagnosis = (translate: TFunction, hasDiagnosis: boolean, hideFromMenu: boolean): ApplicationPage => {
  return hasDiagnosis ? {
    title: translate('Disease Risk'),
    icon: IconLib.activity,
    route: GuardianDiagnosisRoute,
    hideFromMenu: hideFromMenu,
    subPages: [
      {
        title: translate('Alarms'),
        icon: IconLib.dealer,
        route: AlarmsRoute,
        content: GuardianDiagnosisAlarmsPage
      },
      {
        title: shortenIfPossible(translate('Issues')),
        icon: IconLib.dealer,
        route: IssuesRoute,
        content: GuardianDiagnosisIssuesPage
      },
      {
        title: shortenIfPossible(translate('Service')),
        icon: IconLib.dealer,
        route: ServiceRoute,
        content: GuardianDiagnosisServicePage
      },
      {
        title: shortenIfPossible(translate('Stats')),
        icon: IconLib.dealer,
        route: StatsRoute,
        content: GuardianDiagnosisStatsPage
      },
      {
        title: translate('Feedback'),
        icon: IconLib.dealer,
        route: FeedbackRoute,
        content: GuardianDiagnosisFeedbackPage
      },
      {
        title: shortenIfPossible(translate('Settings')),
        icon: IconLib.dealer,
        route: SettingsRoute,
        content: GuardianDiagnosisSettingsPage
      },
      {
        title: translate('Help'),
        icon: IconLib.customHelp,
        route: HelpRoute,
        content: GuardianDiagnosisDocsPage
      }
    ]
  } : {
    title: translate('Disease Risk'),
    icon: IconLib.activity,
    route: GuardianDiagnosisRoute
  };
};

export const getVisio = (translate: TFunction, visioPermission: boolean, userEmail: string): ApplicationPage => {
  // return visioPermission ?
  return sessionStorage.getItem('visioPermission') ?
    {
      title: translate('Visio'),
      icon: IconLib.predictaInventory,
      route: VisioRoute,
      hideFromMenu: !(userEmail.endsWith("@dairydatawarehouse.com") || userEmail.endsWith("@delaval.com")),
      subPages: [
        {
          title: translate('Prescriptions'),
          icon: IconLib.apps,
          route: PrescriptionsRoute,
          content: VisioPrescriptionsPage
        },
        {
          title: translate('Settings'),
          icon: IconLib.apps,
          route: SettingsRoute,
          content: VisioSettingsPage
        }
      ]
    } : {
      title: translate('Visio'),
      icon: IconLib.predictaInventory,
      route: VisioRoute + PrescriptionsRoute,
      content: VisioPrescriptionsPage,
      hideFromMenu: !(userEmail.endsWith("@dairydatawarehouse.com") || userEmail.endsWith("@delaval.com"))
    }
};

export const getHerd = (translate: TFunction): ApplicationPage => {
  return {
    title: translate('Herd'),
    icon: IconLib.building,
    route: HerdfolioRoute,
    subPages: [
      {
        title: translate('Historical'),
        route: HistoricRoute,
        content: HerdfolioHistoricPage
      },
      {
        title: translate('Predicted'),
        route: PredictedRoute,
        content: HerdfolioPredictedPage
      }
    ]
  };
};
