import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BAR_WIDTH, DDW_green_set, DDW_pink } from '../../../../pages/static';
import * as Plotly from 'plotly.js';
import { Data, Layout } from 'plotly.js';
import { ViewContext } from '@ddw/react-framework';
import { ViewSize } from '@ddw/react-components';

export const FeedbackBottomLeftBarChart: React.FC<any> = ({ dfCase5, dfCase5Index }) => {
  const { t: translate } = useTranslation();
  const { viewSize } = useContext(ViewContext);

  const chartRef = useRef<HTMLDivElement>(null);

  const data: Data[] = [
    {
      x: dfCase5Index,
      y: dfCase5['IS_ALARM'],
      opacity: 1.0,
      yaxis: 'y1',
      name: translate('Alarms Delivered'),
      marker: { color: DDW_green_set[0] },
      type: 'bar'
    },
    {
      x: dfCase5Index,
      y: dfCase5['RecordedLabel'],
      opacity: 1.0,
      width: BAR_WIDTH,
      yaxis: 'y1',
      name: translate('Reported in HMS'),
      marker: { color: DDW_pink },
      type: 'bar'
    }
  ];

  const layout: Partial<Layout> = {
    barmode: 'group',
    title: viewSize > ViewSize.S ? translate('Health events as reported in HMS vs alarms delivered') : '',
    showlegend: true,
    legend: {
      xanchor: 'center',
      x: 0.5,
      yanchor: 'bottom',
      y: 1,
      orientation: 'h'
    },
    xaxis: { tickangle: -30, automargin: true },
    hovermode: 'closest'
  };

  useEffect(() => {
    if (chartRef.current) {
      Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
    }
  }, [viewSize]);

  return (
    <>
      <div ref={chartRef} />
    </>
  );
};