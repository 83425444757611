import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIcon, SvgIconTypeMap } from '@material-ui/core';
import React from 'react';

export const AlarmDiffDownRightIcon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> = (props: any) => {
  return (
    <SvgIcon {...props} style={{fill: "#a8bf00"}} viewBox='0 0 16 16'>
      <path fillRule='evenodd'
            d='M14 13.5a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1 0-1h4.793L2.146 2.854a.5.5 0 1 1 .708-.708L13 12.293V7.5a.5.5 0 0 1 1 0v6z' />
    </SvgIcon>
  );
};