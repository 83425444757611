import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HerdProfileContext } from "../../context";
import { AuthenticationContext, SnackbarMessageType, SnackbarMessagesContext, ViewContext } from "@ddw/react-framework";
import useAsyncEffect from "use-async-effect";
import { Api } from "../../services/api";
import { Button, DDWApplication, IconLib, Select, SelectOption, ViewSize } from "@ddw/react-components";
import { PageContainer } from "../../component/PageContainer";
import { CalvingIntervalData, CalvingIntervals, HistoricLactationData, UdderHealthData } from "../../typings/herdfolio/types";
import { AnimalsDryBarChart } from "../../component/Charts/Herdfolio/Historical/TransitionCowRisk/AnimalsDryBarChart";
import { Alert, Col, Paragraph, Row, Span } from "../components";
import Table, { ColumnsType } from "antd/es/table";
import { FarmPopulationEvolution } from "../../typings/visio/types";
import { HistoricalFarmPopulationEvolutionChart } from "../../component/Charts/Visio/VisioAnimalPerformanceChart/HistoricalFarmPopulationEvolutionChart";
import { HerdSelectorSelectParent } from "../../component/HerdSelector/herdselector.styled";
import { Card, InputNumber } from "antd";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useLocation, useNavigate } from "react-router-dom";
import { CalvingIntervalBarChart } from "../../component/Charts/Herdfolio/Historical/TransitionCowRisk/CalvingIntervalBarChart";
import { HistoricLactationCurvesChart } from "../../component/Charts/Herdfolio/Historical/TransitionCowRisk/HistoricLactationCurvesChart";
import { PagesContext } from "../../context/PagesContext";
import { guardianPages, mainPages } from "../pages";
import { InseminationRiskPregnancyRiskChart } from "../../component/Charts/Herdfolio/Historical/Inventory/InseminationRiskPregnancyRiskChart";
import { DaysOpenVsDaysToConceptionChart } from "../../component/Charts/Herdfolio/Historical/Inventory/DaysOpenVsDaysToConceptionChart";
import { AvgDailyProductionVsAvgDIM } from "../../component/Charts/Herdfolio/Historical/Milk/AvgDailyProductionVsAvgDIM";
import './Herdfolio.css';
import { MonthlyHerdProductionVsDailyProductionChart } from "../../component/Charts/Herdfolio/Historical/Milk/MonthlyHerdProductionVsDailyProductionChart";
import { MilkPerCowSpotsChart } from "../../component/Charts/Herdfolio/Historical/Milk/MilkPerCowSpotsChart";
import { HerdfolioMilkHistoricLactationChart } from "../../component/Charts/Herdfolio/Historical/Milk/HerdfolioMilkHistoricLactationChart";
import { HerdHistoricMilkAndSolidsData } from "../../component/Charts/Herdfolio/Historical/Milk/HerdHistoricMilkAndSolidsData";
import { SCCManagementChart } from "../../component/Charts/Herdfolio/Historical/UdderHealth/SCCManagementChart";
import { DryPeriodCureRateChart } from "../../component/Charts/Herdfolio/Historical/UdderHealth/DryPeriodCureRateChart";
import { DryPeriodNewInfectionRateChart } from "../../component/Charts/Herdfolio/Historical/UdderHealth/DryPeriodNewInfectionRateChart";
import { LactationNewInfectionRateChart } from "../../component/Charts/Herdfolio/Historical/UdderHealth/LactationNewInfectionRateChart";
import { TransitionCowInfectionRateChart } from "../../component/Charts/Herdfolio/Historical/UdderHealth/TransitionCowInfectionRateChart";
import { BASE_CURRENCY, CONVERSION_RATES_IN_CASE_OF_FETCH_ERROR, KPI_CODES } from "../static";
import { LessThan42DIMReportedByTypeChart } from "../../component/Charts/Herdfolio/Historical/Health/LessThan42DIMReportedByTypeChart";
import { LactationReportedByTypeChart } from "../../component/Charts/Herdfolio/Historical/Health/LactationReportedByTypeChart";
import { YearlyIncidenceTable } from "../../component/Charts/Herdfolio/Historical/Health/YearlyIncidenceTable";
import { SurvivalCurveHealthyLactationsChart } from "../../component/Charts/Herdfolio/Historical/Health/SurvivalCurveHealthyLactationsChart";
import { getCurrencySign } from "../utils";
import { MilkProductionVsFeedCostChart } from "../../component/Charts/Herdfolio/Historical/Feeding/MilkProductionVsFeedCostChart";
import { AverageGroupSizePerMonth } from "../../component/Charts/Herdfolio/Historical/Feeding/AverageGroupSizePerMonthChart";
import { YearlyFeedCostTable } from "../../component/Charts/Herdfolio/Historical/Feeding/YearlyFeedCostTable";

interface DIRAndCostTableDataType {
    transitionCowDisease: string;
    estimatedDirAndSource: string;
    reportedDiseaseIncidence: string;
    estimatedYearlyCost: number;
};

export const HerdfolioHistoricPage: React.FC = () => {
    const { t: translate } = useTranslation();
    const { herdId, permissions } = useContext(HerdProfileContext);
    const { user, getAccessToken } = useContext(AuthenticationContext);
    const { setPages } = useContext(PagesContext);
    const { viewSize } = useContext(ViewContext);
    const { addMessageToStack } = useContext(SnackbarMessagesContext);

    const location = useLocation();

    const categorySelectionOptions: SelectOption[] = [
        { label: translate('Transition Cow Risk'), value: 'transition-cow-risk' },
        { label: translate('Health'), value: 'health' },
        { label: translate('Milk production'), value: 'milk' },
        { label: translate('Inventory'), value: 'inventory' },
        { label: translate('Udder Health'), value: 'udder-health' },
        { label: translate('Feeding'), value: 'feeding' }
    ];
    const [categorySelection, setCategorySelection] = useState<SelectOption>(() => {
        if (location.state?.selection) {
            const match: SelectOption | undefined = categorySelectionOptions.find((element: SelectOption) => element.value === location.state?.selection);
            return match === undefined ? categorySelectionOptions[0] : match;
        } else {
            return categorySelectionOptions[0];
        }
    });

    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    ////////// Transition cow disease

    const columns: ColumnsType<DIRAndCostTableDataType> = [
        {
            title: translate('Transition cow disease'),
            dataIndex: 'transitionCowDisease',
            key: 'transitionCowDisease'
        },
        {
            title: translate('Estimated disease incidence (< 42 DIM)'),
            dataIndex: 'estimatedDirAndSource',
            key: 'estimatedDirAndSource'
        },
        {
            title: translate('Reported disease incidence (< 42 DIM)'),
            dataIndex: 'reportedDiseaseIncidence',
            key: 'reportedDiseaseIncidence'
        },
        {
            title: translate('Estimated yearly disease cost') + ' (' + getCurrencySign() + ')',
            dataIndex: 'estimatedYearlyCost',
            key: 'estimatedYearlyCost'
        }
    ];

    const [dirAndCostTableData, setDirAndCostTableData] = useState<DIRAndCostTableDataType[]>([]);

    const [historicLactationData, setHistoricLactationData] = useState<HistoricLactationData>({
        l1Dims: {}, l2Dims: {}, l3PlusDims: {}, average: [], daysToPeakL1: 'N.A.', daysToPeakL3Plus: 'N.A.', peakRatio: 'N.A.'
    });

    const [calvingIntervals, setCalvingIntervals] = useState<CalvingIntervals>({
        calvingIntervals: [], period: []
    });

    ////////// Milk

    const [milkingBeds, setMilkingBeds] = useState<number>(0);

    const [historicMilkAndSolidsData, setHistoricMilkAndSolidsData] = useState<any>();

    const [isMroOnlyFarm, setIsMroOnlyFarm] = useState<boolean>(false);

    const fetchHerdProductionForecastData = async () => {
        const result2 = await Api.herdfolio.fetchNumberOfMilkingBeds(getAccessToken(), DDWApplication.DairyInsights, herdId);
        if (result2.success) {
            setMilkingBeds(result2.data);
        }
    };

    ////////// Inventory

    const populationDisplayOptions: SelectOption[] = [
        { label: translate('Absolute'), value: 'abs' },
        { label: translate('Relative'), value: 'rel' },
        { label: translate('Cumulative'), value: 'cum' }
    ];
    const [populationDisplay, setPopulationDisplay] = useState<SelectOption>(populationDisplayOptions[0]);

    const historyOptions: SelectOption[] = [
        { label: translate('1 Year'), value: '1' },
        { label: translate('3 Years'), value: '3' }
    ];
    const [historySelected, setHistorySelected] = useState<SelectOption>(historyOptions[0]);

    const [inventoryStatsData, setInventoryStatsData] = useState<FarmPopulationEvolution>({
        trace1x: [],
        trace1y: [],
        trace2x: [],
        trace2y: [],
        trace3y: [],
        trace4x: [],
        trace4y: [],
        tAxis: [],
        simulation: false,
        optimisation: false,
        displayLongTermProjection: true,
        infoCardText: ''
    });

    const [displayAnomaly, setDisplayAnomaly] = useState<boolean>(false);

    const [inventoryDataErrorOrWarningSeverity, setInventoryDataErrorOrWarningSeverity] = useState('');

    const [volunaryWaitingPeriod, setVoluntaryWaitingPeriod] = useState<number | null>(null);

    const fetchInventoryStats = async () => {
        const result = await Api.visio.fetchStats(
            getAccessToken(),
            DDWApplication.DairyInsights,
            herdId,
            populationDisplay.value,
            Number(historySelected.value),
            0, // hide long term projection, value needed on backend
            'standard'
        );

        if (result.success) {
            setInventoryStatsData(result.data);
            if (result.message === '') {
                setInventoryDataErrorOrWarningSeverity('');
            } else {
                setInventoryDataErrorOrWarningSeverity('warning');
            }
        }

        const result2 = await Api.herdfolio.fetchVoluntaryWaitingPeriod(getAccessToken(), DDWApplication.DairyInsights, herdId);

        if (result2.success) {
            setVoluntaryWaitingPeriod(result2.data);
        }
    };

    // const updateVoluntaryWaitingPeriod = async () => {
    //     document.body.style.cursor = 'progress';
    //     const result = await Api.herdfolio.updateVoluntaryWaitingPeriod(getAccessToken(), DDWApplication.DairyInsights, herdId, volunaryWaitingPeriod);
    //     if (result.success) {
    //         addMessageToStack({
    //             messageType: SnackbarMessageType.success,
    //             message: translate('Successfully updated voluntary waiting period.'),
    //             autoHide: true
    //         });
    //     } else {
    //         addMessageToStack({
    //             messageType: SnackbarMessageType.error,
    //             message: translate('There was a problem updating voluntary waiting period.'),
    //             autoHide: true
    //         });
    //     }
    //     document.body.style.cursor = 'default';
    // };

    useAsyncEffect(async () => {
        if (herdId != 0) {
            document.body.style.cursor = 'progress';
            await fetchInventoryStats();
            document.body.style.cursor = 'default';
        }
    }, [populationDisplay, historySelected]);

    ////////// Udder Health

    const [udderHealthData, setUdderHealthData] = useState<UdderHealthData>({ results: {} });

    ////////// Health

    const [survivalRateData, setSurvivalRateData] = useState<any>({
        keys: [], values: {}
    });
    const [monthlyCasesObserved, setMonthlyCasesObserved] = useState<any>({});
    const [transitionCowDiseaseIncidenceData, setTransitionCowDiseaseIncidenceData] = useState<any>({});

    ////////// Feeding

    const [feedingData, setFeedingData] = useState<any>({
        keys: [], values: {}
    });
    const [herdGroups, setHerdGroups] = useState<any>({
        keys: [], values: {}
    });
    const [averageGroupSizePerMonth, setAverageGroupSizePerMonth] = useState<any>({});
    const [yearlyFeedCostPerGroup, setYearlyFeedCostPerGroup] = useState<any>({});

    ////////// Kpi Data

    const [upstreamKpiData, setUpstreamKpiData] = useState<any>({
        "cycleKpis": {
            "l1Plus": []
        },
        "monthlyKpis": {
            "l1Plus": [],
            "allLactations": []
        },
        "yearlyKpis": {
            "l0Plus": []
        }
    });

    const fetchUpstreamKpis = async () => {
        const result = await Api.herdfolio.fetchUpstreamKpis(getAccessToken(), DDWApplication.DairyInsights, herdId);

        if (result.success) {
            setUpstreamKpiData(result.data);
        }
    };

    const fetchInsightsKpis = async () => {
        const result = await Api.herdfolio.fetchInsightsKpis(getAccessToken(), DDWApplication.DairyInsights, herdId);
        if (result.success) {
            const predictaConversionRates = localStorage.getItem('predictaCurrenciesConversionRates') !== null ? JSON.parse(localStorage.getItem('predictaCurrenciesConversionRates')!) : CONVERSION_RATES_IN_CASE_OF_FETCH_ERROR;
            const preferredCurrency = sessionStorage.getItem('preferredCurrency') !== null ? sessionStorage.getItem('preferredCurrency') : BASE_CURRENCY;

            const yearlyLactatingCows = result.data?.transitionCowDiseaseIncidence?.results?.reduce((acc: number, currentValue: any) => acc + currentValue['lactatingCows'], 0);

            const reportedDiseaseIncidenceDict: { [index: string]: string } = {
                'Ketosis': ((result.data?.transitionCowDiseaseIncidence?.results?.reduce((acc: number, currentValue: any) => acc + currentValue['ketosis'], 0) / yearlyLactatingCows) * 100).toFixed(2) + '%',
                'Milk Fever': ((result.data?.transitionCowDiseaseIncidence?.results?.reduce((acc: number, currentValue: any) => acc + currentValue['milkFever'], 0) / yearlyLactatingCows) * 100).toFixed(2) + '%',
                'Displaced Abomasum': ((result.data?.transitionCowDiseaseIncidence?.results?.reduce((acc: number, currentValue: any) => acc + currentValue['displacedAbomasum'], 0) / yearlyLactatingCows) * 100).toFixed(2) + '%',
                'Retained Placenta': ((result.data?.transitionCowDiseaseIncidence?.results?.reduce((acc: number, currentValue: any) => acc + currentValue['retainedPlacenta'], 0) / yearlyLactatingCows) * 100).toFixed(2) + '%',
                'Lameness': ((result.data?.transitionCowDiseaseIncidence?.results?.reduce((acc: number, currentValue: any) => acc + currentValue['lameness'], 0) / yearlyLactatingCows) * 100).toFixed(2) + '%',
                'Mastitis': ((result.data?.transitionCowDiseaseIncidence?.results?.reduce((acc: number, currentValue: any) => acc + currentValue['mastitis'], 0) / yearlyLactatingCows) * 100).toFixed(2) + '%'
            };

            setDirAndCostTableData(result.data?.estimatedDiseaseRiskCost?.keys.map((disease: string, index: number) => {
                return {
                    transitionCowDisease: translate(disease),
                    estimatedDirAndSource: `${result.data['estimatedDiseaseRiskCost']['values']['dir'][index].toFixed(2)} (${translate(result.data['estimatedDiseaseRiskCost']['values']['source'][index])})`,
                    reportedDiseaseIncidence: reportedDiseaseIncidenceDict[disease],
                    estimatedYearlyCost: parseFloat((result.data['estimatedDiseaseRiskCost']['values']['cost'][index] * predictaConversionRates[preferredCurrency!]["value"]).toFixed(0)).toLocaleString('fr-FR')
                }
            }));

            setCalvingIntervals({
                period: result.data?.period,
                calvingIntervals: result.data?.monthlyCalvingIntervals?.keys.map((_: string, index: number) => {
                    return {
                        calvingInterval: result.data.monthlyCalvingIntervals['values']['calvingInterval'][index],
                        dryOffDay: result.data.monthlyCalvingIntervals['values']['dryOffDay'][index],
                        dryOffDays: result.data.monthlyCalvingIntervals['values']['dryOffDays'][index]
                    } as CalvingIntervalData
                })
            });

            const udderHealthDictionary: UdderHealthData = { results: {} };
            result.data?.udderHealth?.keys.forEach((date: string, index: number) => {
                udderHealthDictionary['results'][date] = {
                    chronicallyInfectedCows: result.data.udderHealth['values']['chronicallyInfectedCows'][index],
                    infectedCows: result.data.udderHealth['values']['infectedCows'][index],
                    curedCows: result.data.udderHealth['values']['curedCows'][index],
                    cleanCows: result.data.udderHealth['values']['cleanCows'][index],
                    cowsRecorded: result.data.udderHealth['values']['cowsRecorded'][index],
                    dryPeriodNewInfectionRate: result.data.udderHealth['values']['dryPeriodNewInfectionRate'][index],
                    transitionCowInfectionRate: result.data.udderHealth['values']['transitionCowInfectionRate'][index],
                    dryCowCureRate: result.data.udderHealth['values']['dryCowCureRate'][index],
                    animalsCleanAtDryOff: result.data.udderHealth['values']['animalsCleanAtDryOff'][index],
                    animalsDirtyAtDryOff: result.data.udderHealth['values']['animalsDirtyAtDryOff'][index],
                    transitionCows: result.data.udderHealth['values']['transitionCows'][index],
                    lactationNewInfectionRate: result.data.udderHealth['values']['lactationNewInfectionRate'][index],
                    sccTimesMilkProduction: result.data.udderHealth['values']['sccTimesMilkProduction'][index],
                    milkProduction: result.data.udderHealth['values']['milkProduction'][index],
                    threeMonthRollingNewInfectionRate: result.data.udderHealth['values']['threeMonthRollingNewInfectionRate'][index],
                    threeMonthRollingCureRate: result.data.udderHealth['values']['threeMonthRollingCureRate'][index],
                    threeMonthRollingLactationNewInfectionRate: result.data.udderHealth['values']['threeMonthRollingLactationNewInfectionRate'][index],
                    threeMonthRollingTransitionNewInfectionRate: result.data.udderHealth['values']['threeMonthRollingTransitionNewInfectionRate'][index],
                }
            });
            setUdderHealthData(udderHealthDictionary);

            setHistoricMilkAndSolidsData(result.data?.dailyMilkAndSolidsYield);
            setIsMroOnlyFarm(result.data?.isMroOnlyFarm);

            setHistoricLactationData({
                l1Dims: result.data?.historicLactationData?.values?.milkValues[0],
                l2Dims: result.data?.historicLactationData?.values?.milkValues[1],
                l3PlusDims: result.data?.historicLactationData?.values?.milkValues[2],
                average: result.data?.historicLactationData?.values?.milkValues[3],
                daysToPeakL1: result.data?.historicLactationData?.values?.daysToPeak[0],
                daysToPeakL3Plus: result.data?.historicLactationData?.values?.daysToPeak[2],
                peakRatio: result.data?.historicLactationData?.values?.peakRatio,
            });

            setMonthlyCasesObserved({ "results": result.data?.monthlyCasesObserved?.results, "period": result.data?.period });
            setTransitionCowDiseaseIncidenceData({ "results": result.data?.transitionCowDiseaseIncidence?.results, "period": result.data?.period });

            setSurvivalRateData({
                keys: result.data?.survivalRateByLactation?.keys, values: result.data?.survivalRateByLactation?.values
            });

            setFeedingData({
                keys: result.data?.milkProductionVsFeedCost?.keys, values: result.data?.milkProductionVsFeedCost?.values
            });

            setHerdGroups(result.data?.herdGroups);
            setAverageGroupSizePerMonth({ "results": result.data?.averageGroupSizePerMonth, "period": result.data?.period });
            setYearlyFeedCostPerGroup(result.data?.yearlyFeedCostPerGroup?.values);
        }
    };

    useAsyncEffect(async () => {
        // setPages(mainPages(translate, permissions.visio));
        if (herdId != 0) {
            setLoading(true);
            await fetchHerdProductionForecastData();
            await fetchInventoryStats();
            await fetchUpstreamKpis();
            await fetchInsightsKpis();
            setLoading(false);
        }
    }, [herdId]);

    useEffect(() => {
        return () => {
            setCategorySelection(categorySelectionOptions[0]);
            setDisplayAnomaly(false);
            setPopulationDisplay(populationDisplayOptions[0]);
            setHistorySelected(historyOptions[0]);
            setInventoryStatsData({
                trace1x: [],
                trace1y: [],
                trace2x: [],
                trace2y: [],
                trace3y: [],
                trace4x: [],
                trace4y: [],
                tAxis: [],
                simulation: false,
                optimisation: false,
                displayLongTermProjection: true,
                infoCardText: ''
            });

            setMilkingBeds(0);

            setDirAndCostTableData([]);

            setCalvingIntervals({
                calvingIntervals: [], period: []
            });

            setHistoricLactationData({
                l1Dims: {}, l2Dims: {}, l3PlusDims: {}, average: [], daysToPeakL1: 'N.A.', daysToPeakL3Plus: 'N.A.', peakRatio: 'N.A.'
            });

            setUdderHealthData({ results: {} })

            setMonthlyCasesObserved({});
            setTransitionCowDiseaseIncidenceData({});

            setVoluntaryWaitingPeriod(null);

            setSurvivalRateData({
                keys: [], values: {}
            });

            setFeedingData({
                keys: [], values: {}
            });
            setHerdGroups({
                keys: [], values: {}
            });
            setAverageGroupSizePerMonth({});
            setYearlyFeedCostPerGroup({});

            setHistoricMilkAndSolidsData(undefined);
            setIsMroOnlyFarm(false);

            setUpstreamKpiData({
                "cycleKpis": {
                    "l1Plus": []
                },
                "monthlyKpis": {
                    "l1Plus": [],
                    "allLactations": []
                },
                "yearlyKpis": {
                    "l0Plus": []
                }
            });
        };
    }, [herdId]);

    return (
        <PageContainer hasFooter={true} loading={loading}>
            <Row>
                <div style={{ width: viewSize > ViewSize.S ? '450px' : '60%' }}>
                    <Select name={translate('Stats')} field={`stats`} options={categorySelectionOptions} selected={categorySelection}
                        onChange={(selected) => {
                            setCategorySelection(selected);
                        }} />
                </div>
            </Row>

            {categorySelection.value === 'milk' ?
                <>
                    <Row className={permissions.visio ? '' : 'mt-4 mb-4'}>
                        <Col width={viewSize > ViewSize.S ? "70%" : "100%"} style={viewSize > ViewSize.S ? { marginRight: 'auto', marginLeft: 'auto' } : {}}>
                            {historicMilkAndSolidsData &&
                                <HerdHistoricMilkAndSolidsData result={historicMilkAndSolidsData} isMroOnlyFarm={isMroOnlyFarm} />
                            }
                        </Col>
                    </Row>

                    {viewSize > ViewSize.S ?
                        <>
                            <Row>
                                <Col width="50%">
                                    <MonthlyHerdProductionVsDailyProductionChart l1PlusData={upstreamKpiData?.monthlyKpis.l1Plus} allLactationsData={upstreamKpiData?.monthlyKpis.allLactations} />
                                </Col>

                                <Col width="50%">
                                    <AvgDailyProductionVsAvgDIM props={upstreamKpiData?.monthlyKpis.l1Plus} />
                                </Col>
                            </Row>

                            <Row>
                                <Col width="50%" onClick={() => {
                                    navigate('/visio/settings');
                                }}>
                                    <MilkPerCowSpotsChart props={milkingBeds > 0 ? upstreamKpiData?.monthlyKpis.l1Plus : []} milkingBeds={milkingBeds} />
                                </Col>

                                <Col width="50%">
                                    <HerdfolioMilkHistoricLactationChart historicAverage={historicLactationData?.average} monthlyHerdKpis={upstreamKpiData?.monthlyKpis.l1Plus} />
                                </Col>
                            </Row>
                        </> :
                        <>
                            <Row>
                                <Col width="100%">
                                    <MonthlyHerdProductionVsDailyProductionChart l1PlusData={upstreamKpiData?.monthlyKpis.l1Plus} allLactationsData={upstreamKpiData?.monthlyKpis.allLactations} />
                                </Col>
                            </Row>

                            <Row>
                                <Col width="100%">
                                    <AvgDailyProductionVsAvgDIM props={upstreamKpiData?.monthlyKpis.l1Plus} />
                                </Col>
                            </Row>

                            <Row>
                                <Col width="100%" onClick={() => {
                                    navigate('/visio/settings');
                                }}>
                                    <MilkPerCowSpotsChart props={milkingBeds > 0 ? upstreamKpiData?.monthlyKpis.l1Plus : []} milkingBeds={milkingBeds} />
                                </Col>
                            </Row>

                            <Row>
                                <Col width="100%">
                                    <HerdfolioMilkHistoricLactationChart historicAverage={historicLactationData?.average} monthlyHerdKpis={upstreamKpiData?.monthlyKpis.l1Plus} />
                                </Col>
                            </Row>
                        </>
                    }
                </> : categorySelection.value === 'inventory' ?
                    <>
                        <Row>
                            <Col width="100%">
                                <Row style={{ alignItems: 'center' }}>
                                    <Col>
                                        <Span style={{ fontWeight: 'bold' }}>
                                            {translate('Voluntary Waiting Period: ')}
                                        </Span>
                                    </Col>

                                    <Col>
                                        <Span>
                                            {volunaryWaitingPeriod !== null ? volunaryWaitingPeriod : 'None'}
                                        </Span>
                                        {/* <Span>
                                            <InputNumber min={0} step={1} value={volunaryWaitingPeriod} onChange={setVoluntaryWaitingPeriod} />
                                        </Span> */}
                                    </Col>

                                    {/* <Col>
                                        <Span>
                                            <Button name={`voluntary-waiting-period`}
                                                onClick={updateVoluntaryWaitingPeriod}>
                                                {translate("Save")}
                                            </Button>
                                        </Span>
                                    </Col> */}
                                </Row>

                                {volunaryWaitingPeriod === null &&
                                    <Row>
                                        <Col>
                                            <Alert severity="warning" maxWidth="100%">
                                                {translate('It seems that you have not entered a value for the voluntary waiting period (VWP) into your herd management software. Please populate this in your herd management software. In the meantime we will be using a default value of 60 DIM to calculate the parameters on this page.')}
                                            </Alert>
                                        </Col>
                                    </Row>
                                }

                                <br></br>

                                <Span style={{ fontWeight: 'bold' }}>
                                    {translate('Age at first insemination: ')}
                                </Span>
                                <Span>
                                    {(upstreamKpiData?.yearlyKpis.l0Plus.filter((data: any) => data.kpiCode === KPI_CODES['AvgAgeAtFirstInsemination']).length > 0) ? `${upstreamKpiData?.yearlyKpis.l0Plus.filter((data: any) => data.kpiCode === KPI_CODES['AvgAgeAtFirstInsemination'])[0]["value"]} ${translate('months')}` : 'None'}
                                </Span>
                            </Col>
                        </Row>

                        <br></br>

                        {inventoryDataErrorOrWarningSeverity === 'warning' &&
                            <Row style={{ display: permissions.visio ? '' : 'none' }}>
                                <Col width="100%">
                                    <Row className={"mt-3"}>
                                        <Col width="100%">
                                            <Button name={`anomaly-identified`}
                                                icon={displayAnomaly ? IconLib.expandLess : IconLib.expandMore}
                                                onClick={() => setDisplayAnomaly(!displayAnomaly)}>
                                                {translate("ATTENTION (Anomaly Identified) - We identified suspicious number of animals in historical data")}
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Row className="mt-2 w-100">
                                        <Col width="100%">
                                            {displayAnomaly &&
                                                <Card className='info-card'
                                                    type={'inner'}
                                                    style={{ border: '1px solid #ffc107', float: "left" }}
                                                    title={translate("List of impacted compartment groups and information about what went wrong:")}>
                                                    <ReactMarkdown remarkPlugins={[remarkGfm]} children={inventoryStatsData.msgAnomalyReportItem} />
                                                </Card>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        }

                        {viewSize > ViewSize.S ?
                            <>
                                <Row className={"mt-3"} style={{ display: permissions.visio ? '' : 'none' }}>
                                    <Col>
                                        <HerdSelectorSelectParent>
                                            <Select name={translate('Display population')} field={`population`} options={populationDisplayOptions} selected={populationDisplay}
                                                onChange={(selected) => {
                                                    setPopulationDisplay(selected);
                                                }} />
                                        </HerdSelectorSelectParent>
                                    </Col>

                                    <Col>
                                        <HerdSelectorSelectParent>
                                            <Select name={translate('Show history')} field={`history`} options={historyOptions} selected={historySelected}
                                                onChange={(selected) => {
                                                    setHistorySelected(selected);
                                                }} />
                                        </HerdSelectorSelectParent>
                                    </Col>
                                </Row>
                            </> :
                            <>
                                <Row className={"mt-3"} style={{ display: permissions.visio ? '' : 'none' }}>
                                    <Col width="90%">
                                        <div>
                                            <Select name={translate('Display population')} field={`population`} options={populationDisplayOptions} selected={populationDisplay}
                                                onChange={(selected) => {
                                                    setPopulationDisplay(selected);
                                                }} />
                                        </div>
                                    </Col>
                                </Row>

                                <Row style={{ display: permissions.visio ? '' : 'none' }}>
                                    <Col width="90%">
                                        <div>
                                            <Select name={translate('Show history')} field={`history`} options={historyOptions} selected={historySelected}
                                                onChange={(selected) => {
                                                    setHistorySelected(selected);
                                                }} />
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }

                        {viewSize > ViewSize.S ?
                            <>
                                <Row>
                                    {permissions.visio ?
                                        <Col width={"70%"} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                            <HistoricalFarmPopulationEvolutionChart trace1x={inventoryStatsData?.trace1x} trace1y={inventoryStatsData?.trace1y}
                                                trace2x={inventoryStatsData.trace2x} trace2y={inventoryStatsData.trace2y} trace3y={inventoryStatsData.trace3y}
                                                trace4x={inventoryStatsData.trace4x} trace4y={inventoryStatsData.trace4y} tAxis={inventoryStatsData.tAxis}
                                                population={populationDisplay.value} history={historySelected.value} />
                                        </Col> :
                                        <Col width={"50%"}>
                                            <Alert maxWidth="100%" severity="primary" style={{ textAlign: 'center' }}>
                                                {translate("Purchase Predicta Visio in order to see farm population evolution")}
                                            </Alert>
                                        </Col>
                                    }
                                </Row>

                                <Row>
                                    <Col width={"70%"} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                        <DaysOpenVsDaysToConceptionChart props={upstreamKpiData?.monthlyKpis.allLactations} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col width={"70%"} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                        <InseminationRiskPregnancyRiskChart props={upstreamKpiData?.cycleKpis.l1Plus} />
                                    </Col>
                                </Row>
                            </> :
                            <>
                                <Row>
                                    {permissions.visio ?
                                        <Col width="100%">
                                            <HistoricalFarmPopulationEvolutionChart trace1x={inventoryStatsData?.trace1x} trace1y={inventoryStatsData?.trace1y}
                                                trace2x={inventoryStatsData.trace2x} trace2y={inventoryStatsData.trace2y} trace3y={inventoryStatsData.trace3y}
                                                trace4x={inventoryStatsData.trace4x} trace4y={inventoryStatsData.trace4y} tAxis={inventoryStatsData.tAxis}
                                                population={populationDisplay.value} history={historySelected.value} />
                                        </Col> :
                                        <Col width="90%">
                                            <Alert maxWidth="100%" severity="primary">
                                                {translate("Purchase Predicta Visio in order to see farm population evolution")}
                                            </Alert>
                                        </Col>
                                    }
                                </Row>

                                <Row>
                                    <Col width="100%">
                                        <DaysOpenVsDaysToConceptionChart props={upstreamKpiData?.monthlyKpis.allLactations} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col width="100%">
                                        <InseminationRiskPregnancyRiskChart props={upstreamKpiData?.cycleKpis.l1Plus} />
                                    </Col>
                                </Row>
                            </>
                        }
                    </> : categorySelection.value === 'udder-health' ?
                        <>
                            {Object.keys(udderHealthData['results']).length > 0 ?
                                <>
                                    {viewSize > ViewSize.S ?
                                        <>
                                            <Row>
                                                <Col width="50%">
                                                    <DryPeriodCureRateChart {...udderHealthData} />
                                                </Col>

                                                <Col width="50%">
                                                    <DryPeriodNewInfectionRateChart {...udderHealthData} />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col width="50%">
                                                    <LactationNewInfectionRateChart {...udderHealthData} />
                                                </Col>

                                                <Col width="50%">
                                                    <TransitionCowInfectionRateChart {...udderHealthData} />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col width="50%" style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                                                    <SCCManagementChart {...udderHealthData} />
                                                </Col>
                                            </Row>
                                        </> :
                                        <>
                                            <Row>
                                                <Col width="100%">
                                                    <DryPeriodCureRateChart {...udderHealthData} />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col width="100%">
                                                    <DryPeriodNewInfectionRateChart {...udderHealthData} />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col width="100%">
                                                    <LactationNewInfectionRateChart {...udderHealthData} />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col width="100%">
                                                    <TransitionCowInfectionRateChart {...udderHealthData} />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col width="100%">
                                                    <SCCManagementChart {...udderHealthData} />
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </> :
                                <Alert maxWidth={viewSize > ViewSize.S ? "30%" : "50%"} severity="danger">
                                    {translate("Data not available")}
                                </Alert>
                            }
                        </> : categorySelection.value === 'health' ?
                            <>
                                <Row>
                                    <Col width={viewSize > ViewSize.S ? "70%" : "100%"} style={viewSize > ViewSize.S ? { marginRight: 'auto', marginLeft: 'auto' } : {}}>
                                        <SurvivalCurveHealthyLactationsChart {...survivalRateData} />
                                    </Col>
                                </Row>

                                <br /><br />

                                {viewSize > ViewSize.S ?
                                    <>
                                        <Row>
                                            <Col width="50%">
                                                <LessThan42DIMReportedByTypeChart {...transitionCowDiseaseIncidenceData} />
                                            </Col>

                                            <Col width="50%">
                                                <YearlyIncidenceTable props={transitionCowDiseaseIncidenceData} lessThan42DIM={true} />
                                            </Col>
                                        </Row>

                                        <br /><br />

                                        <Row>
                                            <Col width="50%">
                                                <LactationReportedByTypeChart {...monthlyCasesObserved} />
                                            </Col>

                                            <Col width="50%">
                                                <YearlyIncidenceTable props={monthlyCasesObserved} lessThan42DIM={false} />
                                            </Col>
                                        </Row>
                                    </> :
                                    <>
                                        <Row>
                                            <Col width="100%">
                                                <LessThan42DIMReportedByTypeChart {...transitionCowDiseaseIncidenceData} />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col width="100%">
                                                <YearlyIncidenceTable props={transitionCowDiseaseIncidenceData} lessThan42DIM={true} />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col width="100%">
                                                <LactationReportedByTypeChart {...monthlyCasesObserved} />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col width="100%">
                                                <YearlyIncidenceTable props={monthlyCasesObserved} lessThan42DIM={false} />
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </> : categorySelection.value === 'feeding' ?
                                <>
                                    <Row>
                                        <Col width={viewSize > ViewSize.S ? "70%" : "100%"} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                            <MilkProductionVsFeedCostChart feedingData={feedingData} herdGroups={herdGroups} />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col width={viewSize > ViewSize.S ? "70%" : "100%"} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                            <AverageGroupSizePerMonth {...averageGroupSizePerMonth} />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col width={viewSize > ViewSize.S ? "70%" : "100%"} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                            <YearlyFeedCostTable {...yearlyFeedCostPerGroup} />
                                        </Col>
                                    </Row>
                                </> :
                                <>
                                    {viewSize > ViewSize.S ?
                                        <>
                                            <Row style={{ alignItems: 'center' }}>
                                                <Col width="50%" onClick={() => {
                                                    setPages(guardianPages(translate, permissions.prevention, permissions.diagnosis, permissions.visio, user !== undefined ? user['profile']['UserEmail'] : ''));
                                                    navigate('/guardian-prevention/settings')
                                                }} onMouseEnter={() => {
                                                    document.body.style.cursor = 'pointer'
                                                }} onMouseLeave={() => {
                                                    document.body.style.cursor = 'default'
                                                }}>
                                                    <Table columns={columns} dataSource={dirAndCostTableData} pagination={false} />
                                                </Col>

                                                <Col width="50%">
                                                    <HistoricLactationCurvesChart
                                                        historicData={historicLactationData} />
                                                </Col>
                                            </Row>

                                            <Row style={{ alignItems: 'center' }}>
                                                <Col width="50%">
                                                    <CalvingIntervalBarChart calvingIntervals={calvingIntervals?.calvingIntervals} period={calvingIntervals.period} />
                                                </Col>

                                                <Col width="50%">
                                                    <AnimalsDryBarChart props={upstreamKpiData?.monthlyKpis.l1Plus} />
                                                </Col>
                                            </Row>
                                        </> :
                                        <>
                                            <Row>
                                                <Col width="100%" onMouseEnter={() => {
                                                    document.body.style.cursor = 'pointer'
                                                }} onMouseLeave={() => {
                                                    document.body.style.cursor = 'default'
                                                }} onClick={() => {
                                                    setPages(guardianPages(translate, permissions.prevention, permissions.diagnosis, permissions.visio, user !== undefined ? user['profile']['UserEmail'] : ''));
                                                    navigate('/guardian-prevention/settings')
                                                }}>
                                                    <Table columns={columns} dataSource={dirAndCostTableData} pagination={false} />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <HistoricLactationCurvesChart
                                                    historicData={historicLactationData} />
                                            </Row>

                                            <Row>
                                                <Col width="100%">
                                                    <CalvingIntervalBarChart calvingIntervals={calvingIntervals?.calvingIntervals} period={calvingIntervals.period} />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col width="100%">
                                                    <AnimalsDryBarChart props={upstreamKpiData?.monthlyKpis.l1Plus} />
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </>
            }
        </PageContainer>
    );
};