import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticationContext, SnackbarMessagesContext, SnackbarMessageType, ViewContext } from '@ddw/react-framework';
import { HerdProfileContext } from '../../../../context';
import { DiagnosisSettings } from '../../../../typings/types';
import { useNavigate } from 'react-router-dom';
import { Api } from '../../../../services/api';
import { ButtonType, DDWApplication, ViewSize } from '@ddw/react-components';
import useAsyncEffect from 'use-async-effect';
import { PageContainer } from '../../../../component/PageContainer';
import { Alert, Col, IconContainer, Paragraph, Row, SubHeader } from '../../../components';
import { ClipboardDataIcon, GearFillIcon } from '../../../../component/Icons';
import { Card, InputNumber, Radio } from 'antd';
import { Button, ButtonGroup, Slider } from '@mui/material';
import { EyeFillIcon } from '../../../../component/Icons/EyeFillIcon';
import { ButtonWrapper } from '../../../../component/ButtonWrapper';
import { getObjectValues } from '../../../utils';
import * as Plotly from 'plotly.js';
import { Data, Layout } from 'plotly.js';
import { DDW_green, DDW_grey } from '../../../static';
import { PagesContext } from '../../../../context/PagesContext';
import { guardianPages } from '../../../pages';

export const GuardianDiagnosisSettingsPage: React.FC = () => {
  const { t: translate } = useTranslation();
  const { user, getAccessToken } = useContext(AuthenticationContext);
  const { herdId, permissions } = useContext(HerdProfileContext);
  const { addMessageToStack } = useContext(SnackbarMessagesContext);
  const { setPages } = useContext(PagesContext);
  const { viewSize } = useContext(ViewContext);

  const [settingsData, setSettingsData] = useState<DiagnosisSettings>();

  const [configuration, setConfiguration] = useState<string | null>(null);
  const [activeExpress, setActiveExpress] = useState<boolean[]>([false, false, false]);
  const [sensitivity, setSensitivity] = useState<number>(50);
  const [specificity, setSpecificity] = useState<number>(0);
  const [specificityMinimumValue, setSpecificityMinimumValue] = useState<number>(90);
  const [diseaseIncidence, setDiseaseIncidence] = useState<number | null>(5);

  const [totalAlarms, setTotalAlarms] = useState<string>('--');
  const [trueAlarms, setTrueAlarms] = useState<string>('--');
  const [missedAlarms, setMissedAlarms] = useState<string>('--');
  const [falseAlarms, setFalseAlarms] = useState<string>('--');

  // this is in order to avoid sending many requests when modifying the sensitivity slider (before the slider is released)
  const [sensitivitySliderClicked, setSensitivitySliderClicked] = useState<boolean>(false);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [dataErrorOrWarningSeverity, setDataErrorOrWarningSeverity] = useState('');

  const chartRef = useRef<HTMLDivElement>(null);

  const layout: Partial<Layout> = {
    title: translate('Model ROC configuration'),
    xaxis: {
      title: translate('1 - Specificity (%)'),
      range: [0, 100],
      autorange: false
    },
    yaxis: {
      title: translate('Sensitivity (%)'),
      range: [0, 100],
      scaleanchor: 'x',
      scaleratio: 1
    },
    hovermode: 'closest',
    height: 600,
    width: 600
  };

  const data: Data[] = [
    {
      x: [0, 100],
      y: [0, 100],
      type: 'scatter',
      mode: 'lines',
      opacity: 0.5,
      line: {
        color: DDW_grey,
        dash: 'dash',
        width: 1
      },
      showlegend: false
    },
    {
      x: settingsData?.roc['Specificity'] ? getObjectValues<number>(settingsData?.roc['Specificity']).map((val: number) => 100 - val) : [],
      y: settingsData?.roc['Sensitivity'] ? getObjectValues<number>(settingsData?.roc['Sensitivity']) : [],
      type: 'scatter',
      mode: 'lines',
      opacity: 1,
      name: translate('Model ROC'),
      line: {
        color: '#000000',
        width: 1
      },
      showlegend: false
    },
    {
      x: [100 - specificity],
      y: [sensitivity],
      mode: 'markers',
      marker: {
        color: DDW_green,
        size: 13
      },
      opacity: 1,
      name: translate('User settings'),
      showlegend: false
    }
  ];

  const marks = [...Array(21)].map((_, i) => {
    const value = i * 5;
    return {
      value: value,
      label: value.toString()
    };
  });

  const [specificityMarks, setSpecificityMarks] = useState([...Array(11)].map((_, i) => {
    const value = i + 90;
    return {
      value: value,
      label: value.toString()
    };
  }));

  const activateSensitivityButton = (position: number) => {
    let arr = [];
    for (let i = 0; i < 3; i++) {
      if (i === position) {
        arr.push(true);
      } else {
        arr.push(false);
      }
    }
    setActiveExpress(arr);
  };

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    setSensitivity(newValue as number);
  };

  const fetchSettings = async () => {
    const result = await Api.diagnosis.fetchDiagnosisSettings(getAccessToken(), DDWApplication.DairyInsights, herdId);
    if (result.success) {
      if (result.message === '') {
        setSettingsData(result.data);
        setConfiguration(result.data.configurationMode);
        setActiveExpress(result.data.activeExpress);
        setSensitivity(result.data.settings['ModelSeLevel']);
        setSpecificity(result.data.specificity);
        setDataErrorOrWarningSeverity('');
      } else {
        setDataErrorOrWarningSeverity('warning');
      }
    } else {
      if (result.message !== '') {
        navigate('/home');
        addMessageToStack({
          messageType: SnackbarMessageType.error,
          message: translate('There was a problem fetching settings.'),
          autoHide: false
        });
      } else {
        setDataErrorOrWarningSeverity('danger');
      }
    }
  };

  const saveUserSettings = async () => {
    document.body.style.cursor = 'progress';
    const result = await Api.diagnosis.saveUserSettings(getAccessToken(), DDWApplication.DairyInsights, herdId, {
      configType: String(configuration),
      seLowActive: activeExpress[0],
      seMedActive: activeExpress[1],
      seHighActive: activeExpress[2],
      sensitivity: sensitivity
    });
    if (result.success) {
      addMessageToStack({
        messageType: SnackbarMessageType.success,
        message: translate('Successfully uploaded user settings.'),
        autoHide: true
      });
    } else {
      addMessageToStack({
        messageType: SnackbarMessageType.error,
        message: translate('Could not upload user settings, please contact DDW.'),
        autoHide: true
      });
    }
    document.body.style.cursor = 'default';
  };

  useAsyncEffect(async () => {
    setPages(guardianPages(translate, permissions.prevention, permissions.diagnosis, permissions.visio, user !== undefined ? user['profile']['UserEmail'] : ''));
    if (herdId != 0) {
      setLoading(true);
      await fetchSettings();
      setLoading(false);
    }
  }, [herdId]);

  useEffect(() => {
    if (settingsData?.roc['Sensitivity']) {
      setSpecificity(() => {
        const rocList = getObjectValues<number>(settingsData?.roc['Sensitivity']);
        let i;
        for (i = 0; i < rocList.length; i++) {
          if (rocList[i] === sensitivity) {
            break;
          }
        }
        const spValue = getObjectValues<number>(settingsData?.roc['Specificity'])[i];
        setSpecificityMinimumValue(spValue >= 90 ? 90 : parseInt(String(spValue)));
        if (spValue < 90) {
          const startMark: number = Math.floor(spValue / 10) * 10;
          setSpecificityMarks([...Array((100 - startMark) / 5 + 1)].map((_, i) => {
            const value = i * 5 + startMark;
            return {
              value: value,
              label: value.toString()
            };
          }));
        } else {
          setSpecificityMarks([...Array(11)].map((_, i) => {
            const value = i + 90;
            return {
              value: value,
              label: value.toString()
            };
          }));
        }
        return spValue;
      });
    }
  }, [sensitivity]);

  useAsyncEffect(async () => {
    if (diseaseIncidence !== null && configuration !== null && activeExpress.some((e) => e)) {
      if (!(configuration === 'advanced' && sensitivitySliderClicked)) {
        document.body.style.cursor = 'progress';
        const result = await Api.diagnosis.estimateAlarms(getAccessToken(), DDWApplication.DairyInsights, herdId, String(configuration), sensitivity, diseaseIncidence, activeExpress[0], activeExpress[1], activeExpress[2]);
        if (result.success) {
          setTotalAlarms(result.data.total);
          setTrueAlarms(result.data.true);
          setMissedAlarms(result.data.missed);
          setFalseAlarms(result.data.false);
        } else {
          addMessageToStack({
            messageType: SnackbarMessageType.error,
            message: translate('There was a problem updating predicted alarms.'),
            autoHide: true
          });
        }
        document.body.style.cursor = 'default';
      }
    }
  }, [activeExpress, sensitivity, diseaseIncidence, configuration, sensitivitySliderClicked]);

  useEffect(() => {
    if (chartRef.current) {
      Plotly.newPlot(chartRef.current, data, layout, { responsive: true, displayModeBar: false });
    }
  });

  return (
    <>
      <PageContainer hasFooter={false} loading={loading}>
        {(dataErrorOrWarningSeverity !== 'danger' && dataErrorOrWarningSeverity !== 'warning') ?
          <>
            <Row>
              <Col width={'100%'}>
                <SubHeader>
                  <Row style={{ alignItems: 'center' }}>
                    <Col width={'3%'}>
                      <IconContainer>
                        <GearFillIcon />
                      </IconContainer>
                    </Col>

                    <Col width={'97%'}>
                      {translate('Alarm configurator')}
                    </Col>
                  </Row>
                </SubHeader>
              </Col>
            </Row>

            <Row className={'mt-3'} style={{ alignItems: 'center' }}>
              <Col width={'15%'}>
                <Paragraph>{translate('Configuration')}</Paragraph>
              </Col>
              <Col>
                <Radio.Group defaultValue={configuration} value={configuration} onChange={(event) => {
                  setConfiguration(event.target.value);
                }}>
                  <Radio value={'express'}>{translate('Express')}</Radio>
                  <Radio value={'advanced'}>{translate('Advanced')}</Radio>
                </Radio.Group>
              </Col>
            </Row>

            {configuration === 'express' &&
              <>
                <Row className={'mt-3'} style={{ alignItems: 'center' }}>
                  <Col width={'15%'}>
                    <Paragraph>{translate('Sensitivity')}</Paragraph>
                  </Col>
                  <Col>
                    <ButtonGroup variant='contained' aria-label='outlined primary button group'>
                      <Button style={{ backgroundColor: activeExpress[0] ? '#004e9d' : '' }} onClick={() => {
                        activateSensitivityButton(0);
                      }}>
                        {translate('Low')}
                      </Button>
                      <Button style={{ backgroundColor: activeExpress[1] ? '#004e9d' : '' }} onClick={() => {
                        activateSensitivityButton(1);
                      }}>
                        {translate('Medium')}
                      </Button>
                      <Button style={{ backgroundColor: activeExpress[2] ? '#004e9d' : '' }} onClick={() => {
                        activateSensitivityButton(2);
                      }}>
                        {translate('High')}
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </>
            }

            {configuration === 'advanced' &&
              <>
                <Row className={'mt-3'} style={{ alignItems: 'center' }}>
                  <Col width={'15%'}>
                    <Paragraph>{translate('Sensitivity (%)')}</Paragraph>
                  </Col>
                  <Col className={'mt-3'} width={'40%'}>
                    <Slider min={0} max={100} step={1} value={sensitivity} onChange={handleSliderChange}
                      valueLabelDisplay={'auto'} marks={marks} onMouseDown={() => {
                        setSensitivitySliderClicked(true);
                      }}
                      onClick={() => {
                        setSensitivitySliderClicked(false);
                      }} />
                  </Col>
                </Row>

                <Row className={'mt-3'} style={{ alignItems: 'center' }}>
                  <Col width={'15%'}>
                    <Paragraph>{translate('Specificity (%)')}</Paragraph>
                  </Col>
                  <Col className={'mt-3'} width={'40%'}>
                    <Slider min={specificityMinimumValue} max={100} step={0.1} disabled={true} value={specificity}
                      valueLabelDisplay={'auto'} marks={specificityMarks} />
                  </Col>
                </Row>

                <Row className={'mt-2'}>
                  {/*<ROCGraph roc={settingsData?.roc} specificity={specificity} sensitivity={sensitivity} />*/}
                  <div ref={chartRef} />
                </Row>
              </>
            }

            <Row className={'mt-2 mb-2'} style={{ alignItems: 'center' }}>
              <Col width={'3%'}>
                <EyeFillIcon />
              </Col>
              <Col>
                <h3>{translate('Epidemiology')}</h3>
              </Col>
            </Row>

            <Row className={'mt-2'} style={{ alignItems: 'center' }}>
              <Col width={'40%'}>
                <Paragraph>{translate('General disease incidence % per month')}</Paragraph>
              </Col>
              <Col>
                <InputNumber min={0} max={100} step={1} value={diseaseIncidence} onChange={(value) => {
                  setDiseaseIncidence(value);
                }} />
              </Col>
            </Row>

            <Row className={'mt-3'} style={{ alignItems: 'center' }}>
              <Col width={'3%'}>
                <ClipboardDataIcon />
              </Col>
              <Col>
                <h3>{translate('Estimated operating conditions')}</h3>
              </Col>
            </Row>

            <Row className={'mt-4 mb-4'} style={{ alignItems: 'center' }}>
              <Col width={'25%'}>
                <Card type={'inner'} title={translate('Estimated alarms')} style={{ border: '1px solid #007bff' }}>
                  <h2 className={'mb-2'}>{totalAlarms}</h2>
                  <Paragraph className={'mb-3'}>{translate('per week')}</Paragraph>
                </Card>
              </Col>
              <Col width={'25%'}>
                <Card type={'inner'} title={translate('Estimated true alarms')} style={{ border: '1px solid #28a745' }}>
                  <h2 className={'mb-2'}>{trueAlarms}</h2>
                  <Paragraph className={'mb-3'}>{translate('per week')}</Paragraph>
                </Card>
              </Col>
              <Col width={'25%'}>
                <Card type={'inner'} title={translate('Estimated missed cases')}
                  style={{ border: '1px solid #dc3545' }}>
                  <h2 className={'mb-2'}>{missedAlarms}</h2>
                  <Paragraph className={'mb-3'}>{translate('per week')}</Paragraph>
                </Card>
              </Col>
              <Col width={'25%'}>
                <Card type={'inner'} title={translate('Estimated false alarms')}
                  style={{ border: '1px solid #ffc107' }}>
                  <h2 className={'mb-2'}>{falseAlarms}</h2>
                  <Paragraph className={'mb-3'}>{translate('per week')}</Paragraph>
                </Card>
              </Col>
            </Row>

            <hr className={'footer-line-break mt-3'} />

            <Row className={'mt-2 mb-2'}>
              <ButtonWrapper name={`save`} type={ButtonType.Primary} isDisabled={false} onClick={async () => {
                await saveUserSettings();
              }} children={translate('Save')} />
            </Row>
          </> :
          <Alert maxWidth={viewSize > ViewSize.S ? '40%' : '100%'} severity={dataErrorOrWarningSeverity}>
            {dataErrorOrWarningSeverity === 'danger' ? <div>{herdId} - {translate('Data not available')}</div> :
              <div>{translate('There is no active session yet for this herd. User settings can be customised after the first prediction session.')}</div>
            }
          </Alert>
        }
      </PageContainer>
    </>
  );
};