import styled, { css } from 'styled-components';
import { CompanyTheme } from '@ddw/react-components';

type Props = {
  severity: string;
  maxWidth?: string;
};

export const Alert = styled.div<Props> `
  ${(props) =>
          props.severity === 'success' ?
                  css`
                    background-color: #d1e7dd;
                  `
                  : props.severity === 'primary' ? css`
                    background-color: #cce5ff;
                  `
                  : props.severity === 'secondary' ? css`
                    background-color: #e2e3e5;
                   `
                  : props.severity === 'warning' ? css `
                    background-color: #fff3cd;
                  `
                  : props.severity === 'danger' ? css `
                    background-color: #f8d7da;
                  `
                  : css `background-color: #e4f4fc;`
  }
  max-width: ${props => props.maxWidth ? props.maxWidth : '50%'};
  padding: 2vh;
  border-radius: 5px;
  font-family: ${({ theme }: { theme: CompanyTheme }) => theme.Font.Family};
  margin: 2px;
`;