import React, { useContext, useEffect, useState } from "react";
import { PageContainer } from "../../../component/PageContainer";
import { AuthenticationContext, SnackbarMessageType, SnackbarMessagesContext, ViewContext } from "@ddw/react-framework";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { HerdProfileContext } from "../../../context";
import { PagesContext } from "../../../context/PagesContext";
import { visioPages } from "../../pages";
import { Col, Row, SubHeader, IconContainer, Label, Alert, Span, Paragraph, Header } from "../../components";
import { AtIcon, GearFillIcon, PeopleFillIcon } from "../../../component/Icons";
import { Button, ButtonType, DDWApplication, Dialog, DialogButtonsRightSide, DialogContent, DialogFooter, DialogHeader, DialogSize, IconLib, Select, SelectOption, ViewSize } from "@ddw/react-components";
import { InputNumber, Radio, Switch } from "antd";
import { SubParagraph } from "../../components/sub-paragraph";
import { VisioSettings } from "../../../typings/visio/types";
import { Api } from "../../../services/api";
import { BullsEyeIcon } from "../../../component/Icons/BullsEyeIcon";
import { HerdSelectorSelectParent } from "../../../component/HerdSelector/herdselector.styled";
import { VisioNotificationUserContainer } from "../../../component/Visio/VisioNotificationUserContainer/VisioNotificationUserContainer";
import { VisioSettingsUsersContext } from "../../../context/VisioSettingsUsersContext";
import { visioUserToStore } from "../../utils";

export const VisioSettingsPage: React.FC = () => {
    const { t: translate } = useTranslation();
    const { herdId, permissions } = useContext(HerdProfileContext);
    const { user, getAccessToken } = useContext(AuthenticationContext);
    const { addMessageToStack } = useContext(SnackbarMessagesContext);
    const { setPages } = useContext(PagesContext);
    const { viewSize } = useContext(ViewContext);

    const [users, setUsers] = useState<{ [key: string]: any }>({
        'primary': null, 'secondary': []
    });

    const [configuration, setConfiguration] = useState<string>('standard');
    const [settingsData, setSettingsData] = useState<VisioSettings>();

    const selectionCriterionOptions: SelectOption[] = [
        { label: translate('Maximum expected lifetime'), value: '1' },
        { label: translate('Maximum expected lifetime production'), value: '2' },
        { label: translate('Maximum expected production next 365d'), value: '3' }
    ];
    const [selectionCriterion, setSelectionCriterion] = useState<SelectOption>(selectionCriterionOptions[0]);

    const [splitSwitch, setSplitSwitch] = useState<boolean>(false);
    const [cowsSwitch, setCowsSwitch] = useState<boolean>(false);
    const [quotaSwitch, setQuotaSwitch] = useState<boolean>(false);
    const [autofreezeSwitch, setAutofreezeSwitch] = useState<boolean>(false);

    const [splitL1, setSplitL1] = useState<number | null>(null);
    const [splitL2, setSplitL2] = useState<number | null>(null);
    const [splitL3, setSplitL3] = useState<number | null>(null);
    const [splitW, setSplitW] = useState<number | null>(1);
    const [milkingBeds, setMilkingBeds] = useState<number | null>(null);
    const [maxTolerance, setMaxTolerance] = useState<number | null>(10);
    const [cowsW, setCowsW] = useState<number | null>(1);
    const [dailyMilkProd, setDailyMilkProd] = useState<number | null>(null);
    const [quotaW, setQuotaW] = useState<number | null>(1);

    const [showResetPrescriptionDialog, setShowResetPrescriptionDialog] = useState<boolean>(false);
    const [showSaveSettingsDialog, setShowSaveSettingsDialog] = useState<boolean>(false);

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [dataErrorOrWarningSeverity, setDataErrorOrWarningSeverity] = useState('');

    const [bodyComponents, setBodyComponents] = useState<(string | string[])[]>();

    const onSplitSwitchChange = (checked: boolean) => {
        setSplitSwitch(checked);
    };

    const onCowsSwitchChange = (checked: boolean) => {
        setCowsSwitch(checked);
    };

    const onQuotaSwitchChange = (checked: boolean) => {
        setQuotaSwitch(checked);
    };

    const onAutofreezeSwitchChange = (checked: boolean) => {
        setAutofreezeSwitch(checked);
        if (checked) {
            setSplitSwitch(false);
            setCowsSwitch(false);
            setQuotaSwitch(false);
        }
    };

    const resetPrescription = async () => {
        document.body.style.cursor = 'progress';
        const result = await Api.visio.resetPrescription(getAccessToken(), DDWApplication.DairyInsights, herdId, configuration);

        setShowResetPrescriptionDialog(false);

        if (result.success) {
            addMessageToStack({
                messageType: SnackbarMessageType.success,
                message: result.message,
                autoHide: true
            });
            setConfiguration('standard');
        } else {
            addMessageToStack({
                messageType: SnackbarMessageType.error,
                message: translate('There was a problem resetting the prescription.'),
                autoHide: false
            });
            if (result.message === '') {
                setConfiguration(result.data.configuration);
            }
        }
        document.body.style.cursor = 'default';
    };

    const submitOptimisationRequest = async () => {
        document.body.style.cursor = 'progress';
        const result = await Api.visio.submitOptimisationReqeust(getAccessToken(), DDWApplication.DairyInsights, herdId, {
            'splitS': splitSwitch, 'cowsS': cowsSwitch, 'quotaS': quotaSwitch, 'autofreezeS': autofreezeSwitch,
            'splitL1': splitL1, 'splitL2': splitL2, 'splitL3': splitL3, 'splitW': splitW,
            'cowsSpots': milkingBeds, 'cowsTol': maxTolerance, 'cowsW': cowsW,
            'quotaProd': dailyMilkProd, 'quotaW': quotaW, 'selectionCriterion': selectionCriterion.value
        });

        if (result.success) {
            addMessageToStack({
                messageType: SnackbarMessageType.success,
                message: result.message,
                autoHide: true
            });
        } else {
            if (result.message === '') {
                addMessageToStack({
                    messageType: SnackbarMessageType.error,
                    message: translate('There was a problem submitting the optimisation request.'),
                    autoHide: false
                });
            } else {
                addMessageToStack({
                    messageType: SnackbarMessageType.error,
                    message: result.message,
                    autoHide: true
                });
            }
        }
        document.body.style.cursor = 'default';
    };

    const fetchSettings = async () => {
        const result = await Api.visio.fetchSettings(getAccessToken(), DDWApplication.DairyInsights, herdId);

        if (result.success) {
            setSettingsData(result.data);

            if (result.data.settings != null) {
                users['primary'] = visioUserToStore(result.data.settings);
            }

            if (result.data.secSettings != null) {
                for (let i = 0; i < result.data.secSettings.length; i++) {
                    users['secondary'].push(visioUserToStore(result.data.secSettings[i]));
                }
            }
        } else {
            if (result.message !== '') {
                navigate('/home');
                addMessageToStack({
                    messageType: SnackbarMessageType.error,
                    message: translate('There was a problem fetching settings.'),
                    autoHide: false
                });
            } else {
                setDataErrorOrWarningSeverity('danger');
            }
        }
    };

    const fetchOptimiserContent = async () => {
        const result = await Api.visio.fetchOptimiserContent(getAccessToken(), DDWApplication.DairyInsights, herdId);

        if (result.success) {
            setSplitL1(result.data.intPercL1);
            setSplitL2(result.data.intPercL2);
            setSplitL3(result.data.intPercL3Plus);
            setMilkingBeds(result.data.milkingBeds);
            setDailyMilkProd(result.data.quotaProd);
        } else {
            if (result.message !== '') {
                navigate('/home');
                addMessageToStack({
                    messageType: SnackbarMessageType.error,
                    message: translate('There was a problem fetching optimiser content.'),
                    autoHide: false
                });
            } else {
                setDataErrorOrWarningSeverity('danger');
            }
        }
    };

    const saveSettings = async (body: any) => {
        document.body.style.cursor = 'progress';
        const result = await Api.visio.updateSettings(getAccessToken(), DDWApplication.DairyInsights, herdId, body);
        if (result.success) {
            if (result.data.bodyComponents) {
                setShowSaveSettingsDialog(true);
                setBodyComponents(result.data.bodyComponents);
            } else {
                window.location.reload();
                addMessageToStack({
                    messageType: SnackbarMessageType.success,
                    message: translate('Successfully uploaded user settings'),
                    autoHide: true
                });
            }
        } else {
            addMessageToStack({
                messageType: SnackbarMessageType.error,
                message: translate('Could not upload user settings, please contact DDW.'),
                autoHide: true
            });
        }
        document.body.style.cursor = 'default';
    };

    useAsyncEffect(async () => {
        setPages(visioPages(translate, permissions.visio, user !== undefined ? user['profile']['UserEmail'] : ''));
        if (herdId != 0) {
            setLoading(true);
            await fetchSettings();
            await fetchOptimiserContent();
            setLoading(false);
        }
    }, [herdId]);

    useEffect(() => {
        return () => {
            users['primary'] = null;
            users['secondary'] = [];
            setConfiguration('standard');

            setSelectionCriterion(selectionCriterionOptions[0]);

            setSplitSwitch(false);
            setCowsSwitch(false);
            setQuotaSwitch(false);
            setAutofreezeSwitch(false);

            setSplitL1(null);
            setSplitL2(null);
            setSplitL3(null);
            setSplitW(1);
            setMilkingBeds(null);
            setMaxTolerance(10);
            setCowsW(1);
            setDailyMilkProd(null);
            setQuotaW(1);

            setShowResetPrescriptionDialog(false);
            setShowSaveSettingsDialog(false);
        };
    }, [herdId]);

    return (
        <PageContainer hasFooter={true} loading={loading}>
            {dataErrorOrWarningSeverity === '' ?
                <VisioSettingsUsersContext.Provider value={{ users, setUsers }}>
                    <Row>
                        <Col width="75%">
                            <SubHeader>
                                <Row style={{ alignItems: 'center' }}>
                                    <Col width={'3%'}>
                                        <IconContainer>
                                            <GearFillIcon />
                                        </IconContainer>
                                    </Col>

                                    <Col width={'90%'}>
                                        {translate('VISIO Settings')}
                                    </Col>
                                </Row>
                            </SubHeader>
                        </Col>
                    </Row>

                    <br /><br />

                    <Row>
                        <Label>
                            {translate('Reset prescription')}
                        </Label>
                    </Row>

                    <Row className="mt-3">
                        <Button name={'reset-prescription'} type={ButtonType.Primary} onClick={() => {
                            setShowResetPrescriptionDialog(true);
                        }}>
                            {translate('Reset')}
                        </Button>
                    </Row>
                    <Dialog size={DialogSize.S} show={showResetPrescriptionDialog} setShow={setShowResetPrescriptionDialog} closeOnClickOutside={true}>
                        <DialogHeader>
                            <h3 style={{ margin: '0px' }}>{translate('Reset Inventory prescriptions')}</h3>
                        </DialogHeader>

                        <DialogContent>
                            <Paragraph>
                                {translate('Are you sure? Any simulation or optimisation work will be lost.')}
                            </Paragraph>
                        </DialogContent>

                        <DialogFooter>
                            <DialogButtonsRightSide>
                                <Button name='confirm-modal' type={ButtonType.Primary} onClick={resetPrescription}>
                                    {translate('Confirm')}
                                </Button>

                                <Button name='close-modal' type={ButtonType.Cancel} onClick={() => {
                                    setShowResetPrescriptionDialog(false);
                                }}>
                                    {translate('Close')}
                                </Button>
                            </DialogButtonsRightSide>
                        </DialogFooter>
                    </Dialog>

                    <Row>
                        <SubParagraph>
                            {translate('By selecting this option, any simulation or optimisation work for this herd will be cleared. Only the default inventory predictions will then be accessble.')}
                        </SubParagraph>
                    </Row>

                    <hr className={'footer-line-break mt-4 mb-4'} />

                    <Row className="mt-3 mb-3">
                        <Col>
                            <Label>
                                {translate('Active configuration')}
                            </Label>
                        </Col>

                        <Col>
                            <Radio.Group defaultValue={configuration} value={configuration} onChange={(event) => {
                                setConfiguration(event.target.value);
                            }}>
                                <Radio value={'standard'}>{translate('Standard')}</Radio>
                                <Radio value={'simulation'} disabled={!settingsData?.simulation} style={{ display: 'none' }}>{translate('Simulation')}</Radio>
                                <Radio value={'optimisation'} disabled={!settingsData?.optimisation}>{translate('Optimisation')}</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>

                    <hr className={'footer-line-break mt-4'} />

                    <Row className={'mt-4 mb-4'}>
                        <Col width={'3%'}>
                            <AtIcon />
                        </Col>
                        <Col style={{ fontWeight: 'bold' }} width={'90%'}>
                            {translate('Notification settings')}
                        </Col>
                    </Row>

                    <div style={{ width: viewSize > ViewSize.S ? '50%' : '90%' }}>
                        <VisioNotificationUserContainer settings={settingsData?.settings} primary={true} admin={settingsData?.admin} index={0} />
                    </div>

                    <Row style={{ alignItems: 'center' }} className={'mt-4 mb-4'}>
                        <PeopleFillIcon className={'mr-3'} />
                        <Span>
                            {translate('Secondary users')}
                        </Span>
                    </Row>

                    {users['secondary'] &&
                        users['secondary'].filter((notification: any) => !notification['_removed'])
                            .map((notification: any, index: number) => {
                                return (
                                    <div style={{ width: viewSize > ViewSize.S ? '50%' : '90%' }} key={notification['UniqueIdentifier']}>
                                        <VisioNotificationUserContainer settings={{ ...notification }}
                                            primary={false} admin={settingsData?.admin} index={index + 1} />
                                    </div>
                                );
                            })
                    }

                    <Row>
                        <Col>
                            <Button name="add-user" type={ButtonType.Cancel} isDisabled={!settingsData?.admin} icon={IconLib.add} onClick={() => {
                                users['secondary'].push({
                                    '_confirmed': true,
                                    'HerdProfileId': herdId,
                                    'PrimaryUser': false,
                                    'PrescriptionFrequency': 0,
                                    'PrescriptionNotificationMode': 0,
                                    'NotificationEmail': null,
                                    'NotificationMobile': null,
                                    'NotificationWeekDay': 1,
                                    'email_modified': false,
                                    '_newlyAdded': true,
                                    'UniqueIdentifier': Math.random().toString(36) // this is only used as key for rendering
                                });
                                setUsers({ ...users });
                            }}>
                                {translate('Add user')}
                            </Button>
                        </Col>

                        <Col>
                            <Row>
                                <Button name="save-settings" type={ButtonType.Primary} onClick={async () => {
                                    console.log(users);

                                    let secondaryuserid: number[] = [];
                                    let confirmed: boolean[] = [];
                                    let emailmodified2: boolean[] = [];
                                    let via_2: number[][] = [];
                                    let email_2: string[] = [];
                                    let mobile2: string[] = [];
                                    let freq_2: number[] = [];
                                    let weekday_2: number[] = [];

                                    for (let i = 0; i < users['secondary'].length; i++) {
                                        secondaryuserid.push(users['secondary'][i]['InventoryInsemPrescSecondaryUserId']);
                                        confirmed.push(users['secondary'][i]['_confirmed']);
                                        emailmodified2.push(users['secondary'][i]['email_modified']);

                                        const binMap: string = users['secondary'][i]['PrescriptionNotificationMode'].toString(2).padStart(3, '0');
                                        const notification_via2: number[] = [parseInt(binMap[2]), 2 * parseInt(binMap[1]), 4 * parseInt(binMap[0])];
                                        via_2.push(notification_via2);

                                        email_2.push(users['secondary'][i]['NotificationEmail']);
                                        mobile2.push(users['secondary'][i]['NotificationMobile']);
                                        freq_2.push(users['secondary'][i]['PrescriptionFrequency']);
                                        weekday_2.push(users['secondary'][i]['NotificationWeekDay']);
                                    }

                                    const binMap: string = users['primary']['PrescriptionNotificationMode'].toString(2).padStart(3, '0');
                                    const notification_via1: number[] = [parseInt(binMap[2]), 2 * parseInt(binMap[1]), 4 * parseInt(binMap[0])];

                                    await saveSettings({
                                        'cfg': configuration, 'notification_via1': notification_via1, 'email1': users['primary']['NotificationEmail'],
                                        'mobile1': users['primary']['NotificationMobile'], 'notification_freq1': users['primary']['PrescriptionFrequency'],
                                        'weekday1': users['primary']['NotificationWeekDay'], 'secondaryuserid': secondaryuserid,
                                        'confirmed': confirmed, 'emailmodified2': emailmodified2, 'via_2': via_2, 'email_2': email_2,
                                        'mobile2': mobile2, 'freq_2': freq_2, 'weekday_2': weekday_2, 'firedFromModal': false
                                    });
                                }}>
                                    {translate('Save')}
                                </Button>
                                {showSaveSettingsDialog &&
                                    <Dialog size={DialogSize.S} show={showSaveSettingsDialog} setShow={setShowSaveSettingsDialog} closeOnClickOutside={true}>
                                        <DialogHeader>
                                            <h3 style={{ margin: '0px' }}>{translate('Add secondary user')}</h3>
                                        </DialogHeader>

                                        <DialogContent>
                                            <Paragraph>
                                                {translate('Are you sure?')}
                                            </Paragraph>

                                            {bodyComponents?.map((component: string | string[]) => {
                                                if (typeof component === "string") {
                                                    return (
                                                        <Paragraph>
                                                            {component}
                                                        </Paragraph>
                                                    );
                                                } else {
                                                    return component.map((subComponent: string) => {
                                                        return (
                                                            <Alert severity="secondary" maxWidth="100%">
                                                                {subComponent}
                                                            </Alert>
                                                        );
                                                    });
                                                }
                                            })}
                                        </DialogContent>

                                        <DialogFooter>
                                            <DialogButtonsRightSide>
                                                <Button name='confirm-users-modal' type={ButtonType.Primary} onClick={async () => {
                                                    // let _users: any[] = [];

                                                    // if (users['primary']) {
                                                    //     _users.push({ ...users['primary'] });
                                                    // }

                                                    let secondaryuserid: number[] = [];
                                                    let confirmed: boolean[] = [];
                                                    let emailmodified2: boolean[] = [];
                                                    let via_2: number[][] = [];
                                                    let email_2: string[] = [];
                                                    let mobile2: string[] = [];
                                                    let freq_2: number[] = [];
                                                    let weekday_2: number[] = [];

                                                    for (let i = 0; i < users['secondary'].length; i++) {
                                                        // if (users['secondary'][i]['_confirmed'] && !users['secondary'][i]['removed']) {
                                                        //     _users.push({ ...users['secondary'][i] });
                                                        // }
                                                        secondaryuserid.push(users['secondary'][i]['InventoryInsemPrescSecondaryUserId']);
                                                        confirmed.push(users['secondary'][i]['_confirmed']);
                                                        emailmodified2.push(users['secondary'][i]['email_modified']);

                                                        const binMap: string = users['secondary'][i]['PrescriptionNotificationMode'].toString(2).padStart(3, '0');
                                                        const notification_via2: number[] = [parseInt(binMap[2]), 2 * parseInt(binMap[1]), 4 * parseInt(binMap[0])];
                                                        via_2.push(notification_via2);

                                                        email_2.push(users['secondary'][i]['NotificationEmail']);
                                                        mobile2.push(users['secondary'][i]['NotificationMobile']);
                                                        freq_2.push(users['secondary'][i]['PrescriptionFrequency']);
                                                        weekday_2.push(users['secondary'][i]['NotificationWeekDay']);
                                                    }

                                                    const binMap: string = users['primary']['PrescriptionNotificationMode'].toString(2).padStart(3, '0');
                                                    const notification_via1: number[] = [parseInt(binMap[2]), 2 * parseInt(binMap[1]), 4 * parseInt(binMap[0])];

                                                    await saveSettings({
                                                        'cfg': configuration, 'notification_via1': notification_via1, 'email1': users['primary']['NotificationEmail'],
                                                        'mobile1': users['primary']['NotificationMobile'], 'notification_freq1': users['primary']['PrescriptionFrequency'],
                                                        'weekday1': users['primary']['NotificationWeekDay'], 'secondaryuserid': secondaryuserid,
                                                        'confirmed': confirmed, 'emailmodified2': emailmodified2, 'via_2': via_2, 'email_2': email_2,
                                                        'mobile2': mobile2, 'freq_2': freq_2, 'weekday_2': weekday_2, 'firedFromModal': true
                                                    });
                                                }}>
                                                    {translate('Confirm')}
                                                </Button>

                                                <Button name='cancel-users-modal' type={ButtonType.Cancel} onClick={() => {
                                                    setShowSaveSettingsDialog(false);
                                                }}>
                                                    {translate('Cancel')}
                                                </Button>
                                            </DialogButtonsRightSide>
                                        </DialogFooter>
                                    </Dialog>
                                }
                            </Row>
                        </Col>
                    </Row>

                    <hr className={'footer-line-break mt-4 mb-4'} />

                    <Row className="mb-3">
                        <Col width="75%">
                            <SubHeader>
                                <Row style={{ alignItems: 'center' }}>
                                    <Col width={'3%'}>
                                        <IconContainer>
                                            <BullsEyeIcon />
                                        </IconContainer>
                                    </Col>

                                    <Col width={'90%'}>
                                        {translate('Optimisation')}
                                    </Col>
                                </Row>
                            </SubHeader>
                        </Col>
                    </Row>

                    <Paragraph>
                        {translate("Tell us how we should optimise your inventory planning")}
                    </Paragraph>

                    <SubParagraph>
                        {translate('Submit your specific herd inventory objectives and ask the system to calculate the best strategy parameters. Results will be available within 24h from the request.')}
                    </SubParagraph>

                    <SubHeader className="mt-4">
                        {translate('Objectives')}
                    </SubHeader>

                    <Row>
                        <Col width="20%">
                            <Label>
                                {translate('Lactation group split')}
                            </Label>
                        </Col>

                        <Col>
                            <Switch size="small" checked={splitSwitch} onChange={onSplitSwitchChange} />
                        </Col>
                    </Row>
                    <SubParagraph className="mb-3">
                        {translate('Select the ideal split of milking animal groups (L1/L2/L3), default values autodetected from the current data. If the sum of percentages does not sum up to 100, we will normalise proportions accordingly. Optionally, it is also possible to specify a weight to express the relative importance of this objective.')}
                    </SubParagraph>
                    {splitSwitch &&
                        <Row style={{ alignItems: 'center' }} className="mb-4">
                            <Col width="15%" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {translate('% Parity 1')}
                            </Col>

                            <Col>
                                <InputNumber min={0} max={100} step={1}
                                    value={splitL1} onChange={setSplitL1} />
                            </Col>

                            <Col width="15%" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {translate('% Parity 2')}
                            </Col>

                            <Col>
                                <InputNumber min={0} max={100} step={1}
                                    value={splitL2} onChange={setSplitL2} />
                            </Col>

                            <Col width="15%" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {translate('% Parity 3')}
                            </Col>

                            <Col>
                                <InputNumber min={0} max={100} step={1}
                                    value={splitL3} onChange={setSplitL3} />
                            </Col>

                            <Col width="15%" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {translate('Weight')}
                            </Col>

                            <Col>
                                <InputNumber min={0} max={1} step={0.1}
                                    value={splitW} onChange={setSplitW} />
                            </Col>
                        </Row>
                    }

                    <Row>
                        <Col width="20%">
                            <Label>
                                {translate("Number of milking cows")}
                            </Label>
                        </Col>

                        <Col>
                            <Switch size="small" checked={cowsSwitch} onChange={onCowsSwitchChange} />
                        </Col>
                    </Row>
                    <SubParagraph className="mb-3">
                        {translate('Select the ideal number of concurrently milking cows in your farm. Specify the maximum tolerance that can be accepted during calving waves. Optionally, it is also possible to specify a weight to express the relative importance of this objective.')}
                    </SubParagraph>
                    {cowsSwitch &&
                        <Row style={{ alignItems: 'center' }} className="mb-4">
                            <Col width="15%" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {translate('Number of spots')}
                            </Col>

                            <Col>
                                <InputNumber min={0} max={100000} step={1}
                                    value={milkingBeds} onChange={setMilkingBeds} />
                            </Col>

                            <Col width="15%" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {translate('Max. tolerance (%)')}
                            </Col>

                            <Col>
                                <InputNumber min={0} max={100} step={1}
                                    value={maxTolerance} onChange={setMaxTolerance} />
                            </Col>

                            <Col width="15%" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {translate('Weight')}
                            </Col>

                            <Col>
                                <InputNumber min={0} max={1} step={0.1}
                                    value={cowsW} onChange={setCowsW} />
                            </Col>
                        </Row>
                    }

                    <Row>
                        <Col width="20%">
                            <Label>
                                {translate("Milk quota max. per day")}
                            </Label>
                        </Col>

                        <Col>
                            <Switch size="small" checked={quotaSwitch} onChange={onQuotaSwitchChange} />
                        </Col>
                    </Row>
                    <SubParagraph className="mb-3">
                        {translate('Select the ideal daily bulk milk production of the farm. Optionally, it is also possible to specify a weight to express the relative importance of this objective.')}
                    </SubParagraph>
                    {quotaSwitch &&
                        <Row style={{ alignItems: 'center' }} className="mb-4">
                            <Col width="15%" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {translate('Daily milk prod.')}
                            </Col>

                            <Col>
                                <InputNumber min={0} max={Number.MAX_SAFE_INTEGER} step={100}
                                    value={dailyMilkProd} onChange={setDailyMilkProd} />
                            </Col>

                            <Col width="15%" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {translate('Weight')}
                            </Col>

                            <Col>
                                <InputNumber min={0} max={1} step={0.1}
                                    value={quotaW} onChange={setQuotaW} />
                            </Col>
                        </Row>
                    }

                    <Row>
                        <Col width="20%">
                            <Label>
                                {translate("Autofreeze")}
                            </Label>
                        </Col>

                        <Col>
                            <Switch size="small" checked={autofreezeSwitch} onChange={onAutofreezeSwitchChange} />
                        </Col>
                    </Row>
                    <SubParagraph className="mb-3">
                        {translate('By selecting this option, we will prescribe an inventory strategy that keeps lactation group split and herd milk production as constant as possible for the predicted period. PLEASE NOTE: this option will automatically deselect the previous objectives.')}
                    </SubParagraph>

                    <Row className="mt-2" style={{ alignItems: 'center' }}>
                        <Col width="20%">
                            <Label>
                                {translate('Selection criterion')}
                            </Label>
                        </Col>

                        <Col>
                            <HerdSelectorSelectParent>
                                <Select
                                    name={viewSize > ViewSize.S ? translate('Selection criterion') : ''}
                                    field={`selectionCriterion`}
                                    options={selectionCriterionOptions}
                                    selected={selectionCriterion}
                                    onChange={(selected: SelectOption) => {
                                        setSelectionCriterion(selected);
                                    }}
                                ></Select>
                            </HerdSelectorSelectParent>
                        </Col>
                    </Row>
                    <SubParagraph className="mb-4">
                        {translate('Animal selection criterion for culling decisions.')}
                    </SubParagraph>

                    <Row className="mt-4">
                        <Col>
                            <Button name="submit-optimisation-request" type={ButtonType.Primary} onClick={submitOptimisationRequest}>
                                {translate('Submit optimisation request')}
                            </Button>
                        </Col>
                    </Row>
                </VisioSettingsUsersContext.Provider> :
                <Alert maxWidth={viewSize > ViewSize.S ? '25%' : '75%'} severity={dataErrorOrWarningSeverity}>
                    {herdId} - {translate('Data not available')}
                </Alert>
            }
        </PageContainer>
    );
};