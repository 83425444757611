import { DDWApplication, parseError, putHeaders, statusHelper, toJSON } from '@ddw/react-components';
import { ServiceResult } from '@ddw/react-framework/build/src/types';
import { DataQuality, DataQualityFromDashboard } from '../../typings/types';
import { urlState } from '../../pages/utils';

export const fetchDataQuality = (accessToken: string, application: DDWApplication, herdId: number): Promise<ServiceResult<DataQuality>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `herds/` + herdId + '/quality';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const fetchDataQualityFromDashboard = (accessToken: string, application: DDWApplication, herdId: number): Promise<ServiceResult<DataQualityFromDashboard>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `herds/` + herdId + '/quality-dashboard';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};
