import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HerdProfileContext } from "../../context";
import { AuthenticationContext, ViewContext } from "@ddw/react-framework";
import { PagesContext } from "../../context/PagesContext";
import { Button, ButtonType, DDWApplication, IconLib, Select, SelectOption, ViewSize } from "@ddw/react-components";
import { CurrentLactationPredictionRelativeToTheFuturePart } from "../../typings/herdfolio/types";
import useAsyncEffect from "use-async-effect";
import { Api } from "../../services/api";
import { PageContainer } from "../../component/PageContainer";
import { Alert, Col, Paragraph, Row } from "../components";
import { CurrentLactationPredictionRelativeToTheFuturePartChart } from "../../component/Charts/Herdfolio/Predicted/TransitionCowRisk/CurrentLactationPredictionRelativeToTheFuturePartChart";
import { NextLactationAnimalsGroupedByParityChart } from "../../component/Charts/Herdfolio/Predicted/TransitionCowRisk/NextLactationAnimalsGroupedByParityChart";
import { FarmPopulationEvolution, HerdProductionForecastData } from "../../typings/visio/types";
import { VisioHerdProductionForecastChart } from "../../component/Charts/Visio/VisioHerdProductionForecastChart/VisioHerdProductionForecastChart";
import { Card, Radio } from "antd";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { HerdSelectorSelectParent } from "../../component/HerdSelector/herdselector.styled";
import { HistoricalFarmPopulationEvolutionChart } from "../../component/Charts/Visio/VisioAnimalPerformanceChart/HistoricalFarmPopulationEvolutionChart";
import { ForecastedFarmPopulationEvolutionChart } from "../../component/Charts/Visio/VisioAnimalPerformanceChart/ForecastedFarmPopulationEvolutionChart";
import { mainPages } from "../pages";

export const HerdfolioPredictedPage: React.FC = () => {
    const { t: translate } = useTranslation();
    const { herdId, permissions } = useContext(HerdProfileContext);
    const { getAccessToken } = useContext(AuthenticationContext);
    const { setPages } = useContext(PagesContext);
    const { viewSize } = useContext(ViewContext);

    const categorySelectionOptions: SelectOption[] = [
        { label: translate('Transition Cow Risk'), value: 'transition-cow-risk' },
        { label: translate('Milk production'), value: 'milk' },
        { label: translate('Inventory'), value: 'inventory' }
    ];
    const [categorySelection, setCategorySelection] = useState<SelectOption>(categorySelectionOptions[0]);

    const [loading, setLoading] = useState(true);

    ////////// Transition cow disease

    const [currentLactationPrediction, setCurrentLactationPrediction] = useState<CurrentLactationPredictionRelativeToTheFuturePart>({
        tracesCur: [], tracesNext: []
    });

    const fetchLactationPredictionData = async () => {
        const result = await Api.herdfolio.fetchCurrentLactationPredictionRelativeToTheFuturePart(getAccessToken(), DDWApplication.DairyInsights, herdId);

        if (result.success) {
            setCurrentLactationPrediction(result.data);
        }
    };

    ////////// Milk

    const [herdProductionForecastData, setHerdProductionForecastData] = useState<HerdProductionForecastData>({
        producedMilkIndex: [],
        producedMilkValues: [],
        producedMilkName: '',
        trace2x: [],
        trace2y: [],
        trace2name: [],
        maximumYAxisRangeValue: 100
    });

    const fetchHerdProductionForecastData = async () => {
        const result = await Api.visio.fetchHerdProductionForecastData(getAccessToken(), DDWApplication.DairyInsights, herdId);
        if (result.success) {
            setHerdProductionForecastData(result.data);
        }
    };

    ////////// Inventory

    const populationDisplayOptions: SelectOption[] = [
        { label: translate('Absolute'), value: 'abs' },
        { label: translate('Relative'), value: 'rel' },
        { label: translate('Cumulative'), value: 'cum' }
    ];
    const [populationDisplay, setPopulationDisplay] = useState<SelectOption>(populationDisplayOptions[0]);

    const projectionOptions: SelectOption[] = [
        { label: translate('Hide'), value: '0' },
        { label: translate('Show'), value: '1' }
    ];
    const [longTermProjection, setLongTermProjection] = useState<SelectOption>(projectionOptions[0]);
    const [longTermProjectionDisplayStyle, setLongTermProjectionDisplayStyle] = useState<string>('inline');

    const [configuration, setConfiguration] = useState<string>('standard');

    const [infoCardTitle, setInfoCardTitle] = useState<string>('');
    const [infoCardColor, setInfoCardColor] = useState<string>('#6c757d');

    const [inventoryStatsData, setInventoryStatsData] = useState<FarmPopulationEvolution>({
        trace1x: [],
        trace1y: [],
        trace2x: [],
        trace2y: [],
        trace3y: [],
        trace4x: [],
        trace4y: [],
        tAxis: [],
        simulation: false,
        optimisation: false,
        displayLongTermProjection: true,
        infoCardText: ''
    });

    const [displayAnomaly, setDisplayAnomaly] = useState<boolean>(false);
    const [displayPredictionInfo, setDisplayPredictionInfo] = useState<boolean>(false);

    const [inventoryDataErrorOrWarningSeverity, setInventoryDataErrorOrWarningSeverity] = useState('');

    const fetchInventoryStats = async () => {
        const result = await Api.visio.fetchStats(
            getAccessToken(),
            DDWApplication.DairyInsights,
            herdId,
            populationDisplay.value,
            1, // history -- needed on backend, 1 is default value
            Number(longTermProjection.value),
            configuration
        );

        if (result.success) {
            setInventoryStatsData(result.data);
            if (result.message === '') {
                setInventoryDataErrorOrWarningSeverity('');
            } else {
                setInventoryDataErrorOrWarningSeverity('warning');
                setLongTermProjectionDisplayStyle('none');
            }
        }
    };

    useAsyncEffect(async () => {
        if (herdId != 0) {
            document.body.style.cursor = 'progress';
            await fetchInventoryStats();
            document.body.style.cursor = 'default';
        }

        if (configuration === 'simulation') {
            setInfoCardTitle(translate('Custom Simulation'));
            setInfoCardColor('#007bff');
        } else if (configuration === 'optimisation') {
            setInfoCardTitle(translate('Custom Optimisation'));
            setInfoCardColor('#28a745');
        } else {
            setInfoCardTitle(translate('Standard prediction'));
            setInfoCardColor('#6c757d');
        }
    }, [populationDisplay, longTermProjection, configuration]);

    useEffect(() => {
        if (populationDisplay.value === 'abs' && inventoryStatsData.displayLongTermProjection) {
            setLongTermProjectionDisplayStyle('inline');
        } else {
            setLongTermProjectionDisplayStyle('none');
        }
    }, [populationDisplay]);

    // Fetch data

    useAsyncEffect(async () => {
        // setPages(mainPages(translate, permissions.visio));
        if (herdId != 0) {
            setLoading(true);

            await fetchLactationPredictionData();
            await fetchHerdProductionForecastData();
            await fetchInventoryStats();

            setLoading(false);
        }
    }, [herdId]);

    // Clean-up
    useEffect(() => {
        return () => {
            setCategorySelection(categorySelectionOptions[0]);
            setDisplayAnomaly(false);
            setDisplayPredictionInfo(false);
            setPopulationDisplay(populationDisplayOptions[0]);
            setLongTermProjection(projectionOptions[0]);
            setLongTermProjectionDisplayStyle('inline');
            setConfiguration('standard');
            setInfoCardTitle('');
            setInfoCardColor('#6c757d');
            setInventoryStatsData({
                trace1x: [],
                trace1y: [],
                trace2x: [],
                trace2y: [],
                trace3y: [],
                trace4x: [],
                trace4y: [],
                tAxis: [],
                simulation: false,
                optimisation: false,
                displayLongTermProjection: true,
                infoCardText: ''
            });

            setCurrentLactationPrediction({
                tracesCur: [], tracesNext: []
            });

            setHerdProductionForecastData({
                producedMilkIndex: [],
                producedMilkValues: [],
                producedMilkName: '',
                trace2x: [],
                trace2y: [],
                trace2name: [],
                maximumYAxisRangeValue: 100
            });
        };
    }, [herdId]);

    return (
        <PageContainer hasFooter={true} loading={loading}>
            <Row>
                <div style={{ width: viewSize > ViewSize.S ? '450px' : '60%' }}>
                    <Select name={translate('Stats')} field={`stats`} options={categorySelectionOptions} selected={categorySelection}
                        onChange={(selected) => {
                            setCategorySelection(selected);
                        }} />
                </div>
            </Row>

            {categorySelection.value === 'milk' ?
                <>
                    {permissions.visio ?
                        <Col width={viewSize > ViewSize.S ? "70%" : "100%"} style={viewSize > ViewSize.S ? { marginRight: 'auto', marginLeft: 'auto' } : {}}>
                            <VisioHerdProductionForecastChart {...herdProductionForecastData} />
                        </Col> :
                        <Col width={viewSize > ViewSize.S ? "50%" : "100%"}>
                            <Alert maxWidth="100%" severity="primary" style={{ textAlign: 'center' }}>
                                {translate("Purchase Predicta Visio in order to see herd production forecast")}
                            </Alert>
                        </Col>
                    }
                </> :
                categorySelection.value === 'inventory' ?
                    <>
                        <Row style={{ alignItems: 'center', display: permissions.visio ? '' : 'none' }}>
                            <Col width={viewSize > ViewSize.S ? '15%' : '30%'}>
                                <Paragraph>{translate('Show prediction')}</Paragraph>
                            </Col>

                            <Col>
                                <Radio.Group defaultValue={configuration} value={configuration} onChange={(event) => {
                                    setConfiguration(event.target.value);
                                }}>
                                    <Radio value={'standard'}>{translate('Standard')}</Radio>
                                    <Radio value={'simulation'} disabled={!inventoryStatsData.simulation} style={{ display: 'none' }}>{translate('Simulation')}</Radio>
                                    <Radio value={'optimisation'} disabled={!inventoryStatsData.optimisation}>{translate('Optimisation')}</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>

                        <Row style={{ display: permissions.visio ? '' : 'none' }}>
                            <Col width="100%">
                                <Row className={"mt-3"}>
                                    <Col width="100%">
                                        <Button name={`prediction-info`}
                                            icon={displayPredictionInfo ? IconLib.expandLess : IconLib.expandMore}
                                            type={ButtonType.Cancel}
                                            onClick={() => setDisplayPredictionInfo(!displayPredictionInfo)}>
                                            {translate("Prediction info")}
                                        </Button>
                                    </Col>
                                </Row>

                                <Row className={"mt-2"}>
                                    <Col width={viewSize > ViewSize.S ? "50%" : '100%'}>
                                        {displayPredictionInfo &&
                                            <Card className='info-card'
                                                type={'inner'}
                                                style={{ border: '1px solid ' + infoCardColor, float: 'left' }}
                                                title={translate("What do I see?")}>
                                                <h3>{infoCardTitle}</h3>
                                                <Paragraph style={{ whiteSpace: 'pre-wrap', lineHeight: 0.9 }}>{inventoryStatsData.infoCardText}</Paragraph>
                                            </Card>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        {inventoryDataErrorOrWarningSeverity === 'warning' &&
                            <Row style={{ display: permissions.visio ? '' : 'none' }}>
                                <Col width="100%">
                                    <Row className={"mt-3"}>
                                        <Col width="100%">
                                            <Button name={`anomaly-identified`}
                                                icon={displayAnomaly ? IconLib.expandLess : IconLib.expandMore}
                                                onClick={() => setDisplayAnomaly(!displayAnomaly)}>
                                                {translate("ATTENTION (Anomaly Identified) - We identified suspicious number of animals in historical data")}
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Row className="mt-2 w-100">
                                        <Col width="100%">
                                            {displayAnomaly &&
                                                <Card className='info-card'
                                                    type={'inner'}
                                                    style={{ border: '1px solid #ffc107', float: "left" }}
                                                    title={translate("List of impacted compartment groups and information about what went wrong:")}>
                                                    <ReactMarkdown remarkPlugins={[remarkGfm]} children={inventoryStatsData.msgAnomalyReportItem} />
                                                </Card>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        }

                        {viewSize > ViewSize.S ?
                            <>
                                <Row className={"mt-3"} style={{ display: permissions.visio ? '' : 'none' }}>
                                    <Col>
                                        <HerdSelectorSelectParent>
                                            <Select name={translate('Display population')} field={`population`} options={populationDisplayOptions} selected={populationDisplay}
                                                onChange={(selected) => {
                                                    setPopulationDisplay(selected);
                                                }} />
                                        </HerdSelectorSelectParent>
                                    </Col>

                                    <Col style={{ display: longTermProjectionDisplayStyle }}>
                                        <HerdSelectorSelectParent>
                                            <Select name={translate('Long-term projection')} field={`projection`} options={projectionOptions} selected={longTermProjection}
                                                onChange={(selected) => {
                                                    setLongTermProjection(selected);
                                                }} />
                                        </HerdSelectorSelectParent>
                                    </Col>
                                </Row>
                            </> :
                            <>
                                <Row className={"mt-3"} style={{ display: permissions.visio ? '' : 'none' }}>
                                    <Col width="90%">
                                        <div>
                                            <Select name={translate('Display population')} field={`population`} options={populationDisplayOptions} selected={populationDisplay}
                                                onChange={(selected) => {
                                                    setPopulationDisplay(selected);
                                                }} />
                                        </div>
                                    </Col>
                                </Row>

                                <Row style={{ display: permissions.visio ? '' : 'none' }}>
                                    <Col width="90%">
                                        <div>
                                            <Select name={translate('Long-term projection')} field={`projection`} options={projectionOptions} selected={longTermProjection}
                                                onChange={(selected) => {
                                                    setLongTermProjection(selected);
                                                }} />
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }

                        {viewSize > ViewSize.S ?
                            <>
                                <Row>
                                    {permissions.visio ?
                                        <Col width={"70%"} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                            <ForecastedFarmPopulationEvolutionChart trace1x={inventoryStatsData?.trace1x} trace1y={inventoryStatsData?.trace1y}
                                                trace2x={inventoryStatsData.trace2x} trace2y={inventoryStatsData.trace2y} trace3y={inventoryStatsData.trace3y}
                                                trace4x={inventoryStatsData.trace4x} trace4y={inventoryStatsData.trace4y} tAxis={inventoryStatsData.tAxis}
                                                population={populationDisplay.value} />
                                        </Col> :
                                        <Col width={"50%"}>
                                            <Alert maxWidth="100%" severity="primary" style={{ textAlign: 'center' }}>
                                                {translate("Purchase Predicta Visio in order to see farm population evolution")}
                                            </Alert>
                                        </Col>
                                    }
                                </Row>
                            </> :
                            <>
                                <Row>
                                    {permissions.visio ?
                                        <Col width="100%">
                                            <ForecastedFarmPopulationEvolutionChart trace1x={inventoryStatsData?.trace1x} trace1y={inventoryStatsData?.trace1y}
                                                trace2x={inventoryStatsData.trace2x} trace2y={inventoryStatsData.trace2y} trace3y={inventoryStatsData.trace3y}
                                                trace4x={inventoryStatsData.trace4x} trace4y={inventoryStatsData.trace4y} tAxis={inventoryStatsData.tAxis}
                                                population={populationDisplay.value} />
                                        </Col> :
                                        <Col width="90%">
                                            <Alert maxWidth="100%" severity="primary">
                                                {translate("Purchase Predicta Visio in order to see farm population evolution")}
                                            </Alert>
                                        </Col>
                                    }
                                </Row>
                            </>
                        }
                    </> :
                    <>
                        {permissions.visio ?
                            <>
                                {viewSize > ViewSize.S ?
                                    <>
                                        <Row>
                                            <Col width="50%">
                                                <CurrentLactationPredictionRelativeToTheFuturePartChart data={currentLactationPrediction} />
                                            </Col>

                                            <Col width="50%">
                                                <NextLactationAnimalsGroupedByParityChart data={currentLactationPrediction} />
                                            </Col>
                                        </Row>
                                    </> :
                                    <>
                                        <Row>
                                            <Col width="100%">
                                                <CurrentLactationPredictionRelativeToTheFuturePartChart data={currentLactationPrediction} />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col width="100%">
                                                <NextLactationAnimalsGroupedByParityChart data={currentLactationPrediction} />
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </> :
                            <Col width={viewSize > ViewSize.S ? "50%" : "90%"}>
                                <Alert maxWidth="100%" severity="primary" style={{ textAlign: 'center' }}>
                                    {translate("Purchase Predicta Visio in order to see lactation predictions.")}
                                </Alert>
                            </Col>
                        }
                    </>
            }
        </PageContainer>
    );
};