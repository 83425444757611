import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Span } from '../../../pages/components';
import { Checkbox } from 'antd';

export const IssueCardChecklist: React.FC<any> = ({ ignored, disease, changeTreatment }) => {
  const { t: translate } = useTranslation();

  const [ignoredChecked, setIgnoredChecked] = useState(ignored);

  const handleIgnoredChange = (event: any) => {
    setIgnoredChecked(event.target.checked);
    changeTreatment({
      disease: parseInt(disease),
      action: event.target.checked ? 0 : null
    });
  };

  return (
    <>
      <Row style={{ marginTop: '5px' }}>
        <Checkbox checked={ignoredChecked}
                  onChange={handleIgnoredChange}
        >
          <Span>{translate('Ignored')}</Span>
        </Checkbox>
      </Row>
    </>
  );
};