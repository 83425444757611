import React, { useEffect, useRef } from 'react';
import * as Plotly from 'plotly.js';
import { useTranslation } from 'react-i18next';
import { Data, Layout } from 'plotly.js';
import { getObjectValues, setBarColour } from '../../../../pages/utils';

export const RiskProfileGraph: React.FC<any> = ({ record }) => {
  const { t: translate } = useTranslation();

  const chartRef = useRef<HTMLDivElement>(null);

  const data: Data[] = [
    {
      x: ['DA', 'GAST', 'KET', 'MAST', 'METR', 'INFC', 'OTH'],
      y: getObjectValues<number>(record['RiskProfile']),
      type: 'bar',
      name: record['CowNumber'],
      hoverinfo: 'skip',
      marker: {
        color: Array.from({ length: 7 }, (_, i) => setBarColour(i + 1))
      }
    }
  ];

  const layout: Partial<Layout> = {
    margin: { l: 10, r: 10, b: 15, t: 30 },
    title: {
      text: translate('Risk profile'),
      x: 0.5,
      y: 0.98,
      xanchor: 'center',
      yanchor: 'top',
      font: { size: 14, color: '#000000' }
    },
    xaxis: {
      showgrid: false,
      zeroline: true,
      visible: true,
      tickfont: { size: 9 },
      tickangle: 0
    },
    yaxis: {
      range: [0, 1],
      showgrid: false,
      zeroline: false,
      visible: false
    },
    height: 150,
    paper_bgcolor: 'rgba(0,0,0,0)',
    plot_bgcolor: 'rgba(0,0,0,0)'
  };

  useEffect(() => {
    if (chartRef.current) {
      Plotly.newPlot(chartRef.current, data, layout, { responsive: true, displayModeBar: false });
    }
  }, []);

  return (
    <div ref={chartRef} />
  );
};