import { DDWApplication, parseError, putHeaders, statusHelper, toJSON } from '@ddw/react-components';
import { ServiceResult } from '@ddw/react-framework/build/src/types';
import { PredictaUserSettings } from '../../typings/types';
import { urlState } from '../../pages/utils';

export const fetchUserSettings = (accessToken: string, application: DDWApplication, herdSelected: number): Promise<ServiceResult<PredictaUserSettings>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  headers.set('herd-selected', String(herdSelected));
  const url = urlState.url + `users/settings`;
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const updateUserSettings = (
  accessToken: string,
  application: DDWApplication,
  herdSelected: number,
  newSettings: PredictaUserSettings): Promise<ServiceResult<PredictaUserSettings>> => {

  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  headers.set('herd-selected', String(herdSelected));
  const url = urlState.url + `users/settings`;
  return fetch(url, { method: 'PUT', headers, body: JSON.stringify(newSettings) })
    .then(statusHelper)
    .then(toJSON)
    .catch(parseError);
};