import { ViewContext } from "@ddw/react-framework";
import Plotly, { Data, Layout } from "plotly.js";
import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { PLOTLY_TEMPLATE, getRandomHexColor } from "../../../../../pages/static";
import { getObjectKeys } from "../../../../../pages/utils";

export const AverageGroupSizePerMonth: React.FC<any> = (props) => {
    const { t: translate } = useTranslation();
    const { viewSize } = useContext(ViewContext);

    const chartRef = useRef<HTMLDivElement>(null);

    //@ts-ignore
    const data: Data[] = getObjectKeys(props.results['values']['results'][0]).map((groupId: any) => (
        {
            x: props.period.map((value: string) => translate(value.substring(0, 3)) + value.substring(3)),
            y: props.results['values']['results'].map((elem: any) => elem[groupId]['count']),
            name: translate('Group') + ' ' + props.results['values']['results'][0][groupId]['GroupName'],
            opacity: 1.0,
            type: 'bar',
            marker: {
                color: getRandomHexColor()
            },
            hovertemplate: translate('%{x}, %{y} animals')
        }));

    const layout: Partial<Layout> = {
        title: translate("Average group size per month"),
        showlegend: true,
        barmode: 'stack',
        hovermode: 'closest',
        template: PLOTLY_TEMPLATE
    };

    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
        }
    }, [viewSize]);

    return (
        <div ref={chartRef} />
    );
};