import React, { useContext } from 'react';
import { createTestIdAttributeValue, Select, SelectOption, ViewSize } from '@ddw/react-components';
import { ViewContext, MonitoringContext } from '@ddw/react-framework';

import { useTranslation } from 'react-i18next';
import { HerdProfileContext } from '../../context';
import { HerdSelectorSelectParent } from './herdselector.styled';

export const HerdSelector = () => {
  const { herds, herdId, setHerdId, setPermissions } = useContext(HerdProfileContext);
  const { t: translate } = useTranslation();
  const { viewSize } = useContext(ViewContext);
  const { logEvent } = useContext(MonitoringContext);
  const options: SelectOption[] = herds.data.map((herd) => {
    return { value: herd.id.toString(), label: herd.id.toString() + " - " +  herd.name };
  });

  const onHerdSelectorChange = (selected: SelectOption) => {
    if (selected.value) {
      logEvent('Change Herd in Predicta', {
        previousHerdId: [herdId],
        herdId: [Number(selected.value)]
      });
    }
    // the setting of the herd id in session storage allows for herd to remain selected after page refresh
    sessionStorage.setItem('herdId', String(Number(selected.value)));
    const herdPermissions = herds.data.find((herdProfile) => herdProfile.id === Number(selected.value))!.permissions;
    sessionStorage.setItem('preventionPermission', String(herdPermissions.prevention));
    sessionStorage.setItem('diagnosisPermission', String(herdPermissions.diagnosis));
    sessionStorage.setItem('visioPermission', String(herdPermissions.visio));
    setPermissions(herdPermissions);
    setHerdId(Number(selected.value));
  };

  return (
    <HerdSelectorSelectParent>
      <Select
        key={createTestIdAttributeValue('header', Math.random().toString(36).substring(7))}
        field={`herdId`}
        name={viewSize <= ViewSize.S ? translate('Herd') : ''}
        options={options}
        selected={options.find((o) => o.value === herdId.toString())}
        onChange={(selected: SelectOption) => {
          onHerdSelectorChange(selected);
        }}
        loading={herds.loading}
        error={herds.error || (herds.data.length === 0 && !herds.loading) ? translate('No herds') : undefined}
      />
    </HerdSelectorSelectParent>
  );
};
