import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HerdProfileContext } from '../../../../context';
import { AuthenticationContext, SnackbarMessagesContext, SnackbarMessageType, ViewContext } from '@ddw/react-framework';
import { PageContainer } from '../../../../component/PageContainer';
import { Alert, Col, IconContainer, Label, Row, SubHeader } from '../../../components';
import { AtIcon, GearFillIcon, InfoCircleFillIcon, SlidersIcon } from '../../../../component/Icons';
import { Button, ButtonType, DDWApplication, RadioButtons, SelectOption, ViewSize } from '@ddw/react-components';
import useAsyncEffect from 'use-async-effect';
import { Api } from '../../../../services/api';
import { useNavigate } from 'react-router-dom';
import { DiseaseSettings } from '../../../../component/DiseaseSettings';
import { HERD_SETTINGS_DIS_NAMES, NOTIFICATION_DEFAULTS } from '../../../static';
import { getObjectKeys, shortenIfPossible, userToStore } from '../../../utils';
import { HerdSettings, SavePreventionSettings } from '../../../../typings/types';
import { Checkbox, Input, InputNumber, Space } from 'antd';
import { DismissibleCard } from '../../../../component';
import { Tooltip } from '@mui/material';
import { NotificationSettingsDialog } from '../../../../component/NotificationSettingsDialog';
import { HerdSettingsUsersContext } from '../../../../context/HerdSettingsUsersContext';
import { DiseaseSettingsOptionsContext } from '../../../../context/DiseaseSettingsOptionsContext';
import { PagesContext } from '../../../../context/PagesContext';
import { guardianPages } from '../../../pages';

export const GuardianPreventionSettingsPage: React.FC = () => {
  const { t: translate } = useTranslation();
  const { herdId, permissions } = useContext(HerdProfileContext);
  const { user, getAccessToken } = useContext(AuthenticationContext);
  const { addMessageToStack } = useContext(SnackbarMessagesContext);
  const { setPages } = useContext(PagesContext);
  const { viewSize } = useContext(ViewContext);

  const [loading, setLoading] = useState(true);
  const [dataErrorOrWarningSeverity, setDataErrorOrWarningSeverity] = useState('');

  const [notificationOption, setNotificationOption] = useState<SelectOption>({ label: '', value: '' });
  const [herdSettings, setHerdSettings] = useState<HerdSettings>();

  const notificationsOptions: SelectOption[] = [
    { label: translate('All together'), value: '1' },
    { label: translate('Separately for each disease'), value: '0' }
  ];

  const [uniqueNotificationDays, setUniqueNotificationDays] = useState<number | null>(null);
  const [uniqueNotificationDaysToDryOff, setUniqueNotificationDaysToDryOff] = useState<number | null>(null);

  const [notificationDaysModeBeforeCalvingChecked, setNotificationDaysModeBeforeCalvingChecked] = useState<boolean>(true);
  const [notificationDaysModeBeforeDryOff, setNotificationDaysModeBeforeDryOff] = useState<boolean>(false);
  const [notificationDaysModeBeforeDryOffDisabled, setNotificationDaysModeBeforeDryOffDisabled] = useState<boolean>(false);

  const [uniqueSummary, setUniqueSummary] = useState('');

  const [showUniqueNotificationSettingsDialog, setShowUniqueNotificationSettingsDialog] = useState(false);

  const [showSaveHint, setShowSaveHint] = useState(false);

  const navigate = useNavigate();

  const [users, setUsers] = useState<{ [key: string]: any }>({
    'unique': { 'primary': null, 'secondary': [] },
    '1': { 'primary': null, 'secondary': [] },
    '3': { 'primary': null, 'secondary': [] },
    '4': { 'primary': null, 'secondary': [] },
    '5': { 'primary': null, 'secondary': [] },
    '6': { 'primary': null, 'secondary': [] },
    '7': { 'primary': null, 'secondary': [] }
  });

  const [diseaseSettingsOptions, setDiseaseSettingsOptions] = useState<{ [key: string]: any }>({
    '1': {},
    '3': {},
    '4': {},
    '5': {},
    '6': {},
    '7': {}
  });

  const saveSettings = async (data: SavePreventionSettings): Promise<void> => {
    document.body.style.cursor = 'progress';
    const result = await Api.herdSettings.saveSettings(getAccessToken(), DDWApplication.DairyInsights, herdId, data);
    if (result.success) {
      addMessageToStack({
        messageType: SnackbarMessageType.success,
        message: translate('Successfully uploaded user settings.'),
        autoHide: true
      });
      setShowSaveHint(false);
      cleanUp();
      await fetchSettings();
    } else {
      addMessageToStack({
        messageType: SnackbarMessageType.error,
        message: translate('Could not upload user settings, please contact DDW.'),
        autoHide: true
      });
    }
    document.body.style.cursor = 'default';
  };

  useEffect(() => {
    if (notificationOption.value === '1') {
      setNotificationDaysModeBeforeDryOffDisabled(true);
    } else {
      setNotificationDaysModeBeforeDryOffDisabled(false);
    }
  }, [notificationOption]);

  const fetchSettings = async () => {
    setLoading(true);
    const result = await Api.herdSettings.fetchHerdSettings(getAccessToken(), DDWApplication.DairyInsights, herdId);
    if (result.success) {
      if (result.message === '') {
        setHerdSettings(result.data);
        setNotificationOption(result.data.herdSettings['UniqueNotificationSettings'] === true ? notificationsOptions[0] : notificationsOptions[1]);
        setUniqueNotificationDays(result.data.notificationUniquePrimary !== null ? result.data.notificationUniquePrimary.NotificationDaysToCalving : NOTIFICATION_DEFAULTS['NotificationDaysToCalving']);
        setUniqueNotificationDaysToDryOff(result.data.notificationUniquePrimary !== null && result.data.notificationUniquePrimary.NotificationDaysToDryOff !== null ? result.data.notificationUniquePrimary.NotificationDaysToDryOff : NOTIFICATION_DEFAULTS['NotificationDaysToDryOff']);
        setNotificationDaysModeBeforeCalvingChecked(result.data.notificationUniquePrimary !== null ? !result.data.notificationUniquePrimary.NotificationDaysMode : true);
        setNotificationDaysModeBeforeDryOff(result.data.notificationUniquePrimary !== null ? result.data.notificationUniquePrimary.NotificationDaysMode : false);
        setUniqueSummary((result.data.uniqueSummary === undefined || result.data.uniqueSummary === null) ? '' : result.data.uniqueSummary);
        setDataErrorOrWarningSeverity('');

        if (result.data.notificationUniquePrimary != null) {
          users['unique']['primary'] = userToStore(result.data.notificationUniquePrimary);
        }
        if (result.data.notificationUniqueSecondary != null) {
          for (let i = 0; i < result.data.notificationUniqueSecondary.length; i++) {
            users['unique']['secondary'].push(userToStore(result.data.notificationUniqueSecondary[i]));
          }
        }

        if (result.data.specificPrimaryDict != null) {
          for (let i = 0; i < result.data.activeModels.length; i++) {
            if (result.data.specificPrimaryDict[result.data.activeModels[i]] !== null) {
              users[result.data.activeModels[i]]['primary'] = userToStore(result.data.specificPrimaryDict[result.data.activeModels[i]]);
            }
          }
        }

        if (result.data.specificSecondaryDict != null) {
          for (let i = 0; i < result.data.activeModels.length; i++) {
            if (result.data.specificSecondaryDict[result.data.activeModels[i]] != null) {
              for (let j = 0; j < result.data.specificSecondaryDict[result.data.activeModels[i]].length; j++) {
                users[result.data.activeModels[i]]['secondary'].push(userToStore(result.data.specificSecondaryDict[result.data.activeModels[i]][j]));
              }
            }
          }
        }

        setUsers({ ...users });
      } else {
        setDataErrorOrWarningSeverity('warning');
      }
    } else {
      if (result.message !== '') {
        navigate('/home');
        addMessageToStack({
          messageType: SnackbarMessageType.error,
          message: translate('There was a problem fetching herd settings.'),
          autoHide: false
        });
      } else {
        setDataErrorOrWarningSeverity('danger');
      }
    }
    setLoading(false);
  };

  useAsyncEffect(async () => {
    setPages(guardianPages(translate, permissions.prevention, permissions.diagnosis, permissions.visio, user !== undefined ? user['profile']['UserEmail'] : ''));
    if (herdId != 0) {
      await fetchSettings();
    }
  }, [herdId]);

  const cleanUp = () => {
    setShowSaveHint(false);

    users['unique']['primary'] = null;
    users['unique']['secondary'] = [];
    users['1']['primary'] = null;
    users['1']['secondary'] = [];
    users['3']['primary'] = null;
    users['3']['secondary'] = [];
    users['4']['primary'] = null;
    users['4']['secondary'] = [];
    users['5']['primary'] = null;
    users['5']['secondary'] = [];
    users['6']['primary'] = null;
    users['6']['secondary'] = [];
    users['7']['primary'] = null;
    users['7']['secondary'] = [];

    diseaseSettingsOptions['1'] = {};
    diseaseSettingsOptions['3'] = {};
    diseaseSettingsOptions['4'] = {};
    diseaseSettingsOptions['5'] = {};
    diseaseSettingsOptions['6'] = {};
    diseaseSettingsOptions['7'] = {};
  };

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, [herdId]);

  return (
    <>
      <PageContainer hasFooter={false} loading={loading}>
        {dataErrorOrWarningSeverity === '' ?
          <>
            <HerdSettingsUsersContext.Provider value={{ users, setUsers }}>
              <Row>
                <Col width={herdSettings?.showEstimatedDiseaseWarning ? '75%' : '100%'}>
                  <SubHeader>
                    <Row style={{ alignItems: 'center' }}>
                      <Col width={'3%'}>
                        <IconContainer>
                          <GearFillIcon />
                        </IconContainer>
                      </Col>

                      <Col>
                        {translate('Prevention settings')}
                      </Col>
                    </Row>
                  </SubHeader>
                </Col>

                <Col className={'mr-3'} width={herdSettings?.showEstimatedDiseaseWarning ? '25%' : '0%'}
                  style={{ display: herdSettings?.showEstimatedDiseaseWarning ? '' : 'none' }}>
                  <DismissibleCard title={``}
                    subheader={translate('Attention!')}
                    content={translate('The estimated Ketosis incidence in your herd is above 50% or the estimated incidence of other transition cow diseases (Milk Fever, Displaced Abomasum, Retained Placenta, Lameness or Mastitis) is above 5.5 %, we recommend to review your transition cow management!')}
                    buttonName={translate('Learn more')}
                    onClick={() => {
                      window.location.href = 'https://www.dairydatawarehouse.com/';
                    }} />
                </Col>
              </Row>

              <h3>
                <Row style={{ alignItems: 'center' }}>
                  <Col width={'3%'}>
                    <IconContainer>
                      <AtIcon />
                    </IconContainer>
                  </Col>

                  <Col width={'90%'}>
                    {translate('Alarm notifications')}
                  </Col>
                </Row>
              </h3>

              <Row>
                <Col>
                  <Label>
                    {translate('Receive Guardian alarm notifications:')}
                  </Label>
                </Col>

                <Col>
                  <RadioButtons field={`notifications`} options={notificationsOptions} selected={notificationOption}
                    onChange={(selected) => {
                      setNotificationOption(selected);
                      setShowSaveHint(true);
                    }} />
                </Col>
              </Row>

              {notificationOption?.value === '1' &&
                <>
                  <Row className={'mt-3'} style={{ alignItems: 'center' }}>
                    <Col width='31%'>
                      <Row style={{ alignItems: 'center' }}>
                        <Col>
                          <Label>
                            {translate('Receive notifications N days before calving')}
                          </Label>
                        </Col>

                        <Col width='3%'>
                          <Checkbox checked={notificationDaysModeBeforeCalvingChecked} onChange={(event) => { setNotificationDaysModeBeforeCalvingChecked(event.target.checked); setNotificationDaysModeBeforeDryOff(!event.target.checked) }} />
                        </Col>
                      </Row>
                    </Col>

                    <Col width='31%'>
                      <InputNumber min={0} max={80} step={1} value={uniqueNotificationDays}
                        onChange={(value: number | null) => {
                          setUniqueNotificationDays(value);
                        }} disabled={!notificationDaysModeBeforeCalvingChecked} />
                    </Col>
                  </Row>

                  <Row className={'mt-2 mb-2'} style={{ alignItems: 'center' }}>
                    <Col width='31%'>
                      <Row style={{ alignItems: 'center' }}>
                        <Col>
                          <Label>
                            {translate('Receive notifications N days prior to dry off')}
                          </Label>
                          <Tooltip
                            title={translate('This option is not available for Ketosis.')}
                            placement={'top'}>
                            <span><InfoCircleFillIcon style={{ height: '13px' }} className={'ml-1'} /></span>
                          </Tooltip>
                        </Col>

                        <Col width='3%'>
                          <Checkbox checked={notificationDaysModeBeforeDryOff} disabled={notificationDaysModeBeforeDryOffDisabled} onChange={(event) => { setNotificationDaysModeBeforeDryOff(event.target.checked); setNotificationDaysModeBeforeCalvingChecked(!event.target.checked); }} />
                        </Col>
                      </Row>
                    </Col>

                    <Col width='31%'>
                      <InputNumber min={0} max={7} step={1} value={uniqueNotificationDaysToDryOff}
                        onChange={(value: number | null) => {
                          setUniqueNotificationDaysToDryOff(value);
                        }} disabled={!notificationDaysModeBeforeDryOff} />
                    </Col>
                  </Row>

                  <Row className={'mt-2 mb-2'} style={{ alignItems: 'center' }}>
                    <Label>
                      {translate('Notification users')}
                    </Label>
                    <Col className={'mt-1'} style={{ width: '500px', maxWidth: '500px', marginLeft: '130px' }}>
                      <Space.Compact>
                        <Input disabled={true} value={uniqueSummary} />
                        <Button name={``} type={ButtonType.Cancel} onClick={() => {
                          setShowUniqueNotificationSettingsDialog(true);
                        }}>{translate('Edit')}</Button>
                      </Space.Compact>
                    </Col>
                  </Row>
                  <NotificationSettingsDialog showDialog={showUniqueNotificationSettingsDialog}
                    setShowDialog={setShowUniqueNotificationSettingsDialog}
                    isAdmin={herdSettings?.isAdmin}
                    headerName={translate('Unique notification settings')}
                    tzOffset={herdSettings?.tzOffset}
                    modelId={'unique'}
                    setUniqueSummary={setUniqueSummary}
                    setShowSaveHint={setShowSaveHint}
                  />
                </>
              }

              <br />
              <hr className={'footer-line-break'} />
              <br />
              <h3>
                <Row>
                  <Col>
                    <SlidersIcon />
                  </Col>
                  <Col>
                    {translate('Model-level settings')}
                  </Col>
                </Row>
              </h3>

              <Row>
                <Col fontWeight={'bold'} width={'15%'}>
                  {translate('Model')}
                </Col>
                <Col fontWeight='bold' width='12%'>
                  {translate('Days prior to dry off')}
                  <Tooltip
                    title={translate('This option is not available for Ketosis.')}
                    placement={'top'}>
                    <span><InfoCircleFillIcon style={{ height: '13px' }} className={'ml-1'} /></span>
                  </Tooltip>
                </Col>
                <Col fontWeight={'bold'} width={'15%'}>
                  {translate('Days before calving')}
                </Col>
                <Col fontWeight={'bold'} width={'8%'}>
                  {translate('Predicta')}
                  <Tooltip
                    title={translate('Predicta can estimate disease incidence and auto-configure the sensitivity.')}
                    placement={'top'}>
                    <span><InfoCircleFillIcon style={{ height: '13px' }} className={'ml-1'} /></span>
                  </Tooltip>
                </Col>
                <Col fontWeight={'bold'} width={'12%'}>
                  {shortenIfPossible(translate('Model configuration'))}
                </Col>
                <Col fontWeight={'bold'} width={'30%'}>
                  {translate('Sensitivity')}
                  <Tooltip
                    title={translate('Choose 0% for sensitivity if you do not wish to receive notifications for a specific disease.')}
                    placement={'top'}>
                    <span><InfoCircleFillIcon style={{ height: '13px' }} className={'ml-1'} /></span>
                  </Tooltip>
                </Col>
                <Col fontWeight={'bold'} width={'10%'}>
                  {translate('Estimated Disease Incidence (%)')}
                </Col>
                <Col fontWeight={'bold'} width={'10%'}>
                  {translate('Estimated Disease Cost')}
                </Col>
                <Col fontWeight={'bold'} width={'10%'}>
                  {translate('Estimated Prevention Cost')}
                </Col>
                <Col fontWeight={'bold'} width={'10%'}>
                  {translate('Estimated alarms (p/m)')}
                </Col>
                <Col fontWeight={'bold'} width={'10%'} style={{ display: notificationOption?.value === '1' ? 'none' : '' }}>
                  {translate('Notification settings')}
                </Col>
              </Row>

              <DiseaseSettingsOptionsContext.Provider value={{ diseaseSettingsOptions, setDiseaseSettingsOptions }}>
                {
                  getObjectKeys(HERD_SETTINGS_DIS_NAMES).map((disease) => {
                    return (
                      <div key={disease}>
                        <DiseaseSettings diseaseHeader={translate(HERD_SETTINGS_DIS_NAMES[disease])}
                          modelId={disease}
                          initValue={herdSettings?.initValue}
                          diseasesAllTogether={notificationOption?.value === '1'}
                          uniqueNotificationDays={uniqueNotificationDays}
                          uniqueNotificationDaysPriorToDryOff={uniqueNotificationDaysToDryOff}
                          uniqueNotificationDaysModeBeforeCalving={notificationDaysModeBeforeCalvingChecked}
                          uniqueNotificationDaysModeBeforeDryOff={notificationDaysModeBeforeDryOff}
                          sensitivityDict={herdSettings?.sensitivityDict}
                          machineEstimatedDiseaseIncidenceDict={herdSettings?.machineEstimatedDiseaseIncidenceDict}
                          machineEstimatedCostDict={herdSettings?.machineEstimatedCostDict}
                          selectedEstimatedDiseaseIncidenceDict={herdSettings?.selectedEstimatedDiseaseIncidenceDict}
                          selectedEstimatedCostDict={herdSettings?.selectedEstimatedCostDict}
                          rocs={herdSettings?.rocs} isAdmin={herdSettings?.isAdmin}
                          tzOffset={herdSettings?.tzOffset}
                          specificPrimaryDict={herdSettings?.specificPrimaryDict[disease]}
                          notificationSpecificSecondary={herdSettings?.specificSecondaryDict[disease]}
                          herdSize={herdSettings?.herdSize}
                          setShowSaveHint={setShowSaveHint}
                        />
                      </div>
                    );
                  })
                }
              </DiseaseSettingsOptionsContext.Provider>

              <hr className={'footer-line-break mt-4'} />

              {showSaveHint &&
                <Alert severity={'warning'}>
                  {translate('Don\'t forget to provide notification settings and to save or any change will be lost!')}
                </Alert>
              }

              <Row className={'mt-2 mb-2'}>
                <Button name={`save`} type={ButtonType.Primary} isDisabled={!herdSettings?.isAdmin} onClick={async () => {
                  const _users: any[][] = [];

                  const uniqueUsers: any[] = [];
                  if (users['unique']['primary'] && users['unique']['primary']['_confirmed']) {
                    uniqueUsers.push(users['unique']['primary']);
                  }
                  users['unique']['secondary'].filter((u: any) => (u['_confirmed'] || (!u['confirmed'] && u['_removed'] && !u['_newlyAdded'])))
                    .forEach(function (value: any) {
                      uniqueUsers.push(value);
                    });
                  _users.push(uniqueUsers);

                  for (let i = 0; i < herdSettings?.activeModels?.length!; i++) {
                    const specificUsers: any[] = [];
                    if (users[String(herdSettings?.activeModels[i])]['primary'] && users[String(herdSettings?.activeModels[i])]['primary']['_confirmed']) {
                      specificUsers.push(users[String(herdSettings?.activeModels[i])]['primary']);
                    }
                    users[String(herdSettings?.activeModels[i])]['secondary']
                      .filter((u: any) => (u['_confirmed'] || (!u['confirmed'] && u['_removed'] && !u['_newlyAdded']))).forEach(function (value: any) {
                        specificUsers.push(value);
                      });
                    _users.push(specificUsers);
                  }

                  // console.log(_users);

                  const _notificationDays: number[] = [];
                  const _notificationDaysToDryOff: number[] = [];
                  const _notificationDaysModes: number[] = [];
                  const _configMode: string[] = [];
                  const _advancedSe: string[] = [];
                  const _estimatedDiseaseIncidence: number[] = [];
                  const _diseaseCost: (number | null)[] = [];
                  const _preventionCost: (number | null)[] = [];
                  const _modelDiseaseIncidenceActive: boolean[] = [];
                  const _express_none: boolean[] = [];
                  const _express_lo: boolean[] = [];
                  const _express_med: boolean[] = [];
                  const _express_hi: boolean[] = [];
                  const _express_all: boolean[] = [];

                  for (let i = 0; i < herdSettings?.activeModels.length!; i++) {
                    _notificationDays.push(diseaseSettingsOptions[herdSettings?.activeModels[i]!]['notificationDays']);
                    _notificationDaysToDryOff.push(diseaseSettingsOptions[herdSettings?.activeModels[i]!]['notificationDaysToDryOff']);
                    _notificationDaysModes.push(diseaseSettingsOptions[herdSettings?.activeModels[i]!]['notificationDaysMode']);
                    _configMode.push(diseaseSettingsOptions[herdSettings?.activeModels[i]!]['configMode']);
                    _advancedSe.push(diseaseSettingsOptions[herdSettings?.activeModels[i]!]['advancedSe']);
                    _estimatedDiseaseIncidence.push(diseaseSettingsOptions[herdSettings?.activeModels[i]!]['estimatedDiseaseIncidence']);
                    _diseaseCost.push(diseaseSettingsOptions[herdSettings?.activeModels[i]!]['diseaseCost']);
                    _preventionCost.push(diseaseSettingsOptions[herdSettings?.activeModels[i]!]['preventionCost']);
                    _modelDiseaseIncidenceActive.push(diseaseSettingsOptions[herdSettings?.activeModels[i]!]['modelDiseaseIncidenceActive']);
                    _express_none.push(diseaseSettingsOptions[herdSettings?.activeModels[i]!]['express'][0]);
                    _express_lo.push(diseaseSettingsOptions[herdSettings?.activeModels[i]!]['express'][1]);
                    _express_med.push(diseaseSettingsOptions[herdSettings?.activeModels[i]!]['express'][2]);
                    _express_hi.push(diseaseSettingsOptions[herdSettings?.activeModels[i]!]['express'][3]);
                    _express_all.push(diseaseSettingsOptions[herdSettings?.activeModels[i]!]['express'][4]);
                  }

                  // console.log(_notificationDays);
                  // console.log(_configMode);
                  // console.log(_advancedSe);
                  // console.log(_estimatedDiseaseIncidence);
                  // console.log(_diseaseCost);
                  // console.log(_preventionCost);
                  // console.log(_modelDiseaseIncidenceActive);
                  // console.log(_express_none);
                  // console.log(_express_lo);
                  // console.log(_express_med);
                  // console.log(_express_hi);
                  // console.log(_express_all);

                  await saveSettings({
                    isNotificationUnique: parseInt(notificationOption?.value),
                    users: _users,
                    uniqueNotificationDays: uniqueNotificationDays,
                    uniqueNotificationDaysToDryOff: uniqueNotificationDaysToDryOff,
                    notificationDays: _notificationDays,
                    notificationDaysToDryOff: _notificationDaysToDryOff,
                    uniqueNotificationDaysMode: notificationDaysModeBeforeCalvingChecked ? 0 : 1,
                    notificationDaysModes: _notificationDaysModes,
                    configMode: _configMode.map((mode: string) => parseInt(mode)),
                    expressNone: _express_none,
                    expressLo: _express_lo,
                    expressMed: _express_med,
                    expressHi: _express_hi,
                    expressAll: _express_all,
                    advancedSe: _advancedSe.map((se: string) => parseInt(se)),
                    estimatedDiseaseIncidence: _estimatedDiseaseIncidence,
                    diseaseCost: _diseaseCost,
                    preventionCost: _preventionCost,
                    modelDiseaseIncidenceActive: _modelDiseaseIncidenceActive
                  } as SavePreventionSettings);
                }}>
                  {translate('Save')}
                </Button>
              </Row>
            </HerdSettingsUsersContext.Provider>
          </> :
          <Alert maxWidth={viewSize > ViewSize.S ? '25%' : '75%'} severity={dataErrorOrWarningSeverity}>
            {dataErrorOrWarningSeverity === 'danger' ? <div>{herdId} - {translate('Data not available')}</div> :
              <div>{herdId} - {translate('No predictions yet. If this persists for several days, it might indicate incomplete/insufficient data for this module.')}</div>
            }
          </Alert>
        }
      </PageContainer>
    </>
  );
};