import Plotly, { Data, Layout } from "plotly.js";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { HistoricLactationData } from "../../../../../typings/herdfolio/types";
import { DDW_blue, DDW_cyan, DDW_green, PLOTLY_TEMPLATE } from "../../../../../pages/static";

type CurrentLactationPredictionRelativeToTheFuturePartChartProps = {
    historicData: HistoricLactationData;
};

export const HistoricLactationCurvesChart: React.FC<CurrentLactationPredictionRelativeToTheFuturePartChartProps> = (props) => {
    const { t: translate } = useTranslation();

    const chartRef = useRef<HTMLDivElement>(null);

    const data: Data[] = [
        {
            x: Array.from({ length: 306 }, (_, j) => j),
            y: props.historicData.average,
            mode: 'lines',
            name: translate('Average'),
            line: { dash: 'dash', color: '#000000' },
            showlegend: true
        },
        {
            x: Array.from({ length: 306 }, (_, j) => j),
            y: props.historicData.l1Dims,
            mode: 'lines',
            name: translate('Lactation 1'),
            line: { dash: 'solid', color: DDW_green },
            showlegend: true
        },
        {
            x: Array.from({ length: 306 }, (_, j) => j),
            y: props.historicData.l2Dims,
            mode: 'lines',
            name: translate('Lactation 2'),
            line: { dash: 'solid', color: DDW_cyan },
            showlegend: true
        },
        {
            x: Array.from({ length: 306 }, (_, j) => j),
            y: props.historicData.l3PlusDims,
            mode: 'lines',
            name: translate('Lactation 3+'),
            line: { dash: 'solid', color: DDW_blue },
            showlegend: true
        }
    ];

    const layout: Partial<Layout> = {
        title: translate('Lactation curves by lactation (last 6 months)'),
        yaxis: {
            title: translate('Daily Milk Yield (kg)'),
        },
        xaxis: {
            title: translate('DIM'),
        },
        showlegend: true,
        legend: {
            xanchor: 'center',
            x: 0.5,
            yanchor: 'bottom',
            y: 1,
            orientation: 'h',
        },
        hovermode: 'closest',
        hoverlabel: { namelength: -1 },
        template: PLOTLY_TEMPLATE
    };

    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, data, layout, { responsive: true });


            const plotlyDiv = chartRef.current!.getElementsByClassName('main-svg')[0];
            const yaxisTicks = plotlyDiv.getElementsByClassName('ytick');
            const lastYTick = yaxisTicks[yaxisTicks.length - 1];

            if (lastYTick) {
                // @ts-ignore
                const lastYTickValue = parseFloat(lastYTick.querySelector("text").getAttribute('data-unformatted'));

                const annotations: Partial<Plotly.Annotations>[] = [{
                    x: 250,
                    y: lastYTickValue,
                    text: translate('Peak ratio L1/L3+') + ': ' + props.historicData.peakRatio + '<br>' + translate('Days to peak L1') + ': ' + props.historicData.daysToPeakL1 + '<br>' + translate('Days to peak L3+') + ': ' + props.historicData.daysToPeakL3Plus,
                    showarrow: false,
                    xref: 'x',
                    yref: 'y',
                    bordercolor: "#c7c7c7",
                    borderwidth: 2,
                    borderpad: 4,
                    bgcolor: "#ff7f0e",
                    opacity: 0.8
                }]

                Plotly.relayout(chartRef.current!, {
                    annotations: annotations,
                });
            }
        }
    }, [props]);

    return (
        <div ref={chartRef} />
    );
};