import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Plotly from 'plotly.js';
import { Data, Layout } from 'plotly.js';
import { DDW_blue } from '../../../../pages/static';
import { getObjectValues, setMarkerColour } from '../../../../pages/utils';

export const RiskEvolutionGraph: React.FC<any> = ({ record }) => {
  const { t: translate } = useTranslation();

  const chartRef = useRef<HTMLDivElement>(null);

  const data: Data[] = [
    {
      x: getObjectValues<string>(record.Evolution['Executed']).map((value: string) => value.split('T')[0] + ' ' + value.split('T')[1]),
      y: getObjectValues<number>(record.Evolution.DisplayLevel),
      customdata: getObjectValues<boolean>(record.Evolution.IS_ALARM).map((_) => translate(String(_))),
      mode: 'lines+markers',
      name: record.CowNumber,
      line: { color: DDW_blue, width: 1, shape: 'spline', smoothing: 1.3 },
      marker: {
        color: getObjectValues<boolean>(record.Evolution.IS_ALARM).map((alarm: boolean) => {
          return setMarkerColour(alarm);
        }),
        size: 5
      },
      hovertemplate: translate('<br>Executed: %{x}<br>Risk level: %{y:.3f}<br>IS ALARM: %{customdata}')
    }
  ];

  const layout: Partial<Layout> = {
    margin: { l: 10, r: 10, b: 15, t: 30 },
    title: {
      text: translate('Risk evolution'),
      x: 0.5,
      y: 0.98,
      xanchor: 'center',
      yanchor: 'top',
      font: { size: 14, color: '#000000' }
    },
    xaxis: {
      showgrid: false,
      zeroline: true,
      visible: true
    },
    yaxis: {
      range: [0, 1],
      showgrid: false,
      zeroline: true,
      visible: true
    },
    height: 150,
    hovermode: 'closest',
    hoverlabel: { namelength: -1 },
    paper_bgcolor: 'rgba(0,0,0,0)',
    plot_bgcolor: 'rgba(0,0,0,0)'
  };

  useEffect(() => {
    if (chartRef.current) {
      Plotly.newPlot(chartRef.current, data, layout, { responsive: true, displayModeBar: false });
    }
  }, []);

  return (
    <>
      <div ref={chartRef} />
    </>
  );
};