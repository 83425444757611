import React, { useContext, useEffect, useState } from 'react';
import { Alert, Col, IconContainer, Paragraph, Row, SubHeader } from '../../../components';
import { BellFillIcon } from '../../../../component/Icons';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../../../../component/PageContainer';
import { Button, ButtonType, DDWApplication, DividerStyle, IconLib, Input, SelectOption, ViewSize } from '@ddw/react-components';
import useAsyncEffect from 'use-async-effect';
import { HerdProfileContext } from '../../../../context';
import { AuthenticationContext, SnackbarMessagesContext, SnackbarMessageType, ViewContext } from '@ddw/react-framework';
import { Api } from '../../../../services/api';
import { DIS_NAMES } from '../../../static';
import { Alarms } from '../../../../typings/types';
import { AlarmCard, DismissibleCard } from '../../../../component';
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getObjectKeys } from '../../../utils';
import { Card, Select } from 'antd';
import { guardianPages } from '../../../pages';
import { PagesContext } from '../../../../context/PagesContext';

export const GuardianPreventionAlarmsPage: React.FC = () => {
  const { t: translate } = useTranslation();
  const { herdId, permissions } = useContext(HerdProfileContext);
  const { user, getAccessToken } = useContext(AuthenticationContext);
  const { addMessageToStack } = useContext(SnackbarMessagesContext);
  const { setPages } = useContext(PagesContext);
  const { viewSize } = useContext(ViewContext);

  const { Option } = Select;

  const [alarmData, setAlarmData] = useState<Alarms>();
  const [filteredAlarms, setFilteredAlarms] = useState<any[]>([]);
  const [activeModels, setActiveModels] = useState<SelectOption[]>([]);
  const [diseaseOptions, setDiseaseOptions] = useState<string[]>([]);
  const [animalSearch, setAnimalSearch] = useState('');
  const [actionTakenAlarmsCollapseOpen, setActionTakenAlarmsCollapseOpen] = useState(false);
  const [displayAnimalSearchWarning, setDisplayAnimalSearchWarning] = useState(false);
  const [displayNoActiveAlarmsAlert, setDisplayNoActiveAlarmsAlert] = useState(false);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [dataErrorOrWarningSeverity, setDataErrorOrWarningSeverity] = useState<string>('');
  const [dataErrorMessage, setDataErrorMessage] = useState<string>('');

  const downloadActiveAlarms = async () => {
    document.body.style.cursor = 'progress';
    const result = await Api.downloads.downloadActiveAlarms(getAccessToken(), DDWApplication.DairyInsights, herdId);
    const blob = await result.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'guardian_prevention_alarms_' + 'session_id' + '_' + herdId + '.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    document.body.style.cursor = 'default';
  };

  const downloadHistoricalAlarms = async () => {
    document.body.style.cursor = 'progress';
    const result = await Api.downloads.downloadHistoricalAlarms(getAccessToken(), DDWApplication.DairyInsights, herdId);
    const blob = await result.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'guardian_prevention_hist_alarms_' + 'session_id' + '_' + herdId + '.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    document.body.style.cursor = 'default';
  };

  const filterAlarms = function (earTag: string, cowNumber: string, diseases: any, isAlarms: any, treated: any, ignored: any, active: boolean) {
    document.body.style.cursor = 'progress';
    if (!(diseases.filter((disease: string) => isAlarms[disease] === true))
      .some((disease: string) => diseaseOptions.map(disease => disease).includes(disease))) {
      return false;
    }

    if (active) {
      if (!Object.values(treated).some((c) => !c) && !Object.values(ignored).some((c) => !c)) {
        return false;
      }
    } else {
      if (!Object.values(treated).some((c) => c) && !Object.values(ignored).some((c) => c)) {
        return false;
      }
    }

    earTag = earTag !== null ? earTag.replaceAll(' ', '').toLowerCase() : '';
    const input = animalSearch.replaceAll(' ', '').toLowerCase();

    if (input === '') {
      return true;
    }
    if (earTag.startsWith(input)) {
      return true;
    }
    return cowNumber.startsWith(animalSearch);
  };

  useEffect(() => {
    let filteredAlarms: any[] = [];
    alarmData?.subsetActive.map((pair: any) => {
      if (filterAlarms(pair[1]['EarTag'], pair[1]['CowNumber'], getObjectKeys(pair[0]['AnimalAlarmId']), pair[0]['IS_ALARM'], pair[0]['Treated'], pair[0]['Ignored'], true)) {
        filteredAlarms.push(pair);
      }
    });
    if (filteredAlarms.length === 0) {
      if (animalSearch !== '') {
        setDisplayAnimalSearchWarning(true);
        setDisplayNoActiveAlarmsAlert(false);
      } else {
        setDisplayAnimalSearchWarning(false);
        setDisplayNoActiveAlarmsAlert(true);
      }
    } else {
      setDisplayAnimalSearchWarning(false);
      setDisplayNoActiveAlarmsAlert(false);
    }
    setFilteredAlarms(filteredAlarms);
    document.body.style.cursor = 'default';
  }, [animalSearch, diseaseOptions]);

  const fetchAlarms = async () => {
    const result = await Api.alarms.fetchAlarms(getAccessToken(), DDWApplication.DairyInsights, herdId);
    if (result.success) {
      if (result.message === '') {
        const data = result.data;
        setAnimalSearch('');
        setAlarmData(data);
        setFilteredAlarms(data.subsetActive);
        setActiveModels(data.activeModels.map(model => {
          return { label: DIS_NAMES[model], value: String(model) };
        }));
        setDiseaseOptions(data.activeModels.map(model => {
          return String(model);
        }));
        if (result.data.herdLevelDQ !== null) {
          setDataErrorOrWarningSeverity('danger');
          setDataErrorMessage(translate('This herd failed with data quality violation: {{dq}}', {
            dq: result.data.herdLevelDQ === 34 ? translate('Invalid average dry off days (at herd level)') : translate('Invalid average calving interval (at herd level)')
          }))
        } else {
          setDataErrorOrWarningSeverity('');
          setDataErrorMessage('');
        }
      } else {
        setDataErrorOrWarningSeverity('warning');
        setDataErrorMessage('');
      }
    } else {
      if (result.message !== '') {
        navigate('/home');
        addMessageToStack({
          messageType: SnackbarMessageType.error,
          message: translate('There was a problem fetching alarms.'),
          autoHide: false
        });
      } else {
        setDataErrorOrWarningSeverity('danger');
        setDataErrorMessage(translate('Data not available'));
      }
    }
  };

  useAsyncEffect(async () => {
    setPages(guardianPages(translate, permissions.prevention, permissions.diagnosis, permissions.visio, user !== undefined ? user['profile']['UserEmail'] : ''));
    if (herdId != 0) {
      setLoading(true);
      await fetchAlarms();
      setLoading(false);
    }
  }, [herdId]);

  useEffect(() => {
    return () => {
      setActionTakenAlarmsCollapseOpen(false);
    };
  }, [herdId]);

  return (
    <>
      <PageContainer hasFooter={false} loading={loading}>
        {dataErrorOrWarningSeverity === '' ?
          <>
            <Row>
              <Col width={alarmData?.showEstimatedDiseaseWarning ? '50%' : '75%'}>
                <SubHeader>
                  <Row style={{ alignItems: 'center' }}>
                    <Col width={'3%'}>
                      <IconContainer>
                        <BellFillIcon />
                      </IconContainer>
                    </Col>

                    <Col width={'90%'}>
                      {translate('Active alarm list')}
                    </Col>
                  </Row>
                </SubHeader>

                <Paragraph>
                  {translate('Report of currently dry animals predicted at risk for developing transition diseases in the following lactation.')}
                </Paragraph>
              </Col>

              <Col className={'mr-3'} width={'25%'} style={{ display: alarmData?.violations ? '' : 'none' }}>
                <DismissibleCard title={``}
                  subheader={translate('Attention!')}
                  content={translate('There are {{violations}} dry animals for which Guardian cannot make a prediction due to data quality violation', {
                    violations: alarmData?.violations
                  })}
                  buttonName={translate('Learn more')}
                  onClick={() => {
                    navigate('/guardian-prevention/issues');
                  }} />
              </Col>

              <Col className={'mr-3'} width={alarmData?.showEstimatedDiseaseWarning ? '25%' : '0%'}
                style={{ display: alarmData?.showEstimatedDiseaseWarning ? '' : 'none' }}>
                <DismissibleCard title={``}
                                 subheader={translate('Attention!')}
                                 content={translate('The estimated Ketosis incidence in your herd is above 50% or the estimated incidence of other transition cow diseases (Milk Fever, Displaced Abomasum, Retained Placenta, Lameness or Mastitis) is above 5.5 %, we recommend to review your transition cow management!')}
                                 buttonName={translate('Learn more')}
                                 onClick={() => {
                                   navigate('/guardian-prevention/settings');
                                 }} />
              </Col>
            </Row>

            <Row className='mt-3'>
              <Card className='w-100' title={translate('Summary of currently dry animals with transition cow disease alert (based on the last 20 alarms)')} >
                <Row style={{ alignItems: 'center' }}>
                  <Col width='25%'>
                    <Card title={translate('Days to conception (DIM)')}>
                      <Paragraph>
                        {alarmData?.daysToConception.toFixed(0)}
                      </Paragraph>
                    </Card>
                  </Col>

                  <Col width='25%'>
                    <Card title={translate('Days to peak (DIM)')}>
                      <Paragraph>
                        {alarmData?.daysToPeak.toFixed(0)}
                      </Paragraph>
                    </Card>
                  </Col>

                  <Col width='25%'>
                    <Card title={translate('Peak milk')}>
                      <Paragraph>
                        {alarmData?.peakMilk.toFixed(2)}
                      </Paragraph>
                    </Card>
                  </Col>

                  <Col width='25%'>
                    <Card title={translate('Disease events < 42 DIM')}>
                      <Paragraph>
                        {alarmData?.diseaseEventsUntil42DIM}
                      </Paragraph>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Row>

            <Row className={'mt-3'} style={{ display: alarmData?.duplicateTags.length ? '' : 'none' }}>
              <Alert severity={'warning'}>
                <span
                  style={{ fontWeight: 'bold' }}>{translate('Warning: ' + alarmData?.duplicateTags.length + ' Animals with duplicate Ear Tags:')}
                </span>
                <br />
                {alarmData?.duplicateTags.join(', ')}
              </Alert>
            </Row>

            <Row className={'mt-3'} style={{ alignItems: 'center' }}>
              <Col width={'20%'}>
                <Paragraph>
                  <label>{translate('Show animals at risk for:')}</label>
                </Paragraph>
              </Col>

              <Col width={viewSize > ViewSize.S ? '50%' : '70%'}>
                <Select mode={'multiple'} style={{ width: '100%' }} optionLabelProp='label' allowClear={true}
                  onChange={setDiseaseOptions} value={diseaseOptions}>
                  {activeModels.map((option) => (
                    <Option value={option.value} label={translate(option.label)}>
                      {translate(option.label)}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>

            <Row>
              <Col width={'20%'}>
                <Paragraph>
                  {translate('Search by Ear Tag or Cow Number:')}
                </Paragraph>
              </Col>
              <Col width={viewSize > ViewSize.S ? '30%' : '50%'}>
                <Input name={''} field={`ear-tag-or-cow-number`} value={animalSearch} onChange={setAnimalSearch} />
              </Col>
            </Row>

            <Row style={{ display: displayAnimalSearchWarning ? '' : 'none' }}>
              <Alert className={'mt-3'} style={{ display: '' }} severity={'warning'}>
                {translate('No animals match the above Ear Tag or Cow Number')}
              </Alert>
            </Row>

            <Row style={{ display: displayNoActiveAlarmsAlert ? '' : 'none' }}>
              <Alert className={'mt-3'} style={{ display: '' }} severity={'primary'}>
                {translate('There are no currently active alarms needing attention.')}
              </Alert>
            </Row>

            {filteredAlarms.map((pair: any) => {
              return (
                <Row key={String(pair[1]['name'])} style={{ margin: '10px' }}>
                  <AlarmCard alarmData={pair[0]} headerData={pair[1]} fetchAlarms={fetchAlarms} />
                </Row>
              );
            })}

            <Row className={'mt-3 mb-2'}>
              <Button name={`display-alarms-action-taken`}
                type={ButtonType.Cancel}
                isOutlined={false}
                onClick={() => {
                  setActionTakenAlarmsCollapseOpen(!actionTakenAlarmsCollapseOpen);
                }}
              >
                {!actionTakenAlarmsCollapseOpen ? translate('Display active alarms for which action has already been taken') : translate('Hide active alarms for which action has already been taken')}
              </Button>
            </Row>

            {actionTakenAlarmsCollapseOpen &&
              <div>
                {alarmData?.subsetActionTaken.map((pair: any) => {
                  if (filterAlarms(pair[1]['EarTag'], pair[1]['CowNumber'], getObjectKeys(pair[0]['AnimalAlarmId']), pair[0]['IS_ALARM'], pair[0]['Treated'], pair[0]['Ignored'], false)) {
                    return (
                      <Row key={String(pair[1]['name'])} style={{ margin: '10px' }}>
                        <AlarmCard alarmData={pair[0]} headerData={pair[1]} fetchAlarms={fetchAlarms} />
                      </Row>
                    );
                  }
                })}
              </div>
            }

            <br />
            <DividerStyle />
            <br />

            <Row>
              <Col>
                <Button name={'download-active-alarms'}
                  type={ButtonType.Cancel}
                  isOutlined={false}
                  onClick={downloadActiveAlarms}
                  icon={IconLib.downloadLight}
                >
                  {translate('Download active alarms')}
                </Button>
              </Col>
              <Tooltip title={translate('Historical alarms raised in the last 12 months.')} placement={'right'}>
                <Col>
                  <Button name={'download-historical-alarms'}
                    type={ButtonType.Cancel}
                    isOutlined={false}
                    onClick={downloadHistoricalAlarms}
                    icon={IconLib.downloadLight}
                  >
                    {translate('Download historical alarms')}
                  </Button>
                </Col>
              </Tooltip>
            </Row>
          </> :
          <Alert maxWidth={viewSize > ViewSize.S ? '50%' : '75%'} severity={dataErrorOrWarningSeverity}>
            {dataErrorOrWarningSeverity === 'danger' ? <div>{herdId} - {dataErrorMessage}</div> :
              <div>{herdId} - {translate('No predictions yet. If this persists for several days, it might indicate incomplete/insufficient data for this module.')}</div>
            }
          </Alert>
        }
      </PageContainer>
    </>
  );
};