import Plotly, { Data, Layout } from "plotly.js";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { KPI_CODES, DDW_orange, PLOTLY_TEMPLATE } from "../../../../../pages/static";
import { format, parse } from 'date-fns';

export const MilkPerCowSpotsChart: React.FC<any> = ({ props, milkingBeds }) => {
    const { t: translate } = useTranslation();

    const chartRef = useRef<HTMLDivElement>(null);

    const data: Data[] = [
        {
            x: props.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerDay']).map((data: any) => format(parse(data.periodValue, 'yyyyMM', new Date()), 'MMM yyyy')),
            y: props.filter((data: any) => data.kpiCode === KPI_CODES['AverageMilkPerDay']).map((data: any) => ((data.value * 30) / milkingBeds)),
            type: 'bar',
            name: translate('Milk production'),
            marker: { color: DDW_orange }
        }
    ];

    const layout: Partial<Layout> = {
        title: translate('Milk / cow spot ({{milkingBeds}} spots)', { milkingBeds: milkingBeds }),
        showlegend: true,
        hovermode: 'closest',
        template: PLOTLY_TEMPLATE
    };

    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
        }
    }, [props, milkingBeds]);

    return (
        <div ref={chartRef} />
    );
};