import Plotly, { Data, Layout } from "plotly.js";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { DDW_blue, DDW_green, DDW_orange, PLOTLY_TEMPLATE } from "../../../../../pages/static";
import { ViewSize, useViewSize } from "@ddw/react-components";
import { Tooltip } from "@mui/material";
import { InfoCircleFillIcon } from "../../../../Icons";

type SurvivalCurveHealthyLactationsChartsProp = {
    keys: number[];
    values: any;
};

export const SurvivalCurveHealthyLactationsChart: React.FC<SurvivalCurveHealthyLactationsChartsProp> = (props) => {
    const { t: translate } = useTranslation();
    const { viewSize } = useViewSize();

    const chartRef = useRef<HTMLDivElement>(null);

    const data: Data[] = [
        {
            x: [42, 42],
            y: [props.values?.average[43], 0],
            mode: 'lines',
            opacity: 0.7,
            line: { color: DDW_blue, dash: 'dot' },
            showlegend: false,
            hovertemplate: translate('DIM %{x}<br>Survival rate: %{y}')
        },
        {
            x: [42, 42],
            y: [props.values?.l1SurvivalRate[43], 0],
            mode: 'lines',
            opacity: 0.7,
            line: { color: DDW_blue, dash: 'dot' },
            showlegend: false,
            hovertemplate: translate('DIM %{x}<br>Survival rate: %{y}')
        },
        {
            x: [42, 42],
            y: [props.values?.l2SurvivalRate[43], 0],
            mode: 'lines',
            opacity: 0.7,
            line: { color: DDW_orange, dash: 'dot' },
            showlegend: false,
            hovertemplate: translate('DIM %{x}<br>Survival rate: %{y}')
        },
        {
            x: [42, 42],
            y: [props.values?.l3PlusSurvivalRate[43], 0],
            mode: 'lines',
            opacity: 0.7,
            line: { color: DDW_green, dash: 'dot' },
            showlegend: false,
            hovertemplate: translate('DIM %{x}<br>Survival rate: %{y}')
        },
        {
            x: props.keys,
            y: props.values?.average,
            mode: 'lines',
            name: translate('Average'),
            line: { dash: 'dash', color: '#000000' },
            showlegend: true,
            hovertemplate: translate('DIM %{x}<br>Survival rate: %{y}')
        },
        {
            x: props.keys,
            y: props.values?.l1SurvivalRate,
            mode: 'lines',
            name: translate('Lactation 1'),
            line: {
                dash: 'solid', color: DDW_blue
            },
            showlegend: true,
            hovertemplate: translate('DIM %{x}<br>Survival rate: %{y}')
        },
        {
            x: props.keys,
            y: props.values?.l2SurvivalRate,
            mode: 'lines',
            name: translate('Lactation 2'),
            line: {
                dash: 'solid', color: DDW_orange
            },
            showlegend: true,
            hovertemplate: translate('DIM %{x}<br>Survival rate: %{y}')
        },
        {
            x: props.keys,
            y: props.values?.l3PlusSurvivalRate,
            mode: 'lines',
            name: translate('Lactation 3+'),
            line: {
                dash: 'solid', color: DDW_green
            },
            showlegend: true,
            hovertemplate: translate('DIM %{x}<br>Survival rate: %{y}')
        }
    ];

    const layout: Partial<Layout> = {
        title: viewSize > ViewSize.S ? translate('Survival rates in function of disease incidence') : '',
        yaxis: {
            title: translate('Overall survival (%)'),
            range: [0, 1]
        },
        xaxis: {
            title: translate('DIM')
        },
        legend: {
            xanchor: 'center',
            x: 0.5,
            yanchor: 'bottom',
            y: 1,
            orientation: 'h',
        },
        hovermode: 'closest',
        hoverlabel: { namelength: -1 },
        template: PLOTLY_TEMPLATE
    };

    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
        }
    }, [props, viewSize]);

    return (
        <div style={{ position: 'relative' }}>
            <div ref={chartRef} />
            <Tooltip
                title={translate('Animals are "surviving" until the DIM of their first case of disease in the given lactation')}
                placement={'top'} style={{ position: 'absolute', top: '30px', right: '30px' }}>
                <span>
                    <InfoCircleFillIcon style={{ height: '18px' }} className={'ml-1'} />
                </span>
            </Tooltip>
        </div>
    )
};