import styled from "styled-components";

export const IconContainer = styled.div`
    transition: transform 0.3s ease;
    margin-right: 16px;

    @media (max-width: 576px) {
        transform: scale(0.6);
        margin-right: 1px;
    }
`;