import { DDWApplication, parseError, putHeaders, statusHelper, toJSON } from '@ddw/react-components';
import { ServiceResult } from '@ddw/react-framework/build/src/types';
import {
  DiagnosisAlarms,
  DiagnosisDashboardInformation,
  DiagnosisIssues,
  DiagnosisSaveUserSettings,
  DiagnosisService,
  DiagnosisSettings,
  DiagnosisSettingsEstimatedAlarms,
  DiagnosisStats,
  Docs,
  Feedback
} from '../../typings/types';
import { urlState } from '../../pages/utils';

export const fetchDiagnosisDashboardInformation = (accessToken: string, application: DDWApplication, herdId: number): Promise<ServiceResult<DiagnosisDashboardInformation>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `herds/` + herdId + '/diagnosis-dashboard-info';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const fetchAlarms = (accessToken: string, application: DDWApplication, herdId: number): Promise<ServiceResult<DiagnosisAlarms>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `herds/` + herdId + '/diagnosis/alarms';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const fetchStats = (accessToken: string, application: DDWApplication, herdId: number): Promise<ServiceResult<DiagnosisStats>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `herds/` + herdId + '/diagnosis/stats';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const fetchFeedback = (accessToken: string, application: DDWApplication, herdId: number): Promise<ServiceResult<Feedback>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `herds/` + herdId + '/diagnosis/feedback';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const fetchDocs = (accessToken: string, application: DDWApplication, herdId: number): Promise<ServiceResult<Docs>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `herds/` + herdId + '/diagnosis/docs';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const fetchIssues = (accessToken: string, application: DDWApplication, herdId: number): Promise<ServiceResult<DiagnosisIssues>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `herds/` + herdId + '/diagnosis/issues';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const fetchService = (accessToken: string, application: DDWApplication, herdId: number, period: string): Promise<ServiceResult<DiagnosisService>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  headers.set('period', period);
  const url = urlState.url + `herds/` + herdId + '/diagnosis/service';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const fetchDiagnosisSettings = (accessToken: string, application: DDWApplication, herdId: number): Promise<ServiceResult<DiagnosisSettings>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `herds/` + herdId + '/diagnosis/settings';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const estimateAlarms = (accessToken: string, application: DDWApplication, herdId: number, configType: string,
                               sensitivity: number, incidence: number, seLowActive: boolean, seMedActive: boolean,
                               seHighActive: boolean): Promise<ServiceResult<DiagnosisSettingsEstimatedAlarms>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  headers.set('config-type', configType);
  headers.set('sensitivity', String(sensitivity));
  headers.set('incidence', String(incidence));
  headers.set('se-low-active', String(seLowActive));
  headers.set('se-med-active', String(seMedActive));
  headers.set('se-high-active', String(seHighActive));
  const url = urlState.url + `herds/` + herdId + '/diagnosis/settings/estimate-alarms';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const saveUserSettings = (accessToken: string, application: DDWApplication, herdId: number, data: DiagnosisSaveUserSettings): Promise<ServiceResult<any>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `herds/` + herdId + '/diagnosis/settings/save';
  return fetch(url, {
    method: 'PUT',
    headers,
    body: JSON.stringify(data)
  }).then(statusHelper).then(toJSON).catch(parseError);
};
