import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Plotly from 'plotly.js';
import { Data, Layout } from 'plotly.js';
import { getObjectValues } from '../../../../pages/utils';
import { DDW_green_set } from '../../../../pages/static';
import { ViewContext } from '@ddw/react-framework';
import { ViewSize } from '@ddw/react-components';

export const FeedbackTopLeftBarChart: React.FC<any> = ({ dfCase1, dfCase1Index }) => {
  const { t: translate } = useTranslation();
  const { viewSize } = useContext(ViewContext);

  const chartRef = useRef<HTMLDivElement>(null);

  const data: Data[] = [
    {
      x: dfCase1Index, y: dfCase1['IS_ALARM'],
      opacity: 1.,
      yaxis: 'y1',
      name: translate('Total Alarms'),
      marker: { color: DDW_green_set[0] },
      type: 'bar'
    },
    {
      x: dfCase1Index,
      y: dfCase1['IsAnimalChecked'],
      opacity: 1.,
      width: 0.4,
      yaxis: 'y1',
      name: translate('of which checked'),
      marker: { color: DDW_green_set[1] },
      type: 'bar'
    },
    {
      x: dfCase1Index,
      y: dfCase1['IsAnimalChecked'].map((value: any, index: number) => value / dfCase1['IS_ALARM'][index]),
      opacity: 1.,
      yaxis: 'y2',
      name: translate('Checked rate'),
      line: { dash: 'solid', color: DDW_green_set[2] },
      type: 'scatter'
    }
  ];

  const layout: Partial<Layout> = {
    barmode: 'overlay',
    title: viewSize > ViewSize.S ? translate('Compliance - total number of alarms and alarms checked') : '',
    showlegend: true,
    legend: {
      xanchor: 'center',
      x: 0.5,
      yanchor: 'bottom',
      y: 1,
      orientation: 'h'
    },
    xaxis: { tickangle: -30, automargin: true },
    hovermode: 'closest',
    yaxis2: {
      title: translate('Compliance rate (%)'),
      overlaying: 'y',
      side: 'right',
      range: [0., 1.],
      automargin: true
    }
  };

  useEffect(() => {
    if (chartRef.current) {
      Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
    }
  }, [viewSize]);

  return (
    <>
      <div ref={chartRef} />
    </>
  );
};