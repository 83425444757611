import Plotly, { Data, Layout } from "plotly.js";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { BENCHMARKS, DDW_cyan, DDW_orange, PLOTLY_TEMPLATE } from "../../../../pages/static";

type AnimalComparisonChart = {
    commonXAxis: number[];
    trace1: any[];
    trace2: any[];
    traceDiffPos_: any[];
    traceDiffPos: any[];
    traceDiffNeg_: any[];
    traceDiffNeg: any[];
    animal1: string;
    animal2: string;
    comparison: string;
};

export const AnimalComparisonChart: React.FC<AnimalComparisonChart> = ({ commonXAxis, trace1, trace2, traceDiffPos_, traceDiffPos, traceDiffNeg_, traceDiffNeg, animal1, animal2, comparison }) => {
    const { t: translate } = useTranslation();

    const chartRef = useRef<HTMLDivElement>(null);

    const data: Data[] = [
        {
            x: commonXAxis,
            y: trace1,
            mode: 'lines',
            opacity: 0.75,
            name: animal1,
            line: { color: DDW_cyan },
            showlegend: true
        },
        {
            x: commonXAxis,
            y: trace2,
            mode: 'lines',
            opacity: 0.75,
            name: animal2 + (comparison === 'timeline' && BENCHMARKS.includes(animal2) ? ' (DRY adjusted)' : ''),
            line: { color: DDW_orange },
            showlegend: true
        },
        {
            x: commonXAxis,
            y: traceDiffPos_,
            mode: 'lines',
            line: { color: 'rgba(0, 0, 0, 0)' },
            showlegend: false,
            hoverinfo: 'skip'
        },
        {
            x: commonXAxis,
            y: traceDiffPos,
            fillcolor: 'rgba(0, 128, 0, 0.1)',
            opacity: 0.15,
            fill: 'tonexty',
            mode: 'none',
            showlegend: false,
            hoverinfo: 'skip'
        },
        {
            x: commonXAxis,
            y: traceDiffNeg_,
            mode: 'lines',
            line: { color: 'rgba(0, 0, 0, 0)' }, // Setting line color to transparent
            showlegend: false,
            hoverinfo: 'skip'
        },
        {
            x: commonXAxis,
            y: traceDiffNeg,
            fillcolor: 'rgba(255, 0, 0, 0.1)',
            opacity: 0.15,
            fill: 'tonexty',
            mode: 'none',
            showlegend: false,
            hoverinfo: 'skip'
        }
    ];

    const layout: Partial<Layout> = {
        xaxis: {
            title: comparison === 'timeline' ? translate('Days from now') : translate('DIM')
        },
        yaxis: {
            title: translate('Daily Milk Yield (kg)')
        },
        title: comparison === 'cur' ? translate('(Animal with higher DIM rules)') : '',
        showlegend: true,
        legend: {
            xanchor: 'center',
            x: 0.5,
            yanchor: 'bottom',
            y: 1,
            orientation: 'h'
        },
        hovermode: 'closest',
        hoverlabel: { namelength: -1 },
        template: PLOTLY_TEMPLATE
    };

    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
        }
    }, [commonXAxis, trace1, trace2, traceDiffPos_, traceDiffPos, traceDiffNeg_, traceDiffNeg, animal1, animal2, comparison]);

    return (
        <div ref={chartRef} />
    );
}