import React, { useContext, useState } from 'react';
import { Card, CardContent, CardHeader, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Col, Paragraph, Row, Span } from '../../pages/components';
import { DIS_COL, DIS_PILL_NAMES } from '../../pages/static';
import { withStyles } from '@mui/styles';
import { DDWApplication } from '@ddw/react-components';
import '../../pages/common.css';
import './AlarmCard.css';
import { getObjectKeys } from '../../pages/utils';
import { AlarmCardChecklist } from './AlarmCardChecklist';
import { Api } from '../../services/api';
import { HerdProfileContext } from '../../context';
import { AuthenticationContext, SnackbarMessagesContext, SnackbarMessageType } from '@ddw/react-framework';
import { UpdateTreatment } from '../../typings/types';
import { useNavigate } from 'react-router-dom';

const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: 'transparent'
  }
})(Tooltip);

export const AlarmCard: React.FC<any> = ({ alarmData, headerData, fetchAlarms }) => {
  const { t: translate } = useTranslation();
  const { herdId } = useContext(HerdProfileContext);
  const { getAccessToken } = useContext(AuthenticationContext);
  const { addMessageToStack } = useContext(SnackbarMessagesContext);

  const diseases = getObjectKeys(alarmData['AnimalAlarmId']);

  const [showTooltip, setShowTooltip] = useState(false);

  const navigate = useNavigate();

  const changeTreatment = async (data: UpdateTreatment) => {
    document.body.style.cursor = 'progress';
    const result = await Api.alarms.performActionOnTreatmentChange(getAccessToken(), DDWApplication.DairyInsights, herdId, headerData['name'], data);
    if (result.success) {
      await fetchAlarms();
    } else {
      addMessageToStack({
        messageType: SnackbarMessageType.error,
        message: translate('There was a problem updating treatment.'),
        autoHide: true
      });
    }
    document.body.style.cursor = 'default';
  };

  return (
    <>
      <Card style={{
        boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)',
        backgroundColor: '#F7F7F7',
        marginBottom: '5px',
        width: '100%'
      }}>
        <CardContent>
          <Row>
            <Col width={'20%'} onClick={() => {
              navigate('/animal', { state: { eartag: headerData.EarTag, cowNumber: headerData.CowNumber } });
            }} onMouseEnter={() => { document.body.style.cursor = 'pointer'; }}
              onMouseLeave={() => { document.body.style.cursor = 'default'; }}>
              <Row className={'ml-2'}><Typography
                style={{ fontWeight: 'bold' }}>{translate('EarTag')}</Typography></Row>
              <Row className={'ml-2 mt-1'}>{headerData.EarTag}</Row>
              <Row className={'ml-2 mt-3'}><Typography
                style={{ fontWeight: 'bold' }}>{translate('Dry Off Date')}</Typography></Row>
              <Row className={'ml-2 mt-1'}>{headerData.DryOffDate}</Row>
            </Col>

            <Col width={'20%'} onClick={() => {
              navigate('/animal', { state: { eartag: headerData.EarTag, cowNumber: headerData.CowNumber } });
            }} onMouseEnter={() => { document.body.style.cursor = 'pointer'; }}
              onMouseLeave={() => { document.body.style.cursor = 'default'; }}>
              <Row><Typography style={{ fontWeight: 'bold' }}>{translate('Cow #')}</Typography></Row>
              <Row className={'mt-1'}>{headerData.CowNumber}</Row>
              <Row className={'mt-3'}><Typography
                style={{ fontWeight: 'bold' }}>{translate('Exp. Calving')}</Typography></Row>
              <Row className={'mt-1'}>{headerData.ExpectedCalvingDate}</Row>
            </Col>

            <Col width={'20%'} onClick={() => {
              navigate('/animal', { state: { eartag: headerData.EarTag, cowNumber: headerData.CowNumber } });
            }} onMouseEnter={() => { document.body.style.cursor = 'pointer'; }}
              onMouseLeave={() => { document.body.style.cursor = 'default'; }}>
              <Row><Typography style={{ fontWeight: 'bold' }}>{translate('Parity')}</Typography></Row>
              <Row className={'mt-1'}>{headerData.Parity}</Row>
              <Row className={'mt-3'}><Typography
                style={{ fontWeight: 'bold' }}>{translate('Exp. 305d Yield')}</Typography></Row>
              <Row className={'mt-1'}>{headerData['Exp. production level 305 days (kg)']}</Row>
            </Col>

            <Col width={'20%'} onClick={() => {
              navigate('/animal', { state: { eartag: headerData.EarTag, cowNumber: headerData.CowNumber } });
            }} onMouseEnter={() => { document.body.style.cursor = 'pointer'; }}
              onMouseLeave={() => { document.body.style.cursor = 'default'; }}>
              <Row><Typography style={{ fontWeight: 'bold' }}>{translate('Alarm Type')}</Typography></Row>
              {diseases.map((disease) => {
                return (
                  <div key={String(disease)}>
                    {alarmData['IS_ALARM'][disease] === true &&
                      <Row style={{ marginTop: '5px' }}>
                        <StyledTooltip open={disease == '1' && showTooltip} title={
                          <Card style={{
                            minWidth: '350px',
                            color: '#6c757d',
                            borderColor: '#f8f9fa',
                            opacity: 0.99
                          }}>
                            <CardHeader style={{ textAlign: 'center', backgroundColor: '#e8e8e8' }}
                              subheader={translate('Risk of Ketosis')} />
                            <CardContent
                              style={{ textAlign: 'center', alignItems: 'center', backgroundColor: 'white' }}>
                              <Row>
                                <Col width={'100%'}>
                                  <h3>{translate('Alarm Notification due')}</h3>
                                  <Paragraph>{alarmData['Executed'][disease]}</Paragraph>
                                </Col>
                              </Row>
                              <Row>
                                <Col width={'50%'}>
                                  <h3>{translate('Exp. production 305')}</h3>
                                  <Paragraph>{headerData['Exp. production level 305 days (kg)']} kg</Paragraph>
                                </Col>
                                <Col width={'50%'}>
                                  <h3>{translate('Exp. milk loss')}</h3>
                                  <Paragraph>{alarmData['Exp. milk loss 305 days (kg)'][disease]} kg</Paragraph>
                                </Col>
                              </Row>
                            </CardContent>
                          </Card>
                        } placement={'bottom-end'}>
                          <Span style={{ width: '120px', backgroundColor: `${DIS_COL[String(disease)]}` }}
                            className={'disease-badge badge-pill badge-primary'}
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}>
                            {translate(DIS_PILL_NAMES[String(disease)])}
                          </Span>
                        </StyledTooltip>
                      </Row>
                    }
                  </div>
                );
              })}
            </Col>

            <Col width={'20%'}>
              <Row><Typography style={{ fontWeight: 'bold' }}>{translate('Action')}</Typography></Row>
              {diseases.map((disease) => {
                return (
                  <div key={String(disease)}>
                    {
                      alarmData['IS_ALARM'][disease] === true &&
                      <AlarmCardChecklist treated={alarmData['Treated'][disease]}
                        ignored={alarmData['Ignored'][disease]}
                        disease={disease}
                        changeTreatment={changeTreatment}
                      />
                    }
                  </div>
                );
              })}
            </Col>
          </Row>
        </CardContent>
      </Card>
    </>
  );
}
  ;