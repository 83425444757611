import { AuthenticationContext } from '@ddw/react-framework';
import { useContext } from 'react';
import { Api } from '../api';
import { DDWApplication } from '@ddw/react-components';

export const useFetchHerdProfiles = () => {
  const { getAccessToken } = useContext(AuthenticationContext);

  const fetchHerdProfiles = async () => {
    return await Api.herdProfiles.fetchHerdProfiles(getAccessToken(), DDWApplication.DairyInsights);
  };

  return { fetchHerdProfiles };
};
