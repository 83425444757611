import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticationContext, SnackbarMessagesContext, SnackbarMessageType, ViewContext } from '@ddw/react-framework';
import { HerdProfileContext } from '../../../../context';
import { PageContainer } from '../../../../component/PageContainer';
import { Alert, Col, IconContainer, Row, SubHeader } from '../../../components';
import { GraphUpIcon } from '../../../../component/Icons';
import { DDWApplication, Select, SelectOption, ViewSize } from '@ddw/react-components';
import { Api } from '../../../../services/api';
import useAsyncEffect from 'use-async-effect';
import { DIS_NAMES } from '../../../static';
import { Stats } from '../../../../typings/types';
import { PieChart } from '../../../../component/Charts/Stats/PieChart';
import { StatsTopLeftBarChart } from '../../../../component/Charts/Stats/StatsTopLeftBarChart';
import { StatsBottomLeftBarChart } from '../../../../component/Charts/Stats/StatsBottomLeftBarChart';
import { StatsBottomRightBarChart } from '../../../../component/Charts/Stats/StatsBottomRightBarChart';
import { useNavigate } from 'react-router-dom';
import { PagesContext } from '../../../../context/PagesContext';
import { guardianPages } from '../../../pages';

export const GuardianPreventionStatsPage: React.FC = () => {
  const { t: translate } = useTranslation();
  const { user, getAccessToken } = useContext(AuthenticationContext);
  const { herdId, permissions } = useContext(HerdProfileContext);
  const { addMessageToStack } = useContext(SnackbarMessagesContext);
  const { setPages } = useContext(PagesContext);
  const { viewSize } = useContext(ViewContext);

  const [loading, setLoading] = useState(true);
  const [activeModels, setActiveModels] = useState<SelectOption[]>([]);
  const [diseaseOption, setDiseaseOption] = useState<SelectOption>({ label: '', value: '' });
  const [statsData, setStatsData] = useState<Stats>();

  const navigate = useNavigate();

  const [dataErrorOrWarningSeverity, setDataErrorOrWarningSeverity] = useState('');

  const fetchStats = async () => {
    const result = await Api.stats.fetchStats(getAccessToken(), DDWApplication.DairyInsights, herdId, diseaseOption.value);
    if (result.success) {
      if (result.message === '') {
        setStatsData(result.data);
        const diseases = result.data.activeModels.map(model => {
          return { label: translate(DIS_NAMES[model]), value: String(model) };
        });
        setActiveModels(diseases);
        if (diseaseOption.value === '') {
          setDiseaseOption({ label: translate(diseases[0].label), value: diseases[0].value });
        }
        setDataErrorOrWarningSeverity('');
      } else {
        setDataErrorOrWarningSeverity('warning');
      }
    } else {
      if (result.message !== '') {
        navigate('/home');
        addMessageToStack({
          messageType: SnackbarMessageType.error,
          message: translate('There was a problem fetching stats.'),
          autoHide: false
        });
      } else {
        setDataErrorOrWarningSeverity('danger');
      }
    }
  };

  useAsyncEffect(async () => {
    if (herdId != 0) {
      setLoading(true);
      await fetchStats();
      setLoading(false);
    }
  }, [herdId, diseaseOption]);

  useEffect(() => {
    setPages(guardianPages(translate, permissions.prevention, permissions.diagnosis, permissions.visio, user !== undefined ? user['profile']['UserEmail'] : ''));
  }, [herdId]);

  return (
    <>
      <PageContainer hasFooter={false} loading={loading}>
        {dataErrorOrWarningSeverity === '' ?
          <>
            <Row>
              <Col width={'75%'}>
                <SubHeader>
                  <Row style={{ alignItems: 'center' }}>
                    <Col width={'3%'}>
                      <IconContainer>
                        <GraphUpIcon />
                      </IconContainer>
                    </Col>

                    <Col width={'90%'}>
                      {translate('Guardian Prevention Statistics')}
                    </Col>
                  </Row>
                </SubHeader>
              </Col>
            </Row>

            <Row>
              <div style={{ width: viewSize > ViewSize.S ? '450px' : '60%' }}>
                <Select name={translate('Disease')} field={`disease`} options={activeModels} selected={diseaseOption}
                  onChange={async (selected) => {
                    setDiseaseOption(selected);
                  }} />
              </div>
            </Row>

            {viewSize > ViewSize.S ?
              <>
                <Row>
                  <Col width={'50%'}>
                    <StatsTopLeftBarChart statsData={statsData} model={diseaseOption.value} />
                  </Col>

                  <Col width={'50%'}>
                    <PieChart statsData={statsData} model={diseaseOption.value} />
                  </Col>
                </Row>

                <Row>
                  <Col width={'50%'}>
                    <StatsBottomLeftBarChart statsData={statsData} model={diseaseOption.value} />
                  </Col>

                  <Col width={'50%'}>
                    <StatsBottomRightBarChart statsData={statsData} model={diseaseOption.value} />
                  </Col>
                </Row>
              </> :
              <>
                <Row>
                  <Col width='100%'>
                    <StatsTopLeftBarChart statsData={statsData} model={diseaseOption.value} />
                  </Col>
                </Row>

                <Row>
                  <Col width='100%'>
                    <PieChart statsData={statsData} model={diseaseOption.value} />
                  </Col>
                </Row>

                <Row>
                  <Col width='100%'>
                    <StatsBottomLeftBarChart statsData={statsData} model={diseaseOption.value} />
                  </Col>
                </Row>

                <Row>
                  <Col width='100%'>
                    <StatsBottomRightBarChart statsData={statsData} model={diseaseOption.value} />
                  </Col>
                </Row>
              </>
            }
          </> :
          <Alert maxWidth={viewSize > ViewSize.S ? '25%' : '75%'} severity={dataErrorOrWarningSeverity}>
            {dataErrorOrWarningSeverity === 'danger' ? <div>{herdId} - {translate('Data not available')}</div> :
              <div>{herdId} - {translate('No predictions yet. If this persists for several days, it might indicate incomplete/insufficient data for this module.')}</div>
            }
          </Alert>
        }
      </PageContainer>
    </>
  );
};