import Plotly, { Data, Layout } from "plotly.js";
import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NUMBERED_COLOURS, PLOTLY_TEMPLATE } from "../../../../pages/static";
import { HerdProductionForecastData } from "../../../../typings/visio/types";
import { ViewContext } from "@ddw/react-framework";
import { ViewSize } from "@ddw/react-components";

export const VisioHerdProductionForecastChart: React.FC<HerdProductionForecastData> = (props) => {
    const { t: translate } = useTranslation();
    const { viewSize } = useContext(ViewContext);

    const chartRef = useRef<HTMLDivElement>(null);

    const trace1Data: Data[] = [{
        x: props.producedMilkIndex,
        y: props.producedMilkValues,
        mode: 'lines',
        opacity: 1,
        name: props.producedMilkName,
        line: {
            color: NUMBERED_COLOURS[0]
        }
    }];

    const trace2Data: Data[] = props.trace2x.map((_: string[], index: number) => ({
        x: _,
        y: props.trace2y[index],
        mode: 'lines',
        opacity: 1,
        name: props.trace2name[index],
        line: {
            color: NUMBERED_COLOURS[index + 1],
            dash: 'dash'
        },
        yaxis: 'y2'
    }));

    const data: Data[] = [...trace1Data, ...trace2Data];

    const layout: Partial<Layout> = {
        title: viewSize > ViewSize.S ? translate('Herd Production Forecast: Milk and Solids') : '',
        xaxis: {
            title: translate('Date')
        },
        yaxis: {
            title: 'Daily Milk Yield (kg)',
            range: [0, props.maximumYAxisRangeValue]
        },
        yaxis2: {
            title: translate('Daily Solid Yield (%)'),
            overlaying: 'y',
            side: 'right',
            range: [0, 10]
        },
        showlegend: true,
        legend: {
            xanchor: 'center',
            x: 0.5,
            yanchor: 'bottom',
            y: 1,
            orientation: 'h'
        },
        hovermode: 'closest',
        template: PLOTLY_TEMPLATE
    };

    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
        }
    }, [viewSize]);

    return (
        <div ref={chartRef} />
    );
}