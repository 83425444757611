import React, { useState } from 'react';
import { Panel } from '@ddw/react-components';
import { Header, PanelContainer } from '../components';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../../component/PageContainer';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const HelpPage: React.FC = () => {
    const { t: translate } = useTranslation();

    type FAQ = {
      title: string;
      content: string;
    }

    const FAQs: FAQ[] = [
      {
        title: translate('How is heat stress in cattle defined?'),
        content: translate('The high metabolic activity of dairy cattle is exacerbated by high temperatures. Heat stress is generally defined as related to Temperature Humidity Index (THI) (Nguyen et al. 2017; Rees et al. 2016; Du Preez 2000).')
          + '\n\n' + translate('There are other studies relating temperature only to rectal temperature, using it to defined heat stress (Dikmen und Hansen 2009).')
          + '\n\n' + translate('There is doubt whether the models currently used are the best, and whether there is need for a more accurate system that allows a quick response against heat stress (Polsky und Keyserlingk 2017).')
      },
      {
        title: translate('What is the definition of THI?'),
        content: translate('The THI combines air temperature and relative humidity: lower temperatures at high humidity give similar heat-stress to higher temperatures at lower humidity (Dunn et al. 2014).')
          + `\n\n` + translate('One definition used by Dunn et al. for THI is:') + '\n' + translate('THI = (1.8T + 32)-(0.55-0.0055RH)x(T-26.8).')
          + '\n\n' + translate('Where T is the temperature in Celsius and RH is the relative humidity in %. It is based on a NRC recommendation \'A Guide to Environmental Research on Animals\' from 1971.')
          + '\n\n' + translate('There are other formulas to calculate a THI in relation to dairy cattle. An overview is given by (Dikmen und Hansen 2009). They concluded that \'Estimates of values of meteorological variables associated with specific rectal temperatures should prove valuable in relating environmental conditions to the magnitude of hyperthermia experienced by heat-stressed cows.\'. They found a fit in different calculation sets used where they gave an overview.')
      },
      {
        title: translate('What data is used to calculate THI?'),
        content: translate('Epidemiological studies or studies investigating the genetic components of heat stress are dealing with large numbers of individual animal data and SNPs, relating this to climate data. In order to calculate THI, they invariably use data from weather stations.')
          + '\n\n' + translate('Examples:') + '\n- ' + translate('(Bohmanova et al. 2008): \'Climatic data were available from 202 public weather stations. Each herd was assigned to the nearest weather station.\'')
          + '\n- ' + translate('(Nguyen et al. 2017): \'The distance between herds and weather stations was measured using the GPS  coordinates of each herd instead of its postcode centroid, where possible.\' \'herds that did not have a weather station within a 60-km radius were excluded from analyses.\'')
          + '\n- ' + translate('(Vitali et al. 2009): \'The main reasons for limiting the THI-mortality relationships study to this geographic area were the high concentration of dairy farms (approximately 60% of the Italian dairy cow population) and the large number of weather stations, which enabled us to obtain temperature and relative humidity data.\'')
      },
      {
        title: translate('What THI does mean \'heat stress\'?'),
        content: translate('Generally, a THI of >70 is considered as causing \'heat stress\' (Dunn et al. 2014). Other authors claim a THI >72 as causing \'discomfort\' (Polsky und Keyserlingk 2017; Rees et al. 2016). The number of days with the respective THI may have to be taken into account (Polsky und Keyserlingk 2017). One study tried to measure Cortisol-metabolites as indicator of stress in relation to a THI of >72 (Rees et al. 2016).')
          + '\n\n' + translate('However, a decrease in milk yield and associated reduced dry matter intake were observed already at a THI of >60, while water intake is seen to increase in a linear way from THI 30. Milk solids (Protein, fat) were only affected by decrease of the milk yield, i.e. not themselves (Gorniak et al. 2014).')
      },
      {
        title: translate('What aspects are to be considered regarding heat stress?'),
        content: translate('It seems to be clear that there is a genetic component as well as an influence of breed in heat tolerance, which has been subject of several studies (Bohmanova et al. 2008; Gantner et al. 2017; Nguyen et al. 2017; Santana et al. 2017).')
          + '\n\n' + translate('It does not seem surprising that high producing animals seem to be more prone to heat stress (Gantner et al. 2017). It has been shown that heat stress negatively affects a variety of physiological parameters (Srikandakumar und Johnson 2004; Tao und Dahl 2013).')
          + '\n\n' + translate('There are different estimates on the actual influence of heat stress on milk production: It has been estimated, that for every day over the heat stress threshold, a cow may loose as much as 0,2 kg per day (Ravagnolo und Misztal 2000), while others have estimated this loss being as high as 0,9 kg per day (West et al. 2003).')
      }
    ];

    const [expanded, setExpanded] = useState<string | false>('');
    const handleOnPanelChange = (panelId: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panelId : false);
    };

    return (
      <>
        <PageContainer>
          <Header>? Frequently Asked Questions</Header>
          <PanelContainer>
            {FAQs.map((faq: FAQ, index) => {
              return (<div key={index}>
                  <Panel index={index} title={faq.title} content={
                    <ReactMarkdown remarkPlugins={[remarkGfm]} children={faq.content} />
                  }
                         expanded={expanded === `panel-id-${index}`}
                         handleOnPanelChange={() => handleOnPanelChange(`panel-id-${index}`)} />
                  <br></br>
                </div>
              );
            })}
          </PanelContainer>
        </PageContainer>
      </>
    );
  }
;
