import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Plotly from 'plotly.js';
import { Data, Layout } from 'plotly.js';
import { getObjectValues } from '../../../../pages/utils';
import { BAR_WIDTH, DDW_green } from '../../../../pages/static';
import { ViewContext } from '@ddw/react-framework';
import { ViewSize } from '@ddw/react-components';

export const FeedbackMiddleLeftBarChart: React.FC<any> = ({ dfCase3 }) => {
  const { t: translate } = useTranslation();
  const { viewSize } = useContext(ViewContext);

  const chartRef = useRef<HTMLDivElement>(null);

  const data: Data[] = [
    {
      x: dfCase3[0],
      y: dfCase3[1],
      type: 'bar',
      opacity: 1.,
      width: BAR_WIDTH,
      name: translate('Alarm Types'),
      marker: {
        color: DDW_green
      }
    }
  ];

  const layout: Partial<Layout> = {
    title: viewSize > ViewSize.S ? translate('Percentage share of correct alarms by disease type and overall') : '',
    showlegend: true,
    legend: {
      xanchor: 'center',
      x: 0.5,
      yanchor: 'bottom',
      y: 1,
      orientation: 'h'
    },
    xaxis: {
      tickangle: -30
    },
    hovermode: 'closest'
  };

  useEffect(() => {
    if (chartRef.current) {
      Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
    }
  }, [viewSize]);

  return (
    <>
      <div ref={chartRef} />
    </>
  );
};