import React, { useContext, useEffect, useState } from 'react';
import { PageContainer } from '../../../../component/PageContainer';
import { useTranslation } from 'react-i18next';
import { Col, PanelContainer, Row, SubHeader } from '../../../components';
import { QuestionIcon } from '../../../../component/Icons';
import { Tabs } from 'antd';
import { FAQ, getGeneralFAQs, getKetosisFAQs } from './faqs';
import { Panel } from '@ddw/react-components';
import ReactMarkdown from 'react-markdown';
import { PagesContext } from '../../../../context/PagesContext';
import { guardianPages } from '../../../pages';
import { HerdProfileContext } from '../../../../context';
import remarkGfm from 'remark-gfm';
import { AuthenticationContext } from '@ddw/react-framework';

export const GuardianPreventionHelpPage: React.FC = () => {
    const { t: translate } = useTranslation();
    const { herdId, permissions } = useContext(HerdProfileContext);
    const { setPages } = useContext(PagesContext);
    const { user } = useContext(AuthenticationContext);

    const [expanded, setExpanded] = useState<string | false>('');

    const handleOnPanelChange = (panelId: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panelId : false);
    };

    useEffect(() => {
      setPages(guardianPages(translate, permissions.prevention, permissions.diagnosis, permissions.visio, user !== undefined ? user['profile']['UserEmail'] : ''));
    }, [herdId]);

    return (
      <>
        <PageContainer hasFooter={false}>
          <SubHeader>
            <Row>
              <Col width={'3%'}>
                <QuestionIcon />
              </Col>
              <Col width={'90%'}>
                {translate('Frequently Asked Questions')}
              </Col>
            </Row>
          </SubHeader>
          <Row>
            <Tabs defaultActiveKey='1' items={[
              {
                key: '1',
                label: translate('General'),
                children: <>
                  <PanelContainer>
                    {getGeneralFAQs(translate).map((faq: FAQ, index) => {
                      return (
                        <>
                          <Panel index={index} title={faq.title} content={
                            <ReactMarkdown children={faq.content} remarkPlugins={[remarkGfm]} />
                          }
                                 expanded={expanded === `panel-id-${index}`}
                                 handleOnPanelChange={() => handleOnPanelChange(`panel-id-${index}`)} />
                          <br />
                        </>
                      );
                    })}
                  </PanelContainer>
                </>
              },
              {
                key: '2',
                label: translate('Ketosis'),
                children: <>
                  <PanelContainer>
                    {getKetosisFAQs(translate).map((faq: FAQ, index) => {
                      return (
                        <>
                          <Panel index={index} title={faq.title} content={
                            <ReactMarkdown remarkPlugins={[remarkGfm]} children={faq.content} />
                          }
                                 expanded={expanded === `panel-id-${index}`}
                                 handleOnPanelChange={() => handleOnPanelChange(`panel-id-${index}`)} />
                          <br />
                        </>
                      );
                    })}
                  </PanelContainer>
                </>
              }
            ]} />
          </Row>
        </PageContainer>
      </>
    )
      ;
  }
;