import Plotly, { Data, Layout } from 'plotly.js';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CalvingIntervalData, CalvingIntervals } from '../../../../../typings/herdfolio/types';
import { DDW_blue, DDW_orange, PLOTLY_TEMPLATE } from '../../../../../pages/static';

export const CalvingIntervalBarChart: React.FC<CalvingIntervals> = (props) => {
    const { t: translate } = useTranslation();

    const chartRef = useRef<HTMLDivElement>(null);

    const data: Data[] = [
        {
            x: props.period,
            y: props.calvingIntervals.map(() => null),
            type: 'bar',
            name: translate('Target'),
            marker:{
                color: '#90EE90'
            }
        },
        {
            x: props.period,
            y: props.calvingIntervals.map((calvingInterval: CalvingIntervalData) => (calvingInterval.dryOffDays).toFixed(0)),
            type: 'bar',
            opacity: 1.0,
            name: translate('Dry Period'),
            marker: {
                color: DDW_orange
            }
        },
        {
            x: props.period,
            y: props.calvingIntervals.map((calvingInterval: CalvingIntervalData) => (calvingInterval.calvingInterval - calvingInterval.dryOffDays).toFixed(0)),
            type: 'bar',
            opacity: 1.0,
            name: translate('Lactation'),
            marker: {
                color: DDW_blue
            }
        }
    ];

    const layout: Partial<Layout> = {
        title: {
            text: translate('Calving Interval'),
        },
        legend: {
            x: 0,
            y: -0.2,
            orientation: 'h'
        },
        barmode: 'stack',
        hovermode: 'closest',
        shapes: [
            {
                type: 'rect',
                xref: 'paper',
                yref: 'y',
                x0: 0,
                x1: 1,
                y0: 370,
                y1: 415,
                fillcolor: '#90EE90',
                opacity: 0.9,
                layer: 'above'
            }
        ],
        template: PLOTLY_TEMPLATE
    };

    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
        }
    }, [props]);

    return (
        <>
            <div ref={chartRef} />
        </>
    );
};