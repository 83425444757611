import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Plotly from 'plotly.js';
import { getColor, getObjectValues } from '../../../../pages/utils';
import { Data, Layout } from 'plotly.js';
import { ViewContext } from '@ddw/react-framework';
import { ViewSize } from '@ddw/react-components';

export const StatsBottomLeftBarChart: React.FC<any> = ({ statsData, model }) => {
  const { t: translate } = useTranslation();
  const { viewSize } = useContext(ViewContext);

  const chartRef = useRef<HTMLDivElement>(null);

  const data: Data[] = [
    {
      x: getObjectValues<string>(statsData.statsPath.Period).map((period: string) => translate(period.substring(0, 3)) + period.substring(3)),
      y: getObjectValues<number>(statsData.statsPath["Total Alarms"]),
      opacity: 1,
      name: translate("Total Alarms"),
      marker: { color: getColor(model, 0) },
      type: "bar",
    },
    {
      x: getObjectValues<string>(statsData.statsPath.Period).map((period: string) => translate(period.substring(0, 3)) + period.substring(3)),
      y: getObjectValues<number>(statsData.statsPath["Observed Cases"]),
      opacity: 1,
      name: translate("Observed Cases"),
      marker: { color: getColor(model, 1) },
      type: "bar",
    }
  ];

  const layout: Partial<Layout> = {
    title: viewSize > ViewSize.S ? translate("Alarms and reported cases - last 12 months") : '',
    showlegend: true,
    legend: {
      xanchor: "center",
      x: 0.5,
      yanchor: "bottom",
      y: 1,
      orientation: "h",
    },
    xaxis: { tickangle: -30 },
    hovermode: "closest"
  }

  useEffect(() => {
    if (chartRef.current) {
      Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
      //Plotly.newPlot(chartRef.current, data, layout);
    }
  }, [viewSize]);

  return (
    <>
      <div ref={chartRef} />
    </>
  );
};