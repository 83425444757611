import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Plotly from 'plotly.js';
import { Data, Layout } from 'plotly.js';
import { DDW_blue, DDW_cyan } from '../../../../../pages/static';

export const DiagnosisPieChart: React.FC<any> = ({ lastSession }) => {
  const { t: translate } = useTranslation();

  const chartRef = useRef<HTMLDivElement>(null);

  const data: Data[] =
    [{
      type: 'pie',
      labels: [translate('Alarmed'), translate('Not alarmed')],
      values: [lastSession['Alarmed'], lastSession['Not Alarmed']],
      marker: {
        colors: [DDW_cyan, DDW_blue]
      },
      hole: 0.6
    }];

  const layout: Partial<Layout> = {
    title: translate('Alarm rate - last session'),
    showlegend: true,
    legend: {
      xanchor: 'left',
      x: 0.85,
      yanchor: 'top',
      y: 1
    },
    hovermode: 'closest'
  };


  useEffect(() => {
    if (chartRef.current) {
      Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
    }
  }, []);

  return (
    <>
      <div ref={chartRef} />
    </>
  );
};