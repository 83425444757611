import { DDWApplication, putHeaders } from '@ddw/react-components';
import { urlState } from '../../pages/utils';

export const downloadActiveAlarms = (accessToken: string, application: DDWApplication, herdId: number): Promise<any> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + 'downloads/guardian_prevention_alarms/' + herdId;
  return fetch(url, { headers, method: 'GET' });
};

export const downloadDiagnosisAlarms = (accessToken: string, application: DDWApplication, herdId: number): Promise<any> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + 'downloads/guardian_diagnosis_alarms/' + herdId;
  return fetch(url, { headers, method: 'GET' });
};

export const downloadHistoricalAlarms = (accessToken: string, application: DDWApplication, herdId: number): Promise<any> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + 'downloads/guardian_prevention_hist_alarms/' + herdId;
  return fetch(url, { headers, method: 'GET' });
};

export const downloadActiveIssues = (accessToken: string, application: DDWApplication, herdId: number): Promise<any> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + 'downloads/guardian_prevention_issues/' + herdId;
  return fetch(url, { headers, method: 'GET' });
};

export const downloadDiagnosisIssues = (accessToken: string, application: DDWApplication, herdId: number): Promise<any> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + 'downloads/guardian_diagnosis_issues/' + herdId;
  return fetch(url, { headers, method: 'GET' });
};

export const downloadVisioPrescriptions = (accessToken: string, application: DDWApplication, herdId: number): Promise<any> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + 'downloads/visio_prescriptions/' + herdId;
  return fetch(url, { headers, method: 'GET' });
};
