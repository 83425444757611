import { DDWApplication, parseError, putHeaders, statusHelper, toJSON } from '@ddw/react-components';
import { ServiceResult } from '@ddw/react-framework/build/src/types';
import { Stats } from '../../typings/types';
import { urlState } from '../../pages/utils';

export const fetchStats = (accessToken: string, application: DDWApplication, herdId: number, modelSelected: string): Promise<ServiceResult<Stats>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  headers.set('model-selected', modelSelected);
  const url = urlState.url + `herds/` + herdId + '/stats';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};