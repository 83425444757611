import React, { PropsWithChildren, createContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const context = {
  sessionId: '',
  setSessionId: async (sessionId: string) => {}
};

export const SessionContext = createContext(context);

export const SessionContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [sessionId, setSessionIdentifier] = useState<string>(() => {
    let savedSessionId = sessionStorage.getItem('sessionId');
    if (savedSessionId === null) {
      savedSessionId = uuidv4();
      sessionStorage.setItem('sessionId', savedSessionId);
    }
    return savedSessionId;
  });

  const setSessionId = async (sessionId: string) => {
    setSessionIdentifier(sessionId);
  };

  return <SessionContext.Provider value={{ sessionId, setSessionId }}>{children}</SessionContext.Provider>;
};
