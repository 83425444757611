import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Plotly from 'plotly.js';
import { Data, Layout } from 'plotly.js';
import { getObjectValues } from '../../../../pages/utils';

export const FeedbackTopRightPieChart: React.FC<any> = ({ dfCase2 }) => {
  const { t: translate } = useTranslation();

  const chartRef = useRef<HTMLDivElement>(null);

  const data: Data[] = [{
    labels: dfCase2[0],
    values: dfCase2[1],
    type: 'pie',
    marker: {
      colors: [
        'rgb(102, 197, 204)',
        'rgb(246, 207, 113)',
        'rgb(248, 156, 116)',
        'rgb(220, 176, 242)',
        'rgb(135, 197, 95)',
        'rgb(158, 185, 243)',
        'rgb(254, 136, 177)',
        'rgb(201, 219, 116)',
        'rgb(139, 224, 164)',
        'rgb(180, 151, 231)',
        'rgb(179, 179, 179)'
      ]
    },
    hole: 0.6
  }];

  const layout: Partial<Layout> = {
    title: translate('Reasons for non-compliance'),
    showlegend: true,
    legend: {
      xanchor: 'left',
      x: 0.85,
      yanchor: 'top',
      y: 1
    },
    hovermode: 'closest'
  };

  useEffect(() => {
    if (chartRef.current) {
      Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
    }
  }, []);

  return (
    <>
      <div ref={chartRef} />
    </>
  );
};