import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Plotly from 'plotly.js';
import { Data, Layout } from 'plotly.js';
import { DDW_cyan } from '../../../../../pages/static';
import { getObjectValues, rollingAverage } from '../../../../../pages/utils';

export const DiagnosisTopRightChart: React.FC<any> = ({ stats }) => {
  const { t: translate } = useTranslation();

  const chartRef = useRef<HTMLDivElement>(null);

  const data: Data[] = [
    {
      x: getObjectValues<string>(stats['Executed']).map((value: string) => value.split('T')[0] + ' ' + value.split('T')[1]),
      y: getObjectValues<number>(stats['Alarmed']).map((value: number, index) => value / getObjectValues<number>(stats['Eligible'])[index]),
      customdata: getObjectValues<number>(stats['Alarmed']),
      name: translate('Alarm rate'),
      type: 'scatter',
      mode: 'lines',
      line: {
        color: DDW_cyan
      },
      hovertemplate: translate('Executed: %{x}<br>Alarm rate: %{y:.2%}<br>Alarms raised: %{customdata}'
      )
    },
    {
      x: getObjectValues<string>(stats['Executed']).map((value: string) => value.split('T')[0] + ' ' + value.split('T')[1]),
      y: rollingAverage(getObjectValues<number>(stats['Alarmed']).map((value: number, index) => value / getObjectValues<number>(stats['Eligible'])[index]), 5),
      name: translate('Rolling'),
      type: 'scatter',
      mode: 'lines',
      line: {
        color: '#000000',
        dash: 'dot'
      },
      hovertemplate: translate('Executed: %{x}<br>Rolling mean: %{y:.2%}')
    }
  ];

  const layout: Partial<Layout> = {
    title: translate('Alarm rate - last 30 days'),
    showlegend: true,
    legend: {
      xanchor: 'center',
      x: 0.5,
      yanchor: 'bottom',
      y: 1,
      orientation: 'h'
    },
    yaxis: {
      tickformat: ',.0%',
      range: [0, 0.1]
    },
    hovermode: 'closest'
  };

  useEffect(() => {
    if (chartRef.current) {
      Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
    }
  }, []);

  return (
    <>
      <div ref={chartRef} />
    </>
  );
};
