import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIcon, SvgIconTypeMap } from '@material-ui/core';
import React from 'react';

export const AlarmDiffRightIcon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> = (props: any) => {
  return (
    <SvgIcon {...props} style={{fill: "#e6b800"}} viewBox='0 0 16 16'>
      <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
    </SvgIcon>
  );
};