import React, { useContext } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { AuthenticationContext, User } from '@ddw/react-framework';
import { PagesContext } from '../../context/PagesContext';
import { HerdProfileContext } from '../../context';
import { GuardianDiagnosisRoute, GuardianPreventionRoute, PrescriptionsRoute, VisioRoute, guardianRoutes, visioRoutes } from '../../pages/pages';

declare type Props = {
  activeUser: User | undefined;
  element: JSX.Element;
}

export const RoutesWrapper: React.FC<Props> = ({ activeUser, element }) => {
  const { pages } = useContext(PagesContext);
  const { permissions } = useContext(HerdProfileContext);
  const { user } = useContext(AuthenticationContext);

  return (
    <Routes>
      <Route element={element}>
        {pages.map((page) => {
          if ((page.route === GuardianPreventionRoute && !permissions.prevention) ||
            (page.route === GuardianDiagnosisRoute && !permissions.diagnosis) ||
            (page.route === VisioRoute && (user !== undefined && !(user['profile']['UserEmail'].endsWith("@dairydatawarehouse.com") || user['profile']['UserEmail'].endsWith("@delaval.com"))))) {
            return;
          }
          const Content = page.content || Outlet;
          return (
            <Route key={page.route} path={page.route} element={<Content />}>
              {page.subPages?.map((subPage) => {
                const Content = subPage.content || Outlet;
                if (subPage.exclusiveDDWAdmin == true && activeUser?.profile?.DDWAdmin != '1') return;
                return (
                  <Route
                    key={page.route + subPage.route}
                    path={page.route + subPage.route}
                    element={<Content />}
                  ></Route>
                );
              })}
            </Route>
          );
        })}
        <Route path='*' key={'default'} element={<Navigate replace to={
          (
            (
              guardianRoutes.includes(window.location.pathname)
              && !(window.location.pathname.startsWith('/guardian-prevention') && sessionStorage.getItem('preventionPermission') !== 'true')
              && !(window.location.pathname.startsWith('/guardian-diagnosis') && sessionStorage.getItem('diagnosisPermission') !== 'true')
            )

            ||

            (
              visioRoutes.includes(window.location.pathname) && sessionStorage.getItem('visioPermission') === 'true'
            )

          ) ? window.location.pathname : pages[0].route} />} />
      </Route>
    </Routes>
  );
};