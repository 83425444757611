import React from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@material-ui/icons';

type DismissibleCardProps = {
  title: string;
  subheader: string;
  content: string;
  buttonName: string;
  onClick: Function;
}

export const DismissibleCard: React.FC<DismissibleCardProps> = (props) => {
  const [dismissed, setDismissed] = React.useState(false);

  const handleDismiss = () => {
    setDismissed(true);
  };

  if (dismissed) {
    return null;
  }

  return (
    <>
      <Card>
        <CardHeader
          title={props.title}
          subheader={props.subheader}
          action={
            <IconButton onClick={handleDismiss}>
              <CloseIcon />
            </IconButton>
          }
        />
        <CardContent>{props.content}</CardContent>
        <CardActions>
          <Button size='small' onClick={() => {
            props.onClick();
          }}>{props.buttonName}</Button>
        </CardActions>
      </Card>
    </>
  );
};