import { AuthenticationContext, SnackbarMessageType, SnackbarMessagesContext, ViewContext } from "@ddw/react-framework";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { HerdProfileContext } from "../../../context";
import { PagesContext } from "../../../context/PagesContext";
import { Api } from "../../../services/api";
import { DDWApplication, Select, SelectOption, ViewSize } from "@ddw/react-components";
import { useLocation, useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { PageContainer } from "../../../component/PageContainer";
import { Alert, BoldSpan, Col, IconContainer, Label, Paragraph, Row, Span, SubHeader } from "../../components";
import { HerdSelectorSelectParent } from "../../../component/HerdSelector/herdselector.styled";
import { VisioAnimalPerformanceChart } from "../../../component/Charts/Visio/VisioAnimalPerformanceChart/VisioAnimalPerformanceChart";
import { AnimalHistoricAndFuturePerformance, DryOffData } from "../../../typings/visio/types";
import { BucketFillIcon } from "../../../component/Icons/BucketFillIcon";
import { Card, Radio, Table, Tabs } from "antd";
import './AnimalPage.css';
import { findMax, formatDate } from "../../utils";
import { DDW_blue, DDW_green, DDW_orange, DDW_pink, DDW_yellow, SCORE_RULE_ID } from "../../static";
import { Slider, Tooltip } from "@mui/material";
import { InfoCircleFillIcon } from "../../../component/Icons";
import { AnimalComparisonChart } from "../../../component/Charts/Visio/VisioAnimalPerformanceChart/AnimalComparisonChart";
import { ColumnsType } from "antd/es/table";
import { mainPages } from "../../pages";

interface ComparisonTableDataType {
    analysis: string;
    value: string | number;
};

export const AnimalPage: React.FC = () => {
    const { t: translate } = useTranslation();
    const { herdId, permissions } = useContext(HerdProfileContext);
    const { getAccessToken } = useContext(AuthenticationContext);
    const { addMessageToStack } = useContext(SnackbarMessagesContext);
    const { setPages } = useContext(PagesContext);
    const { viewSize } = useContext(ViewContext);

    const [earTags, setEarTags] = useState<SelectOption[]>([]);
    const [earTagSelected, setEarTagSelected] = useState<SelectOption>({ label: '', value: '' });
    const [performanceData, setPerformanceData] = useState<AnimalHistoricAndFuturePerformance>();

    const [compareToEarTags, setCompareToEarTags] = useState<SelectOption[]>([]);
    const [earTagSelectedForComparison, setEarTagSelectedForComparison] = useState<SelectOption>({ label: '', value: '' });

    const comparisonTypeOptions: SelectOption[] = [
        { label: translate('Time horizon'), value: 'timeline' },
        { label: translate('Current'), value: 'cur' },
        { label: translate('Next'), value: 'nxt' }
    ];
    const [comparisonType, setComparisonType] = useState<SelectOption>(comparisonTypeOptions[0]);

    const [lactationTabs, setLactationTabs] = useState([
        { label: translate('MAX'), key: 'MAX', disabled: false },
        { label: '5L', key: '5', disabled: false },
        { label: '4L', key: '4', disabled: false },
        { label: '3L', key: '3', disabled: false },
        { label: '2L', key: '2', disabled: false },
        { label: '1L', key: '1', disabled: false },
    ]);
    const defaultActiveLactationTabKey: string = '1';
    const [activeLactationTabKey, setActiveLactationTabKey] = useState<string>(defaultActiveLactationTabKey);

    const [infoActive, setInfoActive] = useState<{ [index: string]: boolean }>({
        "healthEvents": true,
        "heathStress": true,
        "dryOff": true,
        "insemination": true,
        "groupChange": true
    });

    const navigate = useNavigate();
    const location = useLocation();
    const searchParams: URLSearchParams = new URLSearchParams(location.search);
    const eartagFromURL: string | null = searchParams.get('eartag');

    const [loading, setLoading] = useState(true);
    const [dataErrorOrWarningSeverity, setDataErrorOrWarningSeverity] = useState('');

    const healthEventsTileRef = useRef<HTMLDivElement>(null);
    const heatStressTileRef = useRef<HTMLDivElement>(null);
    const dryOffTileRef = useRef<HTMLDivElement>(null);
    const conceptionTileRef = useRef<HTMLDivElement>(null);
    const groupChangeTileRef = useRef<HTMLDivElement>(null);

    const [dataMode, setDataMode] = useState<string>('historical');

    const [qualityIssues, setQualityIssues] = useState([]);

    const marks: { value: number, label: string }[] = [
        { value: 25, label: '25' },
        { value: 50, label: '50' },
        { value: 75, label: '75' },
        { value: 100, label: '100' },
        { value: 125, label: '125' },
        { value: 150, label: '150' },
        { value: 175, label: '175' },
        { value: 200, label: '200' },
        { value: 225, label: '225' },
        { value: 250, label: '250' },
        { value: 275, label: '275' },
        { value: 300, label: '300' },
        { value: 325, label: '325' },
        { value: 365, label: '365' }
    ];
    const [sliderValue, setSliderValue] = useState<number>(365);

    // this is in order to avoid sending many requests when modifying the sensitivity slider (before the slider is released)
    const [sliderClicked, setSliderClicked] = useState<boolean>(false);

    const handleSliderChange = (event: any, newValue: number | number[]) => {
        setSliderValue(newValue as number);
    };

    const [comparisonData, setComparisonData] = useState<any>();
    const [comparisonError, setComparisonError] = useState<boolean>(false);

    const comparisonTableColumns: ColumnsType<ComparisonTableDataType> = [
        {
            title: translate('Analysis'),
            dataIndex: 'analysis',
            key: 'analysis'
        },
        {
            title: translate('Value'),
            dataIndex: 'value',
            key: 'value'
        }
    ];
    const [comparisonTableData, setComparisonTableData] = useState<ComparisonTableDataType[]>([]);

    const onLactationTabChange = async (activeKey: string) => {
        setActiveLactationTabKey(activeKey);
        document.body.style.cursor = 'progress';
        await fetchPerformanceData(earTagSelected.value, activeKey);
        document.body.style.cursor = 'default';
    };

    const fetchPerformanceData = async (earTag: string, lactation: string) => {
        const result = await Api.visio.fetchAnimalHistoricAndFuturePerformance(getAccessToken(), DDWApplication.DairyInsights, herdId, earTag, lactation);
        if (result.success) {
            if (result.message === '') {
                setPerformanceData(result.data);

                const numberOfLactations: number = result.data.numberOfLactations;
                setLactationTabs([
                    { label: translate('MAX') + ' (' + numberOfLactations + 'L)', key: 'MAX', disabled: false },
                    { label: '5L', key: '5', disabled: numberOfLactations < 5 },
                    { label: '4L', key: '4', disabled: numberOfLactations < 4 },
                    { label: '3L', key: '3', disabled: numberOfLactations < 3 },
                    { label: '2L', key: '2', disabled: numberOfLactations < 2 },
                    { label: '1L', key: '1', disabled: false },
                ]);
                setDataErrorOrWarningSeverity('');

                if (healthEventsTileRef.current) {
                    healthEventsTileRef.current.style.height = 'auto';
                }
                if (heatStressTileRef.current) {
                    heatStressTileRef.current.style.height = 'auto';
                }
                if (groupChangeTileRef.current) {
                    groupChangeTileRef.current.style.height = 'auto';
                }
                if (conceptionTileRef.current) {
                    conceptionTileRef.current.style.height = 'auto';
                }
                if (dryOffTileRef.current) {
                    dryOffTileRef.current.style.height = 'auto';
                }

                const healthEventsTileHeight: number = healthEventsTileRef.current?.clientHeight ? healthEventsTileRef.current?.clientHeight : 0;
                const heatStressTileHeight: number = heatStressTileRef.current?.clientHeight ? heatStressTileRef.current?.clientHeight : 0;
                const groupChangeTileHeight: number = groupChangeTileRef.current?.clientHeight ? groupChangeTileRef.current?.clientHeight : 0;
                const conceptionTileHeight = conceptionTileRef.current?.clientHeight ? conceptionTileRef.current?.clientHeight : 0;
                const dryOffTileHeight: number = dryOffTileRef.current?.clientHeight ? dryOffTileRef.current?.clientHeight : 0;

                const maxHeight: number = findMax(healthEventsTileHeight, heatStressTileHeight, groupChangeTileHeight, conceptionTileHeight, dryOffTileHeight);

                if (maxHeight > 0) {
                    if (healthEventsTileRef.current) {
                        healthEventsTileRef.current.style.height = `${maxHeight}px`;
                    }
                    if (heatStressTileRef.current) {
                        heatStressTileRef.current.style.height = `${maxHeight}px`;
                    }
                    if (groupChangeTileRef.current) {
                        groupChangeTileRef.current.style.height = `${maxHeight}px`;
                    }
                    if (conceptionTileRef.current) {
                        conceptionTileRef.current.style.height = `${maxHeight}px`;
                    }
                    if (dryOffTileRef.current) {
                        dryOffTileRef.current.style.height = `${maxHeight}px`;
                    }
                }
            } else {
                setDataErrorOrWarningSeverity('warning');
            }
        } else {
            if (result.message !== '') {
                navigate('/home');
                addMessageToStack({
                    messageType: SnackbarMessageType.error,
                    message: translate('There was a problem fetching historic and future performance for animal with ear tag {{eartag}}.', {
                        eartag: earTag
                    }),
                    autoHide: false
                });
            } else {
                setDataErrorOrWarningSeverity('danger');
            }
        }
    };

    const fetchAnimalDataQuality = async () => {
        const result = await Api.visio.fetchAnimalDataQuality(getAccessToken(), DDWApplication.DairyInsights, herdId, earTagSelected.value);

        if (result.success) {
            setQualityIssues(result.data);
        }
    };

    const fetchEarTags = async () => {
        const result = await Api.visio.fetchAnimalEarTags(getAccessToken(), DDWApplication.DairyInsights, herdId);
        if (result.success) {
            if (result.message === '') {
                const _eartags: SelectOption[] = result.data.eartagsAndCowNumbers.map((earTagAndCowNumber: string[]) => { return { label: earTagAndCowNumber[0] + ' (' + translate('Cow') + ' #' + earTagAndCowNumber[1] + ')', value: earTagAndCowNumber[0] } });
                setEarTags(_eartags);
                const benchmarksPlusEartags: SelectOption[] = result.data.benchmarks.map((benchmark: string) => { return { label: benchmark, value: benchmark } }).concat(result.data.eartagsAndCowNumbers.map((earTagAndCowNumber: string[]) => { return { label: earTagAndCowNumber[0] + ' (' + translate('Cow') + ' #' + earTagAndCowNumber[1] + ')', value: earTagAndCowNumber[0] } }));
                setCompareToEarTags(benchmarksPlusEartags);
                setEarTagSelectedForComparison(benchmarksPlusEartags[0]);
                if (location.state?.eartag && location.state.cowNumber) {
                    setEarTagSelected({ label: location.state.eartag + ' (' + translate('Cow') + ' #' + location.state.cowNumber + ')', value: location.state.eartag });
                } else if (eartagFromURL !== null) {
                    const _foundEarTagCowNumberPair = _eartags.find(option => option.label.startsWith(eartagFromURL));
                    setEarTagSelected({ label: _foundEarTagCowNumberPair!.label, value: eartagFromURL });
                } else {
                    setEarTagSelected({ label: result.data.eartagsAndCowNumbers[0][0] + ' (' + translate('Cow') + ' #' + result.data.eartagsAndCowNumbers[0][1] + ')', value: result.data.eartagsAndCowNumbers[0][0] });
                }
                setDataErrorOrWarningSeverity('');
            } else {
                setDataErrorOrWarningSeverity('warning');
            }
        } else {
            if (result.message !== '') {
                navigate('/home');
                addMessageToStack({
                    messageType: SnackbarMessageType.error,
                    message: translate('There was a problem fetching animal ear tags.'),
                    autoHide: false
                });
            } else {
                setDataErrorOrWarningSeverity('danger');
            }
        }
    };

    useAsyncEffect(async () => {
        if (earTagSelected.value !== '') {
            setActiveLactationTabKey(defaultActiveLactationTabKey);
            setQualityIssues([]);
            document.body.style.cursor = 'progress';
            await fetchPerformanceData(earTagSelected.value, defaultActiveLactationTabKey);
            await fetchAnimalDataQuality();
            document.body.style.cursor = 'default';
        }
    }, [earTagSelected]);

    useAsyncEffect(async () => {
        if (dataMode === 'compare' && permissions.visio && !sliderClicked) {
            document.body.style.cursor = 'progress';
            const result = await Api.visio.fetchAnimalComparison(getAccessToken(), DDWApplication.DairyInsights, herdId, earTagSelected.value, earTagSelectedForComparison.value, comparisonType.value, sliderValue);

            if (result.success) {
                setComparisonData(result.data);
                setComparisonTableData(result.data.tableData.map((value: any) => {
                    return {
                        analysis: translate(value['Analysis']),
                        value: value['Value']
                    };
                }));
                setComparisonError(false);
            } else {
                setComparisonError(true);
            }
            document.body.style.cursor = 'default';
        }
    }, [dataMode, earTagSelected, earTagSelectedForComparison, comparisonType, sliderValue, sliderClicked]);

    useAsyncEffect(async () => {
        // setPages(mainPages(translate, permissions.visio));
        if (herdId != 0) {
            setLoading(true);
            await fetchEarTags();
            setLoading(false);
        }
    }, [herdId]);

    useEffect(() => {
        return () => {
            setEarTags([]);
            setCompareToEarTags([]);
            setEarTagSelected({ label: '', value: '' });
            setEarTagSelectedForComparison({ label: '', value: '' });
            setComparisonType(comparisonTypeOptions[0]);
            setSliderValue(365);
            setActiveLactationTabKey(defaultActiveLactationTabKey);
            let infoActiveCopy = { ...infoActive };
            infoActiveCopy['healthEvents'] = true;
            infoActiveCopy['heatStress'] = true;
            infoActiveCopy['insemination'] = true;
            infoActiveCopy['groupChange'] = true;
            infoActiveCopy['dryOff'] = true;
            setInfoActive(infoActiveCopy);
            setDataMode('historical');
            setComparisonError(false);
            setComparisonTableData([]);
            setQualityIssues([]);
        };
    }, [herdId]);

    return (
        <PageContainer hasFooter={true} loading={loading}>
            {dataErrorOrWarningSeverity === '' ?
                <>
                    <Row>
                        <Col>
                            <SubHeader>
                                <Row style={{ alignItems: 'center' }}>
                                    <Col width={'3%'}>
                                        <IconContainer>
                                            <BucketFillIcon />
                                        </IconContainer>
                                    </Col>

                                    <Col width={'97%'}>
                                        {translate('Animal - historic and future performance')}
                                    </Col>
                                </Row>
                            </SubHeader>
                        </Col>
                    </Row>

                    <Row>
                        <Radio.Group value={dataMode} onChange={(e) => setDataMode(e.target.value)}>
                            <Radio.Button value={'historical'}>{translate('Historical')}</Radio.Button>
                            <Radio.Button value={'predicted'}>{translate('Predicted')}</Radio.Button>
                            <Radio.Button value={'compare'}>{translate('Compare')}</Radio.Button>
                        </Radio.Group>
                    </Row>

                    <Row>
                        <Col width="80%">
                            <Row className={'mt-3'} style={{ alignItems: 'center' }}>
                                <Col width={'10%'}>
                                    <Label>{translate('Animal')}</Label>
                                </Col>

                                <Col width={viewSize > ViewSize.S ? 'auto' : '30%'}>
                                    <HerdSelectorSelectParent>
                                        <Select name={translate('EarTag (Cow #)')} field={`eartag`} options={earTags} selected={earTagSelected}
                                            onChange={async (selected) => {
                                                setEarTagSelected(selected);
                                            }} />
                                    </HerdSelectorSelectParent>
                                </Col>

                                {dataMode === 'compare' &&
                                    <>
                                        <Col width={'10%'}>
                                            <Label>{translate('Compare to')}</Label>
                                        </Col>

                                        <Col width={viewSize > ViewSize.S ? 'auto' : '30%'}>
                                            <HerdSelectorSelectParent>
                                                <Select name={translate('Compare to')} field={`compare-to`} options={compareToEarTags} selected={earTagSelectedForComparison}
                                                    onChange={async (selected) => {
                                                        setEarTagSelectedForComparison(selected);
                                                    }} />
                                            </HerdSelectorSelectParent>
                                        </Col>
                                    </>
                                }
                            </Row>

                            {qualityIssues.length > 0 &&
                                <Row>
                                    <Alert maxWidth="100%" severity="warning">
                                        {qualityIssues.map((tuple: any) => {
                                            return (
                                                <Paragraph className={'mb-2'}>
                                                    {translate('Quality issue: ')}{tuple[2]}, {tuple[3]}, {SCORE_RULE_ID[tuple[3]]}, {tuple[1]}
                                                </Paragraph>
                                            )
                                        })}
                                    </Alert>
                                </Row>
                            }

                            {dataMode === 'compare' &&
                                <>
                                    {permissions.visio ?
                                        <>
                                            <Row className={'mt-3'} style={{ alignItems: 'center' }}>
                                                <Col width={'20%'}>
                                                    <Label>{translate('Type of comparison')}</Label>
                                                </Col>

                                                <Col width={viewSize > ViewSize.S ? 'auto' : '30%'}>
                                                    <HerdSelectorSelectParent>
                                                        <Select name={translate('Comparison')} field={`comparison`} options={comparisonTypeOptions} selected={comparisonType}
                                                            onChange={async (selected) => {
                                                                setComparisonType(selected);
                                                            }} />
                                                    </HerdSelectorSelectParent>
                                                </Col>
                                            </Row>

                                            <Row className={'mt-3'} style={{ alignItems: 'center' }}>
                                                <Col width={'20%'}>
                                                    <Label>{translate('Time horizon (days)')}</Label>
                                                </Col>
                                            </Row>

                                            <Row className={'mt-3'} style={{ alignItems: 'center' }}>
                                                <Slider min={5} max={365} step={5} value={sliderValue} marks={marks} valueLabelDisplay="auto" onChange={handleSliderChange}
                                                    onClick={() => {
                                                        setSliderClicked(false);
                                                    }} onMouseDown={() => {
                                                        setSliderClicked(true);
                                                    }} />
                                            </Row>

                                            {!comparisonError ?
                                                <>
                                                    {comparisonData &&
                                                        <>
                                                            <Row className="mt-3">
                                                                <Col width="100%">
                                                                    <AnimalComparisonChart commonXAxis={comparisonData.commonXAxis} trace1={comparisonData.plotYAxis?.trace1} trace2={comparisonData.plotYAxis?.trace2}
                                                                        traceDiffPos_={comparisonData.plotYAxis?.traceDiffPos_} traceDiffPos={comparisonData.plotYAxis?.traceDiffPos} traceDiffNeg_={comparisonData.plotYAxis?.traceDiffNeg_}
                                                                        traceDiffNeg={comparisonData.plotYAxis?.traceDiffNeg} animal1={earTagSelected.value} animal2={earTagSelectedForComparison.value} comparison={comparisonType.value} />
                                                                </Col>
                                                            </Row>

                                                            <Row className="mt-3">
                                                                <Col width="100%">
                                                                    <Table columns={comparisonTableColumns} dataSource={comparisonTableData} pagination={false} />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    }
                                                </> :
                                                <Row className="mt-5">
                                                    <Alert maxWidth="100%" severity="danger">
                                                        {translate("There was an error fetching the comparsion result.")}
                                                    </Alert>
                                                </Row>
                                            }
                                        </> :
                                        <Row className="mt-3">
                                            <Alert maxWidth="50%" severity="primary">
                                                {translate("Purchase Predicta Visio in order to unlock this feature")}
                                            </Alert>
                                        </Row>
                                    }
                                </>
                            }

                            {performanceData && (
                                <>
                                    {dataMode === 'historical' &&
                                        <Row>
                                            <Tabs defaultActiveKey={defaultActiveLactationTabKey} activeKey={activeLactationTabKey} items={lactationTabs} onChange={onLactationTabChange} />
                                        </Row>
                                    }

                                    {dataMode !== 'compare' &&
                                        <Row className="mb-3">
                                            <Col>
                                                <Span style={{ fontWeight: 'bold' }}>
                                                    {translate('Status: ')}
                                                </Span>
                                            </Col>

                                            <Col>
                                                <Span>
                                                    {translate(String(performanceData.animalStatus))}
                                                </Span>
                                            </Col>
                                        </Row>
                                    }

                                    {!performanceData?.hasVisioOrder && dataMode === 'predicted' &&
                                        <Row>
                                            <Alert maxWidth="50%" severity="primary">
                                                {translate("Purchase Predicta Visio in order to see milk production forecast")}
                                            </Alert>
                                        </Row>
                                    }

                                    {performanceData?.numberOfLactations === 0 && dataMode === 'historical' &&
                                        <Row>
                                            <Alert maxWidth="50%" severity="warning">
                                                {translate("This animal has no historical lactation data")}
                                            </Alert>
                                        </Row>
                                    }

                                    {dataMode !== 'compare' &&
                                        <Row>
                                            <Col width="100%">
                                                <VisioAnimalPerformanceChart histScatter={performanceData?.histScatter} cols={performanceData?.cols}
                                                    healthEvents={performanceData?.healthEvents} toPlotValues={performanceData?.toPlotValues} colsDim={performanceData.colsDim}
                                                    dryOffs={performanceData.dryOffs} inseminations={performanceData.inseminations} groupChanges={performanceData.groupChanges} totalNumberOfLactations={performanceData.numberOfLactations}
                                                    heatStressData={performanceData.heatStress} infoActive={infoActive} lactationsOfInterest={activeLactationTabKey} historical={dataMode === 'historical'} />
                                            </Col>
                                        </Row>
                                    }
                                </>
                            )
                            }

                            {performanceData && dataMode === 'historical' &&
                                <Row>
                                    <Col width="18%">
                                        <Card className='info-card' ref={healthEventsTileRef}
                                            type={'inner'}
                                            title={translate('Health events')}
                                            style={{ border: infoActive['healthEvents'] ? '1px solid ' + DDW_green : '1px solid #f0f0f0' }}
                                            onClick={() => {
                                                let infoActiveCopy = { ...infoActive };
                                                infoActiveCopy['healthEvents'] = !infoActiveCopy['healthEvents'];
                                                setInfoActive(infoActiveCopy);
                                            }}
                                            onMouseEnter={() => { document.body.style.cursor = 'pointer' }}
                                            onMouseLeave={() => { document.body.style.cursor = 'default' }}>
                                            <BoldSpan>{performanceData?.healthEvents ? performanceData?.healthEvents.length : 0}</BoldSpan>
                                        </Card>
                                    </Col>

                                    <Col width="20%">
                                        <Card className='info-card' ref={heatStressTileRef}
                                            type={'inner'}
                                            title={translate('Heat stress events')}
                                            style={{ border: infoActive['heathStress'] ? '1px solid ' + DDW_yellow : '1px solid #f0f0f0' }}
                                            onClick={() => {
                                                let infoActiveCopy = { ...infoActive };
                                                infoActiveCopy['heathStress'] = !infoActiveCopy['heathStress'];
                                                setInfoActive(infoActiveCopy);
                                            }}
                                            onMouseEnter={() => { document.body.style.cursor = 'pointer' }}
                                            onMouseLeave={() => { document.body.style.cursor = 'default' }}>
                                            <BoldSpan>{performanceData?.heatStress?.thiValues ? performanceData?.heatStress?.thiValues.length : 0}</BoldSpan>
                                            {performanceData?.heatStress?.thiValues &&
                                                <Tooltip
                                                    title={translate('THI threshold value is 72. Source: J. Dairy Sci. 103:6751–6770 https://doi.org/10.3168/jds.2019-17929; Invited review: Physiological and behavioral effects of heat stress in dairy cows')}
                                                    placement={'top'}>
                                                    <span><InfoCircleFillIcon style={{ height: '13px' }} className={'ml-1'} /></span>
                                                </Tooltip>
                                            }
                                        </Card>
                                    </Col>

                                    <Col width="20%">
                                        <Card className='info-card' ref={groupChangeTileRef}
                                            type={'inner'}
                                            title={translate('Group change events')}
                                            style={{ border: infoActive['groupChange'] ? '1px solid ' + DDW_blue : '1px solid #f0f0f0' }}
                                            onClick={() => {
                                                let infoActiveCopy = { ...infoActive };
                                                infoActiveCopy['groupChange'] = !infoActiveCopy['groupChange'];
                                                setInfoActive(infoActiveCopy);
                                            }}
                                            onMouseEnter={() => { document.body.style.cursor = 'pointer' }}
                                            onMouseLeave={() => { document.body.style.cursor = 'default' }}>
                                            <BoldSpan>{performanceData?.groupChanges ? performanceData?.groupChanges.length : 0}</BoldSpan>
                                        </Card>
                                    </Col>

                                    <Col width="20%">
                                        <Card className='info-card' ref={conceptionTileRef}
                                            type={'inner'}
                                            title={translate('Inseminations')}
                                            style={{ border: infoActive['insemination'] ? '1px solid ' + DDW_pink : '1px solid #f0f0f0' }}
                                            onClick={() => {
                                                let infoActiveCopy = { ...infoActive };
                                                infoActiveCopy['insemination'] = !infoActiveCopy['insemination'];
                                                setInfoActive(infoActiveCopy);
                                            }}
                                            onMouseEnter={() => { document.body.style.cursor = 'pointer' }}
                                            onMouseLeave={() => { document.body.style.cursor = 'default' }}>
                                            <BoldSpan>{performanceData?.inseminations ? performanceData?.inseminations.length : 0}</BoldSpan>
                                        </Card>
                                    </Col>

                                    <Col width="22%">
                                        <Card className='info-card' ref={dryOffTileRef}
                                            type={'inner'}
                                            title={translate('Dry off events')}
                                            style={{ border: infoActive['dryOff'] ? '1px solid ' + DDW_orange : '1px solid #f0f0f0' }}
                                            onClick={() => {
                                                let infoActiveCopy = { ...infoActive };
                                                infoActiveCopy['dryOff'] = !infoActiveCopy['dryOff'];
                                                setInfoActive(infoActiveCopy);
                                            }}
                                            onMouseEnter={() => { document.body.style.cursor = 'pointer' }}
                                            onMouseLeave={() => { document.body.style.cursor = 'default' }}>
                                            {performanceData?.dryOffs.length > 0 ? performanceData?.dryOffs.map((dryOff: DryOffData) => {
                                                return (
                                                    <>
                                                        <BoldSpan>{formatDate(dryOff.date)}; {dryOff.milkValue} {translate('kg')}</BoldSpan>
                                                        <br />
                                                    </>
                                                );
                                            }) : <BoldSpan>0</BoldSpan>}
                                        </Card>
                                    </Col>
                                </Row>
                            }
                        </Col>

                        <Col width="20%">
                            <Row className={'mb-4'}>
                                <Card className='info-card' type={'inner'} title={translate("Exp. production 365 days")}>
                                    <h2 className={'mb-2'}>{performanceData?.expProduction365}</h2>
                                    <Paragraph className={'mb-2'}>{translate('kg')}</Paragraph>
                                </Card>
                            </Row>

                            <Row className={'mb-4'}>
                                <Card className='info-card' type={'inner'} title={translate("Exp. production level")}>
                                    <h2 className={'mb-2'}>{performanceData?.expProductionLevel}</h2>
                                    <Paragraph className={'mb-2'}>{translate('kg/day')}</Paragraph>
                                </Card>
                            </Row>

                            <Row className={'mb-4'}>
                                <Card className='info-card' type={'inner'} title={translate("Exp. performance")}>
                                    <h2 className={'mb-2'}>{performanceData?.expPerformanceGroup}</h2>
                                    <Paragraph className={'mb-2'}>{translate('within group')}</Paragraph>
                                </Card>
                            </Row>

                            <Row className={'mb-4'}>
                                <Card className='info-card' type={'inner'} title={translate("Exp. performance")}>
                                    <h2 className={'mb-2'}>{performanceData?.expPerformanceTot}</h2>
                                    <Paragraph className={'mb-2'}>{translate('vs. all')}</Paragraph>
                                </Card>
                            </Row>
                        </Col>
                    </Row>
                </> :
                <Alert maxWidth={'25%'} severity={dataErrorOrWarningSeverity}>
                    {dataErrorOrWarningSeverity === 'danger' ? <div>{herdId} - {translate('Data not available')}</div> :
                        <div>{herdId} - {translate('No predictions yet. If this persists for several days, it might indicate incomplete/insufficient data for this module.')}</div>
                    }
                </Alert>
            }
        </PageContainer>
    );
};