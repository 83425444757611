import { withStyles } from '@mui/styles';
import { Card, CardContent, CardHeader, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState } from 'react';
import { HerdProfileContext } from '../../context';
import { AuthenticationContext, SnackbarMessagesContext, SnackbarMessageType } from '@ddw/react-framework';
import { getObjectKeys } from '../../pages/utils';
import { UpdateTreatment } from '../../typings/types';
import { DDWApplication } from '@ddw/react-components';
import { Col, Paragraph, Row, Span } from '../../pages/components';
import { DIS_COL, DIS_NAMES, DIS_PILL_NAMES, DQ_VIOLATION_RANGE } from '../../pages/static';
import { IssueCardChecklist } from './IssueCardChecklist';
import { Api } from '../../services/api';
import { useNavigate } from 'react-router-dom';

const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: 'transparent'
  }
})(Tooltip);

export const IssueCard: React.FC<any> = ({ issueData, headerData, multiDiseaseDQDict, fetchIssues }) => {
  const { t: translate } = useTranslation();
  const { herdId } = useContext(HerdProfileContext);
  const { getAccessToken } = useContext(AuthenticationContext);
  const { addMessageToStack } = useContext(SnackbarMessagesContext);

  const diseases = getObjectKeys(issueData['AnimalAlarmId']);

  const [showTooltip, setShowTooltip] = useState<boolean[]>([false, false, false, false, false, false, false]);

  const navigate = useNavigate();

  const changeTreatment = async (data: UpdateTreatment) => {
    document.body.style.cursor = 'progress';
    const result = await Api.issues.performActionOnTreatmentChangeForIssue(getAccessToken(), DDWApplication.DairyInsights, herdId, headerData['name'], data);
    if (result.success) {
      await fetchIssues();
    } else {
      addMessageToStack({
        messageType: SnackbarMessageType.error,
        message: translate('There was a problem updating treatment.'),
        autoHide: true
      });
    }
    document.body.style.cursor = 'default';
  };

  return (
    <>
      <Card style={{
        boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)',
        backgroundColor: '#F7F7F7',
        marginBottom: '5px',
        width: '100%'
      }}>
        <CardContent>
          <Row>
            <Col width={'15%'} onClick={() => {
              navigate('/animal', { state: { eartag: headerData.EarTag, cowNumber: headerData.CowNumber } });
            }} onMouseEnter={() => { document.body.style.cursor = 'pointer'; }}
              onMouseLeave={() => { document.body.style.cursor = 'default'; }}>
              <Row className={'ml-2'}><Typography
                style={{ fontWeight: 'bold' }}>{translate('EarTag')}</Typography></Row>
              <Row className={'ml-2 mt-1'}>{headerData.EarTag}</Row>
              <Row className={'ml-2 mt-3'}><Typography
                style={{ fontWeight: 'bold' }}>{translate('Dry Off Date')}</Typography></Row>
              <Row className={'ml-2 mt-1'}>{headerData.DryOffDate}</Row>
            </Col>

            <Col width={'15%'} onClick={() => {
              navigate('/animal', { state: { eartag: headerData.EarTag, cowNumber: headerData.CowNumber } });
            }} onMouseEnter={() => { document.body.style.cursor = 'pointer'; }}
              onMouseLeave={() => { document.body.style.cursor = 'default'; }}>
              <Row><Typography style={{ fontWeight: 'bold' }}>{translate('Cow #')}</Typography></Row>
              <Row className={'mt-1'}>{headerData.CowNumber}</Row>
              <Row className={'mt-3'}><Typography
                style={{ fontWeight: 'bold' }}>{translate('Exp. Calving')}</Typography></Row>
              <Row className={'mt-1'}>{headerData.ExpectedCalvingDate}</Row>
            </Col>

            <Col width={'15%'} onClick={() => {
              navigate('/animal', { state: { eartag: headerData.EarTag, cowNumber: headerData.CowNumber } });
            }} onMouseEnter={() => { document.body.style.cursor = 'pointer'; }}
              onMouseLeave={() => { document.body.style.cursor = 'default'; }}>
              <Row><Typography style={{ fontWeight: 'bold' }}>{translate('Parity')}</Typography></Row>
              <Row className={'mt-1'}>{headerData.Parity}</Row>
              <Row className={'mt-3'}><Typography
                style={{ fontWeight: 'bold' }}>{translate('Exp. 305d Yield')}</Typography></Row>
              <Row className={'mt-1'}>{headerData['Exp. production level 305 days (kg)']}</Row>
            </Col>

            <Col width={'20%'} onClick={() => {
              navigate('/animal', { state: { eartag: headerData.EarTag, cowNumber: headerData.CowNumber } });
            }} onMouseEnter={() => { document.body.style.cursor = 'pointer'; }}
              onMouseLeave={() => { document.body.style.cursor = 'default'; }}>
              <Row><Typography style={{ fontWeight: 'bold' }}>{translate('Alarm Type')}</Typography></Row>
              {diseases.map((disease) => {
                return (
                  <div key={String(disease)}>
                    {DQ_VIOLATION_RANGE.includes(issueData['DataQualityViolation'][disease]) &&
                      <Row style={{ marginTop: '5px' }}>
                        <span style={{ width: '120px', backgroundColor: `${DIS_COL[String(disease)]}` }}
                          className={'disease-badge badge-pill badge-primary'}>
                          {translate(DIS_PILL_NAMES[String(disease)])}
                        </span>
                      </Row>
                    }
                  </div>
                );
              })}
            </Col>

            <Col width={'10%'}>
              <Row><Typography style={{ fontWeight: 'bold' }}>{translate('Action')}</Typography></Row>
              {diseases.map((disease) => {
                return (
                  <div key={String(disease)}>
                    {
                      DQ_VIOLATION_RANGE.includes(issueData['DataQualityViolation'][disease]) &&
                      <IssueCardChecklist ignored={issueData['Ignored'][disease]} disease={disease}
                        changeTreatment={changeTreatment} />
                    }
                  </div>
                );
              })}
            </Col>

            <Col width={'25%'} onClick={() => {
              navigate('/animal', { state: { eartag: headerData.EarTag, cowNumber: headerData.CowNumber } });
            }} onMouseEnter={() => { document.body.style.cursor = 'pointer'; }}
              onMouseLeave={() => { document.body.style.cursor = 'default'; }}>
              <Row><Typography style={{ fontWeight: 'bold' }}>{translate('Issues Type')}</Typography></Row>
              {diseases.map((disease) => {
                return (
                  <div key={String(disease)}>
                    {
                      DQ_VIOLATION_RANGE.includes(issueData['DataQualityViolation'][disease]) &&
                      <Row style={{ marginTop: '5px' }}>
                        <StyledTooltip
                          open={showTooltip[parseInt(disease as string) - 1] && multiDiseaseDQDict[issueData['DataQualityViolation'][disease]][2] !== null}
                          title={
                            <Card style={{
                              minWidth: '350px',
                              color: '#6c757d',
                              borderColor: '#f8f9fa',
                              opacity: 0.99
                            }}>
                              <CardHeader style={{ textAlign: 'center', backgroundColor: '#e8e8e8' }}
                                subheader={translate('Violation for {{disease}}', {
                                  disease: translate(DIS_NAMES[String(disease)])
                                })} />
                              <CardContent
                                style={{ textAlign: 'center', alignItems: 'center', backgroundColor: 'white' }}>
                                <Row>
                                  <Paragraph>
                                    {multiDiseaseDQDict[issueData['DataQualityViolation'][disease]][2]}
                                  </Paragraph>
                                </Row>
                              </CardContent>
                            </Card>
                          } placement={'bottom-end'}
                        >
                          <Span onMouseEnter={() => {
                            const arr = [false, false, false, false, false, false, false];
                            arr[parseInt(disease as string) - 1] = true;
                            setShowTooltip(arr);
                          }}
                            onMouseLeave={() => setShowTooltip([false, false, false, false, false, false, false])}>
                            {multiDiseaseDQDict[parseInt(issueData['DataQualityViolation'][disease])][1]}
                          </Span>
                        </StyledTooltip>
                      </Row>
                    }
                  </div>
                );
              })}
            </Col>
          </Row>
        </CardContent>
      </Card>
    </>
  );
}
  ;