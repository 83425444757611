import { DDWApplication, parseError, putHeaders, statusHelper, toJSON } from '@ddw/react-components';
import { ServiceResult } from '@ddw/react-framework/build/src/types';
import { HerdSettings, SavePreventionSettings } from '../../typings/types';
import { urlState } from '../../pages/utils';

export const fetchHerdSettings = (accessToken: string, application: DDWApplication, herdId: number): Promise<ServiceResult<HerdSettings>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `herds/` + herdId + '/settings';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const estimateNAlarms = (accessToken: string, application: DDWApplication, herdId: number, point: string, inc: number): Promise<ServiceResult<number>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  headers.set('point', point);
  headers.set('inc', String(inc));
  const url = urlState.url + `herds/` + herdId + '/settings/estimate-n-alarms';
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};

export const saveSettings = (accessToken: string, application: DDWApplication, herdId: number, data: SavePreventionSettings): Promise<ServiceResult<any>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `herds/` + herdId + '/settings/save';
  return fetch(url, {
    method: 'PUT',
    headers,
    body: JSON.stringify(data)
  }).then(statusHelper).then(toJSON).catch(parseError);
};
