import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { PageContainer } from '../../../component/PageContainer';
import { Alert, Col, Header, IconContainer, Paragraph, Row, Span, SubHeader } from '../../components';
import { ClipboardCheckIcon, HealthIcon } from '../../../component/Icons';
import { useTranslation } from 'react-i18next';
import { Button, ButtonType, DDWApplication, Tile, ViewSize } from '@ddw/react-components';
import { TileContainer } from '../../components/tile-container';
import { useNavigate } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';
import { AuthenticationContext, ViewContext, DairyInsights } from '@ddw/react-framework';
import { HerdProfileContext } from '../../../context';
import { Api } from '../../../services/api';
import { DiagnosisDashboardInformation, GuardianDashboardInformation } from '../../../typings/types';
import { PagesContext } from '../../../context/PagesContext';
import { guardianPages } from '../../pages';
import AzureVideo from '../../../other/AzureVideo/AzureVideo';
import { GUARDIAN_LAMENESS_AND_MASTITIS_VIDEO_URL } from '../../static';
import { SubParagraph } from '../../components/sub-paragraph';

export const GuardianHomePage: React.FC = () => {
  const { t: translate } = useTranslation();
  const { user, getAccessToken } = useContext(AuthenticationContext);
  const { herdId, permissions } = useContext(HerdProfileContext);
  const { setPages } = useContext(PagesContext);
  const { viewSize } = useContext(ViewContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [dashboardInformation, setDashboardInformation] = useState<GuardianDashboardInformation>();
  const [diagnosisDashboardInformation, setDiagnosisDashboardInformation] = useState<DiagnosisDashboardInformation>();

  const [preventionTileHeight, setPreventionTileHeight] = useState(0);

  const measuredRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setPreventionTileHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const [dataNotAvailable, setDataNotAvailable] = useState(false);

  useAsyncEffect(async () => {
    setPages(guardianPages(translate, permissions.prevention, permissions.diagnosis, permissions.visio, user !== undefined ? user['profile']['UserEmail'] : ''));
    if (herdId != 0) {
      setLoading(true);

      if (!permissions.prevention && !permissions.diagnosis) {
        setDataNotAvailable(true);
      } else {
        let preventionFetchSuccessful: boolean = false;

        if (permissions.prevention) {
          const result = await Api.guardian.fetchGuardianDashboardInformation(getAccessToken(), DDWApplication.DairyInsights, herdId);
          if (result.success) {
            setDashboardInformation(result.data);
            setDataNotAvailable(false);
            preventionFetchSuccessful = true;
          } else {
            if (!permissions.diagnosis) {
              setDataNotAvailable(true);
            }
          }
        }

        if (permissions.diagnosis) {
          const result = await Api.diagnosis.fetchDiagnosisDashboardInformation(getAccessToken(), DDWApplication.DairyInsights, herdId);
          if (result.success) {
            setDiagnosisDashboardInformation(result.data);
          } else {
            if (!permissions.prevention || !preventionFetchSuccessful) {
              setDataNotAvailable(true);
            }
          }
        }
      }

      setLoading(false);
    }
  }, [herdId]);

  useEffect(() => {
    return () => {
      setDataNotAvailable(false);
      setDashboardInformation(undefined);
      setDiagnosisDashboardInformation(undefined);
      setPreventionTileHeight(0);
    }
  }, [herdId]);

  return (
    <>
      <PageContainer hasFooter={false} loading={loading}>
        {!dataNotAvailable ?
          <>
            <Row>
              <Col width={viewSize > ViewSize.S ? '70%' : '100%'}>
                <Header>
                  <Row style={{ alignItems: 'center' }}>
                    <IconContainer>
                      <HealthIcon />
                    </IconContainer>
                    {translate('Predicta GUARDIAN')}
                  </Row>
                </Header>

                <Paragraph>
                  {translate('Welcome to Predicta GUARDIAN! Explore our range of AI algorithms to help prevent and manage health incidents in dairy cows.')}
                </Paragraph>

                {viewSize <= ViewSize.S &&
                  <Row className='mt-3 mb-3'>
                    <Row>
                      <SubParagraph>
                        {translate('Find out how to configure Predicta GUARDIAN for your herd.')}
                      </SubParagraph>
                    </Row>
                    <Row>
                      <AzureVideo videoUrl={GUARDIAN_LAMENESS_AND_MASTITIS_VIDEO_URL} width='auto' height='300' />
                    </Row>
                  </Row>
                }

                <SubHeader>
                  <Row style={{ alignItems: 'center' }}>
                    <IconContainer>
                      <ClipboardCheckIcon />
                    </IconContainer>
                    {translate('Dashboard')}
                  </Row>
                </SubHeader>

                <Row>
                  <Col width={'50%'}>
                    <TileContainer ref={measuredRef}>
                      <Tile title={translate('Prevention')} name={'prevention'} error={(dashboardInformation === undefined || dashboardInformation.herdLevelDQ === null) ? '' : translate('This herd failed at farm level with data quality violation: {{dq}}.', {
                        dq: dashboardInformation?.herdLevelDQ === 34 ? translate('Invalid average dry off days (at herd level)') : translate('Invalid average calving interval (at herd level)')
                      })}>
                        <Row style={{ paddingBottom: '8px' }}>
                          <Span>
                            {translate('We predict which currently dry animals are at risk of developing transition diseases during the following lactation, allowing for smart preventive treatment strategies.')}
                          </Span>
                        </Row>
                        {permissions.prevention ?
                          <div>
                            <Row className={'mt-4'}>
                              <Button name={`active-alerts`} type={ButtonType.Primary} onClick={() => {
                                navigate('/guardian-prevention/alarms');
                              }}>
                                {translate('Active alerts')}
                                <Span className={'badge badge-light'}>
                                  {dashboardInformation?.nAlerts}
                                </Span>
                              </Button>
                            </Row>

                            <Row className={'mt-2'}>
                              <Button name={`data-quality-violations`} type={ButtonType.Cancel} onClick={() => {
                                navigate('/guardian-prevention/issues');
                              }}>
                                {translate('Data Quality Violations')}
                                <Span className={'badge badge-light'}>
                                  {dashboardInformation?.nViolations}
                                </Span>
                              </Button>
                            </Row>
                          </div> :
                          <Alert severity={'primary'} maxWidth={'100%'} style={{ marginTop: '64px' }}>
                            {translate('Purchase Guardian Prevention to unlock this feature')}
                          </Alert>
                        }
                      </Tile>
                    </TileContainer>
                  </Col>

                  {user !== undefined && (user['profile']['UserEmail'].endsWith("@dairydatawarehouse.com") || user['profile']['UserEmail'].endsWith("@delaval.com")) &&
                    <Col width='50%'>
                      <TileContainer style={{ height: `${preventionTileHeight}px` }}>
                        <Tile title={translate('Disease Risk') + (diagnosisDashboardInformation ? diagnosisDashboardInformation.modelType : '')} name={`diagnosis`}>
                          <Span>
                            {translate('We predict when animals in lactation will get sick 24h to 36h prior to the actual disease event.')}
                          </Span>
                          {permissions.diagnosis ?
                            <div>
                              <Row className={'mt-4'}>
                                <Button name={`active-alerts-diagnosis`} type={ButtonType.Primary} onClick={() => {
                                  navigate('/guardian-diagnosis/alarms');
                                }}>
                                  {translate('Active alerts')}
                                  <Span className={'badge badge-light'}>
                                    {diagnosisDashboardInformation?.nAlerts}
                                  </Span>
                                </Button>
                              </Row>
                              <Row className={'mt-2'}>
                                <Button name={`data-quality-violations-diagnosis`} type={ButtonType.Cancel} onClick={() => {
                                  navigate('/guardian-diagnosis/issues');
                                }}>
                                  {translate('Data Quality Violations')}
                                  <Span className={'badge badge-light'}>
                                    {diagnosisDashboardInformation?.nViolations}
                                  </Span>
                                </Button>
                              </Row>
                            </div> :
                            <Alert severity={'primary'} maxWidth={'100%'}>
                              {translate('Purchase Guardian Diagnosis to unlock this feature')}
                            </Alert>
                          }
                        </Tile>
                      </TileContainer>
                    </Col>
                  }
                </Row>

              </Col>

              {viewSize > ViewSize.S &&
                <Col width='30%'>
                  <SubParagraph>
                    {translate('Find out how to configure Predicta GUARDIAN for your herd.')}
                  </SubParagraph>

                  <AzureVideo videoUrl={GUARDIAN_LAMENESS_AND_MASTITIS_VIDEO_URL} width='100%' height='300' />
                </Col>
              }
            </Row>
          </> :
          <Alert severity={'danger'} maxWidth={viewSize > ViewSize.S ? '25%' : '75%'}>
            {herdId} - {translate('Data not available')}
          </Alert>
        }
      </PageContainer>
    </>
  );
};