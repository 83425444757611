import { useTranslation } from "react-i18next";
import { UdderHealthData } from "../../../../../typings/herdfolio/types";
import React, { useEffect, useRef } from "react";
import Plotly, { Data, Layout } from "plotly.js";
import { DDW_blue, DDW_cyan, DDW_grey, PLOTLY_TEMPLATE } from "../../../../../pages/static";
import { getObjectKeys } from "../../../../../pages/utils";

export const TransitionCowInfectionRateChart: React.FC<UdderHealthData> = (props) => {
    const { t: translate } = useTranslation();

    const chartRef = useRef<HTMLDivElement>(null);

    const data: Data[] = [{
        type: 'scatter',
        x: getObjectKeys(props.results),
        y: getObjectKeys(props.results).map((date) => props.results[date].threeMonthRollingTransitionNewInfectionRate),
        name: translate('3 Month Rolling (%)'),
        line: {
            dash: 'solid',
            color: DDW_grey
        },
        hovertemplate: '%{x}, %{y}%'
    },
    {
        x: getObjectKeys(props.results),
        y: getObjectKeys(props.results).map((date) => (props.results[date].transitionCowInfectionRate / props.results[date].transitionCows) * 100),
        type: 'bar',
        opacity: 1.0,
        name: translate('Transition Cow Infection Rate (%)'),
        marker: { color: DDW_blue },
        text: getObjectKeys(props.results).map((date) => props.results[date].transitionCowInfectionRate.toString()),
        customdata: getObjectKeys(props.results).map((date) => props.results[date].transitionCows),
        hovertemplate: translate('%{x}<br>Transition Cow Infection Rate: %{y}%<br>New infections: %{text}<br>Recorded cows: %{customdata}')
    },
    {
        type: 'bar',
        x: getObjectKeys(props.results),
        y: getObjectKeys(props.results).map(() => null),
        marker: { color: DDW_cyan },
        showlegend: true,
        name: translate('Target'),
        opacity: 0.2
    }
    ];

    const layout: Partial<Layout> = {
        title: {
            text: translate('Transition Cow Infection Rate'),
            yanchor: 'top'
        },
        xaxis: {
            tickangle: 45,
            tickformat: '%Y-%m-%d',
            type: 'category'
        },
        yaxis: {
            // range: [0, 100],
            // tickformat: '.2%'
        },
        showlegend: true,
        hovermode: 'closest',
        barmode: 'overlay',
        shapes: [
            {
                type: 'rect',
                xref: 'paper',
                yref: 'y',
                x0: 0,
                y0: 0,
                x1: 1,
                y1: 10,
                opacity: 0.5,
                fillcolor: DDW_cyan,
                line: {
                    width: 0,
                },
            },
        ],
        template: PLOTLY_TEMPLATE,
    };

    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
        }
    }, [props]);

    return (
        <div ref={chartRef} />
    );
};