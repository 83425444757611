import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getColor } from '../../../../../pages/utils';
import * as Plotly from 'plotly.js';
import { Data, Layout } from 'plotly.js';
import { DDW_green, DDW_orange, PLOTLY_TEMPLATE } from '../../../../../pages/static';
import { CardData } from '../../../../../typings/types';

interface Props {
  cardData: CardData[];
  countOk: number;
}

export const DiagnosisIssuesPieChart: React.FC<Props> = ({ cardData, countOk }) => {
  const { t: translate } = useTranslation();

  const chartRef = useRef<HTMLDivElement>(null);

  const data: Data[] = [
    {
      labels: [translate('Prediction OK'), translate('Data Rule Violations')],
      values: [countOk, cardData.length],
      type: 'pie',
      marker: {
        colors: [DDW_green, DDW_orange]
      },
      hole: 0.6
    }
  ];

  const layout: Partial<Layout> = {
    showlegend: true,
    legend: {
      xanchor: 'left',
      x: 1,
      yanchor: 'top',
      y: 1
    },
    hovermode: 'closest',
    template: PLOTLY_TEMPLATE
  };

  useEffect(() => {
    if (chartRef.current) {
      Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
    }
  }, []);

  return (
    <>
      <div ref={chartRef} />
    </>
  );
};