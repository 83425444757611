import styled from 'styled-components';
interface PageContainerProps {
  overflow: string;
}
export const PageContent = styled.div<PageContainerProps>`
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  overflow: ${({ overflow }) => (overflow === "true" ? `auto` : `none`)};
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }

  @media (max-width: 480px) {
    padding: 5px;

    h1, h2, h3, h4, h5, h6, p, span, button, input, select, textarea {
      font-size: 0.8em;
    }
  }
`;
