import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticationContext, SnackbarMessagesContext, SnackbarMessageType, ViewContext } from '@ddw/react-framework';
import { HerdProfileContext } from '../../../../context';
import { useNavigate } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';
import { DDWApplication, ViewSize } from '@ddw/react-components';
import { Api } from '../../../../services/api';
import { DiagnosisStats } from '../../../../typings/types';
import { PageContainer } from '../../../../component/PageContainer';
import { Alert, Col, IconContainer, Row, SubHeader } from '../../../components';
import { GraphUpIcon } from '../../../../component/Icons';
import { DiagnosisPieChart } from '../../../../component/Charts/Stats/Diagnosis/DiagnosisPieChart';
import { DiagnosisTopRightChart } from '../../../../component/Charts/Stats/Diagnosis/DiagnosisTopRightChart';
import {
  DiagnosisBottomRightBarChart
} from '../../../../component/Charts/Stats/Diagnosis/DiagnosisBottomRightBarChart';
import { DiagnosisBottomLeftBarChart } from '../../../../component/Charts/Stats/Diagnosis/DiagnosisBottomLeftBarChart';
import { PagesContext } from '../../../../context/PagesContext';
import { guardianPages } from '../../../pages';

export const GuardianDiagnosisStatsPage: React.FC = () => {
  const { t: translate } = useTranslation();
  const { user, getAccessToken } = useContext(AuthenticationContext);
  const { herdId, permissions } = useContext(HerdProfileContext);
  const { addMessageToStack } = useContext(SnackbarMessagesContext);
  const { setPages } = useContext(PagesContext);
  const { viewSize } = useContext(ViewContext);

  const [statsData, setStatsData] = useState<DiagnosisStats>();

  const [loading, setLoading] = useState(true);
  const [dataErrorOrWarningSeverity, setDataErrorOrWarningSeverity] = useState('');

  const navigate = useNavigate();

  const fetchStats = async () => {
    const result = await Api.diagnosis.fetchStats(getAccessToken(), DDWApplication.DairyInsights, herdId);
    if (result.success) {
      if (result.message === '') {
        setStatsData(result.data);
        setDataErrorOrWarningSeverity('');
      } else {
        setDataErrorOrWarningSeverity('warning');
      }
    } else {
      if (result.message !== '') {
        navigate('/home');
        addMessageToStack({
          messageType: SnackbarMessageType.error,
          message: translate('There was a problem fetching stats.'),
          autoHide: false
        });
      } else {
        setDataErrorOrWarningSeverity('danger');
      }
    }
  };

  useAsyncEffect(async () => {
    setPages(guardianPages(translate, permissions.prevention, permissions.diagnosis, permissions.visio, user !== undefined ? user['profile']['UserEmail'] : ''));
    if (herdId != 0) {
      setLoading(true);
      await fetchStats();
      setLoading(false);
    }
  }, [herdId]);

  return (
    <>
      <PageContainer hasFooter={false} loading={loading}>
        {dataErrorOrWarningSeverity === '' ?
          <>
            <Row>
              <Col width={'75%'}>
                <SubHeader>
                  <Row style={{ alignItems: 'center' }}>
                    <Col width={'3%'}>
                      <IconContainer>
                        <GraphUpIcon />
                      </IconContainer>
                    </Col>

                    <Col width={'90%'}>
                      {translate('Health alarms statistics')}
                    </Col>
                  </Row>
                </SubHeader>
              </Col>
            </Row>

            {viewSize > ViewSize.S ?
              <>
                <Row>
                  <Col width={'50%'}>
                    <DiagnosisPieChart lastSession={statsData?.lastSession} />
                  </Col>

                  <Col width={'50%'}>
                    <DiagnosisTopRightChart stats={statsData?.stats} />
                  </Col>
                </Row>

                <Row>
                  <Col width={'50%'}>
                    <DiagnosisBottomLeftBarChart stats={statsData?.stats} statsColumns={statsData?.statsColumns}
                      dqvText={statsData?.dqvText} />
                  </Col>

                  <Col width={'50%'}>
                    <DiagnosisBottomRightBarChart stats={statsData?.stats} statsColumns={statsData?.statsColumns} />
                  </Col>
                </Row>
              </> :
              <>
                <Row>
                  <Col width='100%'>
                    <DiagnosisPieChart lastSession={statsData?.lastSession} />
                  </Col>
                </Row>

                <Row>
                  <Col width='100%'>
                    <DiagnosisTopRightChart stats={statsData?.stats} />
                  </Col>
                </Row>

                <Row>
                  <Col width='100%'>
                    <DiagnosisBottomLeftBarChart stats={statsData?.stats} statsColumns={statsData?.statsColumns}
                      dqvText={statsData?.dqvText} />
                  </Col>
                </Row>

                <Row>
                  <Col width='100%'>
                    <DiagnosisBottomRightBarChart stats={statsData?.stats} statsColumns={statsData?.statsColumns} />
                  </Col>
                </Row>
              </>
            }
          </> :
          <Alert maxWidth={viewSize > ViewSize.S ? '25%' : '75%'} severity={dataErrorOrWarningSeverity}>
            {dataErrorOrWarningSeverity === 'danger' ? <div>{herdId} - {translate('Data not available')}</div> :
              <div>{herdId} - {translate('There is no active session yet for this herd. Please retry later.')}</div>
            }
          </Alert>
        }
      </PageContainer>
    </>
  );
};