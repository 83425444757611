import Plotly, { Annotations, Data, Layout } from "plotly.js";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { UdderHealthData } from "../../../../../typings/herdfolio/types";
import { DDW_blue, DDW_cyan, DDW_green, PLOTLY_TEMPLATE } from "../../../../../pages/static";
import { getObjectKeys } from "../../../../../pages/utils";

export const SCCManagementChart: React.FC<UdderHealthData> = (props) => {
    const { t: translate } = useTranslation();

    const chartRef = useRef<HTMLDivElement>(null);

    // Calculate percentages
    const percentageInfected = getObjectKeys(props.results).map(
        (date) => (props.results[date].infectedCows / props.results[date].cowsRecorded) * 100
    );

    const percentageChronicallyInfected = getObjectKeys(props.results).map(
        (date) => (props.results[date].chronicallyInfectedCows / props.results[date].cowsRecorded) * 100
    );

    const percentageClean = getObjectKeys(props.results).map(
        (date) => (props.results[date].cleanCows / props.results[date].cowsRecorded) * 100
    );

    const data: Data[] = [
        {
            x: getObjectKeys(props.results),
            y: percentageChronicallyInfected,
            type: "bar",
            opacity: 1.0,
            name: translate("Chronically infected (%)"),
            marker: { color: DDW_cyan },
            customdata: getObjectKeys(props.results).map((date) => [props.results[date].cowsRecorded, (props.results[date].sccTimesMilkProduction / props.results[date].milkProduction).toFixed(2)]),
            hovertemplate: translate('%{x}, %{y}%<br>Cows recorded: %{customdata[0]}<br>Average SCC: %{customdata[1]}')
        },
        {
            x: getObjectKeys(props.results),
            y: percentageInfected,
            type: "bar",
            opacity: 1.0,
            name: translate("Infected (%)"),
            marker: { color: DDW_green },
            customdata: getObjectKeys(props.results).map((date) => [props.results[date].cowsRecorded, (props.results[date].sccTimesMilkProduction / props.results[date].milkProduction).toFixed(2)]),
            hovertemplate: translate('%{x}, %{y}%<br>Cows recorded: %{customdata[0]}<br>Average SCC: %{customdata[1]}')
        },
        {
            x: getObjectKeys(props.results),
            y: percentageClean,
            type: "bar",
            opacity: 1.0,
            name: translate("Clean cows (%)"),
            marker: { color: DDW_blue },
            customdata: getObjectKeys(props.results).map((date) => [props.results[date].cowsRecorded, (props.results[date].sccTimesMilkProduction / props.results[date].milkProduction).toFixed(2)]),
            hovertemplate: translate('%{x}, %{y}%<br>Cows recorded: %{customdata[0]}<br>Average SCC: %{customdata[1]}')
        },
        {
            x: getObjectKeys(props.results),
            y: getObjectKeys(props.results).map((date) => (props.results[date].sccTimesMilkProduction / props.results[date].milkProduction).toFixed(2)),
            mode: 'markers',
            name: translate("Average SCC"),
            marker: { color: '#FFFFFF', line: { color: 'black', width: 0.5 } },
            yaxis: 'y2'
        }
    ];

    const layout: Partial<Layout> = {
        title: {
            text: translate('SCC Management'),
            yanchor: 'bottom'
        },
        xaxis: {
            tickangle: 45,
            tickformat: '%Y-%m-%d',
            type: 'category'
        },
        yaxis2: {
            anchor: 'x',
            overlaying: 'y',
            side: 'right'
        },
        legend: {
            xanchor: 'center',
            x: 0.5,
            yanchor: 'bottom',
            y: 1,
            orientation: 'h'
        },
        showlegend: true,
        barmode: 'stack',
        hovermode: 'closest',
        template: PLOTLY_TEMPLATE
    };

    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
        }
    }, [props]);

    return (
        <div ref={chartRef} />
    );
};