import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIcon, SvgIconTypeMap } from '@material-ui/core';
import React from 'react';

export const HealthIcon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> = (props: any) => {
  return (
    <SvgIcon {...props} viewBox='0 0 340.000000 347.000000'>
      <g transform='translate(0.000000,347.000000) scale(0.100000,-0.100000)'
         fill='#000000' stroke='none'>
        <path d='M1077 3143 c-4 -141 -7 -375 -7 -520 l0 -263 -520 0 -520 0 0 -368
c0 -203 3 -491 7 -640 l6 -272 519 0 518 0 0 -520 0 -520 635 0 635 0 0 520 0
520 520 0 520 0 0 635 0 635 -520 0 -520 0 0 525 0 525 -633 0 -634 0 -6 -257z
m1153 -383 l0 -520 520 0 520 0 0 -520 0 -520 -520 0 -520 0 0 -520 0 -520
-517 2 -518 3 -3 517 -2 518 -518 2 -517 3 0 515 0 515 518 3 517 2 0 513 c0
283 3 517 7 520 3 4 237 7 520 7 l513 0 0 -520z' />
      </g>
    </SvgIcon>
  );
};