import Plotly, { Data, Layout } from "plotly.js";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CurrentLactationPredictionRelativeToTheFuturePart } from "../../../../../typings/herdfolio/types";
import { ANIMAL_MILK_PRODUCTION_COLOURS, ANIMAL_MILK_PRODUCTION_SEL, PLOTLY_TEMPLATE } from "../../../../../pages/static";
import { ViewSize, useViewSize } from "@ddw/react-components";

type CurrentLactationPredictionRelativeToTheFuturePartChartProps = {
    data: CurrentLactationPredictionRelativeToTheFuturePart;
};

export const CurrentLactationPredictionRelativeToTheFuturePartChart: React.FC<CurrentLactationPredictionRelativeToTheFuturePartChartProps> = (props) => {
    const { t: translate } = useTranslation();
    const { viewSize } = useViewSize();

    const chartRef = useRef<HTMLDivElement>(null);

    const data: Data[] = [{
        x: Array.from({ length: 306 }, (_, i) => i),
        y: props.data.tracesCur[0],
        mode: 'lines',
        name: translate('Average'),
        line: { dash: 'dash', color: '#000000' },
        showlegend: true,
    }].concat(props.data.tracesCur.slice(1).map((data: number[], index: number) => {
        return {
            x: Array.from({ length: 306 }, (_, j) => j),
            y: data,
            mode: 'lines',
            name: translate(ANIMAL_MILK_PRODUCTION_SEL[index]),
            line: { dash: 'solid', color: ANIMAL_MILK_PRODUCTION_COLOURS[index] },
            showlegend: true,
        }
    }));

    const layout: Partial<Layout> = {
        title: viewSize > ViewSize.S ? translate('Current lactation (prediction relative to the future part)') : '',
        yaxis: {
            title: translate('Daily Milk Yield (kg)'),
        },
        xaxis: {
            title: translate('DIM'),
        },
        showlegend: true,
        legend: {
            xanchor: 'center',
            x: 0.5,
            yanchor: 'bottom',
            y: 1,
            orientation: 'h',
        },
        hovermode: 'closest',
        hoverlabel: { namelength: -1 },
        template: PLOTLY_TEMPLATE
    };

    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
        }
    }, [props, viewSize]);

    return (
        <div ref={chartRef} />
    );
};