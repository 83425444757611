import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "../../../../../pages/components";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { getCurrencySign, getObjectKeys } from "../../../../../pages/utils";
import { BASE_CURRENCY, CONVERSION_RATES_IN_CASE_OF_FETCH_ERROR } from "../../../../../pages/static";

interface YearlyFeedCostTableDataType {
    group: string;
    dmi: string;
    milkYield: string;
    feedCost: string;
};

export const YearlyFeedCostTable: React.FC<any> = (props) => {
    const { t: translate } = useTranslation();

    const predictaConversionRates = localStorage.getItem('predictaCurrenciesConversionRates') !== null ? JSON.parse(localStorage.getItem('predictaCurrenciesConversionRates')!) : CONVERSION_RATES_IN_CASE_OF_FETCH_ERROR;
    const preferredCurrency = sessionStorage.getItem('preferredCurrency') !== null ? sessionStorage.getItem('preferredCurrency') : BASE_CURRENCY;

    const columns: ColumnsType<YearlyFeedCostTableDataType> = [
        {
            title: translate('Group'),
            dataIndex: 'group',
            key: 'group'
        },
        {
            title: translate('Yearly dry matter intake (kg)'),
            dataIndex: 'dmi',
            key: 'dmi'
        },
        {
            title: translate('Yearly milk yield (kg)'),
            dataIndex: 'milkYield',
            key: 'milkYield'
        },
        {
            title: translate('Yearly feeding cost') + ' (' + getCurrencySign() + ')',
            dataIndex: 'feedCost',
            key: 'feedCost'
        }
    ];

    const dataSource: YearlyFeedCostTableDataType[] = getObjectKeys(props).map((group: any) => {
        return {
            group: group,
            dmi: parseFloat(props[group]["estimatedDMI"].toFixed(0)).toLocaleString('fr-FR'),
            milkYield: parseFloat(props[group]["milkYield"].toFixed(0)).toLocaleString('fr-FR'),
            feedCost: parseFloat((props[group]["feedCost"] * predictaConversionRates[preferredCurrency!]["value"]).toFixed(0)).toLocaleString('fr-FR')
        }
    });

    return (
        <Row>
            <Col width='100%'>
                <Table columns={columns} dataSource={dataSource} pagination={false} />
            </Col>
        </Row>
    )
};