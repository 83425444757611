import React from 'react';
import { useTranslation } from 'react-i18next';

interface AzureVideoProps {
    videoUrl: string;
    width: string;
    height: string;
}

const AzureVideo: React.FC<AzureVideoProps> = (props) => {
    const { t: translate } = useTranslation();

    return (
        <div>
            <video controls width={props.width} height={props.height}>
                <source src={props.videoUrl} type="video/mp4" />
                {translate('Your browser does not support the video tag.')}
            </video>
        </div>
    );
};

export default AzureVideo;