import Table, { ColumnsType } from "antd/es/table";
import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "../../../../../pages/components";

interface YearlyIncidenceTableDataType {
    disease: string;
    incidence: string;
    cases: number;
    cows: number;
};

export const YearlyIncidenceTable: React.FC<any> = ({ props, lessThan42DIM }) => {
    const { t: translate } = useTranslation();

    const yearlyLactatingCows: number = lessThan42DIM ? props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['lactatingCows'], 0) : Number((props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['lactatingCows'], 0) / 12).toFixed(0));

    const columns: ColumnsType<YearlyIncidenceTableDataType> = [
        {
            title: translate('Disease'),
            dataIndex: 'disease',
            key: 'disease'
        },
        {
            title: translate('Incidence, last 12 months'),
            dataIndex: 'incidence',
            key: 'incidence'
        },
        {
            title: translate('Cases'),
            dataIndex: 'cases',
            key: 'cases'
        },
        {
            title: translate('Cows recorded'),
            dataIndex: 'cows',
            key: 'cows'
        }
    ];

    const dataSource: YearlyIncidenceTableDataType[] = [
        {
            disease: translate('Ketosis'),
            incidence: ((props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['ketosis'], 0) / yearlyLactatingCows) * 100).toFixed(2) + '%',
            cases: props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['ketosis'], 0),
            cows: yearlyLactatingCows
        },
        {
            disease: translate('Milk Fever'),
            incidence: ((props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['milkFever'], 0) / yearlyLactatingCows) * 100).toFixed(2) + '%',
            cases: props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['milkFever'], 0),
            cows: yearlyLactatingCows
        },
        {
            disease: translate('Displaced Abomasum'),
            incidence: ((props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['displacedAbomasum'], 0) / yearlyLactatingCows) * 100).toFixed(2) + '%',
            cases: props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['displacedAbomasum'], 0),
            cows: yearlyLactatingCows
        },
        {
            disease: translate('Retained Placenta'),
            incidence: ((props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['retainedPlacenta'], 0) / yearlyLactatingCows) * 100).toFixed(2) + '%',
            cases: props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['retainedPlacenta'], 0),
            cows: yearlyLactatingCows
        },
        {
            disease: translate('Lameness'),
            incidence: ((props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['lameness'], 0) / yearlyLactatingCows) * 100).toFixed(2) + '%',
            cases: props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['lameness'], 0),
            cows: yearlyLactatingCows
        },
        {
            disease: translate('Mastitis'),
            incidence: ((props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['mastitis'], 0) / yearlyLactatingCows) * 100).toFixed(2) + '%',
            cases: props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['mastitis'], 0),
            cows: yearlyLactatingCows
        },
        {
            disease: translate('Metritis'),
            incidence: ((props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['metritisAndOthers'], 0) / yearlyLactatingCows) * 100).toFixed(2) + '%',
            cases: props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['metritisAndOthers'], 0),
            cows: yearlyLactatingCows
        },
        {
            disease: translate('Total'),
            incidence: (((props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['ketosis'], 0) + props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['milkFever'], 0) + props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['displacedAbomasum'], 0) + props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['retainedPlacenta'], 0) + props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['lameness'], 0) + props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['mastitis'], 0) + props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['metritisAndOthers'], 0)) / yearlyLactatingCows) * 100).toFixed(2) + '%',
            cases: props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['ketosis'], 0) + props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['milkFever'], 0) + props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['displacedAbomasum'], 0) + props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['retainedPlacenta'], 0) + props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['lameness'], 0) + props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['mastitis'], 0) + props.results?.reduce((acc: number, currentValue: any) => acc + currentValue['metritisAndOthers'], 0),
            cows: yearlyLactatingCows
        }
    ]

    return (
        <Row>
            <Col width='100%'>
                <Table columns={columns} dataSource={dataSource} pagination={false} />
            </Col>
        </Row>
    )
};