import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Span } from '../../pages/components';
import { Button, ButtonGroup } from '@mui/material';
import { Checkbox, Input, InputNumber, Select } from 'antd';
import { DIS_NAMES, GUARDIAN_DISEASE_DEFAULT_COST_VALUES, MODEL_EXPRESS_LEVELS } from '../../pages/static';
import { DiseaseROCDialog } from '../DiseaseROCDialog';
import { Api } from '../../services/api';
import { HerdProfileContext } from '../../context';
import { AuthenticationContext } from '@ddw/react-framework';
import { DDWApplication } from '@ddw/react-components';
import useAsyncEffect from 'use-async-effect';
import { DiseaseData, computeSpecificity, findMinimumCostSensitivityAndSpecificity } from '../../pages/utils';
import { NotificationSettingsDialog } from '../NotificationSettingsDialog';
import { DiseaseSettingsOptionsContext } from '../../context/DiseaseSettingsOptionsContext';

export const DiseaseSettings: React.FC<any> = ({
  diseaseHeader,
  modelId,
  initValue,
  diseasesAllTogether,
  uniqueNotificationDays,
  uniqueNotificationDaysPriorToDryOff,
  uniqueNotificationDaysModeBeforeCalving,
  uniqueNotificationDaysModeBeforeDryOff,
  sensitivityDict,
  machineEstimatedDiseaseIncidenceDict,
  machineEstimatedCostDict,
  selectedEstimatedDiseaseIncidenceDict,
  selectedEstimatedCostDict,
  rocs,
  isAdmin,
  tzOffset,
  specificPrimaryDict,
  notificationSpecificSecondary,
  herdSize,
  setShowSaveHint
}) => {
  const { t: translate } = useTranslation();
  const { herdId } = useContext(HerdProfileContext);
  const { getAccessToken } = useContext(AuthenticationContext);
  const { diseaseSettingsOptions, setDiseaseSettingsOptions } = useContext(DiseaseSettingsOptionsContext);

  const [daysBeforeCalving, setDaysBeforeCalving] = useState(initValue[modelId]['days_to_calving']);
  const [daysBeforeDryOff, setDaysBeforeDryOff] = useState(initValue[modelId]['days_to_dry_off']);

  const [predictaChecked, setPredictaChecked] = useState(initValue[modelId]['validation_agent'] === 'Machine');

  const modelConfigurationOptions = [
    { label: translate('Express'), value: '0' },
    { label: translate('Advanced'), value: '1' },
    { label: translate('Predicta'), value: '2', disabled: true }
  ];
  const [modelConfiguration, setModelConfiguration] = useState(String(initValue[modelId]['config_mode']));
  const [modelConfigurationDisabled, setModelConfigurationDisabled] = useState(!initValue[modelId]['enabled'] || initValue[modelId]['validation_agent'] == 'Machine');

  const [estimatedDiseaseIncidence, setEstimatedDiseaseIncidence] = useState<number | null>(Number(initValue[modelId]['estimated_disease_incidence'].toFixed(2)));
  const [estimatedDiseaseIncidenceDisabled, setEstimatedDiseaseIncidenceDisabled] = useState(!initValue[modelId]['enabled'] || initValue[modelId]['validation_agent'] == 'Machine');

  const [diseaseCost, setDiseaseCost] = useState<number | null>(initValue[modelId]['disease_cost']);
  const [diseaseCostDisabled, setDiseaseCostDisabled] = useState<boolean>(!initValue[modelId]['enabled'] || initValue[modelId]['validation_agent'] == 'Machine');

  const [preventionCost, setPreventionCost] = useState<number | null>(initValue[modelId]['prevention_cost']);
  const [preventionCostDisabled, setPreventionCostDisabled] = useState<boolean>(!initValue[modelId]['enabled'] || initValue[modelId]['validation_agent'] == 'Machine');

  const [advancedSummary, setAdvancedSummary] = useState(initValue[modelId]['se_summary']);

  const [sensitivityButtonsActive, setSensitivityButtonsActive] = useState<boolean[]>(
    [
      initValue[modelId]['none_active'],
      initValue[modelId]['lo_active'],
      initValue[modelId]['med_active'],
      initValue[modelId]['hi_active'],
      initValue[modelId]['all_active']
    ].some((element) => element) ? [
      initValue[modelId]['none_active'],
      initValue[modelId]['lo_active'],
      initValue[modelId]['med_active'],
      initValue[modelId]['hi_active'],
      initValue[modelId]['all_active']
    ] : [false, false, true, false, false]
  );

  const expressSensitivityOptions = [
    { label: translate('0%'), value: '0' },
    { label: translate('Low'), value: '1' },
    { label: translate('Medium'), value: '2' },
    { label: translate('High'), value: '3' },
    { label: translate('100%'), value: '4' }
  ];
  const [expressSensitivity, setExpressSensitivity] = useState(expressSensitivityOptions[sensitivityButtonsActive.indexOf(true)].value);

  const [estimatedAlarms, setEstimatedAlarms] = useState<string | number>('--');

  const [showROCDialog, setShowROCDialog] = useState(false);
  const [showNotificationSettingsDialog, setShowNotificationSettingsDialog] = useState(false);

  const [notificationDaysModeBeforeCalvingChecked, setNotificationDaysModeBeforeCalvingChecked] = useState<boolean>(initValue[modelId]['notification_days_mode'] === 0);
  const [notificationDaysModeBeforeDryOff, setNotificationDaysModeBeforeDryOff] = useState<boolean>(initValue[modelId]['notification_days_mode'] === 1);

  const machineEstimatedDiseaseConst: number = machineEstimatedCostDict['disease'][modelId] != null ? machineEstimatedCostDict['disease'][modelId] : GUARDIAN_DISEASE_DEFAULT_COST_VALUES['disease'][modelId];
  const machineEstimatedPreventionCost: number = machineEstimatedCostDict['prevention'][modelId] != null ? machineEstimatedCostDict['prevention'][modelId] : GUARDIAN_DISEASE_DEFAULT_COST_VALUES['prevention'][modelId];
  const preventionEfficay: number = machineEstimatedCostDict['preventionEfficay'][modelId] != null ? machineEstimatedCostDict['preventionEfficay'][modelId] : 80;
  const predictaGuardianCost: number = machineEstimatedCostDict['predictaGuardianCost'][modelId] != null ? machineEstimatedCostDict['predictaGuardianCost'][modelId] : 0;

  const activateSensitivityButton = (position: number) => {
    let arr = [];
    for (let i = 0; i < 5; i++) {
      if (i === position) {
        arr.push(true);
      } else {
        arr.push(false);
      }
    }
    setSensitivityButtonsActive(arr);
  };

  useEffect(() => {
    setModelConfigurationDisabled(predictaChecked);
    setEstimatedDiseaseIncidenceDisabled(predictaChecked);
    setDiseaseCostDisabled(predictaChecked);
    setPreventionCostDisabled(predictaChecked);
    const configMode = predictaChecked ? '2' : (MODEL_EXPRESS_LEVELS[modelId].includes(sensitivityDict[String(modelId)]) ? '0' : '1');
    setModelConfiguration(configMode);

    let estimatedDir: number = modelId == 1 ? 30 : 5;
    let estimatedDiseaseCost: number = GUARDIAN_DISEASE_DEFAULT_COST_VALUES['disease'][modelId];
    let estimatedPreventionCost: number = GUARDIAN_DISEASE_DEFAULT_COST_VALUES['prevention'][modelId];

    if (predictaChecked) {
      if (machineEstimatedDiseaseIncidenceDict[String(modelId)] != null) {
        estimatedDir = Number(machineEstimatedDiseaseIncidenceDict[String(modelId)].toFixed(2));
      }

      if (machineEstimatedCostDict['disease'][String(modelId)] != null) {
        estimatedDiseaseCost = Number(machineEstimatedCostDict['disease'][String(modelId)].toFixed(2));
      }

      if (machineEstimatedCostDict['prevention'][String(modelId)] != null) {
        estimatedPreventionCost = Number(machineEstimatedCostDict['prevention'][String(modelId)].toFixed(2));
      }
    } else {
      if (selectedEstimatedDiseaseIncidenceDict[String(modelId)] != null) {
        estimatedDir = Number(selectedEstimatedDiseaseIncidenceDict[String(modelId)].toFixed(2));
      }

      if (selectedEstimatedCostDict['disease'][String(modelId)] != null) {
        estimatedDiseaseCost = Number(selectedEstimatedCostDict['disease'][String(modelId)].toFixed(2));
      }

      if (selectedEstimatedCostDict['prevention'][String(modelId)] != null) {
        estimatedPreventionCost = Number(selectedEstimatedCostDict['prevention'][String(modelId)].toFixed(2));
      }
    }

    setEstimatedDiseaseIncidence(estimatedDir);
    setDiseaseCost(estimatedDiseaseCost);
    setPreventionCost(estimatedPreventionCost)
  }, [predictaChecked]);

  useEffect(() => {
    if (modelConfiguration === '1') {
      // Advanced configuration
      setEstimatedDiseaseIncidenceDisabled(false);
      setDiseaseCostDisabled(false);
      setPreventionCostDisabled(false);

      // Auto-configuration of sensitivity
      const diseaseData: DiseaseData = { 'diseaseIncidence': Number(estimatedDiseaseIncidence), 'diseaseCostPerCases': Number(diseaseCost), 'preventionCost': Number(preventionCost), 'preventionEfficacy': preventionEfficay, 'predictaGuardian': predictaGuardianCost };
      const { sensitivity, specificity } = findMinimumCostSensitivityAndSpecificity(rocs, modelId, herdSize, diseaseData);
      setAdvancedSummary(`Se=${Math.round(sensitivity)}%; Sp=${Math.round(specificity)}%`);
    } else if (modelConfiguration === '2') {
      // Predicta configuration
      let estimatedDir: number = modelId == 1 ? 30 : 5;

      if (machineEstimatedDiseaseIncidenceDict[String(modelId)] != null) {
        estimatedDir = Number(machineEstimatedDiseaseIncidenceDict[String(modelId)].toFixed(2));
      }

      // Auto-configuration of sensitivity
      const diseaseData: DiseaseData = { 'diseaseIncidence': estimatedDir, 'diseaseCostPerCases': machineEstimatedDiseaseConst, 'preventionCost': machineEstimatedPreventionCost, 'preventionEfficacy': preventionEfficay, 'predictaGuardian': predictaGuardianCost };
      const { sensitivity, specificity } = findMinimumCostSensitivityAndSpecificity(rocs, modelId, herdSize, diseaseData);
      setAdvancedSummary(`Se=${Math.round(sensitivity)}%; Sp=${Math.round(specificity)}%`);
    } else {
      // Express configuration
      const activeButtons = [
        initValue[modelId]['none_active'],
        initValue[modelId]['lo_active'],
        initValue[modelId]['med_active'],
        initValue[modelId]['hi_active'],
        initValue[modelId]['all_active']
      ].some((element) => element) ? [
        initValue[modelId]['none_active'],
        initValue[modelId]['lo_active'],
        initValue[modelId]['med_active'],
        initValue[modelId]['hi_active'],
        initValue[modelId]['all_active']
      ] : [false, false, true, false, false];

      setExpressSensitivity(expressSensitivityOptions[activeButtons.indexOf(true)].value);

      setEstimatedDiseaseIncidenceDisabled(true);
      setDiseaseCostDisabled(true);
      setPreventionCostDisabled(true);
    }
  }, [modelConfiguration]);

  useEffect(() => {
    if (modelConfiguration === '0') {
      let selSe: number;
      if (sensitivityButtonsActive[0]) {
        selSe = MODEL_EXPRESS_LEVELS[modelId][0];
      } else if (sensitivityButtonsActive[1]) {
        selSe = MODEL_EXPRESS_LEVELS[modelId][1];
      } else if (sensitivityButtonsActive[2]) {
        selSe = MODEL_EXPRESS_LEVELS[modelId][2];
      } else if (sensitivityButtonsActive[3]) {
        selSe = MODEL_EXPRESS_LEVELS[modelId][3];
      } else if (sensitivityButtonsActive[4]) {
        selSe = MODEL_EXPRESS_LEVELS[modelId][4];
      } else {
        selSe = MODEL_EXPRESS_LEVELS[modelId][2];
      }

      const spValue = computeSpecificity(rocs, modelId, selSe);
      setAdvancedSummary(`Se=${Math.round(selSe)}%; Sp=${Math.round(spValue)}%`);
    }
  }, [sensitivityButtonsActive]);

  useAsyncEffect(async () => {
    document.body.style.cursor = 'progress';
    const result = await Api.herdSettings.estimateNAlarms(getAccessToken(), DDWApplication.DairyInsights, herdId, advancedSummary, estimatedDiseaseIncidence ? estimatedDiseaseIncidence : 0);
    if (result.success) {
      setEstimatedAlarms(result.data);
    }
    document.body.style.cursor = 'default';
  }, [advancedSummary, estimatedDiseaseIncidence]);

  useEffect(() => {
    const _settingsOptions = diseaseSettingsOptions;
    _settingsOptions[modelId] = {
      'notificationDays': daysBeforeCalving,
      'notificationDaysToDryOff': daysBeforeDryOff,
      'notificationDaysMode': notificationDaysModeBeforeCalvingChecked ? 0 : 1,
      'configMode': modelConfiguration,
      'advancedSe': advancedSummary.substring(advancedSummary.indexOf('=') + 1, advancedSummary.indexOf('%')),
      'estimatedDiseaseIncidence': estimatedDiseaseIncidence,
      'diseaseCost': diseaseCost,
      'preventionCost': preventionCost,
      'modelDiseaseIncidenceActive': predictaChecked,
      "express": sensitivityButtonsActive
    };
    setDiseaseSettingsOptions({ ..._settingsOptions });

    if (daysBeforeDryOff !== initValue[modelId]['days_to_dry_off'] || daysBeforeCalving !== initValue[modelId]['days_to_calving'] || predictaChecked !== (initValue[modelId]['validation_agent'] === 'Machine')
      || modelConfiguration !== String(initValue[modelId]['config_mode']) || advancedSummary !== initValue[modelId]['se_summary']
      || estimatedDiseaseIncidence !== Number(initValue[modelId]['estimated_disease_incidence'].toFixed(2))
      || initValue[modelId]['disease_cost'] !== null && (diseaseCost !== initValue[modelId]['disease_cost'])
      || (initValue[modelId]['prevention_cost'] !== null && preventionCost !== initValue[modelId]['prevention_cost'])
      || notificationDaysModeBeforeCalvingChecked !== (initValue[modelId]['notification_days_mode'] === 0)
      || notificationDaysModeBeforeDryOff !== (initValue[modelId]['notification_days_mode'] === 1)) {
      setShowSaveHint(true);

      // console.log(modelId, daysBeforeCalving, initValue[modelId]['days_to_calving']);
      // console.log(modelId, predictaChecked, initValue[modelId]['validation_agent'] === 'Machine');
      // console.log(modelId, modelConfiguration, String(initValue[modelId]['config_mode']));
      // console.log(modelId, predictaChecked, advancedSummary, initValue[modelId]['se_summary']);
      // console.log(modelId, estimatedDiseaseIncidence, Number(initValue[modelId]['estimated_disease_incidence'].toFixed(2)));
      // console.log(modelId, diseaseCost, initValue[modelId]['disease_cost']);
      // console.log(modelId, preventionCost, initValue[modelId]['prevention_cost']);
    }
  }, [daysBeforeCalving, daysBeforeDryOff, predictaChecked, modelConfiguration, advancedSummary, estimatedDiseaseIncidence, diseaseCost, preventionCost, notificationDaysModeBeforeCalvingChecked, notificationDaysModeBeforeDryOff]);

  useEffect(() => {
    activateSensitivityButton(Number(expressSensitivity));
  }, [expressSensitivity]);

  useEffect(() => {
    if (modelConfiguration !== '0') {
      // Auto-configuration of sensitivity
      const diseaseData: DiseaseData = { 'diseaseIncidence': Number(estimatedDiseaseIncidence), 'diseaseCostPerCases': Number(diseaseCost), 'preventionCost': Number(preventionCost), 'preventionEfficacy': preventionEfficay, 'predictaGuardian': predictaGuardianCost };
      const { sensitivity, specificity } = findMinimumCostSensitivityAndSpecificity(rocs, modelId, herdSize, diseaseData);
      setAdvancedSummary(`Se=${Math.round(sensitivity)}%; Sp=${Math.round(specificity)}%`);
    }
  }, [estimatedDiseaseIncidence, diseaseCost, preventionCost]);

  return (
    <>
      <Row className={'mt-3'} style={{ alignItems: 'center' }}>
        <Col width={'15%'}>
          <Span>{diseaseHeader}</Span>
        </Col>

        <Col width='12%'>
          <Row style={{ alignItems: 'center' }}>
            <Col>
              <Checkbox checked={modelId === '1' ? false : diseasesAllTogether ? uniqueNotificationDaysModeBeforeDryOff : notificationDaysModeBeforeDryOff}
                disabled={!initValue[modelId]['enabled'] || diseasesAllTogether || modelId === '1'}
                onChange={(event) => { setNotificationDaysModeBeforeDryOff(event.target.checked); setNotificationDaysModeBeforeCalvingChecked(!event.target.checked); }} />
            </Col>

            <Col>
              <InputNumber min={0} max={7} step={1} value={diseasesAllTogether ? uniqueNotificationDaysPriorToDryOff : daysBeforeDryOff}
                onChange={setDaysBeforeDryOff} disabled={!initValue[modelId]['enabled'] || diseasesAllTogether || (!diseasesAllTogether && !notificationDaysModeBeforeDryOff)} />
            </Col>
          </Row>
        </Col>

        <Col width={'15%'}>
          <Row style={{ alignItems: 'center' }}>
            <Col>
              <Checkbox checked={modelId === '1' ? true : diseasesAllTogether ? uniqueNotificationDaysModeBeforeCalving : notificationDaysModeBeforeCalvingChecked}
                disabled={!initValue[modelId]['enabled'] || diseasesAllTogether}
                onChange={(event) => { if (modelId !== '1') { setNotificationDaysModeBeforeCalvingChecked(event.target.checked); setNotificationDaysModeBeforeDryOff(!event.target.checked) } }} />
            </Col>

            <Col>
              <InputNumber min={0} max={80} step={1}
                value={diseasesAllTogether ? uniqueNotificationDays : daysBeforeCalving}
                onChange={setDaysBeforeCalving}
                disabled={!initValue[modelId]['enabled'] || diseasesAllTogether || (!diseasesAllTogether && !notificationDaysModeBeforeCalvingChecked)} />
            </Col>
          </Row>
        </Col>

        <Col width={'8%'}>
          <Checkbox checked={predictaChecked}
            onChange={(event) => setPredictaChecked(event.target.checked)}
            disabled={!initValue[modelId]['enabled'] || !initValue[modelId]['has_machine_estimated_disease_incidence']}
          />
        </Col>
        <Col width={'12%'}>
          <Select style={{ width: 105 }}
            value={modelConfiguration}
            options={modelConfigurationOptions}
            onChange={setModelConfiguration}
            disabled={modelConfigurationDisabled}
          />
        </Col>
        <Col width={'30%'} overflow={'auto'}>
          <Select style={{ width: 105, display: modelConfiguration !== '0' ? 'none' : '' }}
            value={expressSensitivity}
            options={expressSensitivityOptions}
            onChange={setExpressSensitivity}
          />
          <div style={{
            width: '100%',
            display: modelConfiguration === '0' ? 'none' : ''
          }}>
            <Row style={{ alignItems: 'center' }}>
              <Col width={'20%'}>
                <Button onClick={() => {
                  setShowROCDialog(true);
                }}>{translate('View')}</Button>
              </Col>
              <Col width={'80%'}>
                <Input disabled={true} value={advancedSummary} />
              </Col>
            </Row>
            <DiseaseROCDialog showDialog={showROCDialog} setShowDialog={setShowROCDialog} modelId={modelId}
              seLevel={advancedSummary} rocs={rocs} />
          </div>
        </Col>
        <Col width={'10%'}>
          <InputNumber min={0} max={100} step={0.01} value={estimatedDiseaseIncidence}
            onChange={(value: number | null) => {
              setEstimatedDiseaseIncidence(value);
            }}
            disabled={estimatedDiseaseIncidenceDisabled} />
        </Col>
        <Col width={'10%'}>
          <InputNumber min={0} max={10000} step={1} value={diseaseCost} onChange={(value: number | null) => {
            setDiseaseCost(value);
          }} disabled={diseaseCostDisabled} />
        </Col>
        <Col width={'10%'}>
          <InputNumber min={0} max={10000} step={1} value={preventionCost} onChange={(value: number | null) => {
            setPreventionCost(value);
          }} disabled={preventionCostDisabled} />
        </Col>
        <Col width={'10%'} className={'mt-1'}>
          <Span>{estimatedAlarms}</Span>
        </Col>
        <Col width={'10%'} style={{ display: diseasesAllTogether ? 'none' : '' }}>
          <Button disabled={diseasesAllTogether || !initValue[modelId]['enabled']} onClick={() => {
            setShowNotificationSettingsDialog(true);
          }}>
            {translate('Edit')}
          </Button>
          <NotificationSettingsDialog showDialog={showNotificationSettingsDialog}
            setShowDialog={setShowNotificationSettingsDialog}
            headerName={translate(DIS_NAMES[modelId]) + ' ' + translate('Notification settings')}
            isAdmin={isAdmin} tzOffset={tzOffset} modelId={modelId}
            notificationPrimary={specificPrimaryDict}
            notificationSecondary={notificationSpecificSecondary}
            setUniqueSummary={null}
            setShowSaveHint={setShowSaveHint}
          />
        </Col>
      </Row>
    </>
  );
};