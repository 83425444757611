import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Plotly from 'plotly.js';
import { getColor, getObjectValues } from '../../../../pages/utils';
import { Data, Layout } from 'plotly.js';
import { ViewContext } from '@ddw/react-framework';
import { ViewSize } from '@ddw/react-components';

export const StatsBottomRightBarChart: React.FC<any> = ({ statsData, model }) => {
  const { t: translate } = useTranslation();
  const { viewSize } = useContext(ViewContext);

  const chartRef = useRef<HTMLDivElement>(null);

  const data: Data[] = [
    {
      x: getObjectValues<string>(statsData.statsPath.Period).map((period: string) => translate(period.substring(0, 3)) + period.substring(3)),
      y: getObjectValues<number>(statsData.statsPath['Total Alarms']),
      opacity: 1.0,
      yaxis: 'y1',
      name: translate('Total Alarms'),
      marker: { color: getColor(model, 0) },
      type: 'bar'
    },
    {
      x: getObjectValues<string>(statsData.statsPath.Period).map((period: string) => translate(period.substring(0, 3)) + period.substring(3)),
      y: getObjectValues<number>(statsData.statsPath.Treatments),
      opacity: 1.0,
      width: 0.4,
      yaxis: 'y1',
      name: translate('of which treated'),
      marker: { color: getColor(model, 1) },
      type: 'bar'
    },
    {
      x: getObjectValues<string>(statsData.statsPath.Period).map((period: string) => translate(period.substring(0, 3)) + period.substring(3)),
      y: getObjectValues<number>(statsData.statsPath.Treatments).map((treatment: any, i) => treatment / getObjectValues<number>(statsData.statsPath['Total Alarms'])[i]),
      opacity: 1.0,
      yaxis: 'y2',
      name: translate('Treated rate'),
      line: { dash: 'solid', color: getColor(model, 2) },
      type: 'scatter'
    },
    {
      x: getObjectValues<string>(statsData.statsPath.Period).map((period: string) => translate(period.substring(0, 3)) + period.substring(3)),
      y: getObjectValues<number>(statsData.statsPath.Ignored).map((ignored: any, i) => ignored / getObjectValues<number>(statsData.statsPath['Total Alarms'])[i]),
      opacity: 1.0,
      yaxis: 'y2',
      name: translate('Ignored rate'),
      line: { dash: 'dash', color: getColor(model, 2) },
      type: 'scatter'
    }
  ];

  const layout: Partial<Layout> = {
    barmode: 'overlay',
    title: viewSize > ViewSize.S ? translate('Alarm compliance - last 12 months') : '',
    showlegend: true,
    legend: {
      xanchor: 'center',
      x: 0.5,
      yanchor: 'bottom',
      y: 1,
      orientation: 'h'
    },
    xaxis: { tickangle: -30, automargin: true },
    hovermode: 'closest',
    yaxis2: {
      title: translate('Compliance rate (%)'),
      overlaying: 'y',
      side: 'right',
      range: [0.0, 1.0],
      automargin: true
    }
  };

  useEffect(() => {
    if (chartRef.current) {
      Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
      //Plotly.newPlot(chartRef.current, data, layout);
    }
  }, [viewSize]);

  return (
    <>
      <div ref={chartRef} />
    </>
  );
};