import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonType,
  Dialog,
  DialogButtonsRightSide, DialogContent,
  DialogFooter,
  DialogHeader,
  DialogSize
} from '@ddw/react-components';
import { DDW_green, DDW_grey, DIS_NAMES } from '../../pages/static';
import { Col, Paragraph, Row } from '../../pages/components';
import { Slider } from '@mui/material';
// import { ROCGraph } from '../Charts';
import { computeSpecificity, getObjectValues } from '../../pages/utils';
import { Data, Layout } from 'plotly.js';
import * as Plotly from 'plotly.js';

export const DiseaseROCDialog: React.FC<any> = ({
                                                  showDialog,
                                                  setShowDialog,
                                                  modelId,
                                                  seLevel,
                                                  rocs
                                                }) => {
  const { t: translate } = useTranslation();

  const marks = [...Array(21)].map((_, i) => {
    const value = i * 5;
    return {
      value: value,
      label: value.toString()
    };
  });

  const [sensitivity, setSensitivity] = useState(Number(seLevel.substring(seLevel.indexOf("=") + 1, seLevel.indexOf("%"))));
  const [specificity, setSpecificity] = useState<number>(0);

  const [savedSensitivity, setSavedSensitivity] = useState(seLevel);

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    setSensitivity(newValue as number);
  };

  const chartRef = useRef<HTMLDivElement>(null);

  const layout: Partial<Layout> = {
    title: translate('Model ROC configuration'),
    xaxis: {
      title: translate('1 - Specificity (%)'),
      range: [0, 100],
      autorange: false
    },
    yaxis: {
      title: translate('Sensitivity (%)'),
      range: [0, 100]
      //scaleanchor: 'x',
      //scaleratio: 1
    },
    hovermode: 'closest',
    height: 520,
    width: 520
  };

  const data: Data[] = [
    {
      x: [0, 100],
      y: [0, 100],
      type: 'scatter',
      mode: 'lines',
      opacity: 0.5,
      line: {
        color: DDW_grey,
        dash: 'dash',
        width: 1
      },
      showlegend: false
    },
    {
      x: getObjectValues<number>(rocs[modelId]?.Specificity).map((val: number) => 100 - val),
      y: getObjectValues<number>(rocs[modelId]?.Sensitivity),
      type: 'scatter',
      mode: 'lines',
      opacity: 1,
      name: translate('Model ROC'),
      line: {
        color: '#000000',
        width: 1
      },
      showlegend: false
    },
    {
      x: [100 - specificity],
      y: [sensitivity],
      mode: 'markers',
      marker: {
        color: DDW_green,
        size: 13
      },
      opacity: 1,
      name: translate('User settings'),
      showlegend: false
    }
  ];

  useEffect(() => {
    setSensitivity(Number(seLevel.substring(seLevel.indexOf("=") + 1, seLevel.indexOf("%"))));
    setSpecificity(computeSpecificity(rocs, modelId, Number(seLevel.substring(seLevel.indexOf("=") + 1, seLevel.indexOf("%")))));
  }, [seLevel]);

  useEffect(() => {
    if (chartRef.current) {
      Plotly.newPlot(chartRef.current, data, layout, { responsive: true, displayModeBar: false });
    }
  });

  return (
    <>
      <Dialog size={DialogSize.S} show={showDialog} setShow={setShowDialog} closeOnClickOutside={false}
              customMinHeight={'700px'}>
        <DialogHeader>
          <h3 style={{ margin: '0px' }}>{translate(DIS_NAMES[modelId]) + ' ' + translate('Model ROC')}</h3>
        </DialogHeader>

        <DialogContent>
          <Row className={'mt-4'} style={{ alignItems: 'center' }}>
            <Col width={'30%'}>
              <Paragraph>{translate('Sensitivity (%)')}</Paragraph>
            </Col>
            <Col className={'mt-3'} width={'70%'}>
              <Slider min={0} max={100} step={1} value={sensitivity} onChange={handleSliderChange}
                      valueLabelDisplay={'auto'} marks={marks} disabled={true} />
            </Col>
          </Row>

          <Row className={'mt-4'} style={{ alignItems: 'center' }}>
            <Col width={'30%'}>
              <Paragraph>{translate('Specificity (%)')}</Paragraph>
            </Col>
            <Col className={'mt-3'} width={'70%'}>
              <Slider min={0} max={100} step={1} disabled={true} value={specificity}
                      valueLabelDisplay={'auto'} marks={marks} />
            </Col>
          </Row>

          <Row className={'mt-4'}>
            {/*<ROCGraph rocs={rocs} modelId={modelId} specificity={specificity} sensitivity={sensitivity} />*/}
            <div style={{ margin: '0 auto' }} ref={chartRef} />
          </Row>
        </DialogContent>

        <DialogFooter>
          <DialogButtonsRightSide>
            <Button name={`ok`} type={ButtonType.Primary} isOutlined={false} 
            onClick={() => {
              // setAdvancedSummary(`Se=${Math.round(sensitivity)}%; Sp=${Math.round(specificity)}%`);
              // setSavedSensitivity(sensitivity);
              setShowDialog(false);
            }}
            >
              {translate('OK')}
            </Button>
            
            {/* <Button name={`cancel`} type={ButtonType.Cancel} isOutlined={false} onClick={() => {
              setShowDialog(false);
              setSensitivity(savedSensitivity);
            }}>
              {translate('Cancel')}
            </Button> */}
          </DialogButtonsRightSide>
        </DialogFooter>
      </Dialog>
    </>
  );
};