import Plotly, { Data, Layout } from "plotly.js";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { DDW_grey, DIS_COL, PLOTLY_TEMPLATE } from "../../../../../pages/static";

export const LactationReportedByTypeChart: React.FC<any> = (props) => {
    const { t: translate } = useTranslation();

    const chartRef = useRef<HTMLDivElement>(null);

    const data: Data[] = [
        {
            x: props.period.map((value: string) => translate(value.substring(0, 3)) + value.substring(3)),
            y: props.results.map((value: any) => ((value['ketosis'] / value['lactatingCows']) * 100).toFixed(2)),
            opacity: 1.0,
            type: 'bar',
            name: translate('Ketosis'),
            marker: {
                color: DIS_COL['1']
            },
            customdata: props.results.map((value: any) => [value['ketosis'], value['lactatingCows']]),
            hovertemplate: translate('%{x}, Incidence: %{y}%<br>Cases: %{customdata[0]}<br>Lactating cows: %{customdata[1]}')
        },
        {
            x: props.period.map((value: string) => translate(value.substring(0, 3)) + value.substring(3)),
            y: props.results.map((value: any) => ((value['milkFever'] / value['lactatingCows']) * 100).toFixed(2)),
            opacity: 1.0,
            type: 'bar',
            name: translate('Milk Fever'),
            marker: {
                color: DIS_COL['3']
            },
            customdata: props.results.map((value: any) => [value['milkFever'], value['lactatingCows']]),
            hovertemplate: translate('%{x}, Incidence: %{y}%<br>Cases: %{customdata[0]}<br>Lactating cows: %{customdata[1]}')
        },
        {
            x: props.period.map((value: string) => translate(value.substring(0, 3)) + value.substring(3)),
            y: props.results.map((value: any) => ((value['displacedAbomasum'] / value['lactatingCows']) * 100).toFixed(2)),
            opacity: 1.0,
            type: 'bar',
            name: translate('Displaced Abomasum'),
            marker: {
                color: DIS_COL['4']
            },
            customdata: props.results.map((value: any) => [value['displacedAbomasum'], value['lactatingCows']]),
            hovertemplate: translate('%{x}, Incidence: %{y}%<br>Cases: %{customdata[0]}<br>Lactating cows: %{customdata[1]}')
        },
        {
            x: props.period.map((value: string) => translate(value.substring(0, 3)) + value.substring(3)),
            y: props.results.map((value: any) => ((value['retainedPlacenta'] / value['lactatingCows']) * 100).toFixed(2)),
            opacity: 1.0,
            type: 'bar',
            name: translate('Retained Placenta'),
            marker: {
                color: DIS_COL['5']
            },
            customdata: props.results.map((value: any) => [value['retainedPlacenta'], value['lactatingCows']]),
            hovertemplate: translate('%{x}, Incidence: %{y}%<br>Cases: %{customdata[0]}<br>Lactating cows: %{customdata[1]}')
        },
        {
            x: props.period.map((value: string) => translate(value.substring(0, 3)) + value.substring(3)),
            y: props.results.map((value: any) => ((value['lameness'] / value['lactatingCows']) * 100).toFixed(2)),
            opacity: 1.0,
            type: 'bar',
            name: translate('Lameness'),
            marker: {
                color: DIS_COL['6']
            },
            customdata: props.results.map((value: any) => [value['lameness'], value['lactatingCows']]),
            hovertemplate: translate('%{x}, Incidence: %{y}%<br>Cases: %{customdata[0]}<br>Lactating cows: %{customdata[1]}')
        },
        {
            x: props.period.map((value: string) => translate(value.substring(0, 3)) + value.substring(3)),
            y: props.results.map((value: any) => ((value['mastitis'] / value['lactatingCows']) * 100).toFixed(2)),
            opacity: 1.0,
            type: 'bar',
            name: translate('Mastitis'),
            marker: {
                color: DIS_COL['7']
            },
            customdata: props.results.map((value: any) => [value['mastitis'], value['lactatingCows']]),
            hovertemplate: translate('%{x}, Incidence: %{y}%<br>Cases: %{customdata[0]}<br>Lactating cows: %{customdata[1]}')
        },
        {
            x: props.period.map((value: string) => translate(value.substring(0, 3)) + value.substring(3)),
            y: props.results.map((value: any) => ((value['metritisAndOthers'] / value['lactatingCows']) * 100).toFixed(2)),
            opacity: 1.0,
            type: 'bar',
            name: translate('Metritis'),
            marker: {
                color: DDW_grey
            },
            customdata: props.results.map((value: any) => [value['metritisAndOthers'], value['lactatingCows']]),
            hovertemplate: translate('%{x}, Incidence: %{y}%<br>Cases: %{customdata[0]}<br>Lactating cows: %{customdata[1]}')
        }
    ];

    const layout: Partial<Layout> = {
        title: {
            text: translate('Disease incidence (overall lactation)'),
            yanchor: 'top'
        },
        yaxis: {
            title: translate('Incidence (%)')
        },
        showlegend: true,
        barmode: 'stack',
        hovermode: 'closest',
        template: PLOTLY_TEMPLATE
    };

    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, data, layout, { responsive: true });
        }
    }, [props]);

    return (
        <div ref={chartRef} />
    );
};