import Plotly, { Data, Layout } from "plotly.js";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { DDW_blue, DDW_cyan, DDW_dark_grey, DDW_green, DDW_grey, DDW_orange, DDW_pink, DDW_purple, DDW_yellow, PLOTLY_TEMPLATE } from "../../../../pages/static";
import { DryOffData, GroupChange, HealthEvent, HeatStressData, Insemination } from "../../../../typings/visio/types";
import { createMatrixFromHistScatter, findMinInMatrix, formatDate, formatDateToYYYYMMDD } from "../../../../pages/utils";

type VisioAnimalPerformanceChartProps = {
    histScatter: any;
    cols: string[] | undefined;
    colsDim: (number | null)[];
    healthEvents: HealthEvent[];
    dryOffs: DryOffData[];
    inseminations: Insemination[];
    groupChanges: GroupChange[];
    heatStressData: HeatStressData;
    toPlotValues: any;
    infoActive: { [index: string]: boolean };
    lactationsOfInterest: string;
    historical: boolean;
    totalNumberOfLactations: number;
};

export const VisioAnimalPerformanceChart: React.FC<VisioAnimalPerformanceChartProps> = ({ histScatter, cols, colsDim, totalNumberOfLactations,
    healthEvents, dryOffs, inseminations, groupChanges, heatStressData, toPlotValues, infoActive, lactationsOfInterest, historical }) => {
    const { t: translate } = useTranslation();

    const chartRef = useRef<HTMLDivElement>(null);

    // @ts-ignore
    const minimumMilkValue: number = findMinInMatrix(createMatrixFromHistScatter(histScatter));

    function splitArrayWithNulls(arr: (number | null)[]) {
        const nullArray: (null | number)[] = arr.slice(arr.indexOf(null), arr.length - 1);

        if (nullArray.every(item => item === null)) {
            return [[], arr];
        } else {
            let arrayWithNullsBefore = arr.slice(0, arr.indexOf(null));
            const lengthToAddForAfterNulls: number = arrayWithNullsBefore.length;
            const additionalLength = arr.length - arrayWithNullsBefore.length;
            let filledArray = (new Array(additionalLength)).fill(null);
            arrayWithNullsBefore = arrayWithNullsBefore.concat(filledArray);

            let arrayWithNullsAfter = arr.slice(arr.indexOf(null));
            filledArray = (new Array(lengthToAddForAfterNulls)).fill(null);
            arrayWithNullsAfter = filledArray.concat(arrayWithNullsAfter);

            return [arrayWithNullsBefore, arrayWithNullsAfter];
        }
    };

    const [arrayWithNullsBefore, arrayWithNullsAfter] = splitArrayWithNulls(toPlotValues != null && toPlotValues.length > 0 ? toPlotValues[0] : []);

    const data: Data[] =
        histScatter.filter((element: any[]) => element[1].length > 0 && element[3].length > 0)
            .map((element: any[], index: number) => ({
                // x: lactationsOfInterest !== '1' ? element[0] : element[3], // Dates or DIMs
                x: historical ? element[0] : element[3], // Dates or DIMs
                y: element[1], // Raw milk values
                mode: 'markers',
                opacity: 0.75,
                name: translate('Historical'),
                line: { color: DDW_grey },
                showlegend: index === 0 ? true : false,
                customdata: element[1].map((_: any, index: number) => [element[3][index], formatDate(element[0][index])]),
                hovertemplate: translate('(DIM: %{customdata[0]}, %{customdata[1]}, Milk: %{y})')
            }))

            .concat(histScatter.filter((element: any[]) => element[2].length > 0 && element[4].length > 0)
                .map((element: any[], index: number) => ({
                    // x: lactationsOfInterest !== '1' ? element[5] : element[4], // Dates or DIMs
                    x: historical ? element[5] : element[4], // Dates or DIMs
                    y: element[2], // Smoothened milk value
                    mode: 'lines',
                    opacity: 1,
                    name: translate('Historical (smoothened)'),
                    line: { color: DDW_purple },
                    showlegend: index === 0 ? true : false,
                    customdata: element[1].map((_: any, index: number) => [element[4][index], formatDate(element[5][index])]),
                    hovertemplate: translate('(DIM: %{customdata[0]}, %{customdata[1]}, Milk: %{y})')
                })))

            .concat(healthEvents.map((event: HealthEvent) => (infoActive['healthEvents'] ? {
                // x: lactationsOfInterest !== '1' ? [event.startDate, event.startDate] : [event.startDim, event.startDim],
                x: historical ? [event.startDate, event.startDate] : [event.startDim, event.startDim],
                y: [event.milkValue, minimumMilkValue],
                mode: 'lines+markers',
                opacity: 1,
                name: translate('Health event'),
                line: { color: DDW_green, dash: 'dot' },
                marker: { symbol: 'diamond' },
                showlegend: false,
                customdata: [[event.label, event.startDim, formatDate(event.startDate), event.milkValue], [event.label, event.startDim, formatDate(event.startDate), event.milkValue]],
                hovertemplate: translate('Label: %{customdata[0]}<br>DIM: %{customdata[1]}<br>Start date: %{customdata[2]}<br>Milk: %{customdata[3]}')
            } : {})))

            .concat(heatStressData?.thiValues ? heatStressData.thiValues.map((event: (string | number)[], index: number) => (infoActive['heathStress'] ? {
                // x: lactationsOfInterest !== '1' ? [formatDateToYYYYMMDD(event[0] as string), formatDateToYYYYMMDD(event[0] as string)] : [heatStressData.thiDims![index], heatStressData.thiDims![index]],
                x: historical ? [formatDateToYYYYMMDD(event[0] as string), formatDateToYYYYMMDD(event[0] as string)] : [heatStressData.thiDims![index], heatStressData.thiDims![index]],
                y: [heatStressData.milkValues![index], minimumMilkValue],
                mode: 'lines+markers',
                opacity: 1,
                name: translate('Heat stress event'),
                line: { color: DDW_yellow, dash: 'dot' },
                marker: { symbol: 'diamond' },
                showlegend: false,
                customdata: [[formatDate(event[0] as string), (event[1] as number).toFixed(2), heatStressData.thiDims![index] == null ? 'N.A.' : heatStressData.thiDims![index], heatStressData.milkValues![index]],
                [formatDate(event[0] as string), (event[1] as number).toFixed(2), heatStressData.thiDims![index] == null ? 'N.A.' : heatStressData.thiDims![index], heatStressData.milkValues![index]]],
                hovertemplate: translate('Date: %{customdata[0]}<br>THI: %{customdata[1]}<br>DIM: %{customdata[2]}<br>Milk: %{customdata[3]}')
            } : {})) : [])

            .concat(dryOffs.map((dryOff: DryOffData) => (infoActive['dryOff'] ? {
                // x: lactationsOfInterest !== '1' ? [dryOff.date, dryOff.date] : [dryOff.day, dryOff.day],
                x: historical ? [dryOff.date, dryOff.date] : [dryOff.day, dryOff.day],
                y: [dryOff.milkValue, minimumMilkValue],
                mode: 'lines+markers',
                opacity: 1,
                name: translate('Dry off'),
                line: { color: DDW_orange, dash: 'dot' },
                marker: { symbol: 'diamond' },
                showlegend: false,
                customdata: [[formatDate(dryOff.date), dryOff.day, dryOff.days, dryOff.milkValue],
                [formatDate(dryOff.date), dryOff.day, dryOff.days, dryOff.milkValue]],
                hovertemplate: translate('Date: %{customdata[0]}<br>DIM: %{customdata[1]}<br>Dry off days: %{customdata[2]}<br>Milk: %{customdata[3]}')
            } : {})))

            .concat(groupChanges.map((groupChange: GroupChange) => (infoActive['groupChange'] ? {
                // x: lactationsOfInterest !== '1' ? [groupChange.startDate, groupChange.startDate] : [groupChange.startDim, groupChange.startDim],
                x: historical ? [groupChange.startDate, groupChange.startDate] : [groupChange.startDim, groupChange.startDim],
                y: [groupChange.milkValue, minimumMilkValue],
                mode: 'lines+markers',
                opacity: 1,
                name: translate('Group change'),
                line: { color: DDW_blue, dash: 'dot' },
                marker: { symbol: 'diamond' },
                showlegend: false,
                customdata: [[groupChange.groupName, groupChange.startDim, formatDate(groupChange.startDate), groupChange.milkValue],
                [groupChange.groupName, groupChange.startDim, formatDate(groupChange.startDate), groupChange.milkValue]],
                hovertemplate: translate('Group: %{customdata[0]}<br>Start DIM: %{customdata[1]}<br>Start date: %{customdata[2]}<br>Milk: %{customdata[3]}')
            } : {})))

            .concat(inseminations.map((insemination: Insemination) => (infoActive['insemination'] ? {
                // x: lactationsOfInterest !== '1' ? [insemination.date, insemination.date] : [insemination.dim, insemination.dim],
                x: historical ? [insemination.date, insemination.date] : [insemination.dim, insemination.dim],
                y: [insemination.milkValue, minimumMilkValue],
                mode: 'lines+markers',
                opacity: 1,
                name: translate('Artificial insemination'),
                line: { color: DDW_pink, dash: 'dot' },
                marker: { symbol: 'diamond' },
                showlegend: false,
                customdata: [[formatDate(insemination.date), insemination.dim, insemination.milkValue],
                [formatDate(insemination.date), insemination.dim, insemination.milkValue]],
                hovertemplate: translate('Date: %{customdata[0]}<br>DIM: %{customdata[1]}<br>Milk: %{customdata[2]}')
            } : {})))

            .concat(totalNumberOfLactations > 0 ? {
                // x: lactationsOfInterest !== '1' ? cols : colsDim,
                x: historical ? cols : colsDim,
                y: arrayWithNullsBefore,
                mode: 'lines',
                opacity: 1,
                name: translate('Forecast (remaining lactation)'),
                legendgroup: 'Forecast (remaining lactation)',
                line: { color: DDW_dark_grey },
                showlegend: true,
                customdata: cols?.map((_: any, index: number) => [colsDim[index], formatDate(cols![index])]),
                hovertemplate: translate('(DIM: %{customdata[0]}, %{customdata[1]}, Milk: %{y})')
            } : {})

            .concat({
                // x: lactationsOfInterest !== '1' ? cols : colsDim,
                x: historical ? cols : (totalNumberOfLactations === 0 ? cols : colsDim),
                y: arrayWithNullsAfter,
                mode: 'lines',
                opacity: 1,
                name: translate('Forecast (next lactation)'),
                legendgroup: 'Forecast (next lactation)',
                line: { color: DDW_cyan },
                showlegend: true,
                customdata: cols?.map((_: any, index: number) => [colsDim[index], formatDate(cols![index])]),
                hovertemplate: translate('(DIM: %{customdata[0]}, %{customdata[1]}, Milk: %{y})')
            });

    const layout: Partial<Layout> = {
        xaxis: {
            // title: lactationsOfInterest !== '1' ? '' : translate('DIM')
            title: historical ? '' : translate('DIM')
        },
        yaxis: {
            title: translate('Daily Milk Yield (kg)')
        },
        showlegend: true,
        legend: {
            xanchor: 'center',
            x: 0.5,
            yanchor: 'bottom',
            y: 1,
            orientation: 'h'
        },
        height: 550,
        hovermode: 'closest',
        template: PLOTLY_TEMPLATE
    };

    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, historical ? data.slice(0, -2) : data.slice(-2), layout, { responsive: true });
        }
    }, [histScatter, cols, colsDim, healthEvents, dryOffs, inseminations, groupChanges, heatStressData, toPlotValues, infoActive, historical, totalNumberOfLactations]);

    return (
        <div ref={chartRef} />
    );
};