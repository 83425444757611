import { Card, CardContent, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Span } from "../../pages/components";
import { VISIO_PRESCRIPTION_COLOR } from "../../pages/static";
import { Prescription } from "../../typings/visio/types";
import { Checkbox } from "antd";
import { Api } from "../../services/api";
import { DDWApplication } from "@ddw/react-components";
import { HerdProfileContext } from "../../context";
import { AuthenticationContext, SnackbarMessageType, SnackbarMessagesContext } from "@ddw/react-framework";
import { useNavigate } from "react-router-dom";

type PrescriptionCardProps = {
    prescriptionData: Prescription;
    fetchPrescriptions: () => Promise<void>;
};

export const PrescriptionCard: React.FC<PrescriptionCardProps> = ({ prescriptionData, fetchPrescriptions }) => {
    const { t: translate } = useTranslation();
    const { herdId } = useContext(HerdProfileContext);
    const { getAccessToken } = useContext(AuthenticationContext);
    const { addMessageToStack } = useContext(SnackbarMessagesContext);

    const navigate = useNavigate();

    const [ignoredChecked, setIgnoredChecked] = useState<boolean>(prescriptionData.Ignored === 1);

    const handleIgnoredChange = async (event: any) => {
        setIgnoredChecked(event.target.checked);
        document.body.style.cursor = 'progress';
        const result = await Api.visio.updatePrescription(getAccessToken(), DDWApplication.DairyInsights, herdId, event.target.checked, prescriptionData.AnimalId, prescriptionData.Parity, prescriptionData.DIM);
        if (result.success) {
            await fetchPrescriptions();
            addMessageToStack({
                messageType: SnackbarMessageType.success,
                message: translate('Successfully updated prescription.'),
                autoHide: true
            });
        } else {
            addMessageToStack({
                messageType: SnackbarMessageType.error,
                message: translate('There was a problem updating prescription.'),
                autoHide: true
            });
        }
        document.body.style.cursor = 'default';
    };

    return (
        <>
            <Card style={{
                boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)',
                backgroundColor: '#F7F7F7',
                marginBottom: '5px',
                width: '100%'
            }}>
                <CardContent>
                    <Row>
                        <Col width={'25%'} onClick={() => {
                            navigate('/animal', { state: { eartag: prescriptionData.EarTag, cowNumber: prescriptionData.CowNumber } });
                        }} onMouseEnter={() => { document.body.style.cursor = 'pointer'; }}
                            onMouseLeave={() => { document.body.style.cursor = 'default'; }}>
                            <Row className={'ml-2'}><Typography
                                style={{ fontWeight: 'bold' }}>{translate('EarTag')}</Typography></Row>
                            <Row className={'ml-2 mt-1'}>{prescriptionData.EarTag}</Row>
                            <Row className={'ml-2 mt-3'}><Typography
                                style={{ fontWeight: 'bold' }}>{translate('Predicted average daily production if conception in this cycle')}</Typography></Row>
                            <Row className={'ml-2 mt-1'}>{prescriptionData.PredictedAverageDailyProductionIfConceptionInThisCycle ? `${prescriptionData.PredictedAverageDailyProductionIfConceptionInThisCycle} ${translate('kg / lactation day')}` : 'n.a.'}</Row>
                        </Col>

                        <Col width={'25%'} onClick={() => {
                            navigate('/animal', { state: { eartag: prescriptionData.EarTag, cowNumber: prescriptionData.CowNumber } });
                        }} onMouseEnter={() => { document.body.style.cursor = 'pointer'; }}
                            onMouseLeave={() => { document.body.style.cursor = 'default'; }}>
                            <Row><Typography style={{ fontWeight: 'bold' }}>{translate('Cow #')}</Typography></Row>
                            <Row className={'mt-1'}>{prescriptionData.CowNumber ? prescriptionData.CowNumber : 'n.a.'}</Row>
                            <Row className={'mt-3'}><Typography
                                style={{ fontWeight: 'bold' }}>{translate('Lactation length if conception in this cycle')}</Typography></Row>
                            <Row className={'mt-1'}>{prescriptionData.LactationLengthIfConceptionInThisCycle ? `\n${prescriptionData.LactationLengthIfConceptionInThisCycle} ${translate('DIM')}` : '\nn.a.'}</Row>
                        </Col>

                        <Col width={'25%'} onClick={() => {
                            navigate('/animal', { state: { eartag: prescriptionData.EarTag, cowNumber: prescriptionData.CowNumber } });
                        }} onMouseEnter={() => { document.body.style.cursor = 'pointer'; }}
                            onMouseLeave={() => { document.body.style.cursor = 'default'; }}>
                            <Row><Typography style={{ fontWeight: 'bold' }}>{translate('Parity')}</Typography></Row>
                            <Row className={'mt-1'}>{prescriptionData.Parity}</Row>
                            <Row className={'mt-3'}><Typography
                                style={{ fontWeight: 'bold' }}>{translate('Predicted average daily production if conception in next cycle')}</Typography></Row>
                            <Row className={'mt-1'}>{prescriptionData.PredictedAverageDailyProductionIfConceptionInNextCycle ? `${prescriptionData.PredictedAverageDailyProductionIfConceptionInNextCycle} ${translate('kg / lactation day')}` : 'n.a.'}</Row>
                        </Col>

                        <Col width={'25%'}>
                            <Row><Typography style={{ fontWeight: 'bold' }}>{translate('Prescription type')}</Typography></Row>
                            <Row style={{ marginTop: '5px' }}>
                                <Span style={{ backgroundColor: VISIO_PRESCRIPTION_COLOR[prescriptionData.InventoryPrescriptionDescription ? prescriptionData.InventoryPrescriptionDescription : ''] }}
                                    className={'disease-badge badge-pill badge-primary'}>
                                    {prescriptionData.InventoryPrescriptionDescription != null ?
                                        translate(prescriptionData.InventoryPrescriptionDescription!) : 'n.a.'}
                                </Span>
                            </Row>
                            <Row className={'mt-3'}>
                                <Typography style={{ fontWeight: 'bold' }}>
                                    {translate('Action')}
                                </Typography>
                            </Row>
                            <Row className={'mt-1'}>
                                <Row style={{ marginTop: '5px' }}>
                                    <Checkbox value={0}
                                        checked={ignoredChecked}
                                        onChange={handleIgnoredChange}
                                    >
                                        <Span>{translate('Ignored')}</Span>
                                    </Checkbox>
                                </Row>
                            </Row>
                        </Col>
                    </Row>
                </CardContent>
            </Card>
        </>
    );
};