import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Table, { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { SCORE_RULE_ID } from "../../../pages/static";

interface TableDataType {
    radarCategory: string;
    scoreName: string;
    ruleId: string;
    shareOfAnimalsWithLowScore: string;
    shareOfActive: string;
    shareOfInactive: string;
    activeAnimals: string;
};

export const DataQualityFromDashboardInvalidAnimalsTable: React.FC<any> = ({ invalidAnimalsTableData, loading }) => {
    const { t: translate } = useTranslation();

    const columns: ColumnsType<TableDataType> = [
        {
            title: translate("Radar Category"),
            dataIndex: 'radarCategory',
            key: 'radarCategory'
        },
        {
            title: translate("Score Name"),
            dataIndex: 'scoreName',
            key: 'scoreName'
        },
        {
            title: translate("Rule Id"),
            dataIndex: 'ruleId',
            key: 'ruleId'
        },
        {
            title: translate("Share of animals with low score"),
            dataIndex: 'shareOfAnimalsWithLowScore',
            key: 'shareOfAnimalsWithLowScore'
        },
        {
            title: translate("Share of Active"),
            dataIndex: 'shareOfActive',
            key: 'shareOfActive'
        },
        {
            title: translate("Share of Inactive"),
            dataIndex: 'shareOfInactive',
            key: 'shareOfInactive'
        },
        {
            title: translate("Active Animals"),
            dataIndex: 'activeAnimals',
            key: 'activeAnimals',
            width: 400,
            render: (text: string) => <span dangerouslySetInnerHTML={{ __html: text }} />
        }
    ];

    const [tableData, setTableData] = useState<TableDataType[]>([]);

    useEffect(() => {
        if (invalidAnimalsTableData !== undefined) {
            const hyperlinkify = (text: string) => {
                // Update regex to match any eartag-like structure
                // const eartagRegex = /[A-Z]{2} \d+/g;
                const eartagRegex = /\('[A-Z]{2} \d+|\('\d+/g;
                return text.replace(eartagRegex, (match) => `('<a href="/animal?eartag=${encodeURIComponent(match.slice(2))}">${match.slice(2)}</a>`);
            };

            setTableData(
                invalidAnimalsTableData.tables[0].rows.map((value: (string | number)[]) => {
                    return {
                        radarCategory: value[2],
                        scoreName: SCORE_RULE_ID[value[3]],
                        ruleId: value[3],
                        shareOfAnimalsWithLowScore: value[4],
                        shareOfActive: value[5],
                        shareOfInactive: value[6],
                        activeAnimals: hyperlinkify(value[7] as string)
                    }
                })
            );
        }
    }, [invalidAnimalsTableData]);

    return (
        <Table columns={columns} dataSource={tableData} scroll={{ y: 240 }} pagination={false} loading={loading} />
    );
};