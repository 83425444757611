import { ServiceResult } from '@ddw/react-framework/build/src/types';
import { HerdProfile } from '../../typings/types';
import { DDWApplication, parseError, putHeaders, statusHelper, toJSON } from '@ddw/react-components';
import { urlState } from '../../pages/utils';

export const fetchHerdProfiles = (accessToken: string, application: DDWApplication): Promise<ServiceResult<HerdProfile[]>> => {
  const headers = putHeaders(accessToken, application);
  headers.set('sessionId', String(sessionStorage.getItem('sessionId')));
  const url = urlState.url + `herds`;
  return fetch(url, { method: 'GET', headers }).then(statusHelper).then(toJSON).catch(parseError);
};